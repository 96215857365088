export type RestError = {
    statusCode: number;
    data: RestErrorPayload;
};

export type RestErrorPayload = {
    correlationId: string;
    errorCode: string;
    message: string;
    errors: RestErrorSingleErrorEntry[];
};

export type RestErrorSingleErrorEntry = {
    errorCode: string;
    message: string;
    property: string;
};

export const ErrorCodes = {
    EmailInUse: 'email-in-use',
    InvalidCaptcha: 'invalid-captcha',
    ResetLinkExpired: 'reset-link-expired',
    InvalidLoginOrPassword: 'invalid-login-or-password',
    FavouritesAuthenticateError: 'favourites-authenticate-error'
};
