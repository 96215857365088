import React, { ReactNode, useContext } from "react";
import { RouteComponentProps } from "react-router";
import {
  TranslationKeys,
  RouterPaths,
  PaymentStatus,
} from "../../infrastructure/const";
import HyperlinkButton from ".././common/hyperlink-button";
import TranslationsContext from "../../infrastructure/context/translations/translations-context";
import ITranslationsContext from "../../infrastructure/context/translations/translations-context.interface";
import TranslationManager from "../../infrastructure/translation-manager";
import UserContext from "../../infrastructure/context/user/user.context";
import IUserContext from "../../infrastructure/context/user/user.context.interface";
import {
  UpdateCartRequest,
  TransactionResponse,
} from "../../infrastructure/http/modules/cart/cart.models";
import CacheManager from "../../infrastructure/cache/cache-manager";
import Spinner from ".././common/spinner";
import CheckUtils from "../../utilities/check-utils";
import { GetHttpClientInstance } from "../../infrastructure/context/http/http-context-provider";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import RedirectButton from "../common/redirect-button";
import PaymentConfirmationInner from "./common/payment-confirmation";
import OrderConfirmationInner from "./common/order-confirmation";
import PaymentFailedInner from "./common/payment-failed";

type NetsConfirmationProps = {
  transactionId: string;
  correlationId?: string | undefined;
};

type NetsConfirmationState = {
  intervalId: NodeJS.Timeout | null;
  isLoading: boolean;
  orderNumber: string | null;
  paymentId: string | null;
  orderPlaced: boolean;
  status: string;
};

class NetsConfirmation extends React.Component<
  RouteComponentProps<NetsConfirmationProps>,
  NetsConfirmationState
> {
  private transactionId: string;
  private correlationId: string = "";
  private invoideAddressKey: keyof UpdateCartRequest;
  private deliveryAddressKey: keyof UpdateCartRequest;

  constructor(
    props: RouteComponentProps<NetsConfirmationProps>,
    context: IUserContext
  ) {
    super(props, context);
    this.transactionId = this.props.match.params.transactionId;

    if (this.props.match.params.correlationId) {
      this.correlationId = this.props.match.params.correlationId;
    }

    this.invoideAddressKey = "invoiceAddress";
    this.deliveryAddressKey = "deliveryAddress";

    this.state = {
      isLoading: true,
      intervalId: null,
      orderNumber: null,
      paymentId: null,
      orderPlaced: false,
      status: "",
    };
  }

  componentDidMount() {
    this.context.refreshCart();

    // Remove checkout address from cache
    CacheManager.removeItem(this.invoideAddressKey);
    CacheManager.removeItem(this.deliveryAddressKey);

    // Check if order was created
    this.startCheckingForOrderNumber();
    setTimeout(() => {
      this.stopCheckingForOrderNumber();
      this.setState({ isLoading: false });
    }, 15000);
  }

  componentWillUnmount() {
    this.stopCheckingForOrderNumber();
  }

  getOrderNumberFromTransaction() {
    GetHttpClientInstance()
      .cart.getTransaction(
        this.transactionId,
        this.context.user.userProfile.userId,
        this.correlationId
      )
      .then((transactionResponse: TransactionResponse) => {
        // Save Payment ID for payment confirmation
        this.setState({ paymentId: transactionResponse.paymentId });

        // If order number was returned - display order confirmation page
        if (
          !CheckUtils.isNullOrEmptyString(transactionResponse.orderNumber) &&
          transactionResponse.orderPlaced
        ) {
          this.stopCheckingForOrderNumber();
          this.setState({
            orderNumber: transactionResponse.orderNumber,
            isLoading: false,
            orderPlaced: transactionResponse.orderPlaced,
            status: transactionResponse.status,
          });
        }
      })
      .catch((error: any) => {
        // Display payment confirmation
        this.stopCheckingForOrderNumber();
        this.setState({ isLoading: false });
      });
  }

  private isTransactionSuccessStatus(): boolean {
    let allowedStatuses: string[] = [
      PaymentStatus.Succeeded,
      PaymentStatus.Pending,
      PaymentStatus.PartialSuccess,
    ];
    return allowedStatuses.includes(this.state.status);
  }

  private isTransactionFailedStatus(): boolean {
    let failedStatuses: string[] = [
      PaymentStatus.Cancelled,
      PaymentStatus.Refused,
      PaymentStatus.Error,
    ];
    return failedStatuses.map((status) => status.toLowerCase()).includes(this.state.status.toLowerCase());
  }

  render(): ReactNode {
    if (this.state.isLoading) {
      return <Spinner show />;
    }

    if (
      this.state.orderNumber &&
      this.state.orderPlaced &&
      this.isTransactionSuccessStatus()
    ) {
      return <OrderConfirmationInner orderNumber={this.state.orderNumber} />;
    }

    if (this.isTransactionFailedStatus())
      return (
        <PaymentFailedInner
          transactionId={this.transactionId}
          paymentId={this.state.paymentId}
          status={this.state.status}
        />
      );

    return (
      <PaymentConfirmationInner
        transactionId={this.transactionId}
        paymentId={this.state.paymentId}
      />
    );
  }

  private startCheckingForOrderNumber() {
    const intervalId = setInterval(() => {
      this.getOrderNumberFromTransaction();
    }, 3000);
    this.setState({ intervalId: intervalId });
  }

  private stopCheckingForOrderNumber() {
    if (this.state.intervalId !== null) {
      clearInterval(this.state.intervalId);
    }
  }
}

NetsConfirmation.contextType = UserContext;

export default withRouter(NetsConfirmation);
