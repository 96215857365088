import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { OrderHistoryDetailsResponse } from '../../../infrastructure/http/modules/orders/orders.models.history';
import { TranslationKeys, RouterPaths } from '../../../infrastructure/const';
import HyperlinkButton from '../../common/hyperlink-button';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import IUserContext from '../../../infrastructure/context/user/user.context.interface';
import OrderHistoryDetailsAddressRow from './details/details-address-row';
import TranslationManager from '../../../infrastructure/translation-manager';
import OrderHistoryDetailsOrderLine from './details/details-order-line';
import OrderHistoryDetailsSummaryRow from './details/details-summary-row';
import { GetHttpClientInstance } from '../../../infrastructure/context/http/http-context-provider';
import Spinner from '../../common/spinner';
import SelectedPaymentMethod from '../../common/order/selected-payment-method';
import SelectedShippingMethod from '../../common/order/selected-shipping-method';
import OrderUtils from '../../../utilities/order-utils';

type Props = {
    orderNumber: string;
    translationsContext: ITranslationsContext;
    userContext: IUserContext;
};

type State = {
    order?: OrderHistoryDetailsResponse;
    isLoaded: boolean;

    redirectToLogin: boolean;
    redirectToCart: boolean;
};

class OrderHistoryDetails extends React.Component<Props, State> {
    constructor(props: Props, private translations: { [key: string]: string }) {
        super(props);

        this.state = {
            isLoaded: false,
            redirectToLogin: false,
            redirectToCart: false
        };

        this.handleReorderClick = this.handleReorderClick.bind(this);
        this.handlePrintClick = this.handlePrintClick.bind(this);
    }

    public componentDidMount(): void {
        this.initializeTranslations();
        this.initializeOrderDetails();
    }

    public render(): ReactNode {
        if (!this.state.isLoaded) {
            return <Spinner show />;
        }

        return (
            <>
                {this.renderRedirect()}
                {this.renderCartRedirect()}
                <div className='account-wr order-info-block'>
                    {this.renderHeader()}
                    {this.renderShippingAddress()}
                    {this.renderBillingAddress()}
                </div>
                <div className='account-wr order-items order-details'>
                    <h3 className='table-caption'>{this.translations.itemsOrdered}</h3>
                    <table className='data-table my-order-block__table' id='my-orders-table' summary={this.translations.itemsOrdered}>
                        <tbody>
                            {this.state.order!.orderLines.map(ol => <OrderHistoryDetailsOrderLine key={ol.itemNumber} orderLine={ol} />)}
                        </tbody>
                        <OrderHistoryDetailsSummaryRow
                            pricingDetails={this.state.order!.pricingDetails}
                            translations={{
                                subtotalExclVat: this.translations.subtotalExclVat,
                                subtotalInclVat: this.translations.subtotalInclVat,
                                shipping: this.translations.shipping,
                                tax: this.translations.tax,
                                total: this.translations.total,
                            }}
                        />
                    </table>
                </div>
            </>
        );
    }

    private renderRedirect() {
        return (
            <>
                {this.state.redirectToLogin && <Redirect to={RouterPaths.Login} />}
            </>
        );
    }

    private renderCartRedirect() {
        return (
            <>
                {this.state.redirectToCart && <Redirect to={RouterPaths.CartPage} />}
            </>
        );
    }

    private renderShippingAddress(): ReactNode {
        return (
            <OrderHistoryDetailsAddressRow
                address={this.state.order!.shippingLocation.address}
                leftHeader={this.translations.shippingAddress}
                rightHeader={this.translations.shippingMethod}
                rightText={() => <SelectedShippingMethod shippingMethod={this.state.order!.shippingLocation.shippingMethod} />}
            />
        );
    }

    private renderBillingAddress(): ReactNode {
        return (
            <OrderHistoryDetailsAddressRow
                address={this.state.order!.invoiceAccount.address}
                leftHeader={this.translations.billingAddress}
                rightHeader={this.translations.paymentMethod}
                rightText={() => <SelectedPaymentMethod paymentMethod={this.state.order!.paymentType} poNumber={this.state.order!.purchaseOrderNumber} />}
            />
        );
    }

    private renderHeader(): ReactNode {
        return (
            <>
                <h1>{this.translations.orderNumber} <span className="order-hash">#</span>{this.state.order!.orderNumber} - {OrderUtils.getOrderStatusTranslation(this.props.translationsContext, this.state.order!.status)}</h1>
                <HyperlinkButton onClick={this.handleReorderClick}>{this.translations.reorder}</HyperlinkButton>
                <span className='separator'> &nbsp; </span>
                <HyperlinkButton onClick={this.handlePrintClick}>{this.translations.printOrder}</HyperlinkButton>
                <p className='order-date clearfix'>{this.translations.orderDate}: {this.state.order!.placementDate}</p>
            </>
        );
    }

    private initializeTranslations(): void {
        const translationManager = new TranslationManager(this.props.translationsContext);

        this.translations = {
            orderNumber: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_ORDERNUMBER),
            reorder: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_REORDER),
            printOrder: translationManager.getTranslation(TranslationKeys.ACCOUNT_PRINTORDER),
            shippingAddress: translationManager.getTranslation(TranslationKeys.SHIPPINGADDRESS),
            shippingMethod: translationManager.getTranslation(TranslationKeys.SHIPPINGMETHOD),
            billingAddress: translationManager.getTranslation(TranslationKeys.INVOICEADDRESS),
            paymentMethod: translationManager.getTranslation(TranslationKeys.PAYMENTMETHOD),
            orderDate: translationManager.getTranslation(TranslationKeys.PRINT_ORDERDATE),
            itemsOrdered: translationManager.getTranslation(TranslationKeys.PRINT_ITEMSORDERED_HEADER),
            subtotalExclVat: translationManager.getTranslation(TranslationKeys.UK.CART_SUBTOTAL_EXCLUDING_VAT),
            subtotalInclVat: translationManager.getTranslation(TranslationKeys.UK.CART_SUBTOTAL_INCLUDING_VAT),
            shipping: translationManager.getTranslation(TranslationKeys.PRINT_SHIPPING),
            tax: translationManager.getTranslation(TranslationKeys.PRINT_VAT),
            total: translationManager.getTranslation(TranslationKeys.PRINT_TOTALPRICE),
        };
    }

    private initializeOrderDetails(): void {
        const { tokenInfo } = this.props.userContext.user;

        if (!tokenInfo) {
            this.props.userContext.clearUser();
            this.setState({ redirectToLogin: true });
            return;
        }

        GetHttpClientInstance()
            .orders
            .getHistoryDetails(this.props.orderNumber, { accessToken: tokenInfo.accessToken })
            .then((orderDetails: OrderHistoryDetailsResponse) => {
                this.setState({ order: orderDetails, isLoaded: true });
            })
            .catch((err: any) => {
                this.props.userContext.clearUser();
                this.setState({ redirectToLogin: true });
            });
    }

    private handleReorderClick(): void {
        GetHttpClientInstance()
            .orders
            .reorder(this.props.orderNumber, this.props.userContext.user.userProfile.userId)
            .then(() => {
                this.props.userContext.refreshCart().then(() => {
                    this.setState({ redirectToCart: true });
                });
            });
    }

    private handlePrintClick(): void {
        window.open(`${RouterPaths.OrderPrint}/${this.state.order!.orderNumber}`);
    }
}

export default OrderHistoryDetails;
