import React from 'react';
import MaterialSpots from './material-spots/material-spots';
import CategoryMenuTiles from './menu/category-menu-tiles';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';
import { FeatureFlagValues } from '../infrastructure/const';
import StaticContentContext from '../infrastructure/context/static-content/static-content.context';
import IStaticContentContext from '../infrastructure/context/static-content/static-content.context.interface';
import LandingPageSplashBanner from './landing-page/banner/splash-banner';
import LandingPageSimpleWelcomeBanner from './landing-page/banner/simple-welcome-banner';
import LandingPageIntroBanner from './landing-page/banner/intro-banner';
import TextImageSpots from './image-text-spots/text-image-spots';
import RecommendedProductsSlider from './product-list/recommended-products-slider';

const LandingPage: React.FC = () => {
  return (
    <div className="col1-layout cms-index-index cms-start">
      <div className="col-main">
        <ConfigurationContext.Consumer>
          {(configuration: IConfigurationContext) => (
            <>
              {
                configuration.getFeatureFlagValue("landingPageType") === FeatureFlagValues.LandingPage.Splash &&
                <LandingPageSplashBanner />
              }
              {
                configuration.getFeatureFlagValue("landingPageType") === FeatureFlagValues.LandingPage.Intro &&
                <>
                  <LandingPageIntroBanner />
                </>
              }
              {
                configuration.getFeatureFlagValue("landingPageType") === FeatureFlagValues.LandingPage.Tiles &&
                <>
                  <LandingPageSimpleWelcomeBanner />
                  <CategoryMenuTiles />
                </>
              }
              {
                configuration.isFeatureFlagEnabled("showRecommendedProducts") &&
                <RecommendedProductsSlider productIds={configuration.recommendedProducts} displaySeeAllButton={true} />
              }
            </>
          )}
        </ConfigurationContext.Consumer>
        <TextImageSpots />
        <StaticContentContext.Consumer>
          {(staticContent: IStaticContentContext) => (
            <>

              <MaterialSpots items={staticContent.materialSpots} />
            </>
          )}
        </StaticContentContext.Consumer>
      </div>
    </div>// MiddleComponent
  );
};

export default LandingPage;
