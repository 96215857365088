import { ModuleBase } from "../../common/module.base";
import { IConfigurationModule } from "./configuration.interface";
import { IHttp } from "../../http.interface";
import { IResponse } from "../../http-response.interface";
import { FrontendConfiguration } from "./configuration.models";

export default class ConfigurationModule extends ModuleBase implements IConfigurationModule {
    constructor(private http: IHttp) {
        super('Configuration');
    }

    public getFrontEndconfiguration(): Promise<FrontendConfiguration> {
        return new Promise<FrontendConfiguration>((resolve, reject) => {
            this.http
                .get('/api/v1.0/configuration/frontend')
                .then((response: IResponse<any>) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                })
                .catch(() => reject());
        });
    }

    public getRecommendedProductsConfiguration(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            this.http
                .get('/api/v1.0/configuration/recommended-products')
                .then((response: IResponse<any>) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                })
                .catch(() => reject());
        });
    }

}