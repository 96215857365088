import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { RouterPaths, TranslationKeys } from "../../../infrastructure/const";
import TranslationsContext from "../../../infrastructure/context/translations/translations-context";
import ITranslationsContext from "../../../infrastructure/context/translations/translations-context.interface";
import TranslationManager from "../../../infrastructure/translation-manager";
import RedirectButton from "../../common/redirect-button";

const PaymentFailedInner = (props: {
    paymentId: string | null;
    transactionId: string;
    status: string;
  }): JSX.Element => {
    const translationsContext =
      useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);
  
    const translations = {
      header: translationManager.getTranslation(
        TranslationKeys.PAYMENT_FAILEDHEADER
      ),
      description: translationManager.getTranslation(
        TranslationKeys.PAYMENT_FAILEDDESCRIPTION
      ),
      paymentIdReference: translationManager.getTranslation(
        TranslationKeys.PAYMENT_FAILED_PAYMENTID
      ),
      contactCustomerService: translationManager.getTranslation(
        TranslationKeys.PAYMENT_CONTACTCUSTOMERSERVICE
      ),
      clickHere: translationManager.getTranslation(TranslationKeys.CLICKHERE),
      continueShopping: translationManager.getTranslation(
        TranslationKeys.TO_CONTINUE_SHOPPING
      ),
      tryAgain: translationManager.getTranslation(
        TranslationKeys.ORDERFAILED_TRYAGAIN
      ),
    };
  
    return (
      <div className="col-main">
        <div className="checkout-failed">
          <div className="wr-failed">
            <div className="wrapper">
              <h1>{translations.header}</h1>
              <p>{translations.description}</p>
              <p>{translations.contactCustomerService}</p>
              <div className="order-confirmation-wrapper">
                <p className="order-failed">
                  {translations.paymentIdReference}:{" "}
                  <span className="order-number">
                    {props.paymentId || props.transactionId}
                  </span>
                  .
                </p>
                <p>
                  <Link to={RouterPaths.LandingPage}>
                    {translations.clickHere}{" "}
                  </Link>
                  {translations.continueShopping}
                </p>
              </div>
              <RedirectButton
                buttonLabel={translations.tryAgain}
                redirectUrl={RouterPaths.CheckoutPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default PaymentFailedInner;