import { AxiosError } from "axios";
import { ModuleBase } from "../../common/module.base";
import { ISubscriptionsModule } from "./subscriptions.interface";
import { IHttp } from "../../http.interface";
import { IResponse } from "../../http-response.interface";
import CheckUtils from "../../../../utilities/check-utils";
import { CreateSubscriptionRequest } from "./subscriptions.models";
import { ErrorCodes } from "../../../const";

export class SubscriptionsModule extends ModuleBase implements ISubscriptionsModule {
    constructor(private http: IHttp) {
        super('Newsletter');
    }

    public async subscribe(request: CreateSubscriptionRequest): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.http.post<string>(`/api/v1.0/subscriptions`, request)
                .then((response: IResponse<string>) => {
                    resolve(response.data);
                })
                .catch((err: AxiosError) => {
                    reject(err.response);
                });
        });

    }

    public async get(type: string, accessToken: string): Promise<string> {
        if (CheckUtils.isNullOrEmptyString(accessToken)) {
            return new Promise<string>((resolve, reject) => {
                reject(ErrorCodes.Unauthorized);
            })
        }

        this.http.setToken(accessToken as string);
        return new Promise<string>((resolve, reject) => {
            this.http.get<string>(`/api/v1.0/user-subscriptions?type=${type}`)
                .then((response: IResponse<string>) => {
                    resolve(response.data);
                })
                .catch((err: AxiosError) => {
                    reject(err.response);
                });
        });
    }

    public async delete(id: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.http.delete(`/api/v1.0/subscriptions/${id}`)
            .then(() => resolve())
            .catch((err: AxiosError) => {
                reject(err.response);
            });
        });
    }
}