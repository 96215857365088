import * as React from 'react';
import IStaticContentContext from './static-content.context.interface';

export const defaultContext: IStaticContentContext = {
  materialSpots: [],
  footerContainer: {
    columns: [],
    links: [],
    newsletter: false,
  },
  categoryHeaders: [],
  customerService: {
    menu: [],
    pages: [],
  },
  introBanner: {
    title: "",
    description: "",
    imageUrl: "",
    buttons: [],
    bottomDisclaimer: "",
    nestedComponentName: ""
  },
  introBannerList: [],
  imageTextSpots: [],
  sellingPointsBar: {
    text: "",
    sellingPoints: [],
    phoneNumber: "",
    contactHours: "",
  },
  seo: {
    faviconIcon: "/images/favicon-empty.ico",
    defaultTitle: "Oticon webshop",
    defaultDescription: "",
    defaultKeywords: "",
    pages: [],
    imageUrl: "",
  },
  partners: {
    text: "",
    images: [],
  },
};

const StaticContentContext = React.createContext<IStaticContentContext>(defaultContext);
export default StaticContentContext;