import * as React from 'react';
import PageHeaderThemeContext from './pageheader-theme.context';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PageHeaderThemes, RouterPaths, AccountRoutersPaths } from '../../const';

type ProviderState = {
  theme: string;
  isInAccountSection: boolean;
  isHeaderVisible: boolean;
  isMenuVisible: boolean;
  isMiniCartDisabled: boolean;
};

class PageHeaderThemeContextProvider extends React.Component<RouteComponentProps, ProviderState> {
  private lightThemeRoutes: string[] = [RouterPaths.CheckoutPage, RouterPaths.CartPage];
  private miniCartDisabledRoutes: string[] = [RouterPaths.CheckoutPage, RouterPaths.CartPage, RouterPaths.PaymentsPage];
  private noMenuRoutes: string[] = [RouterPaths.CheckoutPage, RouterPaths.CartPage, RouterPaths.DetailsPage, RouterPaths.PaymentsPage];
  private noHeaderRoutes = [RouterPaths.OrderPrint];


  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      theme: PageHeaderThemes.Dark,
      isInAccountSection: false,
      isHeaderVisible: true,
      isMenuVisible: true,
      isMiniCartDisabled: false
    };
  }

  componentDidMount() {
    this.setTheme();
    this.setisInAccountSection();
    this.setHeaderVisibility();
    this.setMenuVisibility();
    this.setMiniCartVisibility();
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setTheme();
      this.setisInAccountSection();
      this.setHeaderVisibility();
      this.setMenuVisibility();
      this.setMiniCartVisibility();
    }
  }

  setTheme() {
    const pathName = this.props.location.pathname;
    if (this.lightThemeRoutes.some(x => pathName.includes(x))) {
      this.setState({
        theme: PageHeaderThemes.Light
      });
    } else {
      this.setState({
        theme: PageHeaderThemes.Dark
      });
    }
  }

  setisInAccountSection() {
    if (AccountRoutersPaths.some(route => this.props.location.pathname.includes(route))) {
      this.setState({ isInAccountSection: true });
    } else {
      this.setState({ isInAccountSection: false });
    }
  }

  setHeaderVisibility() {
    if (this.noHeaderRoutes.some(route => this.props.location.pathname.includes(route))) {
      this.setState({ isHeaderVisible: false });
    } else {
      this.setState({ isHeaderVisible: true });
    }
  }

  setMenuVisibility() {
    if (this.noMenuRoutes.some(route => this.props.location.pathname.includes(route))) {
      this.setState({ isMenuVisible: false });
    } else {
      this.setState({ isMenuVisible: true });
    }
  }

  setMiniCartVisibility() {
    if (this.miniCartDisabledRoutes.some(route => this.props.location.pathname.includes(route))) {
      this.setState({ isMiniCartDisabled: true });
    } else {
      this.setState({ isMiniCartDisabled: false });
    }
  }

  render() {
    return (
      <PageHeaderThemeContext.Provider
        value={{
          theme: this.state.theme,
          isInAccountSection: this.state.isInAccountSection,
          isHeaderVisible: this.state.isHeaderVisible,
          isMenuVisible: this.state.isMenuVisible,
          isMiniCartDisabled: this.state.isMiniCartDisabled
        }}
      >
        {this.props.children}
      </PageHeaderThemeContext.Provider>
    );
  }
}

export default withRouter(PageHeaderThemeContextProvider);