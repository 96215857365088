import React from 'react';
import { Link } from 'react-router-dom';
import CartProductList from './cart/cart-product-list';
import CartSummary from './cart/cart-summary';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import TranslationManager from '../infrastructure/translation-manager';
import { TranslationKeys, RouterPaths } from '../infrastructure/const';
import UserContext from '../infrastructure/context/user/user.context';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import MessageBanner from './common/message-banner';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';
import RecommendedProductsSlider from './product-list/recommended-products-slider';

type CartPageProps = {

};

type CartPageState = {
    message: string;
};

export default class CartPage extends React.Component<CartPageProps, CartPageState> {
    constructor(props: CartPageProps) {
        super(props);
        this.state = {
            message: ""
        };
    }

    renderEmptyCartMessage(): JSX.Element {
        return (
            <div className="cart-empty-block">
                <div className="cart-empty">
                    <div className="wrapper">
                        <TranslationsContext.Consumer>
                            {(translationsContext) => (
                                <>
                                    <h1>{new TranslationManager(translationsContext).getTranslation(TranslationKeys.CART_EMPTY)}</h1>
                                    <p>{new TranslationManager(translationsContext).getTranslation(TranslationKeys.CART_NOITEMSINCART)}</p>
                                    <p><Link to={RouterPaths.LandingPage}>{new TranslationManager(translationsContext).getTranslation(TranslationKeys.CLICKHERE)}</Link>  {new TranslationManager(translationsContext).getTranslation(TranslationKeys.TO_CONTINUE_SHOPPING)}</p>
                                </>
                            )}
                        </TranslationsContext.Consumer>
                    </div>
                </div>
            </div>
        );
    }

    renderCart(userContext: IUserContext, configurationContext: IConfigurationContext): JSX.Element {
        return (
            <TranslationsContext.Consumer>
                {(translationsContext) => (
                    <div className="cart-block">

                        <div className="wrapper clearfix">

                            <h1 className="page-big-title">
                                {new TranslationManager(translationsContext).getTranslation(
                                    TranslationKeys.CART_HEADER
                                )}
                            </h1>

                            <MessageBanner message={this.state.message} />
                            <CartProductList
                                products={userContext.cart.products}
                                context={userContext}
                            />
                            <CartSummary
                                userContext={userContext}
                                configurationContext={configurationContext}
                                updateMessage={(message: string) => {
                                    this.setState({ message });
                                }}
                            />
                        </div>

                        <RecommendedProductsSlider
                            productIds={configurationContext.cartRecommendedProducts}
                            displaySeeAllButton={false}
                            headerTitle={new TranslationManager(translationsContext).getTranslation(TranslationKeys.CART_RECOMMENDEDPRODUCTS_HEADER)}
                        />


                    </div>
                )}
            </TranslationsContext.Consumer>
        );
    }

    render(): JSX.Element {
        return (
            <div className="checkout-cart-index">
                <div className="col1-layout">
                    <div className="col-main">
                        <ConfigurationContext.Consumer>
                            {(configurationContext: IConfigurationContext) =>
                                <UserContext.Consumer>
                                    {(userContext) => (
                                        userContext.cart.total > 0 ?
                                            (this.renderCart(userContext, configurationContext)) : (this.renderEmptyCartMessage())
                                    )}
                                </UserContext.Consumer>
                            }
                        </ConfigurationContext.Consumer>
                    </div>
                </div>
            </div>
        );
    }
}