import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PageContainer, MenuItem, StaticPage } from '../infrastructure/http/modules/customer-service/customer-service.models';
import HyperlinkButton from '../components/common/hyperlink-button';
import ContactFormPage from '../components/customer-service/contact-form';
import Spinner from './common/spinner';
import StaticContentContext from '../infrastructure/context/static-content/static-content.context';
import CheckUtils from '../utilities/check-utils';

interface CustomerServicePageState {
    __html: string;
    container: PageContainer;
    url: string;
}

interface CustomerServicePageProps {
    url: string;
}

interface CustomerServicePageInnerProps extends CustomerServicePageProps {
    container: PageContainer;
    pathName: string;
}

class CustomerServicePage extends React.Component<RouteComponentProps<CustomerServicePageProps>> {
    render(): JSX.Element | null {
        return (
            <StaticContentContext.Consumer>
                {(context) => (
                    <CustomerServicePageInner pathName={this.props.location.pathname} container={context.customerService} url={this.props.match.params.url} />
                )}
            </StaticContentContext.Consumer>
        );
    }
}

class CustomerServicePageInner extends React.Component<CustomerServicePageInnerProps, CustomerServicePageState> {
    constructor(props: CustomerServicePageInnerProps) {
        super(props);
        this.state = {
            __html: "",
            url: ""
        } as CustomerServicePageState;
    }

    componentDidMount(): void {
        this.getPageContainer(this.props);
    }

    componentWillReceiveProps(nextProps: CustomerServicePageInnerProps) {
        if (this.props.pathName !== nextProps.pathName) {
            this.getPageContainer(nextProps);
        } else if (JSON.stringify(nextProps.container) !== JSON.stringify(this.props.container)) {
            this.getPageContainer(nextProps);
        }
    }

    getPageContainer(props: CustomerServicePageInnerProps): void {
        if (props.container.menu.length === 0) {
            return;
        }

        if (!this.state.container) {
            const pageContainer: PageContainer = props.container;
            if (CheckUtils.isNullObject(pageContainer)) {
                return;
            }
            let html = this.matchUrlOrDefault(pageContainer.pages, props.url);
            this.setState({
                container: pageContainer,
                __html: html,
                url: props.url
            });
            return;
        }

        let html = this.matchUrlOrDefault(this.state.container.pages, props.url);
        this.setState({
            __html: html,
            url: props.url
        });

    }

    render(): JSX.Element | null {

        if (this.state.container === null) {
            return <Spinner show={true} />;
        }

        return (
            <div className="col2-left-layout">
                <div className="wrapper">
                    {this.renderMenu()}
                    {this.renderContentPage()}
                </div>
            </div>
        );
    }
    renderMenu(): JSX.Element {
        return (
            <div className="sidebar">
                <div className="block cms-side">
                    <div className="white-wr">
                        <ul>
                            {this.state.container && this.state.container.menu.map(item => this.renderMenuItem(item))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    renderContentPage(): JSX.Element {
        if (this.state.url === 'contact-us') {
            return (
                <div className="col-main">
                    <ContactFormPage />
                </div>
            );
        } else {
            return (
                <div className="col-main">
                    {ReactHtmlParser(this.state.__html)}
                </div>
            );
        }
    }

    renderMenuItem(item: MenuItem): JSX.Element {
        return (
            <li key={item.route}>
                <HyperlinkButton
                    onClick={() => this.handleClick(item.route)}
                    id={item.route}
                    title={item.title} className={item.route === this.state.url ? "active" : ""} >
                    {item.title}
                </HyperlinkButton>
            </li>
        );
    }

    handleClick(route: string) {

        let html = this.matchUrlOrDefault(this.state.container.pages, route);

        this.setState({
            __html: html,
            url: route
        });
        window.history.pushState("", "", route);
    }

    matchUrlOrDefault(pages: StaticPage[], url: string) {
        if (pages.find(x => x.route === url)) {
            return pages.find(x => x.route === url)!.contentHtml;
        } else {
            return "";
        }
    }
}

CustomerServicePage.contextType = StaticContentContext;
export default withRouter(CustomerServicePage);
