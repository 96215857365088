import React from "react";
import useScript from "../infrastructure/use-script";
import CheckUtils from "../utilities/check-utils";
import ConfigurationContext from "../infrastructure/context/configuration/configuration.context";
import IConfigurationContext from "../infrastructure/context/configuration/configuration.context.interface";

type CookieBannerData = {
  "gcm-version": string;
  culture: string;
};

type Data =
  | Pick<CookieBannerData, Exclude<keyof CookieBannerData, "culture">>
  | CookieBannerData;

const CookieBannerExternal: React.FC = () => {
  const configurationContext =
    React.useContext<IConfigurationContext>(ConfigurationContext);

  let data: Data = {
    "gcm-version": "2.0",
  };

  if (
    !CheckUtils.isNullOrEmptyString(configurationContext.cookieBannerCulture)
  ) {
    data = {
      ...data,
      culture: configurationContext.cookieBannerCulture,
    };
  }

  useScript(
    "https://policy.app.cookieinformation.com/uc.js",
    "CookieConsent",
    data
  );

  return null;
};

export default CookieBannerExternal;
