import * as React from 'react';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import { withRouter, RouteComponentProps } from 'react-router';
import IFooterContext from '../../infrastructure/context/footer/footer.context.interface';
import FooterContext from '../../infrastructure/context/footer/footer.context';
import { TranslationKeys } from '../../infrastructure/const';
import { Link } from 'react-router-dom';
import StaticContentContext from '../../infrastructure/context/static-content/static-content.context';
import CheckUtils from '../../utilities/check-utils';
import TranslationManager from '../../infrastructure/translation-manager';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import NewsletterRegisterEmail from '../newsletter/newsletter-register-email';
// import NewsletterRegisterEmail from '../newsletter/newsletter-register-email';

class Footer extends React.Component<RouteComponentProps> {
    private translations: { [id: string]: string };

    constructor(props: RouteComponentProps, context: ITranslationsContext) {
        super(props, context);
        const translationManager = new TranslationManager(context);

        this.translations = {
            regulations: translationManager.getTranslation(TranslationKeys.FOOTER_REGULATIONS),
            termsAndService: translationManager.getTranslation(TranslationKeys.FOOTER_TERMSANDSERVICE),
            cookieRegulation: translationManager.getTranslation(TranslationKeys.FOOTER_COOKIEREGULATION),
            newsletterHeader: translationManager.getTranslation(TranslationKeys.NEWSLETTER_HEADER_TITLE)
        };
    }
    render(): JSX.Element | null {
        return (
            <FooterContext.Consumer>
                {(context: IFooterContext) => (
                    this.renderFooter(context)
                )}
            </FooterContext.Consumer>
        );
    }

    private renderFooter(context: IFooterContext) {
        if (!context.isVisible) {
            return null;
        }

        return (
            <StaticContentContext.Consumer>
                {(staticContent) => (
                    <footer id="footer" className="footer-block">
                        <div className="footer-block__top">
                            <div className="wrapper">
                                {!CheckUtils.isNullOrEmptyArray(staticContent.footerContainer.columns) &&
                                    <div className={"wrap_col-4"}>
                                        {staticContent.footerContainer.columns.map((column) => (
                                            <div className={"col-4"} key={column.title}>
                                                <h4>{column.title}</h4>
                                                <p>{column.subtitle}</p>
                                                <ul>
                                                    {column.links.map((link) => (
                                                        <li key={link.title}>
                                                            {CheckUtils.isNullOrEmptyString(link.url) ? (
                                                                <span>{link.title}</span>
                                                            ): (
                                                                <a href={link.url} title={link.title} target={link.url.includes("http") ? "_blank" : "_self"}>{link.title}</a>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                        {staticContent.footerContainer.newsletter &&
                                            <div className={"col-4"}>
                                                <h4>{this.translations.newsletterHeader}</h4>
                                                <NewsletterRegisterEmail />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="footer-block__bottom">
                            <div className="wrapper">
                                {!CheckUtils.isNullOrEmptyString(this.translations.regulations) &&
                                    <span className="copyright">
                                        &copy; <span>{this.translations.regulations}</span>
                                    </span>
                                }
                                <ul className="bottomlinks">
                                    {staticContent.footerContainer.links.map((link) => (
                                        <li key={link.title}>
                                            <a href={link.url} title={link.title} target={link.url.includes("http") ? "_blank" : "_self"}>{link.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </footer>
                )}
            </StaticContentContext.Consumer>
        );
    }
}

Footer.contextType = TranslationsContext;
export default withRouter(Footer);