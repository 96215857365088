import React from 'react';
import { OrderHistoryOrderLine } from '../../../../infrastructure/http/modules/orders/orders.models.history';
import CheckUtils from '../../../../utilities/check-utils';

type Props = {
    orderLine: OrderHistoryOrderLine;
};

const OrderHistoryDetailsOrderLine: React.FC<Props> = (props: Props) => {
    let name = props.orderLine.name;
    if (CheckUtils.isNullOrEmptyString(name)) {
        name = props.orderLine.description;
    }

    return (
        <tr className='border' key={props.orderLine.itemNumber}>
            <td>
                <h3 className='product-name'>{name}</h3>
            </td>
            <td>{props.orderLine.itemNumber}</td>
            <td className='a-right'>
                <span className='price-incl-tax'>
                    <span className='cart-price'>
                        <span className='price'>{props.orderLine.unitPriceStr}</span>
                    </span>
                </span>
            </td>
            <td className='a-right'>
                <span className='nobr'>
                    Ordered: <strong>{props.orderLine.quantity}</strong><br />
                    {/* todo: add translation ACCOUNT_ORDERHISTORY_QUANTITYORDERED */}
                </span>
            </td>
            <td className='a-right'>
                <span className='price-incl-tax'>
                    <span className='cart-price'>
                        <span className='price'>{props.orderLine.totalStr}</span>
                    </span>
                </span>
            </td>
        </tr>
    );
};

export default OrderHistoryDetailsOrderLine;
