import * as React from "react";
import { GetHttpClientInstance } from "../http/http-context-provider";
import {
  MaterialSpot,
  ProductCategoryHeader,
  StaticContentContainer,
  FooterContainer,
  TextImageSpot,
  SellingPointsBar,
  Seo,
  Partners,
} from "../../http/modules/static-content/static-content.models";
import IStaticContentContext from "./static-content.context.interface";
import StaticContentContext, { defaultContext } from "./static-content.context";
import Spinner from "../../../components/common/spinner";
import {
  IntroBanner,
  PageContainer,
} from "../../http/modules/customer-service/customer-service.models";

type ProviderState = {
  materialSpots: MaterialSpot[];
  footerContainer: FooterContainer;
  categoryHeaders: ProductCategoryHeader[];
  customerService: PageContainer;
  introBanner: IntroBanner;
  introBannerList: IntroBanner[];
  imageTextSpots: TextImageSpot[];
  sellingPointsBar: SellingPointsBar;
  seo: Seo;
  partners: Partners;
};

class StaticContentProvider extends React.Component<{}, ProviderState> {
  constructor(props: any) {
    super(props);

    this.state = defaultContext;

    GetHttpClientInstance()
      .staticContent.getAll()
      .then((result: StaticContentContainer) => {
        this.setState({
          materialSpots: result.materials,
          footerContainer: result.footer,
          categoryHeaders: result.categories,
          introBanner: result.introBanner,
          imageTextSpots: result.imageTextSpots,
          sellingPointsBar: result.sellingPointsBar,
          seo: result.seo,
          partners: result.partners,
          introBannerList: result.introBannerList,
        });
      });
    GetHttpClientInstance()
      .customerService.get()
      .then((result) => {
        this.setState({ customerService: result });
      });
  }

  render() {
    if (this.state.materialSpots === null) {
      return <Spinner show={true} default />;
    }

    const contextValue: IStaticContentContext = {
      materialSpots: this.state.materialSpots,
      footerContainer: this.state.footerContainer,
      categoryHeaders: this.state.categoryHeaders,
      customerService: this.state.customerService,
      introBanner: this.state.introBanner,
      introBannerList: this.state.introBannerList,
      imageTextSpots: this.state.imageTextSpots,
      sellingPointsBar: this.state.sellingPointsBar,
      seo: this.state.seo,
      partners: this.state.partners,
    };

    return (
      <StaticContentContext.Provider value={contextValue}>
        {this.props.children}
      </StaticContentContext.Provider>
    );
  }
}

export default StaticContentProvider;
