import * as React from 'react';
import CheckUtils from '../../utilities/check-utils';
import { useContext } from 'react';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../infrastructure/translation-manager';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import { CustomPropertyKeys, CustomPropertyTranslations } from '../../infrastructure/const';

type DiscountsSummaryProps = {
  discounts: { [id: string]: string }
};

const DiscountsSummary = (props: DiscountsSummaryProps) => {
  const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
  const translationManager: TranslationManager = new TranslationManager(translationsContext);

  const getDiscountTranslation = (key: string) : string => {
    const translationKey = CustomPropertyTranslations[key as keyof typeof CustomPropertyTranslations];
    return translationManager.getTranslation(translationKey);
  };

  if (CheckUtils.isNullObject(props.discounts)) {
    return null;
  }

  return (
    <>
      {Object.keys(props.discounts).filter(x => x !== CustomPropertyKeys.DK.EANNumber).map((key, i) =>
        <tr key={`discount-${i}`}>
          <td className="a-right">{getDiscountTranslation(key)}</td>
          <td className="a-right"><span className="price">-{props.discounts[key]}</span></td>
        </tr>
      )}
    </>
  );
};

export default DiscountsSummary;