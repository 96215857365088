import React, { useContext, useRef, useState } from 'react';
import { TranslationKeys } from '../../../infrastructure/const';
import HyperlinkButton from '../../common/hyperlink-button';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import IUserContext, { IUserProfile } from '../../../infrastructure/context/user/user.context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import { GetHttpClientInstance } from '../../../infrastructure/context/http/http-context-provider';
import { LoginResponse } from '../../../infrastructure/http/modules/auth/auth.models';
import { ErrorCodes, RestError } from '../../../infrastructure/http/modules/errors';
import ErrorMessages from '../../common/error-messages';
import LoginForm from '../../common/auth/login-form';
import UserContext from '../../../infrastructure/context/user/user.context';
import CreateAccountForm from '../../auth/create-account-form';
import { CreateUserAccountRequest } from '../../../infrastructure/http/modules/account/account.models';
import ErrorCodesExtractor from '../../../utilities/error-codes-extractor';

type KlarnaAuthenticationProps = {
    onUserChanged: (() => void)
};

type KlarnaAuthenticationState = {
    displayLoginForm: boolean;
    errorKeys: string[];
};

type LoginFieldsType = Pick<KlarnaAuthenticationState, Exclude<keyof KlarnaAuthenticationState, "displayLoginForm" | "errorKeys">>;


const KlarnaAuthenticationBox = (props: KlarnaAuthenticationProps): JSX.Element | null => {
    // Initialize
    const loginFormRef = useRef<LoginForm>(null);
    const registerFormRef = useRef<CreateAccountForm>(null);

    const translationContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationContext);

    const userContext = useContext<IUserContext>(UserContext);
    const userProfile: IUserProfile = userContext.user.userProfile;
    const { auth, account } = GetHttpClientInstance();

    const translations: { [id: string]: string } = {
        haveAccount: translationManager.getTranslation(TranslationKeys.LOGIN_FORM_I_HAVE_ACCOUNT),
        doYouHaveAccount: translationManager.getTranslation(TranslationKeys.LOGIN_FORM_DOYOUHAVEACCOUNT),
        clickToLogin: translationManager.getTranslation(TranslationKeys.LOGIN_FORM_CLICKTOLOGIN_LINK),
        logIn: translationManager.getTranslation(TranslationKeys.LOGIN_FORM_BUTTON),
        createAccount: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_HEADER)
    }

    // Set state
    const [displayLoginForm, setDisplayLoginForm] = useState(false);
    const [displayRegisterForm, setDisplayRegisterForm] = useState(false);

    const [errorKeys, setErrorKeys] = useState<Array<string>>([]);

    // If user is already logged in - do not render
    if (userProfile && userProfile.isAuthenticated) {
        return null;
    }

    function handleLoginClicked(email: string, password: string): void {
        if (email && password) {
            auth.login({ email, password })
                .then((loginResponse: LoginResponse) => {
                    userContext.updateUser(loginResponse);
                    setDisplayLoginForm(false);
                    setErrorKeys([]);
                    props.onUserChanged();
                }).catch(() => {
                    setErrorKeys([ErrorCodes.InvalidLoginOrPassword]);
                    if (loginFormRef && loginFormRef.current) {
                        loginFormRef.current.resetPasswordInput();
                    }
                });
        }
    }

    function handleAccountCreation(request: CreateUserAccountRequest): void {
        account
            .createUserAccount(request)
            .then(() => {
                if (request.receiveMarketingMaterials) {
                    userContext.subscribe({ email: request.email, type: 'Newsletter' });
                }
                // Login user
                auth.login({ email: request.email, password: request.password })
                    .then((loginResponse: LoginResponse) => {
                        userContext.updateUser(loginResponse);
                        setDisplayLoginForm(false);
                        setErrorKeys([]);
                        props.onUserChanged();
                    });
            }).catch((error: RestError) => {
                const errorKeys = ErrorCodesExtractor.extract(error);
                if (registerFormRef && registerFormRef.current) {
                    registerFormRef.current.handleCaptchaChange(null);
                }

                setErrorKeys(errorKeys);
            });
    }

    return (
        <div className="authentication-wrapper">
            <HyperlinkButton
                onClick={() => {
                    if (displayRegisterForm) {
                        setDisplayRegisterForm(false);
                        return;
                    }
                    setDisplayLoginForm(!displayLoginForm)
                }}
                id="click-here-to-show-login"
                title={translations.clickToLogin}
                className={displayLoginForm || displayRegisterForm ? 'active' : ''}
                >
                <span>{translations.logIn}</span>
            </HyperlinkButton>
            {displayLoginForm &&
                <div className="login-box">
                    <ErrorMessages errorKeys={errorKeys} />
                    <LoginForm
                        ref={loginFormRef}
                        isCheckoutPage={true}
                        onLoginClicked={(login, password) => handleLoginClicked(login, password)}
                        resetInvalidLoginOrPasswordError={(() => {/* this.resetInvalidLoginOrPasswordError() */ })}
                    />
                    <HyperlinkButton
                        onClick={() => {
                            setDisplayLoginForm(false);
                            setDisplayRegisterForm(true);
                        }}
                        id="click-here-to-hide-login"
                        title={translations.createAccount} >
                        {translations.createAccount}
                    </HyperlinkButton>
                </div>
            }
            {displayRegisterForm &&
                <div className="register-box">
                    <CreateAccountForm
                        ref={registerFormRef}
                        onAccountCreate={(request: CreateUserAccountRequest) => handleAccountCreation(request)}
                        errorKeys={[]}
                        displayPageTitle={false}
                    />
                    {/* <HyperlinkButton
                        onClick={() => {
                            setDisplayLoginForm(true);
                            setDisplayRegisterForm(false);
                        }}
                        id="click-here-to-login"
                        title={translations.clickToLogin} >
                        {translations.doYouHaveAccount} {translations.clickToLogin}
                    </HyperlinkButton> */}
                </div>
            }
        </div >
    );
};

export default KlarnaAuthenticationBox;