import { IHttp } from '../../http.interface';
import { ModuleBase } from '../../common/module.base';
import { IOrdersModule } from './orders.interface';
import { CreateOrderRequest, CreateOrderResponse, Order } from './orders.models';
import { OrderHistoryListResponse, OrderHistoryDetailsResponse } from './orders.models.history';
import { AuthenticatedRequest } from '../account/account.models';
import { IResponse } from '../../http-response.interface';

export class OrdersModule extends ModuleBase implements IOrdersModule {
    constructor(private http: IHttp) {
        super('Orders');
    }

    create(request: CreateOrderRequest): Promise<CreateOrderResponse> {
        return new Promise<CreateOrderResponse>((resolve, reject) => {
            this.http.put<CreateOrderResponse>(`/api/orders`, request)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    get(orderNumber: string, userId: string): Promise<Order> {
        return new Promise<Order>((resolve, reject) => {
            this.http.get<Order>(`/api/orders/${orderNumber}/${userId}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    reorder(orderNumber: string, userId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.http.post<void>(`/api/orders/reorder/${orderNumber}/${userId}`, null)
                .then((result: any) => {
                    resolve(result.data);
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }

    async getHistory(pageNumber: number, pageSize: number, request: AuthenticatedRequest): Promise<OrderHistoryListResponse> {
        return new Promise<OrderHistoryListResponse>((resolve, reject) => {
            this.http.setToken(request.accessToken);

            this.http
                .get(`/api/v1.0/orders/history?pageNumber=${pageNumber}&pageSize=${pageSize}`)
                .then(async (response: IResponse<any>) => resolve(await response.data))
                .catch((err: any) => reject(err));
        });
    }

    async getHistoryDetails(orderNumber: string, request: AuthenticatedRequest): Promise<OrderHistoryDetailsResponse> {
        return new Promise<OrderHistoryDetailsResponse>((resolve, reject) => {
            this.http.setToken(request.accessToken);

            this.http
                .get(`/api/v1.0/orders/history/${orderNumber}`)
                .then(async (response: IResponse<any>) => resolve(await response.data))
                .catch((err: any) => reject(err));
        });
    }
}