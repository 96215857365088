import React from 'react';
import CheckUtils from '../../utilities/check-utils';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import { TranslationKeys } from '../../infrastructure/const';

export class InputProps {
    onValueChange?: ((name: string, value: any, isValid: boolean) => void);
    onBlur?: ((event: React.SyntheticEvent<HTMLInputElement>) => void);

    id?: string;
    value?: string;
    name: string = '';
    label: string = '';
    placeholder?: string;

    type?: string;
    minValue?: number;
    maxValue?: number;
    maxLength?: number;

    isVisible?: boolean;
    hasError?: boolean;
    isRequired?: boolean;

    showValidationText?: boolean;
    helpTextHtml?: string;
    additionalClassName?: string;

    validationMessageClass?: string;
}

type InputState = {
    hasValue: boolean;
    hasError: boolean;
    isVisible: boolean;
    value: string;
};

const defaultProps: InputProps = {
    onValueChange: () => undefined,
    onBlur: () => undefined,
    id: '',
    value: '',
    name: '',
    label: '',
    placeholder: '',
    type: 'text',
    minValue: 0,
    isVisible: true,
    hasError: false,
    isRequired: false,
    showValidationText: false,
    helpTextHtml: '',
    additionalClassName: '',
    validationMessageClass: 'validation-advice'
};

function getValue(value: string | undefined) {
    return (value != null && value !== undefined)
        ? value
        : '';
}

function hasValue(value: string | undefined) {
    return value != null && value !== undefined && value !== "";
}

export default class Input extends React.Component<InputProps, InputState> {

    static defaultProps = defaultProps;
    private inputRef = React.createRef<HTMLInputElement>();

    constructor(props: InputProps) {
        super(props);
        this.state = {
            hasError: false,
            hasValue: hasValue(this.props.value),
            isVisible: this.props.isVisible,
            value: getValue(this.props.value),
        } as InputState;
    }

    componentDidMount() {
        const hasError = !this.isValid(this.state.value);

        if (hasError !== this.state.hasError) {
            this.setState({ hasError });
        }
    }

    componentDidUpdate(nextProps: InputProps) {
        if (nextProps.isVisible !== this.state.isVisible) {
            this.setState({
                isVisible: nextProps.isVisible !== undefined ? nextProps.isVisible : !!defaultProps.isVisible
            })
        }

        if (nextProps.hasError !== this.props.hasError || nextProps.isRequired !== this.props.isRequired) {
            const isValid = this.isValid(this.state.value);

            this.setState({
                hasError: !isValid,
            });
        }

    }

    overrideValueFromParent(newValue: string): void {
        this.setState({ value: getValue(newValue) });
    }

    isValid(newValue?: string): boolean {
        const hasMandatoryError: boolean = (this.props.isRequired === undefined ? false : this.props.isRequired)
            && !hasValue(newValue);

        const hasPropsError: boolean = CheckUtils.isNullObject(this.props.hasError) ? false : this.props.hasError as boolean;

        const hasError: boolean = hasMandatoryError || hasPropsError;

        return !hasError;
    }

    onChange(e: React.SyntheticEvent<HTMLInputElement>) {
        let eventTarget = e.currentTarget;
        let val = e.currentTarget.value;

        if (this.props.maxLength && val.length > this.props.maxLength) {
            return;
        }

        let intValue = Number.parseInt(val);

        if (!isNaN(intValue)) {
            if (this.props.maxValue) {
                if (intValue >= this.props.maxValue) {
                    val = this.props.maxValue.toString();
                }
            }

            if (this.props.minValue) {
                if (intValue < this.props.minValue) {
                    val = this.props.minValue.toString();
                }
            }
        }

        const isValid = this.isValid(val);

        this.setState({
            value: val,
            hasValue: hasValue(val),
            hasError: !isValid
        }, () => {
            eventTarget.value = val;

            if (this.props.onValueChange) {
                e.persist();
                this.props.onValueChange(this.props.name, val, isValid);
            }
        })
    }

    onBlur(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({ hasValue: hasValue(this.state.value) });

        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    onFocus(e: React.SyntheticEvent<HTMLInputElement>) {
        if (!CheckUtils.isNullObject(this.inputRef) && !CheckUtils.isNullObject(this.inputRef.current)) {
            this.inputRef!.current!.select();
        }
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }

        return (
            <>
                <input
                    ref={this.inputRef}
                    type={this.props.type}
                    id={this.props.id === '' ? this.props.name : this.props.id}
                    name={this.props.name}
                    title={this.props.label}
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    min={this.props.minValue}
                    max={this.props.maxValue}
                    maxLength={this.props.maxLength}
                    onChange={(event: React.SyntheticEvent<HTMLInputElement>) => this.onChange(event)}
                    onFocus={(event: React.SyntheticEvent<HTMLInputElement>) => this.onFocus(event)}
                    onBlur={(event: React.SyntheticEvent<HTMLInputElement>) => this.onBlur(event)}
                    className={`input-text span4 ${this.props.isRequired ? "required-entry" : ''} ${this.state.value ? 'has-value' : ''}`}
                />
                <label className="required">
                    {this.props.label}
                    {this.props.isRequired && <span className="required"> *</span>}
                </label>
                {this.state.hasError && this.props.showValidationText &&
                    <div className={this.props.validationMessageClass} id={`advice-required-entry:${this.props.name}`}>
                        <span className="icon-arrow-up"></span>
                        {CheckUtils.isNullOrEmptyString(this.props.helpTextHtml) ? new TranslationManager(this.context).getTranslation(TranslationKeys.REQUIRED) : this.props.helpTextHtml}
                    </div>
                }
            </>
        );
    }
}

Input.contextType = TranslationsContext;
