import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import UserContext from '../../../infrastructure/context/user/user.context';
import IUserContext from '../../../infrastructure/context/user/user.context.interface';
import Spinner from '../../common/spinner';
import { GetHttpClientInstance } from '../../../infrastructure/context/http/http-context-provider';
import InnerHTML from 'dangerously-set-html-content'
import { InitializeKlarnaResponse, Transaction, TransactionResponse } from '../../../infrastructure/http/modules/cart/cart.models';
import PaymentSummary from '../../common/payments/payment-summary';
import KlarnaAuthenticationBox from './klarna-authentication-box';

type KlarnaCheckoutState = {
    isLoading: boolean;
    transaction: Transaction | null;
    __html: string;
};

class KlarnaCheckout extends React.Component<{}, KlarnaCheckoutState> {
    constructor(props: RouteComponentProps<{}>, context: IUserContext) {
        super(props, context);

        this.state = {
            isLoading: true,
            transaction: null,
            __html: ""
        };
    }

    componentDidMount():void {
        this.fetchKlarnaDropIn();
    }

    fetchKlarnaDropIn(): void {
        GetHttpClientInstance().cart.initializeKlarna(this.context.user.userProfile.userId).then((response: InitializeKlarnaResponse) => {
            this.setState(
                {
                    __html: response.dropInHtml,
                    isLoading: false,
                }, () => {
                    GetHttpClientInstance().cart.getTransaction(response.transactionId, this.context.user.userProfile.userId, "").then((transactionResponse: TransactionResponse) => {
                        this.setState({ transaction: transactionResponse });
                    })
                });
        });

    }

    render(): ReactNode {
        if (this.state.isLoading) {
            return <Spinner show />;
        }
        else {

            return (
                <div className="klarna-checkout">
                    <div className="payment-summary">
                        <div className="klarna-wrapper">
                            {/* <CheckoutLoginPopup /> */}
                            <InnerHTML html={this.state.__html} />
                        </div>
                        <div className="order-summary">

                            <KlarnaAuthenticationBox
                                onUserChanged={() => { this.fetchKlarnaDropIn() }}
                            />

                            {this.state.transaction !== null &&
                                <PaymentSummary transaction={this.state.transaction} />
                            }
                        </div>
                    </div>
                </div>
            )
        }

    }
}

KlarnaCheckout.contextType = UserContext;
export default KlarnaCheckout;