import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { RouterPaths, TranslationKeys, PasswordMinLength } from '../../../infrastructure/const';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import CheckUtils from '../../../utilities/check-utils';
import EmailInput from '../email-input';
import PasswordInput from '../password-input';

type Props = {
    onLoginClicked: ((email: string, password: string) => void);
    isCheckoutPage: boolean;
    resetInvalidLoginOrPasswordError: (() => void);
};

type State = {
    email: string;
    password: string;
    emailHasError: boolean;
    passwordHasError: boolean;
};

export default class LoginForm extends React.Component<Props, State> {
    private passwordRef = React.createRef<PasswordInput>();
    public state: State = {
        email: '',
        password: '',
        emailHasError: false,
        passwordHasError: false,
    };
    private translationManager = new TranslationManager(this.context);

    public resetPasswordInput(): void {
        this.setState({ password: '' });
        if (this.passwordRef.current !== undefined && this.passwordRef.current != null) {
            this.passwordRef.current.overrideValueFromParent('');
        }
    }

    public render(): ReactNode {
        const translations = {
            header: this.getTranslation(TranslationKeys.LOGIN_REGISTERED_CUSTOMERS_HEADER),
            description: this.getTranslation(TranslationKeys.LOGIN_REGISTERED_CUSTOMERS_DESCRIPTION),
            email: this.getTranslation(TranslationKeys.LOGIN_FORM_EMAIL),
            password: this.getTranslation(TranslationKeys.LOGIN_FORM_PASSWORD),
            forgotPassword: this.getTranslation(TranslationKeys.LOGIN_FORM_FORGOT_PASSWORD_LINK),
            button: this.getTranslation(TranslationKeys.LOGIN_FORM_BUTTON),
        };

        if (this.props.isCheckoutPage) {
            return (
                <form id="login-form" className="login-form-validate" onSubmit={evt => this.handleSubmit(evt)}>
                    <div id="qc-loginform">
                        <ul>
                            {this.renderEmailInput(translations)}
                            {this.renderCheckoutPagePasswordInput(translations)}
                        </ul>
                        {this.renderForgotPasswordLink(translations, "forgot-password-link")}
                    </div>
                </form>
            );
        }

        return (
            <>
                <h2>{translations.header}</h2>
                <div className='login-block'>
                    <form onSubmit={evt => this.handleSubmit(evt)}>
                        <div className='content'>
                            <p>{translations.description}</p>
                            <ul className='form-list'>
                                {this.renderEmailInput(translations)}
                                {this.renderPasswordInput(translations)}
                            </ul>
                        </div>
                        <div>
                            {this.renderForgotPasswordLink(translations)}
                            {this.renderSubmitButton(translations)}
                        </div>
                    </form>
                </div>
            </>
        );
    }

    private renderForgotPasswordLink(translations: { [key: string]: string }, className: string = "") {
        return (
            <Link className={className} to={RouterPaths.ForgotPassword} title={translations.forgotPassword}>{translations.forgotPassword}</Link>
        );
    }

    private renderSubmitButton(translations: { [key: string]: string }, additionalClass: string = ""): JSX.Element {
        return (
            <button
                type='submit'
                className={`button ${additionalClass}`}
                title={translations.button}
                name='send'
                id='send2'>
                {translations.button}
            </button>
        );
    }

    private renderEmailInput(translations: { [key: string]: string }): JSX.Element {
        return (
            <li className="input-container">
                <EmailInput
                    label={translations.email}
                    name={'email'}
                    isRequired={true}
                    hasError={this.state.emailHasError}
                    showValidationText={this.state.emailHasError}
                    value={this.state.email}
                    onValueChange={(_name: string, value: any, isValid: boolean) => this.handleChange('email', value, isValid)}
                />
            </li>
        );
    }

    private renderPasswordInput(translations: { [key: string]: string }): JSX.Element {
        return (
            <li className="input-container input-container--rev">
                <PasswordInput
                    ref={this.passwordRef}
                    minLength={PasswordMinLength.Login}
                    includeRegexValidation={false}
                    label={translations.password}
                    name={'password'}
                    isRequired={true}
                    hasError={this.state.passwordHasError}
                    showValidationText={this.state.passwordHasError}
                    value={this.state.password}
                    onValueChange={(_name: string, value: any, isValid: boolean) => this.handleChange('password', value, isValid)}
                />
            </li>
        );
    }

    private renderCheckoutPagePasswordInput(translations: { [key: string]: string }): JSX.Element {
        return (
            <li className="input-container input-container--rev">
                <input
                    type="password"
                    className={`input-text required-entry span4 ${this.state.password ? 'has-value' : ''}`}
                    id="login-password"
                    name="password"
                    value={this.state.password}
                    onChange={evt => {
                        const isValid = !CheckUtils.isNullObject(evt.currentTarget) && !CheckUtils.isNullOrEmptyString(evt.currentTarget.value);
                        const value = !CheckUtils.isNullObject(evt.currentTarget) ? evt.currentTarget.value : "";
                        this.handleChange('password', value, isValid);
                    }}
                    required />
                <label>{translations.password}<span className="required"> *</span></label>
                {this.renderSubmitButton(translations, 'btn-white')}
            </li>
        );
    }

    private handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();

        if (this.state.emailHasError || this.state.passwordHasError) {
            return;
        }

        const { email, password } = this.state;

        let anyFieldHasError = false;
        if (CheckUtils.isNullOrEmptyString(email)) {
            this.setState({ emailHasError: true });
            anyFieldHasError = true;
        }

        if (CheckUtils.isNullOrEmptyString(password)) {
            this.setState({ passwordHasError: true });
            anyFieldHasError = true;
        }

        if (anyFieldHasError) {
            return;
        }

        this.props.onLoginClicked(email, password);
    }

    private getTranslation(key: string): string {
        return this.translationManager.getTranslation(key);
    }

    private handleChange<K extends keyof State>(prop: K, value: string, isValid: boolean): void {
        if (prop === 'email') {
            this.setState({ email: value, emailHasError: !isValid });
        }
        if (prop === 'password') {
            this.setState({ password: value, passwordHasError: !isValid });
        }
        this.props.resetInvalidLoginOrPasswordError();
    }
}

LoginForm.contextType = TranslationsContext;
