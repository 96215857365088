import { TextImageSpot, TextImageSpotType } from "../../../infrastructure/http/modules/static-content/static-content.models";
import { TextImageSpotComponent } from "../../../infrastructure/http/modules/static-content/static-content.models";
import getTextImageSpotTypeFunction from "./getSpotTypeFunction";

const groupTextImageSpotsByTypeFunction = function (items: TextImageSpot[]): TextImageSpotComponent[] {
    let components: TextImageSpotComponent[] = [];

    if (!items) {
        return [];
    }

    for (let index = 0; index < items.length; index++) {
        const item = items[index];
        const type = getTextImageSpotTypeFunction(item);

        // First component in the list or additional component should always render as a new component
        if (index === 0 || type === TextImageSpotType.NestedComponent) {
            components.push({
                Type: type,
                Items: [item],
            } as TextImageSpotComponent);
            continue;
        }

        let previousType = components[components.length - 1].Type;

        // Items of the same type would be rendered next to each other as one component
        if (previousType === type) {
            components[components.length - 1].Items.push(item);
            continue;
        }

        // By default render as a separate component
        components.push({
            Type: type,
            Items: [item],
        } as TextImageSpotComponent);
    }

    return components;
}

export default groupTextImageSpotsByTypeFunction;