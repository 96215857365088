import * as React from 'react';
import CheckUtils from '../utilities/check-utils';
import { Product } from '../infrastructure/http/modules/product/product.models';
import ProductList from './product-list/product-list';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import Spinner from './common/spinner';
import Breadcrumb from './common/breadcrumb';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';
import { FeatureFlagValues } from '../infrastructure/const';
import ProductsCategoryBanner from './product-list/product-category-banner/products-category-banner';
import ProductsCategoryLongDescription from './product-list/product-category-banner/products-category-long-description';

class ProductListPageProps {
  routeQuery: string;

  constructor() {
    this.routeQuery = '';
  }
}

class ProductListPageState {
  routeQuery: string;
  productsList: Array<Product>;
  isLoaded: boolean;

  constructor() {
    this.routeQuery = '';
    this.productsList = [];
    this.isLoaded = false;
  }
}

export default class ProductListPage extends React.Component<ProductListPageProps, ProductListPageState> {
  constructor(props: ProductListPageProps) {
    super(props);
    this.state = new ProductListPageState();
  }
  componentDidMount(): void {
    this.getCategoryProducts(this.props.routeQuery);
  }

  getCategoryProducts(routeQuery: string): void {
    if (CheckUtils.isNullObject(routeQuery)) {
      return;
    }

    this.setState({ routeQuery: routeQuery }, () => {
      GetHttpClientInstance().product.browse(this.state.routeQuery).then((result) => {
        if (!CheckUtils.isNullObject(result)) {
          this.setState({ productsList: result.products, isLoaded: true });
        }
      });
    });
  }

  renderBreadcrumb() {
    return (
      <ConfigurationContext.Consumer>
        {(configuration: IConfigurationContext) => (
          configuration.getFeatureFlagValue("breadcrumbType") === FeatureFlagValues.BreadcrumbType.Global ?
            <Breadcrumb />
            : null
        )}
      </ConfigurationContext.Consumer>
    );
  }

  render(): JSX.Element | null {
    if (!this.state.isLoaded) {
      return <Spinner show />;
    }

    return (
      <div className="col1-layout catalog-category-view categorypath-connectline category-connectline">
        <div className="col-main">
          {this.renderBreadcrumb()}
          <ProductsCategoryBanner />
          {(CheckUtils.isNullObject(this.state.productsList) || this.state.productsList.length === 0) ? null :
            <ProductList products={this.state.productsList} />
          }
          <ProductsCategoryLongDescription />
        </div>
      </div>
    );
  }
}