import React from 'react';
import CheckUtils from '../../../utilities/check-utils';
import Spinner from '../../common/spinner';

export type ShippingType = {
    code: string,
    name: string,
    disclaimerTranslation: string,
};

export type ShippingTranslations = {
    header: string
};

type CheckoutShippingProps = {
    availableShippingTypes: ShippingType[],
    selectedShippingTypeCode: string,
    translations: ShippingTranslations,
    onSelectedShippingTypeChange: ((shippingCode: string) => void),
    isLoaded: boolean
};

export default class CheckoutShipping extends React.Component<CheckoutShippingProps> {

    onChange(e: React.SyntheticEvent<HTMLInputElement>) {
        if (CheckUtils.isNullObject(e) || CheckUtils.isNullObject(e.currentTarget) || CheckUtils.isNullObject(e.currentTarget.value)) {
            return;
        }

        if (!this.props.availableShippingTypes.some((x: ShippingType) => x.code === e.currentTarget.value)) {
            return;
        }

        this.props.onSelectedShippingTypeChange(e.currentTarget.value);
    }

    render() {
        return (
            <div id="checkout-step-shipping_method" className="checkout-step">
                <div className="checkout-step__title">
                    <span className="text">{this.props.translations.header}</span>
                </div>
                <div className="content-container">
                    <form id="co-shipping-method-form">
                        <div className="inner-box">
                            {this.props.isLoaded ? (
                                <div className="default-box" id="checkout-shipping-method-load">
                                    <dl id="shipping-method-select" key="shipping-method-select">
                                        {this.props.availableShippingTypes.map((type) => (
                                            <dt key={"shipping-method-" + type.code} className="radio-input-container">
                                                <input
                                                    id={"shipping-method-" + type.code}
                                                    name={"shipping-method"}
                                                    type="radio"
                                                    value={type.code}
                                                    checked={this.props.selectedShippingTypeCode === type.code}
                                                    onChange={(e: React.SyntheticEvent<HTMLInputElement>) => this.onChange(e)} />
                                                <label htmlFor={"shipping-method-" + type.code}>
                                                    <span></span>
                                                    {type.name}</label>
                                                <span>{type.disclaimerTranslation}</span>
                                            </dt>
                                        ))}
                                    </dl>
                                </div>
                            ) : (
                                    <Spinner show />
                                )}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}