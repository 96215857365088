import React, { useContext } from 'react';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import { Product, Variant } from '../../infrastructure/http/modules/product/product.models';
import ProductsEnrichmentUtils from '../../utilities/product-enrichment-utils';
import AddToCartButton from '../product-list/add-to-cart-button';

type Props = {
    product: Product,
    variant: Variant | null,
    quantityStr: string,
    quantity: number,
    onAddedToCart: (() => void),
    onCartRefresh: (() => void),
    onInputValueChange: ((e: React.ChangeEvent<HTMLInputElement>) => void),
    onInputBlur: ((e: React.ChangeEvent<HTMLInputElement>) => void),
    onInputValueButtonChange: ((type: string) => void)
}

const AddToCartForm = (props: Props): JSX.Element | null => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);

    if (configurationContext.isFeatureFlagEnabled("useEnrichedProductData") && !props.product.canAddToCart) {
        return null;
    }

    const hasPrice = ProductsEnrichmentUtils.hasPrice(props.product, props.variant);
    if (!hasPrice) {
        return null;
    }

    const isInStock = ProductsEnrichmentUtils.isInStock(configurationContext, props.product, props.variant);
    const isSelected = !props.product.isConfigurable || props.variant !== null;

    const selectedProductId = isSelected ? getAddToCartProductId() : null;

    return (
        <div className="product-options-bottom">
            <div className="add-to-cart">
                <div className="add-to-cart__wrapper">
                    <button
                        type="button"
                        onClick={() => props.onInputValueButtonChange('Dec')}
                        className="add-to-cart__button add-to-cart__button--dec"
                    ></button>
                    <input type="text" name="qty" id="qty" maxLength={12} value={props.quantityStr} title="Quantity" className="input-text qty" pattern="[0-9]*" onChange={(e) => props.onInputValueChange(e)} onBlur={(e) => props.onInputBlur(e)} />
                    <button
                        type="button"
                        onClick={() => props.onInputValueButtonChange('Inc')}
                        className="add-to-cart__button add-to-cart__button--inc"
                    ></button>
                </div>
                <AddToCartButton
                    disabled={!isInStock || !isSelected}
                    productId={selectedProductId}
                    quantity={props.quantity}
                    classAttr={`button-addToCart ${!isInStock || !isSelected ? 'disabled' : ''}`}
                    onClick={() => props.onAddedToCart()}
                    onCartRefresh={() => props.onCartRefresh()} />
            </div>
        </div>
    );


    function getAddToCartProductId(): string | null {
        if (!props.product.isConfigurable) {
            return props.product.id;
        }

        if (props.variant !== null) {
            return props.variant.id;
        }

        return null;
    }
};

export default AddToCartForm;