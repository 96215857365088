import { AxiosError } from "axios";
import { ModuleBase } from "../../common/module.base";
import { IAccountModule } from "./account.interface";
import { IHttp } from "../../http.interface";
import {
    CreateUserAccountRequest, CreateUserAccountResponse,
    AuthenticatedRequest, UpdateMeRequest,
    AddEditAddressRequest
} from "./account.models";
import { UserProfileResponse } from "../auth/auth.models";
import { IResponse } from "../../http-response.interface";
import { AuthResponseMapper } from "./account.mapper";
import { RestError } from "../errors";

export class AccountModule extends ModuleBase implements IAccountModule {
    constructor(private http: IHttp) {
        super('Account');
    }

    public async createUserAccount(request: CreateUserAccountRequest): Promise<CreateUserAccountResponse> {
        return new Promise<CreateUserAccountResponse>((resolve, reject) => {
            this.http.post('/api/v1.0/create-me', request)
                .then((response: IResponse<any>) => {
                    const jsonResponse = response.data;

                    resolve({
                        id: jsonResponse.id,
                        firstName: jsonResponse.firstName,
                        lastName: jsonResponse.lastName,
                        middleName: jsonResponse.middleName,
                        email: jsonResponse.email,
                        websiteId: jsonResponse.websiteId,
                        addressBook: [],
                    });
                })
                .catch((err: AxiosError) => {
                    reject(err.response);
                });
        });
    }

    public async updateMe(request: UpdateMeRequest, authRequest: AuthenticatedRequest): Promise<UserProfileResponse> {
        return new Promise<UserProfileResponse>((resolve, reject) => {
            this.http.setToken(authRequest.accessToken);

            this.http
                .post('/api/v1.0/me', request)
                .then((response: IResponse<any>) => {
                    const jsonResponse = response.data;

                    const userProfileResponse = AuthResponseMapper.mapUserProfileResponse(jsonResponse);

                    resolve(userProfileResponse);
                })
                .catch((err: AxiosError) => {
                    const restErr = { statusCode: err.response!.status, data: err.response!.data } as RestError;
                    reject(restErr);
                });
        });
    }

    public async addAddress(request: AddEditAddressRequest, authRequest: AuthenticatedRequest): Promise<UserProfileResponse> {

        return new Promise<UserProfileResponse>((resolve, reject) => {
            this.http.setToken(authRequest.accessToken);

            this.http
                .post('/api/v1.0/me/address-book', request)
                .then((response: IResponse<any>) => {
                    const jsonResponse = response.data;

                    const userProfileResponse = AuthResponseMapper.mapUserProfileResponse(jsonResponse);

                    resolve(userProfileResponse);
                })
                .catch((err: AxiosError) => {
                    const restErr = { statusCode: err.response!.status };
                    reject(restErr);
                });
        });
    }

    public async editAddress(addressId: string, request: AddEditAddressRequest, authRequest: AuthenticatedRequest): Promise<UserProfileResponse> {
        return new Promise<UserProfileResponse>((resolve, reject) => {
            this.http.setToken(authRequest.accessToken);

            this.http
                .post(`/api/v1.0/me/address-book/${addressId}`, request)
                .then((response: IResponse<any>) => {
                    const jsonResponse = response.data;

                    const userProfileResponse = AuthResponseMapper.mapUserProfileResponse(jsonResponse);
                    resolve(userProfileResponse);
                })
                .catch((err: AxiosError) => {
                    const restErr = { statusCode: err.response!.status };
                    reject(restErr);
                });
        });
    }

    public async deleteAddress(addressId: string, authRequest: AuthenticatedRequest): Promise<UserProfileResponse> {
        return new Promise<UserProfileResponse>((resolve, reject) => {
            this.http.setToken(authRequest.accessToken);

            this.http
                .delete(`/api/v1.0/me/address-book/${addressId}`)
                .then((response: IResponse<any>) => {
                    const jsonResponse = response.data;
                    const userProfileResponse = AuthResponseMapper.mapUserProfileResponse(jsonResponse);
                    resolve(userProfileResponse);
                })
                .catch((err: AxiosError) => {
                    const restErr = { statusCode: err.response!.status };
                    reject(restErr);
                });
        });
    }

    public async addFavourite(itemNumber: string, authRequest: AuthenticatedRequest): Promise<UserProfileResponse> {

        return new Promise<UserProfileResponse>((resolve, reject) => {
            this.http.setToken(authRequest.accessToken);

            this.http
                .post(`/api/v1.0/me/favourites/${itemNumber}`, null)
                .then((response: IResponse<any>) => {
                    const jsonResponse = response.data;

                    const userProfileResponse = AuthResponseMapper.mapUserProfileResponse(jsonResponse);

                    resolve(userProfileResponse);
                })
                .catch((err: AxiosError) => {
                    const restErr = { statusCode: err.response!.status };
                    reject(restErr);
                });
        });
    }

    public async deleteFavourite(itemNumber: string, authRequest: AuthenticatedRequest): Promise<UserProfileResponse> {
        return new Promise<UserProfileResponse>((resolve, reject) => {
            this.http.setToken(authRequest.accessToken);

            this.http
                .delete(`/api/v1.0/me/favourites/${itemNumber}`)
                .then((response: IResponse<any>) => {
                    const jsonResponse = response.data;
                    const userProfileResponse = AuthResponseMapper.mapUserProfileResponse(jsonResponse);
                    resolve(userProfileResponse);
                })
                .catch((err: AxiosError) => {
                    const restErr = { statusCode: err.response!.status };
                    reject(restErr);
                });
        });
    }
}