import React from 'react';
import { TranslationKeys } from '../../../infrastructure/const';
import HyperlinkButton from '../../common/hyperlink-button';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import IUserContext from '../../../infrastructure/context/user/user.context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import { GetHttpClientInstance } from '../../../infrastructure/context/http/http-context-provider';
import { LoginResponse } from '../../../infrastructure/http/modules/auth/auth.models';
import { ErrorCodes } from '../../../infrastructure/http/modules/errors';
import ErrorMessages from '../../common/error-messages';
import LoginForm from '../../common/auth/login-form';
import CheckUtils from '../../../utilities/check-utils';

type CheckoutLoginBoxProps = {
    userContext: IUserContext
};

type CheckoutLoginBoxState = {
    displayLoginForm: boolean;
    errorKeys: string[];
};

type LoginFieldsType = Pick<CheckoutLoginBoxState, Exclude<keyof CheckoutLoginBoxState, "displayLoginForm" | "errorKeys">>;

export default class CheckoutLoginBox extends React.Component<CheckoutLoginBoxProps, CheckoutLoginBoxState> {
    private TranslationManager: TranslationManager;
    private translations: { [id: string]: string };
    private loginFormRef: React.RefObject<LoginForm>;

    constructor(props: CheckoutLoginBoxProps, context: ITranslationsContext) {
        super(props, context);
        this.state = {
            displayLoginForm: false,
            errorKeys: []
        } as CheckoutLoginBoxState;

        this.TranslationManager = new TranslationManager(context);
        this.translations = {
            haveAccount: this.TranslationManager.getTranslation(TranslationKeys.LOGIN_FORM_I_HAVE_ACCOUNT),
            doYouHaveAccount: this.TranslationManager.getTranslation(TranslationKeys.LOGIN_FORM_DOYOUHAVEACCOUNT),
            clickToLogin: this.TranslationManager.getTranslation(TranslationKeys.LOGIN_FORM_CLICKTOLOGIN_LINK),
        };

        this.loginFormRef = React.createRef<LoginForm>();
    }

    handleClick(displayForm: boolean) {
        this.setState({
            displayLoginForm: displayForm,
            errorKeys: []
        });
    }

    render() {
        return (
            <div className="qc-login">
                <div className="login-area">
                    {this.state.displayLoginForm ? (
                        <>
                            <HyperlinkButton
                                onClick={() => this.handleClick(false)}
                                id="click-here-to-hide-login"
                                title={this.translations.haveAccount} >
                                {this.translations.haveAccount}
                            </HyperlinkButton>
                            <ErrorMessages errorKeys={this.state.errorKeys} />
                            <LoginForm
                                ref={this.loginFormRef}
                                isCheckoutPage={true}
                                onLoginClicked={(login, password) => this.handleLoginClicked(login, password)}
                                resetInvalidLoginOrPasswordError={(() => this.resetInvalidLoginOrPasswordError())}
                            />
                        </>
                    ) : (
                            <HyperlinkButton
                                onClick={() => this.handleClick(true)}
                                id="click-here-to-show-login"
                                title={this.translations.clickToLogin} >
                                <span>{this.translations.doYouHaveAccount}</span> {this.translations.clickToLogin}
                            </HyperlinkButton>
                        )
                    }
                </div>
            </div>
        );
    }

    private handleLoginClicked(email: string, password: string): void {
        const auth = GetHttpClientInstance().auth;

        if (email && password) {
            auth.login({ email, password })
                .then((loginResponse: LoginResponse) => {
                    this.props.userContext.updateUser(loginResponse);
                }).catch(() => {
                    this.setState({ errorKeys: [ErrorCodes.InvalidLoginOrPassword] });
                    if (this.loginFormRef !== null && this.loginFormRef.current !== null) {
                        this.loginFormRef.current.resetPasswordInput();
                    }
                });
        }
    }

    private resetInvalidLoginOrPasswordError(): void {
        if (!CheckUtils.isNullObject(this.state) && !CheckUtils.isNullOrEmptyArray(this.state.errorKeys)) {
            let errorKeys = [...this.state.errorKeys];
            errorKeys = errorKeys.filter(x => x !== ErrorCodes.InvalidLoginOrPassword);
            this.setState({ errorKeys });
        }
    }
}

CheckoutLoginBox.contextType = TranslationsContext;