import React, { useContext } from 'react';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../../infrastructure/translation-manager';
import { TranslationKeys } from '../../../infrastructure/const';

type AddressSelectionProps = {
    isDifferentAddress: boolean,
    onChange: ((newValue: boolean) => void)
};

const CheckoutAddressSelectionCheckbox = (props: AddressSelectionProps) => {
    const context = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(context);
    const translations: { [id: string]: string } = {
        shipToThisAddress: translationManager.getTranslation(TranslationKeys.CHECKOUT_SHIP_TO_THIS_ADDRESS_CHECKBOX),
        shipToDifferentAddress: translationManager.getTranslation(TranslationKeys.CHECKOUT_SHIP_TO_DIFFERENT_ADDRESS_CHECKBOX)
    };

    function handleChange() {
        props.onChange(!props.isDifferentAddress);
    }

    return (
        <fieldset className="qc-shipping-choice">
            <p>
                <input type="radio" value={"option1"} name="use_for_shipping" id="billingUseForShippingYes" checked={!props.isDifferentAddress} onChange={handleChange} />
                <label className="shipTo-address" htmlFor="billingUseForShippingYes">
                    <span></span>
                    {translations.shipToThisAddress}
                </label>
                <input type="radio" value={"option2"} name="use_for_shipping" id="billingUseForShippingNo" checked={props.isDifferentAddress} onChange={handleChange} />
                <label className="shipTo-different-address" htmlFor="billingUseForShippingNo">
                    <span></span>
                    {translations.shipToDifferentAddress}
                </label>
            </p>
        </fieldset>
    );
};

export default CheckoutAddressSelectionCheckbox;