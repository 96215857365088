import React from 'react';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../../infrastructure/translation-manager';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import { TranslationKeys, FeatureFlagValues, MandatoryConsentCheckboxes } from '../../../infrastructure/const';
import Spinner from '../../common/spinner';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';
import PaymentIcons from '../../common/payment-icons';
import DiscountsSummary from '../../common/discounts-summary';
import MandatoryCheckboxesSection, { MandatoryCheckboxesRefs, RegulationsCheckboxProps } from './mandatory-checkboxes-section';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';

type CheckoutSummaryProps = {
    totalPrice: string;
    vat: string;
    vatExempt: string;
    subtotalExclVat: string;
    subtotalInclVat: string;
    shippingPriceExclVat: string;
    shippingPriceInclVat: string;
    discounts: { [id: string]: string };
    onContinueClick: ((checkboxesSelected: Array<string>, areAllMandatoryCheckboxesSelected: boolean) => void);
    isLoaded: boolean;
    displayValidationMessages: boolean;
    displayVatPrices: boolean;
};

type CheckoutSummaryState = {
    goToCheckoutClicked: boolean
};

export default class CheckoutSummary extends React.Component<CheckoutSummaryProps, CheckoutSummaryState> {
    private TranslationManager: TranslationManager;
    private translations: { [id: string]: string };
    private checkboxesRef: React.RefObject<MandatoryCheckboxesRefs>;

    constructor(props: CheckoutSummaryProps, context: ITranslationsContext) {
        super(props, context);
        this.state = {
            goToCheckoutClicked: false
        } as CheckoutSummaryState;

        this.TranslationManager = new TranslationManager(this.context);

        this.translations = {
            continue: this.TranslationManager.getTranslation(TranslationKeys.CONTINUE),
            orderSummary: this.TranslationManager.getTranslation(TranslationKeys.CART_ORDERSUMMARY_HEADER),
            total: this.TranslationManager.getTranslation(TranslationKeys.TOTAL),
            subtotalInclVat: this.TranslationManager.getTranslation(TranslationKeys.UK.CART_SUBTOTAL_INCLUDING_VAT),
            subtotalExclVat: this.TranslationManager.getTranslation(TranslationKeys.UK.CART_SUBTOTAL_EXCLUDING_VAT),
            shipping: this.TranslationManager.getTranslation(TranslationKeys.CART_SHIPPING),
            vat: this.TranslationManager.getTranslation(TranslationKeys.UK.VAT),
            vatExempt: this.TranslationManager.getTranslation(TranslationKeys.UK.VAT_EXEMPT),
            termsAndConditions: this.TranslationManager.getTranslation(TranslationKeys.CHECKOUT_TERMSANDCONDITIONS_CHECKBOX),
            acceptedPaymentMethods: this.TranslationManager.getTranslation(TranslationKeys.ACCEPTEDPAYMENTMETHODS),
            consentCheckboxAcceptPrivacyPolicy: this.TranslationManager.getTranslation(TranslationKeys.CHECKOUT_CONSENTCHECKBOX_ACCEPTPRIVACYPOLICY)
        };

        this.checkboxesRef = React.createRef<MandatoryCheckboxesRefs>();
    }

    onContinueClick(): void {
        let selectedCheckboxes: Array<string>;
        let areAllCheckboxesSelected: boolean;

        if (this.checkboxesRef !== undefined && this.checkboxesRef !== null && this.checkboxesRef.current !== null && this.checkboxesRef.current !== undefined) {
            selectedCheckboxes = this.checkboxesRef.current.getSelectedCheckboxesList();
            areAllCheckboxesSelected = this.checkboxesRef.current.allCheckboxesSelected();

            this.props.onContinueClick(selectedCheckboxes, areAllCheckboxesSelected);
        }
    }

    render(): JSX.Element {
        return (
            <ul className="section section--summary">
                <li id="opc-review" >
                    <div id="checkout-step-review" className="checkout-step">
                        <div className="checkout-step__title">
                            <span className="text">{this.translations.orderSummary}</span>
                        </div>
                        <div className="content-container">
                            {this.props.isLoaded ?
                                (
                                    <div className="checkout-final">
                                        <ConfigurationContext.Consumer>
                                            {(configuration) => (
                                                <div id="checkout-review-load" className="default-box" >
                                                    <table className="table table-condensed" id="checkout-review-table">
                                                        <tbody>
                                                            <tr className="first">
                                                                <td className="a-right">
                                                                    {this.props.displayVatPrices ? this.translations.subtotalInclVat : this.translations.subtotalExclVat}
                                                                </td>
                                                                <td className="a-right last"> <span className="price">
                                                                    {this.props.displayVatPrices ? this.props.subtotalInclVat : this.props.subtotalExclVat}
                                                                </span> </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="a-right">{this.translations.shipping}</td>
                                                                <td className="a-right last"> <span className="price">
                                                                    {this.props.displayVatPrices ? this.props.shippingPriceInclVat : this.props.shippingPriceExclVat}
                                                                </span> </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="a-right">{this.translations.vat}</td>
                                                                <td className="a-right last"><span className="price">{this.props.vat}</span></td>
                                                            </tr>
                                                            {
                                                                configuration.getFeatureFlagValue("orderSummaryType") === FeatureFlagValues.OrderSummary.UK &&
                                                                <tr>
                                                                    <td className="a-right">{this.translations.vatExempt}</td>
                                                                    <td className="a-right last"><span className="price">-{this.props.vatExempt}</span></td>
                                                                </tr>}
                                                            <DiscountsSummary discounts={this.props.discounts} />
                                                            <tr className="last">
                                                                <td className="a-right"> <strong>{this.translations.total}</strong> </td>
                                                                <td className="a-right last"> <strong><span className="price">{this.props.totalPrice}</span></strong> </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    {this.renderTermsAndConditionsCheckbox(configuration)}
                                                </div>
                                            )}
                                        </ConfigurationContext.Consumer>
                                        <div className="content button-set">
                                            <span id="review-buttons-container">
                                                <button id="place-order-button" className="button-addToCart" type="button" onClick={() => this.onContinueClick()}>{this.translations.continue}</button>
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <Spinner show />
                                )
                            }
                        </div>
                    </div>
                </li>
                <li>
                    <PaymentIcons paymentMethodsTranslation={this.translations.acceptedPaymentMethods} boldLabel />
                </li>
            </ul>
        );
    }

    private renderTermsAndConditionsCheckbox(configurationContext: IConfigurationContext) {
        let checkboxes: RegulationsCheckboxProps[] = [];

        checkboxes.push({
            id: MandatoryConsentCheckboxes.TermsAndConditions,
            labelHtml: this.translations.termsAndConditions,
        });

        if (configurationContext.isFeatureFlagEnabled("showPrivacyPolicyConsent")) {
            checkboxes.push({
                id: MandatoryConsentCheckboxes.PrivacyPolicy,
                labelHtml: this.translations.consentCheckboxAcceptPrivacyPolicy
            })
        }

        return (
            <MandatoryCheckboxesSection
                ref={this.checkboxesRef}
                checkboxes={checkboxes}
                displayValidationMessages={this.props.displayValidationMessages}
            />
        );
    }
}

CheckoutSummary.contextType = TranslationsContext;