import React, { useContext } from 'react';
import { TranslationKeys } from '../../infrastructure/const';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../infrastructure/translation-manager';
import ReactHtmlParser from 'react-html-parser';
import StringUtils from '../../utilities/string-utils';

type Props = {
    handleChange: ((newValue: boolean) => void),
    isChecked: boolean
}

export const MarketingConsentCheckbox = (props: Props) : JSX.Element => {
    const translationContext = useContext<ITranslationsContext>(TranslationsContext);

    const translationManager = new TranslationManager(translationContext);
    const translations = {
        signUpForMarketingMaterial: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_SIGNUPFORMARKETINGMATERIALS),
    };

    function handleCheckboxChange(event: React.SyntheticEvent<HTMLInputElement>): void {
        const { checked } = event.currentTarget;
        props.handleChange(checked);
    }

    // This is required for Phonic Ear and Bernafon
    const translationWithEmail = StringUtils.convertEmailInTextToLink(translations.signUpForMarketingMaterial);

    return (
        <>
            <input
                defaultChecked={props.isChecked}
                onChange={(evt) => handleCheckboxChange(evt)}
                type='checkbox'
                name='is_subscribed'
                id='is_subscribed'
                className='checkbox css-checkbox prettyCheckable hidden-checkbox'
            />
            <label htmlFor='is_subscribed' className='css-label'>{ReactHtmlParser(translationWithEmail)}</label>
        </>
    );
};

export default MarketingConsentCheckbox;

