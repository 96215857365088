import React, { useContext } from 'react';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import TranslationManager from '../infrastructure/translation-manager';
import { TranslationKeys } from '../infrastructure/const';
import { Helmet } from 'react-helmet';

const NotFoundPage: React.FC = () => {
    const context = useContext(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(context);
    const translations = {
        notFoundPage: translationManager.getTranslation(TranslationKeys.PAGE_NOT_FOUND)
    };

    return (
        <div className='page-not-found'>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <h1>{translations.notFoundPage}</h1>
        </div>
    );
};

export default NotFoundPage;
