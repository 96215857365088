import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { FeatureFlagValues, RouterPaths, TranslationKeys } from '../../../infrastructure/const';
import AccountLoginChecker from '../account-login-checker';
import AccountMenuLink from './account-menu-link';
import HyperlinkButton from '../../common/hyperlink-button';
import IUserContext from '../../../infrastructure/context/user/user.context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import UserContext from '../../../infrastructure/context/user/user.context';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';

type State = {
    redirectToLogout: boolean;
};

class AccountMenu extends React.Component {
    public state: State = {
        redirectToLogout: false,
    };

    public render(): ReactNode {
        const translationManager = new TranslationManager(this.context);

        const translations = {
            accountDashboard: translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_DASHBOARD),
            addressBook: translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_ADDRESS_BOOK),
            myOrders: translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_MY_ORDERS),
            myFavourites: translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_MY_FAVOURITES),
            logout: translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_LOGOUT),
        };

        return (
            <ConfigurationContext.Consumer>
                {(configurationContext) =>
                    <UserContext.Consumer>
                        {(userContext: IUserContext) =>
                            <div className="sidebar">
                                <AccountLoginChecker />
                                <div className="block block-account">
                                    <div className="block-account__content">
                                        <ul>
                                            <AccountMenuLink link={RouterPaths.AccountDashboard} translation={translations.accountDashboard} />

                                            <AccountMenuLink link={RouterPaths.AccountAddressBook} translation={translations.addressBook} />

                                            <AccountMenuLink link={RouterPaths.AccountOrderHistory} translation={translations.myOrders} />

                                            {configurationContext.isFeatureFlagEnabled("allowFavourites") &&
                                                <AccountMenuLink link={RouterPaths.AccountFavourites} translation={translations.myFavourites} />
                                            }

                                            <li className='last'>
                                                <HyperlinkButton onClick={() => this.handleLogoutClicked(userContext)} title={translations.logout}>
                                                    {translations.logout}
                                                </HyperlinkButton>
                                            </li>



                                            {this.state.redirectToLogout &&
                                                <Redirect push to={RouterPaths.Logout} />
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </UserContext.Consumer>
                }
            </ConfigurationContext.Consumer>
        );
    }


    private handleLogoutClicked(userContext: IUserContext): void {
        userContext.clearUser();
        this.setState({ redirectToLogout: true });
    }
}

AccountMenu.contextType = TranslationsContext;

export default AccountMenu;
