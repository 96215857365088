import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import CheckUtils from '../../utilities/check-utils';
import HyperlinkButton from '../common/hyperlink-button';

type MaterialSpotItemProps = {
    title: string;
    subtitle: string;
    link: string;
    linkDescription: string;
};

export default class MaterialSpotItem extends React.Component<MaterialSpotItemProps> {

    render(): JSX.Element | null {
        if (CheckUtils.isNullObject(this.props)) {
            return null;
        }
        return (
            <div>
                <h6>{ReactHtmlParser(this.props.title)}</h6>
                <p>{ReactHtmlParser(this.props.subtitle)}</p>
                <HyperlinkButton
                    className="link"
                    href={this.props.link}
                    onClick={() => { window.open(this.props.link); }}
                    title={this.props.linkDescription}
                >
                    {this.props.linkDescription &&
                        <span className="link-text">{this.props.linkDescription}</span>
                    }
                </HyperlinkButton>
            </div>
        );
    }
}