import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import HyperlinkButton from '../common/hyperlink-button';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import { TranslationKeys, RouterPaths } from '../../infrastructure/const';
import CheckUtils from '../../utilities/check-utils';

const PageHeaderLinks = (): JSX.Element => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);
    const translations: { [key: string]: string } = {
        customerService: translationManager.getTranslation(TranslationKeys.HEADER_CUSTOMERSERVICE_LABEL),
        instructionalVideos: translationManager.getTranslation(TranslationKeys.UK.HEADER_INSTRUCTIONALVIDEOS_LABEL)
    };
    return (
        <ul className="links static-links">
            {!CheckUtils.isNullOrEmptyString(translations.customerService) &&
                <li className="customer-service">
                    <div className="header-link-container">
                        <strong>
                            <span>
                                <Link to={RouterPaths.CustomerServiceContactUs}>{translations.customerService}</Link>
                            </span>
                        </strong>
                    </div>
                </li>
            }
            {!CheckUtils.isNullOrEmptyString(translations.instructionalVideos) &&
                <li>
                    <div className="header-link-container">
                        <strong>
                            <span>
                                <HyperlinkButton
                                    href={RouterPaths.UK_OticonConnectline_InstructionalVideos}
                                    onClick={() => { window.open(RouterPaths.UK_OticonConnectline_InstructionalVideos); }}>
                                    {translations.instructionalVideos}
                                </HyperlinkButton>
                            </span>
                        </strong>
                    </div>
                </li>
            }
        </ul>
    );
};

export default PageHeaderLinks;