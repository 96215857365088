import { ModuleBase } from '../../common/module.base';
import { IAuthModule } from './auth.interface';
import { IHttp } from '../../http.interface';
import { LoginResponse, ValidateForgotPasswordSessionRequest, RedeemForgotPasswordSessionRequest, LoginRequest, RequestForgotPasswordSessionRequest } from './auth.models';
import { IResponse } from '../../http-response.interface';
import { AuthResponseMapper } from '../account/account.mapper';
import { AxiosError } from 'axios';

export class AuthModule extends ModuleBase implements IAuthModule {
    constructor(private http: IHttp) {
        super('Auth');
    }

    public async login(request: LoginRequest): Promise<LoginResponse> {
        const response: IResponse<any> = await this.http.post('/api/v1.0/authenticate', request);
        const jsonResponse = response.data;

        const now = new Date();
        const expiresAt = new Date(now.getTime() + jsonResponse.expires_in * 1000);

        return {
            tokenInfo: {
                accessToken: jsonResponse.access_token,
                issuedAt: now,
                expiresIn: jsonResponse.expires_in,
                expiresAt: expiresAt,
                tokenType: jsonResponse.token_type,
                scope: jsonResponse.scope,
            },
            userProfile: {
                userId: jsonResponse.user.id,
                firstName: jsonResponse.user.firstName,
                middleName: jsonResponse.user.middleName,
                lastName: jsonResponse.user.lastName,
                email: jsonResponse.user.email,
                receiveMarketingMaterials: jsonResponse.user.receiveMarketingMaterials,
                addressBook: AuthResponseMapper.mapAddressBook(jsonResponse.user),
                customerPreference: jsonResponse.user.customerPreference,
                favouriteProducts: jsonResponse.user.favouriteProducts
            }
        };
    }

    public requestForgotPasswordSession(request: RequestForgotPasswordSessionRequest): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.http.post('/api/v1.0/forgot-password', request)
                .then(() => resolve())
                .catch((err: AxiosError) => {
                    reject(err.response);
                });
        });
    }

    public async validateForgotPasswordSession(request: ValidateForgotPasswordSessionRequest): Promise<void> {
        await this.http.post('/api/v1.0/forgot-password/validate-session', request);
    }

    public async redeemForgotPasswordSession(request: RedeemForgotPasswordSessionRequest): Promise<void> {
        await this.http.post('/api/v1.0/forgot-password/redeem-session', request);
    }
}