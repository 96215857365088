import { TextImageSpot, TextImageSpotType } from "../../../infrastructure/http/modules/static-content/static-content.models";

const getTextImageSpotTypeFunction = function (item: TextImageSpot): TextImageSpotType {
    if (item.nestedComponentName) {
        return TextImageSpotType.NestedComponent;
    }

    if (item.image) {
        return TextImageSpotType.Image;
    }

    return TextImageSpotType.Text;
}

export default getTextImageSpotTypeFunction;