import React, { useContext, useState, useEffect } from 'react';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import { TranslationKeys, FeatureFlagValues } from '../../../infrastructure/const';
import TranslationManager from '../../../infrastructure/translation-manager';
import { Transaction } from '../../../infrastructure/http/modules/cart/cart.models';
import PaymentSummaryHeader from './payment-summary-header';
import PaymentSummaryProduct from './payment-summary-product';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';

type Props = {
    transaction: Transaction
};

const PaymentSummary = (props: Props): JSX.Element => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    const displayPriceIncludingVat = configurationContext.isFeatureFlagEnabled("displayPriceIncludingVat");

    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);
    const translations: { [id: string]: string } = {
        amount: translationManager.getTranslation(TranslationKeys.AMOUNT),
        total: translationManager.getTranslation(TranslationKeys.TOTAL),
        subtotalExclVat: translationManager.getTranslation(TranslationKeys.UK.CART_SUBTOTAL_EXCLUDING_VAT),
        subtotalInclVat: translationManager.getTranslation(TranslationKeys.UK.CART_SUBTOTAL_INCLUDING_VAT),
        shipping: translationManager.getTranslation(TranslationKeys.CART_SHIPPING),
        vat: translationManager.getTranslation(TranslationKeys.UK.VAT),
        vatExempt: translationManager.getTranslation(TranslationKeys.UK.VAT_EXEMPT),
    };

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        props.transaction.products.length < 6 && window.innerWidth > 991 ? setIsOpen(true) : setIsOpen(false);
    }, []);

    return (
        <div className="payment-product-list">
            <div className={`product-list ${isOpen ? 'product-list--open' : ''}`}>
                <PaymentSummaryHeader
                    productsCount={props.transaction.products.length}
                    setIsOpen={(() => setIsOpen(!isOpen))}
                />
                <ProductListInner displayPriceIncludingVat={displayPriceIncludingVat} transaction={props.transaction} translations={translations} />
            </div>
        </div>
    );
};

const ProductListInner = (props: { displayPriceIncludingVat: boolean, transaction: Transaction, translations: { [id: string]: string } }): JSX.Element => {
    return (
        <div>
            <div className="list-content">
                <table className="cart-table">
                    <tbody>
                        {props.transaction.products.map((product) => (
                            <PaymentSummaryProduct
                                id={product.id}
                                imageUrl={product.imageUrl}
                                name={product.name}
                                description={product.description}
                                colorName={product.colorName}
                                itemNumber={product.itemNumber}
                                total={product.total}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div>
                <table className="summary-table">
                    <tbody>
                        {props.displayPriceIncludingVat ? (
                            <tr>
                                <td className="summary-table__column">{props.translations.subtotalInclVat}</td>
                                <td className="summary-table__column">{props.transaction.subTotalInclVat}</td>
                            </tr>
                        ) : (
                                <tr>
                                    <td className="summary-table__column">{props.translations.subtotalExclVat}</td>
                                    <td className="summary-table__column">{props.transaction.subTotalExclVat}</td>
                                </tr>
                            )}
                        {props.displayPriceIncludingVat ? (
                            <tr>
                                <td className="summary-table__column">{props.translations.shipping}</td>
                                <td className="summary-table__column">{props.transaction.shippingPriceInclVat}</td>
                            </tr>
                        ) : (
                                <tr>
                                    <td className="summary-table__column">{props.translations.shipping}</td>
                                    <td className="summary-table__column">{props.transaction.shippingPriceExclVat}</td>
                                </tr>
                            )}
                        <tr>
                            <td className="summary-table__column">{props.translations.vat}</td>
                            <td className="summary-table__column">{props.transaction.vat}</td>
                        </tr>
                        <ConfigurationContext.Consumer>
                            {(configuration) => (
                                configuration.getFeatureFlagValue("orderSummaryType") === FeatureFlagValues.OrderSummary.UK &&
                                <tr>
                                    <td className="summary-table__column">{props.translations.vatExempt}</td>
                                    <td className="summary-table__column">{props.transaction.vatExempt}</td>
                                </tr>
                            )}
                        </ConfigurationContext.Consumer>
                        <tr>
                            <td className="summary-table__column summary-table__column--total">{props.translations.total}</td>
                            <td className="summary-table__column summary-table__column--total">{props.transaction.totalPrice} {props.transaction.currencyCode}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PaymentSummary;