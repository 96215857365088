import React from 'react';
import { Helmet } from "react-helmet";
import { Environment } from '../../infrastructure/const';

const RobotsMetatags = () => {
    return (
        <Helmet>
            {process.env.REACT_APP_DEPLOY_ENV !== Environment.Production &&
                <meta name="robots" content="noindex" />
            }
        </Helmet>
    );
};

export default RobotsMetatags;