import React, { useContext, useEffect, useState } from 'react';
import { TranslationKeys } from '../../../infrastructure/const';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';
import { GetHttpClientInstance } from '../../../infrastructure/context/http/http-context-provider';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import { Product } from '../../../infrastructure/http/modules/product/product.models';
import TranslationManager from '../../../infrastructure/translation-manager';
import CheckUtils from '../../../utilities/check-utils';
import ProductListSlider, { SliderTranslations } from '../../product-list/product-list-slider';

type Props = {
    products: Array<string>
}

const RelatedProductsSlider = (props: Props): JSX.Element | null => {
    const configurationContext: IConfigurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    const relatedProductsEnabled = configurationContext.isFeatureFlagEnabled("useEnrichedProductData");

    const translationsContext: ITranslationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);

    const [products, setProducts] = useState<Product[]>([]);

    const sliderTranslations: SliderTranslations = {
        headerTitle: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_RELATED_PRODUCTS),
        seeAll: "",
        addedToCart: translationManager.getTranslation(TranslationKeys.CART_ADDEDTOCART)
    }

    useEffect(() => {
        if (relatedProductsEnabled && !CheckUtils.isNullOrEmptyArray(props.products)) {
            GetHttpClientInstance().product.searchByItemNumbers(props.products, true).then((response) => {
                setProducts(response.products);
            });
        } else if(!CheckUtils.isNullObject(props.products) && props.products.length === 0){
            setProducts([]);
        }
    }, [props.products])

    if (!relatedProductsEnabled) {
        return null;
    }

    if (CheckUtils.isNullOrEmptyArray(products)) {
        return null;
    }

    return (
        <ProductListSlider
            translations={sliderTranslations}
            products={products}
            displaySeeAllButton={true}
            seeAllRedirectionLink=""
        />
    );
};

export default RelatedProductsSlider;