import React, { SyntheticEvent } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import IntroBannerSlide from "./intro-banner-slide-content";
import { IntroBanner } from "../../../../infrastructure/http/modules/customer-service/customer-service.models";

SwiperCore.use([Autoplay]);

interface IProps {
  banners: IntroBanner[];
  onButtonClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

const IntroBannerSlider = ({ banners, onButtonClick }: IProps) => {
  return (
    <Swiper
      slidesPerView={1}
      loop={true}
      pagination={{ clickable: true }}
      navigation={true}
      modules={[Navigation, Pagination]}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
    >
      {banners.map((banner) => (
        <SwiperSlide>
          <IntroBannerSlide banner={banner} onButtonClick={onButtonClick} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default IntroBannerSlider;
