import * as React from 'react';
import CheckUtils from '../../utilities/check-utils';
import TranslationManager from '../../infrastructure/translation-manager';
import { TranslationKeys } from '../../infrastructure/const';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';

export type TextAreaProps = {
    onValueChange?: ((name: string, value: any, isValid: boolean) => void);
    onBlur?: ((event: React.SyntheticEvent<HTMLTextAreaElement>) => void);

    id?: string;
    value?: string;
    name: string;
    label: string;
    placeholder?: string;

    columns?: number;
    rows?: number;
    maxLength?: number;

    isVisible?: boolean;
    hasError?: boolean;
    isRequired?: boolean;

    showValidationText?: boolean;
    helpTextHtml?: string;
    additionalClassName?: string;
};

type TextAreaState = {
    hasValue: boolean;
    hasError: boolean;
    isVisible: boolean;
    onComposition: boolean;
    value: string;
};

const defaultProps: TextAreaProps = {
    onValueChange: () => undefined,
    onBlur: () => undefined,
    id: '',
    value: '',
    name: '',
    label: '',
    placeholder: '',
    isVisible: true,
    hasError: false,
    isRequired: false,
    showValidationText: false,
    helpTextHtml: '',
    additionalClassName: ''
};

export default class TextArea extends React.Component<TextAreaProps, TextAreaState> {

    static defaultProps = defaultProps;
    private textAreaRef = React.createRef<HTMLTextAreaElement>();

    constructor(props: TextAreaProps) {
        super(props);
        this.state = {
            hasError: false,
            hasValue: !CheckUtils.isNullOrEmptyString(this.props.value),
            isVisible: this.props.isVisible,
            onComposition: false,
            value: !CheckUtils.isNullOrEmptyString(this.props.value) ? this.props.value : '',
        } as TextAreaState;
    }

    componentDidMount() {
        this.isValid();
    }

    componentDidUpdate(prevProps: TextAreaProps) {
        // Visibility change
        if (prevProps.isVisible !== this.props.isVisible) {
            this.setState({ isVisible: CheckUtils.isNullObject(this.props.isVisible) ? !!defaultProps.isVisible : !!prevProps.isVisible });
        }

        // Value change
        if (prevProps.value !== this.props.value && this.state.value !== this.props.value) {
            const hasValue = !CheckUtils.isNullOrEmptyString(this.props.value);
            this.setState({
                hasValue,
                value: hasValue ? this.props.value as string : '',
            }, () => {
                this.isValid();
            });
        }

        if (prevProps.hasError !== this.props.hasError) {
            this.isValid();
        }

        if (prevProps.isRequired !== this.props.isRequired) {
            this.isValid();
        }
    }

    isValid(): boolean {
        const hasMandatoryError: boolean = (this.props.isRequired === undefined ? false : this.props.isRequired)
            && !this.state.hasValue;
        const hasPropsError: boolean = CheckUtils.isNullObject(this.props.hasError) ? false : this.props.hasError as boolean;

        const hasError: boolean = hasMandatoryError || hasPropsError;

        if (hasError !== this.state.hasError) {
            this.setState({
                hasError
            });
        }

        return !hasError;
    }

    onChange(e: React.SyntheticEvent<HTMLTextAreaElement>) {
        let eventTarget = e.currentTarget;
        let val = e.currentTarget.value;

        if (this.props.maxLength && val.length > this.props.maxLength) {
            return;
        }

        this.setState({
            value: val,
            hasValue: !CheckUtils.isNullOrEmptyString(val)
        }, () => {
            const isValid = this.isValid();

            eventTarget.value = val;

            if (this.props.onValueChange) {
                e.persist();
                this.props.onValueChange(this.props.name, val, isValid);
            }
        });
    }

    onBlur(e: React.SyntheticEvent<HTMLTextAreaElement>) {
        const input = e.currentTarget;
        this.setState({ hasValue: !CheckUtils.isNullObject(input) && !CheckUtils.isNullOrEmptyString(input.value) });

        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    onFocus(e: React.SyntheticEvent<HTMLTextAreaElement>) {
        if (!CheckUtils.isNullObject(this.textAreaRef) && !CheckUtils.isNullObject(this.textAreaRef.current)) {
            this.textAreaRef!.current!.select();
        }
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }
        return (
            <>
                <textarea
                    ref={this.textAreaRef}
                    id={this.props.id}
                    name={this.props.name}
                    title={this.props.label}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    maxLength={this.props.maxLength}
                    cols={this.props.columns}
                    rows={this.props.rows}
                    onChange={(event: React.SyntheticEvent<HTMLTextAreaElement>) => this.onChange(event)}
                    onFocus={(event: React.SyntheticEvent<HTMLTextAreaElement>) => this.onFocus(event)}
                    onBlur={(event: React.SyntheticEvent<HTMLTextAreaElement>) => this.onBlur(event)}
                    className={
                        `input-text span4 " + ${this.props.isRequired ? "required-entry" : ''}
                        ${this.props.value ? 'has-value' : ''}`
                    }
                />
                <label className="required">
                    {this.props.label}
                    {this.props.isRequired && <span className="required"> *</span>}
                </label>
                {this.state.hasError && this.props.showValidationText &&
                    <div className="validation-advice" id="advice-required-entry-billing:firstname">
                        <span className="icon-arrow-up"></span>{CheckUtils.isNullOrEmptyString(this.props.helpTextHtml) ? new TranslationManager(this.context).getTranslation(TranslationKeys.REQUIRED) : this.props.helpTextHtml}
                    </div>
                }
            </>
        );
    }
}

TextArea.contextType = TranslationsContext;