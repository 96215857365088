import { Cookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

class CookiesManager {
    private static options: CookieSetOptions = {
        path: '/',
    };

    public static setCookie(key: string, value: any): void {
        const cookies = new Cookies();

        cookies.set(key, value, this.options);
    }

    public static getCookie(key: string): any {
        const cookies = new Cookies();

        return cookies.get(key);
    }
}

export default CookiesManager;