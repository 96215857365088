import React, { ReactNode } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import { RestError, ErrorCodes } from '../infrastructure/http/modules/errors';
import { TranslationKeys, RouterPaths, RouterStateParams } from '../infrastructure/const';
import Captcha from './common/captcha';
import EmailInput from './common/email-input';
import ErrorCodesExtractor from '../utilities/error-codes-extractor';
import TranslationManager from '../infrastructure/translation-manager';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import ErrorMessages from './common/error-messages';
import { RequestForgotPasswordSessionRequest } from '../infrastructure/http/modules/auth/auth.models';
import CheckUtils from '../utilities/check-utils';

type State = {
    email: string;
    emailHasError: boolean;
    redirectToLogin: boolean;
    captcha: string;
    captchaHasError: boolean;
    showResetLinkExpiredErrorMessage: boolean;

    errorKeys: string[];
};

class ForgotPasswordPage extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);

        this.state = {
            email: '',
            emailHasError: false,
            redirectToLogin: false,
            showResetLinkExpiredErrorMessage: false,
            captcha: '',
            captchaHasError: false,
            errorKeys: [],
        };

        this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
        this.handleCaptchaErrorOrExpired = this.handleCaptchaErrorOrExpired.bind(this);
    }

    public componentDidMount(): void {
        if (this.props.location.state) {
            const state = this.props.location.state as { [key: string]: any };
            if (state[RouterStateParams.ShowResetLinkExpiredErrorMessage]) {
                this.setState({ errorKeys: [ErrorCodes.ResetLinkExpired] });
                this.props.history.replace({ state: undefined });
            }
        }
    }

    public render(): ReactNode {
        const translationManager = new TranslationManager(this.context);

        const translations = {
            forgotYourPassword: translationManager.getTranslation(TranslationKeys.FORGOT_PASSWORD_HEADER),
            instruction: translationManager.getTranslation(TranslationKeys.FORGOT_PASSWORD_DESCRIPTION),
            emailAddress: translationManager.getTranslation(TranslationKeys.LOGIN_FORM_EMAIL),
            submit: translationManager.getTranslation(TranslationKeys.FORGOT_PASSWORD_SUBMIT),
            yourResetLinkExpired: translationManager.getTranslation(TranslationKeys.RESETPASSWORD_YOURPASSWORDRESETLINKEXPIRED),
        };

        return (
            <div className='col1-layout'>
                {this.state.redirectToLogin &&
                    <Redirect to={{
                        pathname: RouterPaths.Login,
                        state: { [RouterStateParams.ShowForgotPasswordSubmittedSuccessMessage]: this.state.email },
                    }} />
                }
                <div className='col-main'>
                    <div className='forgotpassword-page'>
                        <h2 className='page-big-title'>{translations.forgotYourPassword}</h2>
                        <div className='wrapper wr-block'>
                            <ErrorMessages errorKeys={this.state.errorKeys} />
                            <form className='form-validate' onSubmit={(evt) => this.handleSubmit(evt)}>
                                <div className='fieldset'>
                                    <p className='form-instructions'>
                                        {translations.instruction}
                                    </p>
                                    <ul className='form-list'>
                                        <li className="input-container">
                                            <EmailInput
                                                label={translations.emailAddress}
                                                name={'email'}
                                                isRequired={true}
                                                value={this.state.email}
                                                hasError={this.state.emailHasError}
                                                showValidationText={this.state.emailHasError}
                                                onValueChange={(_name, value, isValid) => this.handleValueChange(value, isValid)}
                                            />
                                        </li>
                                        <li style={{ marginTop: '10px' }}>
                                            <Captcha hasError={this.state.captchaHasError} onChange={this.handleCaptchaChange} onExpiration={this.handleCaptchaErrorOrExpired} onError={this.handleCaptchaErrorOrExpired} />
                                        </li>
                                    </ul>
                                </div>
                                <button type='submit' title={translations.submit} className='button'>{translations.submit}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private handleSubmit(evt: React.FormEvent<HTMLFormElement>): void {
        evt.preventDefault();

        let anyFieldHasError = false;
        if (CheckUtils.isNullOrEmptyString(this.state.email)) {
            this.setState({ emailHasError: true });
            anyFieldHasError = true;
        }

        if (CheckUtils.isNullOrEmptyString(this.state.captcha)) {
            this.setState({ captchaHasError: true });
            anyFieldHasError = true;
        }

        if (anyFieldHasError) {
            return;
        }

        this.resetPassword();
    }

    private handleValueChange(value: string, isValid: boolean): void {
        this.setState({ email: value, emailHasError: !isValid });
    }

    private handleCaptchaChange(token: string | null): void {
        if (!token) {
            return;
        }

        this.setState({ captcha: token, captchaHasError: false });
    }

    private handleCaptchaErrorOrExpired(): void {
        this.setState({ captcha: '', captchaHasError: true });
    }

    private resetPassword(): void {
        const { auth } = GetHttpClientInstance();
        const request: RequestForgotPasswordSessionRequest = {
            email: this.state.email,
            captcha: this.state.captcha,
        };

        auth.requestForgotPasswordSession(request)
            .then(() => {
                this.setState({ redirectToLogin: true });
            })
            .catch((error: RestError) => {
                const errorKeys = ErrorCodesExtractor.extract(error);

                this.setState({ errorKeys });
            });
    }
}

ForgotPasswordPage.contextType = TranslationsContext;

export default ForgotPasswordPage;
