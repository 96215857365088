import React from 'react';
import CheckUtils from '../../../utilities/check-utils';
import { Address } from '../../../infrastructure/http/modules/cart/cart.models';
import Input from '../../common/input';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import { TranslationKeys } from '../../../infrastructure/const';
import StringUtils from '../../../utilities/string-utils';
import Select, { SelectOption } from '../../common/select';
import UserContext from '../../../infrastructure/context/user/user.context';
import IUserContext, { IAddress } from '../../../infrastructure/context/user/user.context.interface';
import EmailInput from '../../common/email-input';
import SaveAddressCheckbox from './save-address-checkbox';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';

type CheckoutAddressFormProps = {
    displayEmailAddress: boolean,
    address: Address,
    onUpdate: ((address: Address) => void),
    displayValidationMessages: boolean,
    defaultAddressId: string | null,
    supportedCountries: Map<string, string>,
    apiErrorResponse: Array<string>,
    saveAddress: boolean,
    onSaveAddressChanged: ((saveAddressValue: boolean) => void);
    isInvoiceAddress: boolean;
};

type CheckoutAddressFormState = {
    id: string,
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    company: string,
    addressLine1: string,
    addressLine2: string,
    postalCode: string,
    city: string,
    phoneNumber: string,
    countryCode: string,
    validationErrors: Array<string>,
    selectedAddressId: string,
    supportedCountries: Map<string, string>,
    apiErrorResponse: Array<string>,
};

type AddressStateType = Pick<CheckoutAddressFormState, Exclude<keyof CheckoutAddressFormState, "validationErrors" | "supportedCountries" | "apiErrorResponse">>;

export default class CheckoutAddressForm extends React.Component<CheckoutAddressFormProps, CheckoutAddressFormState> {

    private TranslationManager: TranslationManager;
    private translations: { [id: string]: string };
    private inputIds: { [id: string]: string };
    private newAddressId: string;
    private addressList: Address[] = [];
    private requiredFields: Array<keyof AddressStateType>;
    private idPrefix: string;

    constructor(props: CheckoutAddressFormProps, context: ITranslationsContext) {
        super(props, context);
        this.newAddressId = "-1";

        this.requiredFields = this.props.isInvoiceAddress ?
            ["firstName", "lastName", "email", "addressLine1", "postalCode", "city", "phoneNumber"]
            : ["firstName", "lastName", "addressLine1", "postalCode", "city", "phoneNumber"];

        this.idPrefix = this.getIdPrefix(props);

        this.state = this.getInitialState();
        this.TranslationManager = new TranslationManager(this.context);

        this.translations = {
            firstName: this.TranslationManager.getTranslation(TranslationKeys.FIRST_NAME),
            lastName: this.TranslationManager.getTranslation(TranslationKeys.LAST_NAME),
            middleName: this.TranslationManager.getTranslation(TranslationKeys.MIDDLE_NAME),
            email: this.TranslationManager.getTranslation(TranslationKeys.EMAIL),
            company: this.TranslationManager.getTranslation(TranslationKeys.COMPANY),
            address: this.TranslationManager.getTranslation(TranslationKeys.ADDRESS),
            postalCode: this.TranslationManager.getTranslation(TranslationKeys.POSTALCODE),
            city: this.TranslationManager.getTranslation(TranslationKeys.CITY),
            phone: this.TranslationManager.getTranslation(TranslationKeys.PHONE),
            country: this.TranslationManager.getTranslation(TranslationKeys.COUNTRY),
            newAddress: this.TranslationManager.getTranslation(TranslationKeys.NEWADDRESS),
            selectAddress: this.TranslationManager.getTranslation(TranslationKeys.CHECKOUT_SELECT_ADDRESS)
        };

        this.inputIds = {
            firstName: this.getInputId(this.translations.firstName),
            lastName: this.getInputId(this.translations.lastName),
            middleName: this.getInputId(this.translations.middleName),
            email: this.getInputId(this.translations.email),
            company: this.getInputId(this.translations.company),
            address: this.getInputId(this.translations.address),
            postalCode: this.getInputId(this.translations.postalCode),
            city: this.getInputId(this.translations.city),
            phone: this.getInputId(this.translations.phone),
            country: this.getInputId(this.translations.country),
        };
    }

    componentDidUpdate(prevProps: CheckoutAddressFormProps): void {
        if (CheckUtils.isNullObject(prevProps.address) && !CheckUtils.isNullObject(this.props.address)) {
            const state = this.getInitialState();
            this.setState(state);
        } else if (prevProps.address !== this.props.address) {
            const state = {
                firstName: this.props.address.firstName,
                middleName: this.props.address.middleName,
                lastName: this.props.address.lastName,
                email: this.props.address.email,
                company: this.props.address.company,
                addressLine1: this.props.address.addressLine1,
                addressLine2: this.props.address.addressLine2,
                postalCode: this.props.address.postalCode,
                city: this.props.address.city,
                phoneNumber: this.props.address.phoneNumber,
                countryCode: this.props.address.countryCode,
            } as CheckoutAddressFormState;
            this.setState(state);
        }
        if (JSON.stringify(prevProps.apiErrorResponse) !== JSON.stringify(this.props.apiErrorResponse)) {
            this.setState({ apiErrorResponse: this.props.apiErrorResponse });
        }
    }

    componentDidMount() {
        if (this.props.defaultAddressId !== this.state.selectedAddressId && !CheckUtils.isNullObject(this.props.defaultAddressId)) {
            this.setState({ id: this.props!.defaultAddressId as string, selectedAddressId: this.props!.defaultAddressId as string }, () => {
                this.onAddressSelectionChange(this.props!.defaultAddressId as string, this.addressList);
            });
        }
    }

    getInitialValidationErrors(state: CheckoutAddressFormState) {
        const errors: string[] = [];
        this.requiredFields.forEach((key) => {
            if (CheckUtils.isNullOrEmptyString(state[key])) {
                errors.push(key);
            }
        });
        return errors;
    }

    isInvoiceAddress(address: Address | null): boolean {
        return this.props.isInvoiceAddress;
    }

    getInitialState(): CheckoutAddressFormState {
        const supportedCountries = CheckUtils.isNullObject(this.props.supportedCountries) ? new Map() : this.props.supportedCountries;
        const result: CheckoutAddressFormState = {
            id: this.newAddressId,
            firstName: this.props.address.firstName,
            middleName: this.props.address.middleName,
            lastName: this.props.address.lastName,
            email: this.isInvoiceAddress(this.props.address) ? (this.props.address as Address).email : '',
            company: this.props.address.company,
            addressLine1: this.props.address.addressLine1,
            addressLine2: this.props.address.addressLine2,
            postalCode: this.props.address.postalCode,
            city: this.props.address.city,
            phoneNumber: this.props.address.phoneNumber,
            countryCode: Object.keys(supportedCountries).length > 0 ? Object.keys(supportedCountries)[0] : "",
            validationErrors: [],
            selectedAddressId: this.newAddressId,
            supportedCountries: supportedCountries,
            apiErrorResponse: []
        };
        result.validationErrors = this.getInitialValidationErrors(result);
        return result;
    }

    getAddressObjectFromForm(): Address {
        if (this.isInvoiceAddress(this.props.address)) {
            return {
                firstName: this.state.firstName,
                middleName: this.state.middleName,
                lastName: this.state.lastName,
                email: this.state.email,
                company: this.state.company,
                addressLine1: this.state.addressLine1,
                addressLine2: this.state.addressLine2,
                postalCode: this.state.postalCode,
                city: this.state.city,
                phoneNumber: this.state.phoneNumber,
                countryCode: this.state.countryCode
            } as Address;
        } else {
            return {
                firstName: this.state.firstName,
                middleName: this.state.middleName,
                lastName: this.state.lastName,
                company: this.state.company,
                addressLine1: this.state.addressLine1,
                addressLine2: this.state.addressLine2,
                postalCode: this.state.postalCode,
                city: this.state.city,
                phoneNumber: this.state.phoneNumber,
                countryCode: this.state.countryCode
            } as Address;
        }
    }

    isComponentFilledCorrectly() {
        return CheckUtils.isNullOrEmptyArray(this.state.validationErrors) && CheckUtils.isNullOrEmptyArray(this.state.apiErrorResponse);
    }

    onInputValueChange<K extends keyof AddressStateType>(key: K, value: any, isValid: boolean): void {
        this.updateValidationErrors(key, !isValid);
        let valueStr: string = CheckUtils.isNullObject(value) ? '' : value.toString();
        let state: AddressStateType = { ...this.state };

        if (!Object.keys(state).some(x => x === key)) {
            return;
        }

        this.setState({
            [key]: valueStr
        } as AddressStateType, () => {
            this.props.onUpdate(this.getAddressObjectFromForm());
        });
    }

    onAddressSelectionChange(selectedAddressId: string, addressList: Address[]) {
        if (CheckUtils.isNullOrEmptyString(selectedAddressId) || selectedAddressId === this.newAddressId || CheckUtils.isNullOrEmptyArray(addressList)) {
            this.props.onUpdate(this.getInitialState());
            return;
        }

        const selectedAddress: Address | undefined = addressList.find(x => x.id === selectedAddressId);
        if (CheckUtils.isNullObject(selectedAddress)) {
            return;
        }
        this.props.onUpdate(selectedAddress!);
    }

    updateValidationErrors(key: string, hasError: boolean): void {
        // const hadError = this.state.validationErrors.some(x => x == key);
        let validationErrors: Array<string> = Object.assign([], this.state.validationErrors);

        if (CheckUtils.isNullObject(validationErrors)) {
            validationErrors = [] as Array<string>;
        }

        if (!hasError) {
            this.setState({
                validationErrors: validationErrors.filter(x => x !== key)
            });
            return;
        }

        if (!validationErrors.some(x => x === key)) {
            validationErrors.push(key);
            this.setState({ validationErrors });
        }
    }

    getAddressString(address: Address): string {
        const userData = [address.firstName, address.middleName, address.lastName].filter(Boolean).join(" ");
        const addressData = [address.addressLine1, address.addressLine2].filter(Boolean).join(" ");
        return [userData, addressData, address.city, address.postalCode, address.countryCode].filter(Boolean).join(", ");
    }

    renderAddressSelect(addressList: Address[]): JSX.Element | null {
        if (CheckUtils.isNullOrEmptyArray(addressList)) {
            return null;
        }
        const id = this.idPrefix + "address_select";
        let options = addressList.map((address, index) => ({ valueAttribute: address.id, valueTranslated: this.getAddressString(address) } as SelectOption));
        options.push({ valueAttribute: this.newAddressId, valueTranslated: this.translations.newAddress } as SelectOption);
        this.addressList = addressList;
        return (
            <>
                <p>{this.translations.selectAddress}</p>
                <Select
                    displayLabel={false}
                    name={id}
                    id={id}
                    isRequired={true}
                    additionalClassName="address-select"
                    options={options}
                    selectedOption={this.state.selectedAddressId}
                    onSelectionChange={(value: string) => {
                        this.setState({ selectedAddressId: value });
                        this.onAddressSelectionChange(value, addressList);
                    }}
                />
            </>
        );
    }

    renderNewAddressForm(hasAddressList: boolean): JSX.Element | null {
        if (this.state.selectedAddressId !== this.newAddressId && hasAddressList) {
            return null;
        }
        return (
            <fieldset className="group-select" id="billing-new-address-form" >
                <ul>
                    {this.renderInput(this.translations.firstName, this.inputIds.firstName, this.state.firstName, "firstName")}
                    <ConfigurationContext.Consumer>
                        {(configurationContext) =>
                            !configurationContext.isFeatureFlagEnabled("hideCustomerMiddleName") &&
                            this.renderInput(this.translations.middleName, this.inputIds.middleName, this.state.middleName, "middleName")
                        }
                    </ConfigurationContext.Consumer>
                    {this.renderInput(this.translations.lastName, this.inputIds.lastName, this.state.lastName, "lastName")}

                    {this.props.displayEmailAddress &&
                        this.renderEmailInput(this.translations.email, this.inputIds.email, this.state.email, "email")
                    }

                    {this.renderInput(this.translations.company, this.inputIds.company, this.state.company, "company")}
                    {this.renderInput(this.translations.address, this.inputIds.address, this.state.addressLine1, "addressLine1")}
                    {this.renderInput("", this.inputIds.address + "2", this.state.addressLine2, "addressLine2")}
                    {this.renderInput(this.translations.postalCode, this.inputIds.postalCode, this.state.postalCode, "postalCode")}
                    {this.renderInput(this.translations.city, this.inputIds.city, this.state.city, "city")}
                    {this.renderInput(this.translations.phone, this.inputIds.phone, this.state.phoneNumber, "phoneNumber")}

                    {this.renderCountrySelect()}
                </ul>
                <SaveAddressCheckbox
                    checked={this.props.saveAddress}
                    onChange={this.props.onSaveAddressChanged}
                    id={this.idPrefix + "save-checkbox"}
                />
            </fieldset>
        );
    }

    renderCountrySelect(): JSX.Element {
        let options: SelectOption[] = [];

        Object.entries(this.state.supportedCountries).forEach(
            ([key, value]) => options.push({ valueAttribute: key, valueTranslated: value } as SelectOption)
        );

        return (
            <li className='select-container'>
                <Select
                    name={this.inputIds.country}
                    label={this.translations.country}
                    id={this.inputIds.country}
                    title={this.translations.country}
                    isRequired={true}
                    additionalClassName="billing-country-wr"
                    options={options}
                    selectedOption={options[0].valueAttribute}
                    onSelectionChange={(countryCode: string) => {
                        this.setState({ countryCode });
                    }}
                />
            </li>
        );
    }

    render(): JSX.Element {
        return (
            <>
                <UserContext.Consumer>
                    {(userContext: IUserContext) => (
                        <>
                            {this.renderAddressSelect(this.convertToCheckoutAddressList(userContext.user.userProfile.addressBook, userContext.user.userProfile.email))}
                            {this.renderNewAddressForm(!CheckUtils.isNullOrEmptyArray(userContext.user.userProfile.addressBook))}
                        </>
                    )}
                </UserContext.Consumer>
            </>
        );
    }

    private getInputId(inputLabel: string): string {
        const id = StringUtils.removeSpacesAndSpecialCharacters(inputLabel).toLowerCase();
        return this.idPrefix + id;
    }

    private getIdPrefix(props: CheckoutAddressFormProps): string {
        if (this.isInvoiceAddress(props.address)) {
            return "invoiceForm_";
        }
        return "deliveryForm_";
    }

    private renderInput<K extends keyof AddressStateType>(label: string, id: string, value: string, stateName: K): JSX.Element {
        const hasApiError = !CheckUtils.isNullOrEmptyArray(this.state.apiErrorResponse)
            && this.state.apiErrorResponse.some(x => x === stateName.toLowerCase());
        const isRequired = this.requiredFields.some(x => x === stateName);
        return (
            <li className='input-container'>
                <Input
                    label={label}
                    id={id}
                    isRequired={isRequired}
                    name={stateName}
                    hasError={hasApiError}
                    showValidationText={this.props.displayValidationMessages}
                    value={value}
                    onValueChange={(name: string, value: any, isValid: boolean) => this.onInputValueChange(stateName, value, isValid)}
                />
            </li>
        );
    }

    private renderEmailInput<K extends keyof AddressStateType>(label: string, id: string, value: string, stateName: K): JSX.Element {
        const hasApiError = !CheckUtils.isNullOrEmptyArray(this.state.apiErrorResponse)
            && this.state.apiErrorResponse.some(x => x === stateName.toLowerCase());
        const isRequired = this.requiredFields.some(x => x === stateName);

        return (
            <li className='input-container'>
                <EmailInput
                    label={label}
                    id={id}
                    isRequired={isRequired}
                    name={stateName}
                    hasError={hasApiError || this.state.validationErrors.some(x => x === stateName)}
                    showValidationText={this.props.displayValidationMessages}
                    value={value}
                    onValueChange={(name: string, value: any, isValid: boolean) => this.onInputValueChange(stateName, value, isValid)}
                />
            </li>
        );
    }

    private convertToCheckoutAddressList(userAddressList: IAddress[], userEmail: string | undefined): Address[] {
        let result = [] as Address[];

        if (CheckUtils.isNullOrEmptyArray(userAddressList)) {
            return result;
        }

        userAddressList.forEach((userAddress) => {
            result.push({
                id: userAddress.id,
                firstName: userAddress.firstName,
                middleName: userAddress.middleName === null || userAddress.middleName === undefined ? '' : userAddress.middleName,
                lastName: userAddress.lastName,
                email: userEmail === undefined ? '' : userEmail,
                company: userAddress.company,
                addressLine1: userAddress.streetAddress1,
                addressLine2: userAddress.streetAddress2 === null || userAddress.streetAddress2 === undefined ? '' : userAddress.streetAddress2,
                postalCode: userAddress.postalCode,
                city: userAddress.city,
                phoneNumber: userAddress.telephone,
                countryCode: userAddress.country,
            });
        });

        return result;
    }
}

CheckoutAddressForm.contextType = TranslationsContext;