import * as React from 'react';
import CheckUtils from '../utilities/check-utils';
import { Product } from '../infrastructure/http/modules/product/product.models';
import ProductList from './product-list/product-list';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import Spinner from './common/spinner';
import Breadcrumb from './common/breadcrumb';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';
import { FeatureFlagValues, RouterPaths } from '../infrastructure/const';
import { Redirect, RouteComponentProps } from 'react-router-dom';

class RecommendedProductListPageProps {
}

class RecommendedProductListPageState {
  productsList: Array<Product>;
  isLoaded: boolean;
  redirect: boolean;

  constructor() {
    this.productsList = [];
    this.isLoaded = false;
    this.redirect = false;
  }
}

export default class RecommendedProductListPage extends React.Component<RouteComponentProps<RecommendedProductListPageProps>, RecommendedProductListPageState> {
  constructor(props: RouteComponentProps<RecommendedProductListPageProps>, context: IConfigurationContext) {
    super(props, context);
    this.state = new RecommendedProductListPageState();
  }
  componentDidMount(): void {
    this.getProducts();
  }

  getProducts(): void {
    if (!this.context.isFeatureFlagEnabled("showRecommendedProducts")) {
      this.setState({ redirect: true });
      return;
    }

    // Get products from location state
    if (!CheckUtils.isNullObject(this.props.location.state)) {
      const locationState = this.props.location.state as { [key: string]: any };

      if (!CheckUtils.isNullObject(locationState.products)) {
        this.setState({ productsList: locationState.products as Product[], isLoaded: true });
      }
    }

    // Search for products
    const client = GetHttpClientInstance();

    if (CheckUtils.isNullOrEmptyArray(this.context.recommendedProducts)) {
      this.setState({ redirect: true });
      return;
    }

    client.product.searchByItemNumbers(this.context.recommendedProducts, true).then((response) => {
      if (CheckUtils.isNullObject(response) || CheckUtils.isNullOrEmptyArray(response.products)) {
        this.setState({ redirect: true });
        return;
      };

      let result: Array<Product> = []

      this.context.recommendedProducts.forEach((id: string) => {
        let product = response.products.find(x => x.itemNumber === id);
        if (product) {
          result.push(product);
        }
      })

      this.setState({ productsList: result, isLoaded: true });
    })

  }

  renderBreadcrumb() {
    return (
      <ConfigurationContext.Consumer>
        {(configuration: IConfigurationContext) => (
          configuration.getFeatureFlagValue("breadcrumbType") === FeatureFlagValues.BreadcrumbType.Global ?
            <Breadcrumb />
            : null
        )}
      </ConfigurationContext.Consumer>
    );
  }

  render(): JSX.Element | null {
    if (this.state.redirect) {
      return <Redirect to={RouterPaths.LandingPage} />;
    }

    if (!this.state.isLoaded) {
      return <Spinner show />;
    }

    return (
      <div className="col1-layout catalog-category-view categorypath-connectline category-connectline">
        <div className="col-main">
          {this.renderBreadcrumb()}
          {(CheckUtils.isNullObject(this.state.productsList) || this.state.productsList.length === 0) ? null :
            <ProductList products={this.state.productsList} />
          }
        </div>
      </div>
    );
  }
}

RecommendedProductListPage.contextType = ConfigurationContext;