import React, { useContext } from 'react';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import TranslationManager from '../infrastructure/translation-manager';
import { TranslationKeys } from '../infrastructure/const';
import { useHistory } from 'react-router-dom';
import ServerErrorMessage, { ServerErrorTranslations } from './common/server-error-message';

const ErrorPage: React.FC = () => {
    const context = useContext(TranslationsContext);
    const history = useHistory();
    const translationManager: TranslationManager = new TranslationManager(context);
    const translations = {
        customerService: translationManager.getTranslation(TranslationKeys.ERRORPAGE_CUSTOMERSERVICE),
        tryAgain: translationManager.getTranslation(TranslationKeys.ERRORPAGE_TRYAGAIN),
        somethingWentWrong: translationManager.getTranslation(TranslationKeys.ERRORPAGE_SOMETHINGWENTWRONG),
        pleaseContact: translationManager.getTranslation(TranslationKeys.ERRORPAGE_PLEASECONTACT),
        goBackToShop: translationManager.getTranslation(TranslationKeys.ERRORPAGE_GOBACKTOSHOP)
    };

    return (
        <div className="col-main">
            <div className="wrapper">
                <div className='page-not-found'>
                    <ServerErrorMessage translations={translations as ServerErrorTranslations} />
                    <button className="button" onClick={() => history.goBack()}>{translations.goBackToShop}</button>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
