import React, { useContext, SyntheticEvent } from "react";
import { useHistory } from "react-router-dom";
import IStaticContentContext from "../../../infrastructure/context/static-content/static-content.context.interface";
import StaticContentContext from "../../../infrastructure/context/static-content/static-content.context";
import CheckUtils from "../../../utilities/check-utils";
import IntroBannerSlide from "./intro-banner/intro-banner-slide-content";
import IntroBannerSlider from "./intro-banner/intro-banner-slider";

const LandingPageIntroBanner: React.FC = () => {
  const configurationContext =
    useContext<IStaticContentContext>(StaticContentContext);
  const history = useHistory();

  const list = configurationContext.introBannerList;
  const banner = configurationContext.introBanner;

  const onButtonClick = React.useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      if (!(e.target instanceof HTMLButtonElement)) {
        return;
      }
      const url = e.target.dataset["url"];
      if (url) {
        history.push(url);
      }
    },
    [history]
  );

  if (
    CheckUtils.isNullObject(banner) ||
    CheckUtils.isNullOrEmptyString(banner.title)
  ) {
    return null;
  }

  if (!CheckUtils.isNullOrEmptyArray(list)) {
    return (
      <div className="intro-banner">
        {list.length > 1 ? (
          <IntroBannerSlider banners={list} onButtonClick={onButtonClick} />
        ) : (
          <IntroBannerSlide banner={list[0]} onButtonClick={onButtonClick} />
        )}
      </div>
    );
  }

  return (
    <div className="intro-banner">
      <IntroBannerSlide banner={banner} onButtonClick={onButtonClick} />
    </div>
  );
};

export default LandingPageIntroBanner;
