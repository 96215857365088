import React from "react";
import { Link } from "react-router-dom";
import { RouterPaths } from "../../infrastructure/const";

export type ServerErrorTranslations = {
    somethingWentWrong: string;
    tryAgain: string;
    pleaseContact: string;
    customerService: string;
};

type Props = {
    translations: ServerErrorTranslations
};

const ServerErrorMessage = (props: Props) => {
    return (
        <div className="cart-empty-block">
            <div className="wrapper">
                <h1>{props.translations.tryAgain}</h1>
                <p>{props.translations.somethingWentWrong}.</p>
                <p>{props.translations.pleaseContact} <Link to={RouterPaths.CustomerServiceContactUs}>{props.translations.customerService}.</Link></p>
            </div>
        </div>
    );
};

ServerErrorMessage.defaultProps = {
    translations: {
        somethingWentWrong: 'Sorry, something went wrong',
        tryAgain: 'Please try again',
        pleaseContact: 'If you continue experiencing issues, please contact',
        customerService: 'Customer Service',
    }
};

export default ServerErrorMessage;