import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import UserContext from "../../infrastructure/context/user/user.context";
import IUserContext from "../../infrastructure/context/user/user.context.interface";
import {
  KlarnaTransactionConfirmResponse,
  TransactionResponse,
} from "../../infrastructure/http/modules/cart/cart.models";
import Spinner from ".././common/spinner";
import { GetHttpClientInstance } from "../../infrastructure/context/http/http-context-provider";
import InnerHTML from "dangerously-set-html-content";
import { withRouter } from "react-router-dom";
import { RouterPaths } from "../../infrastructure/const";
import CheckUtils from "../../utilities/check-utils";
import GoogleTagManager from "../../external-scripts/gmt-google-tag-manager";
import PaymentConfirmationInner from "./common/payment-confirmation";
import OrderConfirmationInner from "./common/order-confirmation";

type KlarnaConfirmationProps = {
  transactionId: string;
  correlationId: string;
};

type KlarnaConfirmationState = {
  isLoading: boolean;
  orderNumber: string;
  paymentId: string | null;
  timeoutId: NodeJS.Timeout | null;
  klarnaConfirmationCompleted: boolean;
  __html: string;
};

class KlarnaConfirmation extends React.Component<
  RouteComponentProps<KlarnaConfirmationProps>,
  KlarnaConfirmationState
> {
  private transactionId: string;
  private correlationId: string;

  constructor(
    props: RouteComponentProps<KlarnaConfirmationProps>,
    context: IUserContext
  ) {
    super(props, context);
    this.transactionId = this.props.match.params.transactionId;
    this.correlationId = this.props.match.params.correlationId;

    this.state = {
      isLoading: true,
      orderNumber: "",
      paymentId: null,
      __html: "",
      timeoutId: null,
      klarnaConfirmationCompleted: false,
    };

    this.onKlarnaCompletionError = this.onKlarnaCompletionError.bind(this);
  }

  componentDidMount() {
    this.fetchKlarnaDropIn();
  }

  componentWillUnmount() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
  }

  onKlarnaCompletionError() {
    // Check if the transaction exists - if yes, the payment has been processed and there was a problem retrieving the Klarna confirmation.
    // If not - redirect to the error page
    GetHttpClientInstance()
      .cart.getTransaction(
        this.transactionId,
        this.context.user.userProfile.userId,
        this.correlationId
      )
      .then(() => {
        this.setState({
          klarnaConfirmationCompleted: false,
          isLoading: false,
        });
      })
      .catch(() => {
        this.props.history.push(RouterPaths.Error);
      });
  }

  fetchKlarnaDropIn() {
    GetHttpClientInstance()
      .cart.getKlarnaOrder(
        this.transactionId,
        this.context.user.userProfile.userId,
        this.correlationId,
        this.onKlarnaCompletionError
      )
      .then((transactionResponse: KlarnaTransactionConfirmResponse) => {
        this.setState({
          __html: transactionResponse.dropInHtml,
          orderNumber: transactionResponse.orderNumber,
          isLoading: false,
          klarnaConfirmationCompleted: true,
          paymentId: transactionResponse.paymentId,
        });

        this.context.refreshCart();

        // Get transaction details for google tag manager
        GetHttpClientInstance()
          .cart.getTransaction(
            this.transactionId,
            this.context.user.userProfile.userId,
            this.correlationId
          )
          .then((transactionDetailedResponse: TransactionResponse) => {
            if (
              CheckUtils.isNullOrEmptyString(
                transactionDetailedResponse.orderNumber
              )
            ) {
              transactionDetailedResponse.orderNumber =
                transactionResponse.orderNumber;
            }
            GoogleTagManager.purchase(transactionDetailedResponse);
          });
      })
      .catch(() => {
        this.onKlarnaCompletionError();
      })
      .finally(() => {
        // Redirect to the landing page after 5mins
        const timeoutID = setTimeout(() => {
          this.props.history.push(RouterPaths.LandingPage);
        }, 300 * 1000);

        this.setState({
          timeoutId: timeoutID,
        });
      });
  }

  render(): ReactNode {
    if (this.state.isLoading) {
      return <Spinner show />;
    }

    if (!this.state.klarnaConfirmationCompleted) {
      return (
        <PaymentConfirmationInner
          transactionId={this.transactionId}
          paymentId={this.state.paymentId}
        />
      );
    }

    return (
      <>
        <OrderConfirmationInner orderNumber={this.state.orderNumber} />
        <InnerHTML html={this.state.__html} />
      </>
    );
  }
}

KlarnaConfirmation.contextType = UserContext;

export default withRouter(KlarnaConfirmation);
