import React from 'react';
import NavigationContext from '../../infrastructure/context/navigation/navigation.context';
import INavigationContext from '../../infrastructure/context/navigation/navigation.context.interface';
import { RouterPaths, TranslationKeys } from '../../infrastructure/const';
import CheckUtils from '../../utilities/check-utils';
import { Link } from 'react-router-dom';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import classNames from 'classnames';
import { ProductCategory } from '../../infrastructure/http/modules/product/product.models';

interface Props {
    productName?: string | undefined;
    additionalClasses: string;
}

interface BreadcrumbItemProps {
    url: string;
    title: string;
    isLastItem: boolean;
}

const defaultProps = {
    productName: ""
};

class Breadcrumb extends React.Component<Props> {
    static defaultProps = defaultProps as Props;

    private translations: { [id: string]: string };
    private TranslationManager: TranslationManager;

    constructor(props: Props, context: ITranslationsContext) {
        super(props, context);
        this.TranslationManager = new TranslationManager(context);
        this.translations = {
            home: this.TranslationManager.getTranslation(TranslationKeys.HOME),
            recommendedProducts: this.TranslationManager.getTranslation(TranslationKeys.PRODUCTSLIDER_HEADER_TITLE),
            searchResults: this.TranslationManager.getTranslation(TranslationKeys.SEARCH_SEARCHRESULTS)
        };
    }

    public render(): React.ReactNode {
        const breadcrumbClasses = classNames({
            'breadcrumbs': true,
            [`${this.props.additionalClasses}`]: this.props.additionalClasses ? true : false
        });
        return (
            <NavigationContext.Consumer>
                {(context: INavigationContext) => (
                    <nav id="breadcrumbs" className={breadcrumbClasses}>
                        <div className="wrapper">
                            <ul className="breadcrumbs__list">
                                <li className="home">
                                    <Link to={RouterPaths.LandingPage} title={this.translations.home}> {this.translations.home}</Link>
                                    <BreadcrumbSeparator />
                                </li>
                                {this.GetBreadcrumbItems(context).map((breadcrumbItem) =>
                                    <BreadcrumbItem
                                        key={breadcrumbItem.title}
                                        url={breadcrumbItem.url}
                                        title={breadcrumbItem.title}
                                        isLastItem={breadcrumbItem.isLastItem} />
                                )}
                            </ul>
                        </div>
                    </nav>
                )}
            </NavigationContext.Consumer>
        );
    }

    private GetBreadcrumbItems(context: INavigationContext): BreadcrumbItemProps[] {
        let items: BreadcrumbItemProps[] = [];

        if (CheckUtils.isNullObject(context) || CheckUtils.isNullOrEmptyString(context.currentPath)) {
            return [];
        }

        // Search results page
        if (context.currentPath.includes(RouterPaths.SearchResultsPage)) {
            items.push({
                url: '',
                title: this.getSearchResultsLabel(context.currentPath),
                isLastItem: true
            });
            return items;
        }else if(context.currentPath.includes(RouterPaths.RecommendedProductsPage)){
            items.push({
                url: '',
                title: this.translations.recommendedProducts,
                isLastItem: true
            });
            return items;
        }

        // Product details page
        if (context.currentPath.includes(RouterPaths.DetailsPage)) {
            if (!CheckUtils.isNullOrEmptyString(context.previousPath) && context.previousPath.includes(RouterPaths.SearchResultsPage)) {
                items.push({
                    url: RouterPaths.SearchResultsPage + "/" + this.getSearchResultsQuery(context.previousPath),
                    title: this.getSearchResultsLabel(context.previousPath),
                    isLastItem: false
                });
            } else if (!CheckUtils.isNullOrEmptyArray(context.activeCategory)) {
                context.activeCategory!.forEach(element => {
                    items.push({
                        url: element.url,
                        title: element.title,
                        isLastItem: false
                    });
                });
            }
            items.push({
                url: '',
                title: this.props.productName as string,
                isLastItem: true
            });
            return items;
        }

        // Product list page
        if (!CheckUtils.isNullOrEmptyArray(context.activeCategory)) {
            context.activeCategory!.forEach((element: ProductCategory, index: number) => {
                items.push({
                    url: element.url,
                    title: element.title,
                    isLastItem: context.activeCategory!.length -1 === index
                });
            });
        }
        return items;
    }

    private getSearchResultsLabel(pathname: string): string {
        if (pathname.indexOf(RouterPaths.SearchResultsPage) < 0) {
            return "";
        }

        return `${this.translations.searchResults} '${this.getSearchResultsQuery(pathname)}'`;
    }

    private getSearchResultsQuery(pathname: string): string {
        if (pathname.indexOf(RouterPaths.SearchResultsPage) < 0) {
            return "";
        }

        const searchQueryIndex = pathname.lastIndexOf("/");
        return pathname.substring(searchQueryIndex + 1);
    }
}

const BreadcrumbSeparator = (): JSX.Element => (
    <span></span>
);

const BreadcrumbItem = (props: { url: string, title: string, isLastItem: boolean }): JSX.Element | null => {
    if (CheckUtils.isNullOrEmptyString(props.title)) {
        return null;
    }

    if (props.isLastItem) {
        return (
            <li className="product">
                {props.title}
            </li>
        );
    }
    return (
        <li className="home">
            <Link to={props.url}>
                {props.title}
            </Link>
            <BreadcrumbSeparator />
        </li>
    );
};

Breadcrumb.contextType = TranslationsContext;

export default Breadcrumb;
