import React, { ReactNode } from 'react';
import Input, { InputProps } from './input';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import { TranslationKeys, PasswordMinLength } from '../../infrastructure/const';

class PasswordInputProps extends InputProps {
    minLength: number;
    includeRegexValidation: boolean;

    constructor() {
        super();
        this.minLength = PasswordMinLength.Register;
        this.includeRegexValidation = true;
    }
}

class PasswordInput extends React.Component<PasswordInputProps> {
    private inputRef = React.createRef<Input>();
    static defaultProps: PasswordInputProps = {
        minLength: PasswordMinLength.Register,
        includeRegexValidation: true,
        name: 'password',
        label: ''
    };

    private maxLength = 30;

    public render(): ReactNode {
        const parentProps = { ...this.props };

        parentProps.onValueChange = (name: string, value: string, isValid: boolean) => this.handleChange(name, value, isValid);

        if (!parentProps.helpTextHtml) {
            parentProps.helpTextHtml = new TranslationManager(this.context).getTranslation(TranslationKeys.PLEASE_ENTER_SIX_OR_MORE_CHARACTERS);
        }
        parentProps.type = 'password';

        return (
            <Input {...parentProps} ref={this.inputRef} />
        );
    }

    overrideValueFromParent(newValue: string) {
        if (this.inputRef.current !== undefined && this.inputRef.current != null) {
            this.inputRef.current.overrideValueFromParent(newValue);
        }
    }

    private handleChange(name: string, value: string, isValid: boolean): void {
        let isPasswordValid = true;

        if (!this.props.onValueChange) {
            return;
        }

        if (!value) {
            isPasswordValid = false;
            this.props.onValueChange(name, value, isPasswordValid);
            return;
        }

        value = value.trim();

        if (value.length < this.props.minLength || value.length > this.maxLength) {
            isPasswordValid = false;
        } else if (this.props.includeRegexValidation && !(/\d/.test(value) && /[a-zA-Z]/.test(value))) {
            isPasswordValid = false;
        }

        this.props.onValueChange(name, value, isPasswordValid);
    }
}

PasswordInput.contextType = TranslationsContext;

export default PasswordInput;
