import React from 'react';

type PageHeaderTopBarProps = {
    phoneNumber: string;
    contactHours: string;
    sellingPoints: string[];
};

const PageHeaderTopBar = (props: PageHeaderTopBarProps) => {
    return (
        <div className="header-top-bar">
            <div className="wrapper">
                <div className="header-top-bar__wrapper">
                    <ul className="header-top-bar__list">
                        {props.sellingPoints.map((item, index) =>
                            <li className="header-top-bar__list-item" key={index}>{item}</li>
                        )}
                    </ul>
                    {props.phoneNumber && <div className="header-top-bar__phone-wrapper">
                        <a href={`tel:${props.phoneNumber}`} className="header-top-bar__phone">{props.phoneNumber}</a>
                        <span className="header-top-bar__contact-hours">{props.contactHours}</span>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default PageHeaderTopBar;