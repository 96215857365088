import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import CheckUtils from '../../utilities/check-utils';

type SelectProps = {
    name: string,
    id: string,
    title: string,
    isRequired: boolean,
    label: string,
    displayLabel: boolean,
    additionalClassName: string,
    options: SelectOption[],
    selectedOption: string,
    onSelectionChange: ((value: string) => void),
    placeholderText?: string
};

type SelectState = {
    isOpen: boolean,
    placeholder: string
};

export type SelectOption = {
    valueTranslated: string,
    valueAttribute: string
};

const defaultProps = {
    label: "",
    displayLabel: true,
    title: ""
};

export default class Select extends React.Component<SelectProps, SelectState> {
    static defaultProps = defaultProps;
    private dropdownRef: React.RefObject<HTMLDListElement>;

    constructor(props: SelectProps) {
        super(props);
        this.state = {
            isOpen: false,
            placeholder: CheckUtils.isNullOrEmptyString(props.placeholderText) ? '' : props.placeholderText
        } as SelectState;
        this.dropdownRef = React.createRef();
    }

    onBlur(e: any) {
        this.setState({ isOpen: false });
    }

    handleChange(value: string): void {
        this.props.onSelectionChange(value);
        this.onBlur(value);
    }

    handleClick(e: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        const selectedItemText = this.props.options.find(x => x.valueAttribute === this.props.selectedOption);

        return (
            <>
                <div className={this.props.additionalClassName}>
                    <dl
                        className={`custom-select ${this.state.isOpen ? 'open' : ''}`}
                        onBlur={(event: React.SyntheticEvent<HTMLDListElement>) => this.onBlur(event)}
                    >
                        <dt>
                            <button
                                type="button"
                                className={"custom-select__btn" + (selectedItemText ? "" : " placeholder")}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.handleClick(event)}
                            >{ReactHtmlParser(selectedItemText ? selectedItemText.valueTranslated : this.state.placeholder)}</button>
                        </dt>
                        <dd
                            className="custom-select__dropdown-menu"
                            ref={this.dropdownRef}
                            style={this.state.isOpen ? { maxHeight: this.dropdownRef.current!.scrollHeight + 'px' } : { maxHeight: '0px' }}
                        >
                            <ul
                                className="custom-select__menu-list"
                            >
                                {this.props.options.map((option) => (
                                    <li
                                        key={option.valueAttribute}
                                        className={`custom-select__menu-item ${option.valueAttribute === this.props.selectedOption ? 'custom-select__menu-item--active' : ''}`}
                                        onMouseDown={(e: React.MouseEvent<HTMLLIElement>) => {
                                            this.handleChange(option.valueAttribute);
                                        }}
                                    >
                                        {option.valueTranslated}
                                    </li>
                                ))}
                            </ul>
                        </dd>
                    </dl>
                </div>
                {this.props.displayLabel &&
                    <label className={`custom-select__label ${this.props.isRequired ? 'required' : ''}`}>
                        {this.props.label}{this.props.isRequired && <span className="required"> *</span>}
                    </label>
                }
            </>
        );
    }
}

Select.contextType = TranslationsContext;
