import React, { ReactNode, FormEvent, SyntheticEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { TranslationKeys, RouterPaths, RouterStateParams, PasswordMinLength, FeatureFlagValues, DefaultFeatureFlags } from '../infrastructure/const';
import AccountMenu from './account/menu/account-menu';
import EmailInput from './common/email-input';
import Input from './common/input';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import TranslationManager from '../infrastructure/translation-manager';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import UserContext from '../infrastructure/context/user/user.context';
import PasswordInput from './common/password-input';
import CheckUtils from '../utilities/check-utils';
import { UserProfileResponse } from '../infrastructure/http/modules/auth/auth.models';
import { UpdateMeRequest } from '../infrastructure/http/modules/account/account.models';
import { RestError, ErrorCodes } from '../infrastructure/http/modules/errors';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import ErrorMessages from './common/error-messages';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';

const EditContactInformationPage: React.FC = () => {
    return (
        <UserContext.Consumer>
            {(userContext: IUserContext) =>
                <TranslationsContext.Consumer>
                    {(translationsContext: ITranslationsContext) =>
                        <ConfigurationContext.Consumer>
                            {(configurationContext: IConfigurationContext) =>
                                <EditContactInformationPageInner
                                    translationsContext={translationsContext}
                                    userContext={userContext}
                                    configurationContext={configurationContext}
                                />
                            }
                        </ConfigurationContext.Consumer>
                    }
                </TranslationsContext.Consumer>
            }
        </UserContext.Consumer>
    );
};

type Props = {
    userContext: IUserContext;
    translationsContext: ITranslationsContext;
    configurationContext: IConfigurationContext;
};

type State = {
    userId: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    email?: string;
    receiveMarketingMaterials?: boolean;

    changePassword: boolean;
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;

    errors: { [key: string]: boolean };
    errorCodes: Array<keyof typeof ErrorCodes>;

    showPasswordsShouldMatchHelpText: boolean;
    isLoaded: boolean;
    validationErrorMessage: string;

    [key: string]: any;

    redirectToAccountDashboard: boolean;
    redirectToLogin: boolean;
};

class EditContactInformationPageInner extends React.Component<Props, State> {
    constructor(props: Props, private translations: { [key: string]: string }) {
        super(props);

        this.state = {
            userId: '',
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            receiveMarketingMaterials: false,
            changePassword: false,
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',

            showPasswordsShouldMatchHelpText: false,
            isLoaded: false,
            validationErrorMessage: '',
            errors: {
                firstName: false,
                lastName: false,
                email: false,
                currentPassword: false,
                newPassword: false,
                confirmNewPassword: false,
            },
            errorCodes: [],
            redirectToAccountDashboard: false,
            redirectToLogin: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePasswordClick = this.handleChangePasswordClick.bind(this);
    }

    public componentDidMount(): void {
        this.initializeTranslations();
        this.initializeUserState();
        this.setState({ isLoaded: true });
    }

    public render(): ReactNode {
        if (!this.state.isLoaded) {
            return null;
        }

        return (
            <div className='col2-left-layout oticon-address'>
                <div className='wrapper'>
                    <AccountMenu />
                    <div className='col-main'>
                        <div className='my-account'>
                            <div className='dashboard account-wr'>
                                {this.renderRedirectToDashboard()}
                                {this.renderRedirectToLogin()}
                                <h2>{this.translations.header}</h2>
                                <h3>{this.state.validationErrorMessage && this.state.validationErrorMessage}</h3>
                                <ErrorMessages errorKeys={this.state.errorCodes.map(x => ErrorCodes[x])} />
                                <form onSubmit={this.handleSubmit}>
                                    <div className='fieldset'>
                                        <ul className='form-list'>
                                            {this.renderInput(this.translations.firstName, 'firstName', this.state.firstName, true, this.state.errors.firstName)}
                                            {!this.props.configurationContext.isFeatureFlagEnabled("hideCustomerMiddleName") &&
                                                this.renderInput(this.translations.middleName, 'middleName', this.state.middleName, false, false)
                                            }
                                            {this.renderInput(this.translations.lastName, 'lastName', this.state.lastName, true, this.state.errors.lastName)}
                                            {this.renderEmailInput()}
                                            {this.renderChangePasswordCheckbox()}
                                        </ul>
                                    </div>
                                    {this.state.changePassword &&
                                        <div className='fieldset change-password-block'>
                                            <h2 className='legend'>{this.translations.changePassword}</h2>
                                            <ul className='form-list'>
                                                {this.renderPasswordInput(this.translations.currentPassword, 'currentPassword', this.state.currentPassword, this.state.errors.currentPassword, false, PasswordMinLength.Login)}
                                                {this.renderPasswordInput(this.translations.newPassword, 'newPassword', this.state.newPassword, this.state.errors.newPassword, true, PasswordMinLength.Register)}
                                                {this.renderPasswordInput(this.translations.confirmNewPassword, 'confirmNewPassword', this.state.confirmNewPassword, this.state.errors.confirmNewPassword, true, PasswordMinLength.Register, this.translations.pleaseMakeSureYourPasswordsMatch)}
                                            </ul>
                                        </div>
                                    }
                                    <div className='buttons-set'>
                                        <button type='submit' title={this.translations.save} className='button'>
                                            {this.translations.save}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderInput(label: string, name: string, value: string | undefined, isRequired: boolean, hasError: boolean, type: string = 'text'): ReactNode {
        return (
            <li className='input-container'>
                <Input
                    label={label}
                    name={name}
                    value={value}
                    type={type}
                    isRequired={isRequired}
                    hasError={hasError}
                    showValidationText={hasError}
                    onValueChange={this.handleChange}
                />
            </li>
        );
    }

    private renderEmailInput(): ReactNode {
        return (
            <li className='input-container'>
                <EmailInput
                    label={this.translations.emailAddress}
                    name={'email'}
                    value={this.state.email}
                    isRequired={true}
                    hasError={this.state.errors.email}
                    showValidationText={this.state.errors.email}
                    onValueChange={this.handleChange}
                />
            </li>
        );
    }

    private renderPasswordInput(label: string, name: string, value: string | undefined, hasError: boolean, includeRegexValidation: boolean, minLength: number, helpText: string = ''): ReactNode {
        return (
            <li className='input-container'>
                <PasswordInput
                    includeRegexValidation={includeRegexValidation}
                    minLength={minLength}
                    label={label}
                    name={name}
                    value={value}
                    isRequired={true}
                    hasError={hasError}
                    showValidationText={hasError}
                    helpTextHtml={this.state.showPasswordsShouldMatchHelpText ? helpText : ''}
                    onValueChange={this.handleChange}
                />
            </li>
        );
    }

    private renderRedirectToLogin(): ReactNode {
        return (
            <>
                {this.state.redirectToLogin && <Redirect to={RouterPaths.Login} />}
            </>
        );
    }

    private renderRedirectToDashboard(): ReactNode {
        return (
            <>
                {this.state.redirectToAccountDashboard &&
                    <Redirect
                        push
                        to={{
                            pathname: RouterPaths.AccountDashboard,
                            state: { [RouterStateParams.ShowEditedContactInformationSuccessMessage]: true },
                        }}
                    />
                }
            </>
        );
    }

    private renderChangePasswordCheckbox(): ReactNode {
        return (
            <li className='control'>
                <input checked={this.state.changePassword} onChange={this.handleChangePasswordClick} title={this.translations.changePassword} className='checkbox css-checkbox hidden-checkbox validation-passed' type='checkbox' name='change_password' id='change_password' />
                <label className='css-label' htmlFor='change_password'>{this.translations.changePassword}</label>
            </li>
        );
    }

    private initializeUserState(): void {
        const { userProfile } = this.props.userContext.user;

        this.setState({
            userId: userProfile.userId,
            firstName: userProfile.firstName,
            middleName: userProfile.middleName,
            lastName: userProfile.lastName,
            email: userProfile.email,
            errors: {
                firstName: false,
                lastName: false,
                email: false,
            }
        });
    }

    private initializeTranslations(): void {
        const translationManager = new TranslationManager(this.props.translationsContext);

        this.translations = {
            header: translationManager.getTranslation(TranslationKeys.ACCOUNT_ACCOUNTINFORMATION_HEADER),
            firstName: translationManager.getTranslation(TranslationKeys.FIRST_NAME),
            middleName: translationManager.getTranslation(TranslationKeys.MIDDLE_NAME),
            lastName: translationManager.getTranslation(TranslationKeys.LAST_NAME),
            emailAddress: translationManager.getTranslation(TranslationKeys.EMAIL),
            changePassword: translationManager.getTranslation(TranslationKeys.ACCOUNT_ACCOUNTINFORMATION_CHANGEPASSWORD),
            currentPassword: translationManager.getTranslation(TranslationKeys.ACCOUNT_ACCOUNTINFORMATION_CURRENTPASSWORD),
            newPassword: translationManager.getTranslation(TranslationKeys.ACCOUNT_ACCOUNTINFORMATION_NEWPASSWORD),
            confirmNewPassword: translationManager.getTranslation(TranslationKeys.ACCOUNT_ACCOUNTINFORMATION_CONFIRMNEWPASSWORD),
            save: translationManager.getTranslation(TranslationKeys.ACCOUNT_SAVE),
            pleaseMakeSureYourPasswordsMatch: translationManager.getTranslation(TranslationKeys.PLEASE_MAKE_SURE_YOUR_PASSWORDS_MATCH),
        };
    }

    private handleChangePasswordClick(event: SyntheticEvent<HTMLInputElement>): void {
        const { checked } = event.currentTarget;

        this.setState({ changePassword: checked });
    }

    private handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();

        const newState = this.state;

        if (this.state.changePassword) {
            const keys = ['currentPassword', 'newPassword', 'confirmNewPassword'];

            for (const key of keys) {
                if (CheckUtils.isNullOrEmptyString(this.state[key])) {
                    newState.errors[key] = true;
                }
            }
        }

        this.setState({ newState }, () => {
            for (const key of Object.keys(this.state.errors)) {
                if (this.state.errors[key]) {
                    return;
                }
            }

            this.updateMe();
        });
    }

    private updateMe() {
        const { tokenInfo } = this.props.userContext.user;

        if (!tokenInfo) {
            this.handleUnauthorized();
            return;
        }

        const { account } = GetHttpClientInstance();

        const updateMe: UpdateMeRequest = {
            firstName: this.state.firstName,
            middleName: this.state.middleName,
            lastName: this.state.lastName,
            email: this.state.email,
            receiveMarketingMaterials: this.state.receiveMarketingMaterials,
            updatePassword: this.state.changePassword,
        };

        if (this.state.changePassword) {
            updateMe.currentPassword = this.state.currentPassword;
            updateMe.password = this.state.newPassword;
            updateMe.passwordConfirmed = this.state.confirmNewPassword;
        }

        account
            .updateMe(updateMe, { accessToken: tokenInfo.accessToken })
            .then((userProfileResponse: UserProfileResponse) => {
                this.props.userContext.updateUserProfile(userProfileResponse);
                this.setState({ redirectToAccountDashboard: true });
            })
            .catch((err: RestError) => this.catchHttpError(err));
    }

    private handleChange(name: string, value: string, isValid: boolean) {
        const errors = this.state.errors;
        errors[name] = !isValid;

        if (name === 'firstName' || name === 'lastName') {
            errors[name] = CheckUtils.isNullOrEmptyString(value);
        } else if (name === 'currentPassword' || name === 'newPassword' || name === 'confirmNewPassword' || name === 'email') {
            errors[name] = !isValid;
        } else {
            errors[name] = false;
        }

        let showPasswordsShouldMatchHelpText = false;
        if (name === 'confirmNewPassword') {
            const confirmedPasswordDoesNotMatch = value !== this.state.newPassword;
            showPasswordsShouldMatchHelpText = confirmedPasswordDoesNotMatch;
            errors.confirmNewPassword = confirmedPasswordDoesNotMatch;
        }

        this.setState({ errors: errors, [name]: value, showPasswordsShouldMatchHelpText: showPasswordsShouldMatchHelpText });
    }

    private catchHttpError(err: RestError): void {
        if (err.statusCode === 401) {
            this.handleUnauthorized();
            return;
        }
        if (!CheckUtils.isNullObject(err.data) && !CheckUtils.isNullOrEmptyArray(err.data.errors)) {
            const errorCodes: Array<keyof typeof ErrorCodes> = Object.keys(ErrorCodes).filter(((x: string) => err.data.errors.some(error => error.errorCode === ErrorCodes[x as keyof typeof ErrorCodes]))).map((x: string) => (x as keyof typeof ErrorCodes));
            if (!CheckUtils.isNullOrEmptyArray(errorCodes)) {
                this.setState({ errorCodes });
                return;
            }
        }
        this.setState({ validationErrorMessage: err.data.message });
    }

    private handleUnauthorized(): void {
        this.props.userContext.clearUser();
        this.setState({ redirectToLogin: true });
    }
}

export default EditContactInformationPage;
