import React, { useContext } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { TranslationKeys } from '../../infrastructure/const';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../infrastructure/translation-manager';

type Props = {
  onConfirmClick: (() => void),
  onCancelClick: (() => void),
  isOpen: boolean
};

const RemoveProductConfirmationPopup = (props: Props): JSX.Element => {
  const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
  const translationManager = new TranslationManager(translationsContext);
  const translations = {
    confirmationMessage: translationManager.getTranslation(TranslationKeys.MINICART_REMOVEPRODUCT_CONFIRMATION),
    cancel: translationManager.getTranslation(TranslationKeys.CANCEL),
    remove: translationManager.getTranslation(TranslationKeys.REMOVE)
  };

  return (
    <Popup closeOnDocumentClick={false} open={props.isOpen}>
      <div className="modal">
        <button className="close" onClick={props.onCancelClick}> &times;</button>
        <div className="content">
          {translations.confirmationMessage}
        </div>
        <div className="actions">
          <button className="button" onClick={props.onConfirmClick}>
            {translations.remove}
          </button>
          <button className="button" onClick={props.onCancelClick}>
            {translations.cancel}
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default RemoveProductConfirmationPopup;