import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Product } from '../infrastructure/http/modules/product/product.models';
import ProductList from './product-list/product-list';
import { ProductsSelect, FeatureFlagValues, TranslationKeys } from '../infrastructure/const';
import SortUtils from '../utilities/sort-utils';
import CheckUtils from '../utilities/check-utils';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import Spinner from './common/spinner';
import Breadcrumb from './common/breadcrumb';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';
import TranslationManager from '../infrastructure/translation-manager';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import SortByToolbar from './product-list/sort-by-toolbar';

type SearchResultPageState = {
  searchQuery: string;
  productsList: Array<Product>;
  productsListSorted: Array<Product>;
  isLoaded: boolean;
};

type SearchResultPageProps = {
  searchQuery: string;
};

class SearchResultPage extends React.Component<RouteComponentProps<SearchResultPageProps>, SearchResultPageState> {
  private TranslationManager: TranslationManager;
  private translations: { [id: string]: string };

  constructor(props: RouteComponentProps<SearchResultPageProps>, context: ITranslationsContext) {
    super(props, context);

    this.TranslationManager = new TranslationManager(context);
    this.state = {
      searchQuery: '',
      productsListSorted: [] as Array<Product>,
      productsList: [] as Array<Product>,
      isLoaded: false
    } as SearchResultPageState;

    this.translations = {
      searchResults: this.TranslationManager.getTranslation(TranslationKeys.SEARCH_SEARCHRESULTS),
      noResults: this.TranslationManager.getTranslation(TranslationKeys.SEARCH_NORESULTS),
    };
  }

  componentDidMount(): void {
    this.getSearchProducts(this.props.match.params.searchQuery);
  }

  componentDidUpdate(prevProps: RouteComponentProps<SearchResultPageProps>): void {
    if (prevProps.match.params.searchQuery !== this.props.match.params.searchQuery) {
      this.getSearchProducts(this.props.match.params.searchQuery);
    }
  }

  getSearchProducts(searchQuery: string): void {
    if (CheckUtils.isNullObject(searchQuery)) {
      return;
      // TODO redirect to landing?
    }
    this.setState({ searchQuery }, () => {
      GetHttpClientInstance().product.search(this.state.searchQuery).then((result) => {
        if (!CheckUtils.isNullObject(result)) {
          this.setState({
            productsList: result.products,
            productsListSorted: result.products,
            isLoaded: true
          });
        }
      });
    });
  }

  hasProducts(): boolean {
    return !CheckUtils.isNullOrEmptyArray(this.state.productsListSorted);
  }


  renderMessageBlock(hasResults: boolean = true): JSX.Element {
    return (
      <div className="page-title wrapper message-block">
        {/* todo */}
        {this.translations.searchResults} '{this.state.searchQuery}'
        {!hasResults &&
          <p className="note-msg">
            {this.translations.noResults}
          </p>
        }
      </div>
    );
  }

  renderProductsList(): JSX.Element | null {
    if (this.state.productsListSorted === null || this.state.productsListSorted === undefined || this.state.productsListSorted.length === 0) {
      return null;
    }
    return (
      <div className="category-products wrapper">
        <SortByToolbar
          currentItemsCount={this.state.productsList.length}
          productsList={this.state.productsList}
          handleProductListSorting={(newList: Array<Product>) => this.setState({ productsListSorted: newList })}
        />
        <ProductList products={this.state.productsListSorted} />
      </div>
    );
  }

  renderBreadcrumb() {
    return (
      <ConfigurationContext.Consumer>
        {(configuration: IConfigurationContext) => (
          configuration.getFeatureFlagValue("breadcrumbType") === FeatureFlagValues.BreadcrumbType.Global ?
            <Breadcrumb />
            : null
        )}
      </ConfigurationContext.Consumer>
    );
  }

  render(): JSX.Element | null {
    // TODO IJAR => add error handling
    if (!this.state.isLoaded) {
      return <Spinner show />;
    }

    return (
      <div className="catalog-category-searchresult">
        <div className="col1-layout">
          <div className="col-main">
            {this.renderBreadcrumb()}
            {this.hasProducts() ? (
              <>{this.renderMessageBlock()}
                <div className="results-view">
                  {this.renderProductsList()}
                </div>
              </>
            ) : (
              this.renderMessageBlock(false)
            )}
          </div>
        </div>
      </div>
    );
  }
}

SearchResultPage.contextType = TranslationsContext;
export default withRouter(SearchResultPage);