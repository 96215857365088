import React, { useContext } from 'react';
import { AddProductRequest } from '../../infrastructure/http/modules/cart/cart.models';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import UserContext from '../../infrastructure/context/user/user.context';
import { GetHttpClientInstance } from '../../infrastructure/context/http/http-context-provider';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../infrastructure/translation-manager';
import { TranslationKeys } from '../../infrastructure/const';

type AddToCartButtonProps = {
    disabled: boolean,
    productId: string | null,
    quantity: number,
    classAttr: string,
    onClick: (() => void)
    onCartRefresh: (() => void)
};

const AddToCartButton = (props: AddToCartButtonProps) => {
    const context = useContext<IUserContext>(UserContext);
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);

    const buttonText = new TranslationManager(translationsContext).getTranslation(TranslationKeys.CART_ADDTOCART);

    function handleClick() {
        if (props.disabled) {
            return;
        }
        if (props.productId == null) {
            return;
        }

        const request: AddProductRequest = {
            userId: context.user.userProfile!.userId,
            products: { [props.productId]: props.quantity }
        };
        props.onClick();
        context.setCartUpdateInProgress().then(() => {
            GetHttpClientInstance().cart.addProduct(request).then((result) => {
                context.refreshCart().then(() => {
                    props.onCartRefresh();
                });
            });
        });
    }

    return (
        <button type="button" title={buttonText} className={props.classAttr} onClick={handleClick} disabled={props.disabled}>
            <span className="icon-add-to-cart"></span>
            {buttonText}
        </button>
    );
};
AddToCartButton.defaultProps = {
    disabled: false,
    quantity: 1,
    classAttr: "button btn-white buy-button",
    onClick: (() => {/* */ }),
    onCartRefresh: (() => {/* */ })
};

export default AddToCartButton;