import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FeatureFlagValues } from '../../infrastructure/const';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import NavigationContext from '../../infrastructure/context/navigation/navigation.context';
import INavigationContext from '../../infrastructure/context/navigation/navigation.context.interface';
import { ProductCategory, ProductSubcategory } from '../../infrastructure/http/modules/product/product.models';
import CheckUtils from '../../utilities/check-utils';

const PageHeaderBottomLinks: React.FC = () => {
    const navigationContext: INavigationContext = useContext(NavigationContext);
    const configurationContext: IConfigurationContext = useContext(ConfigurationContext);

    const categories: ProductCategory[] = navigationContext.categories;
    const activeCategory: ProductCategory[] | null = navigationContext.activeCategory;

    if (CheckUtils.isNullOrEmptyArray(categories)) {
        return null;
    }

    if (configurationContext.getFeatureFlagValue("topMenuType") !== FeatureFlagValues.TopMenu.Multilevel) {
        return null;
    }

    return (
        <div className="header__border-wrapper">
            <div className="wrapper clearfix header__bottom br-large">
                <ul className="header-bottom-links">
                    {categories.map((category) => (
                        <MenuLinkInner
                            key={category.id}
                            category={category}
                            activeCategoryUrl={CheckUtils.isNullOrEmptyArray(activeCategory) ? "" : activeCategory![0].url}
                            subcategories={category.subcategories} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

const MenuLinkInner = (props: { category: ProductCategory, activeCategoryUrl: string, subcategories: ProductSubcategory[] }): JSX.Element => {
    const [hasSubcategories, setHasSubcategories] = useState(true);

    useEffect(() => {
        if (CheckUtils.isNullOrEmptyArray(props.subcategories)) {
            setHasSubcategories(false);
        };
    }, [props.subcategories]);

    return (
        <li className={
            `header-bottom-links__list-item
            ${hasSubcategories ? 'header-bottom-links__list-item--arrow-down' : ''}`
        }>
            <Link
                to={props.category.url}
                className="header-bottom-links__list-link"
                title={props.category.title}>
                {props.category.title.toLowerCase().indexOf("connectline") > -1 ? "Connectline Product Range" : props.category.title}
            </Link>
            <MenuSubcategoriesLinkInner category={props.category} subcategories={props.subcategories} />
        </li>
    );
};

const MenuSubcategoriesLinkInner = (props: { category: ProductCategory, subcategories: ProductSubcategory[] }): JSX.Element | null => {
    if (CheckUtils.isNullOrEmptyArray(props.subcategories)) {
        return null;
    }

    return (
        <ul className="header-bottom-links__lvl2">
            {props.subcategories.map((subcategory) => (
                <li className="header-bottom-links__lvl2-item" key={subcategory.title}>
                    <Link
                        to={props.category.url + subcategory.url}
                        title={subcategory.title}>
                        {subcategory.title.toLowerCase().indexOf("connectline") > -1 ? "Connectline Product Range" : subcategory.title}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default PageHeaderBottomLinks;