import React, { ReactNode } from 'react';
import AccountMenu from './account/menu/account-menu';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import OrderHistoryDetails from './account/order-history/order-history-details';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import { RouteComponentProps } from 'react-router-dom';
import UserContext from '../infrastructure/context/user/user.context';
import IUserContext from '../infrastructure/context/user/user.context.interface';

type Props = {
    orderNumber: string;
};

class AccountOrderHistoryDetailsPage extends React.Component<RouteComponentProps<Props>> {
    public render(): ReactNode {
        return (
            <div className='col2-left-layout oticon-address'>
                <div className='wrapper'>
                    <AccountMenu />
                    <div className='col-main'>
                        <div className='my-account'>
                            <TranslationsContext.Consumer>
                                {(translationsContext: ITranslationsContext) =>
                                    <UserContext.Consumer>
                                        {(userContext: IUserContext) =>
                                            <OrderHistoryDetails
                                                orderNumber={this.props.match.params.orderNumber}
                                                translationsContext={translationsContext}
                                                userContext={userContext}
                                            />
                                        }
                                    </UserContext.Consumer>
                                }
                            </TranslationsContext.Consumer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountOrderHistoryDetailsPage;
