import React, { ReactNode } from 'react';
import { ContactForm } from '../../infrastructure/http/modules/customer-service/customer-service.models';
import { GetHttpClientInstance } from '../../infrastructure/context/http/http-context-provider';
import { TranslationKeys } from '../../infrastructure/const';
import EmailInput from '../common/email-input';
import Input from '../common/input';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TextArea from '../common/text-area';
import TranslationManager from '../../infrastructure/translation-manager';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import Captcha from '../common/captcha';
import { RestError } from '../../infrastructure/http/modules/errors';
import ErrorCodesExtractor from '../../utilities/error-codes-extractor';
import ErrorMessages from '../common/error-messages';
import CheckUtils from '../../utilities/check-utils';
import MultiLineText from '../common/new-line-text';
import MarketingConsentCheckbox from '../common/marketing-consent-checkbox';
import UserContext from '../../infrastructure/context/user/user.context';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import { CreateSubscriptionRequest } from '../../infrastructure/http/modules/subscriptions/subscriptions.models';
import StringUtils from '../../utilities/string-utils';

const ContactUsPage: React.FC = () => {
    return (
        <TranslationsContext.Consumer>
            {(translationsContext: ITranslationsContext) =>
                <UserContext.Consumer>
                    {(userContext: IUserContext) =>
                        <ContactUsPageInner
                            translationsContext={translationsContext}
                            userContext={userContext}
                        />
                    }
                </UserContext.Consumer>
            }
        </TranslationsContext.Consumer>
    );
};

type Props = {
    translationsContext: ITranslationsContext;
    userContext: IUserContext;
};

type State = {
    message: string;
    name: string;
    phone: string;
    email: string;
    captcha: string;

    errors: { [key: string]: boolean };
    required: string[]

    isSubmitted: boolean;
    isLoaded: boolean;

    [key: string]: any;
    errorKeys: string[];
    signUpForMarketingMaterial: boolean;
};

class ContactUsPageInner extends React.Component<Props, State> {
    private nameRef: React.RefObject<Input>;
    private phoneRef: React.RefObject<Input>;
    private emailRef: React.RefObject<EmailInput>;

    constructor(props: Props, private translations: { [key: string]: string }) {
        super(props);

        this.state = {
            message: "",
            name: "",
            phone: "",
            email: "",
            captcha: "",

            required: ["message", "name", "phone", "email", "captcha"],
            errors: {},

            isSubmitted: false,
            isLoaded: false,
            errorKeys: [],
            signUpForMarketingMaterial: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
        this.handleCaptchaErrorOrExpired = this.handleCaptchaErrorOrExpired.bind(this);

        this.nameRef = React.createRef<Input>();
        this.phoneRef = React.createRef<Input>();
        this.emailRef = React.createRef<EmailInput>();
    }

    public componentDidMount(): void {
        this.initializeTranslations();
        this.setState({ isLoaded: true });
    }

    public render(): ReactNode {
        let subheaderWithEmailAndPhones = this.translations.subHeader;
        if (this.translations.subHeader) {
            const subheaderWithEmail = StringUtils.convertEmailInTextToLink(this.translations.subHeader);
            subheaderWithEmailAndPhones = StringUtils.convertPhoneNumberInTextToLink(subheaderWithEmail);
        }

        if (!this.state.isLoaded) {
            return null;
        }

        return (
            <div className='cms-content'>
                <ErrorMessages errorKeys={this.state.errorKeys} />
                {this.renderSubmitMessage()}
                <h1 className="title" >{this.translations.header}</h1>
                <p><MultiLineText text={subheaderWithEmailAndPhones} /></p>
                {!CheckUtils.isNullOrEmptyString(this.translations.additionalText) &&
                    <p>
                        <MultiLineText text={this.translations.additionalText} />
                    </p>
                }
                <form onSubmit={this.handleSubmit}>
                    <div className='fieldset'>
                        <ul className='form-list contact-form'>
                            {this.renderTextArea(this.translations.message, 'message', this.state.message, this.state.errors.message)}
                            {this.renderInput(this.translations.name, 'name', this.state.name, this.state.errors.name, this.nameRef)}
                            {this.renderInput(this.translations.phone, 'phone', this.state.phone, this.state.errors.phone, this.phoneRef)}
                            {this.renderEmailInput()}
                            <li key="marketingConsent" className='input-container'>
                                <MarketingConsentCheckbox
                                    isChecked={this.state.signUpForMarketingMaterial}
                                    handleChange={(newValue) => this.handleCheckboxChange('signUpForMarketingMaterial', newValue)}
                                />
                            </li>
                            <li style={{ marginTop: '10px' }} key="captcha" className='input-container'>
                                <Captcha hasError={this.state.errors.captcha} onChange={this.handleCaptchaChange} onExpiration={this.handleCaptchaErrorOrExpired} onError={this.handleCaptchaErrorOrExpired} />
                            </li>
                        </ul>
                    </div>
                    <div className='row'>
                        <div className='buttons-set'>
                            <button type='submit' title={this.translations.send} className='button'>
                                {this.translations.send}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    private initializeTranslations(): void {
        const translationManager = new TranslationManager(this.props.translationsContext);

        this.translations = {
            header: translationManager.getTranslation(TranslationKeys.CONTACTFORM_HEADER),
            subHeader: translationManager.getTranslation(TranslationKeys.CONTACTFORM_SUBHEADER),
            additionalText: translationManager.getTranslation(TranslationKeys.CONTACTFORM_ADDITIONALTEXT),
            name: translationManager.getTranslation(TranslationKeys.CONTACTFORM_NAME),
            phone: translationManager.getTranslation(TranslationKeys.CONTACTFORM_PHONE),
            email: translationManager.getTranslation(TranslationKeys.CONTACTFORM_EMAIL),
            message: translationManager.getTranslation(TranslationKeys.CONTACTFORM_MESSAGE),
            send: translationManager.getTranslation(TranslationKeys.CONTACTFORM_SEND),
            success: translationManager.getTranslation(TranslationKeys.CONTACTFORM_SUCCESS)
        };
    }

    private renderSubmitMessage(): ReactNode {
        if (!this.state.isSubmitted) {
            return null;
        }

        return (
            <div>
                <ul className="messages">
                    <li className="success-msg">
                        <span>
                            {this.translations.success}
                        </span>
                    </li>
                </ul>
            </div>
        );
    }

    private renderInput(label: string, name: string, value: string | undefined, hasError: boolean, ref: React.RefObject<Input>, type: string = 'text'): ReactNode {
        return (
            <li key={name} className='input-container'>
                <Input
                    ref={ref}
                    label={label}
                    name={name}
                    value={value}
                    type={type}
                    isRequired={true}
                    hasError={hasError}
                    showValidationText={hasError}
                    onValueChange={this.handleChange}
                />
            </li>
        );
    }

    private renderTextArea(label: string, name: string, value: string | undefined, hasError: boolean): ReactNode {
        return (
            <li key={name} className='input-container'>
                <TextArea
                    label={label}
                    name={name}
                    value={value}
                    columns={5}
                    rows={3}
                    isRequired={true}
                    hasError={hasError}
                    showValidationText={hasError}
                    onValueChange={this.handleChange}
                />
            </li>
        );
    }

    private renderEmailInput(): ReactNode {
        return (
            <li key={'email'} className='input-container email-container'>
                <EmailInput
                    ref={this.emailRef}
                    label={this.translations.email}
                    name={'email'}
                    value={this.state.email}
                    isRequired={true}
                    hasError={this.state.errors.email}
                    showValidationText={this.state.errors.email}
                    onValueChange={this.handleChange}
                />
            </li>
        );
    }

    private handleChange(name: string, value: string, isValid: boolean) {
        const errors = this.state.errors;

        errors[name] = name === 'email'
            ? !isValid
            : CheckUtils.isNullOrEmptyString(value);

        this.setState({ errors: errors, [name]: value, isSubmitted: false });
    }

    private handleCheckboxChange(name: keyof State, checked: boolean): void {
        const newState = { ...this.state };
        newState[name] = checked;

        this.setState(newState);
    }

    private handleSubmit(event: React.FormEvent): void {

        event.preventDefault();
        this.validateRequired();

        for (const key of Object.keys(this.state.errors)) {

            if (this.state.errors[key]) {
                return;
            }
        }

        this.postForm();
    }

    private handleCaptchaChange(token: string | null): void {
        if (!token) {
            return;
        }

        const { errors } = this.state;
        errors.captcha = false;

        this.setState({ captcha: token, errors: errors });
    }

    private handleCaptchaErrorOrExpired(): void {
        const { errors } = this.state;
        errors.captcha = true;

        this.setState({ captcha: '', errors: errors });
    }

    private validateRequired(): void {
        const errors = this.state.errors;
        for (let key = 0; key < this.state.required.length; key++) {
            const requiredField = this.state.required[key];

            if (this.state[requiredField].length === 0) {
                if (!this.state.errors[requiredField]) {
                    errors[requiredField] = true;
                }
            }
        }

        this.setState({ errors: errors });
    }

    private postForm(): void {
        const { customerService } = GetHttpClientInstance();

        const request: ContactForm = {
            message: this.state.message,
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            captcha: this.state.captcha,
        };

        customerService
            .postContactUs(request)
            .then(() => {
                if (this.state.signUpForMarketingMaterial) {
                    const subscribeRequest: CreateSubscriptionRequest = {
                        email: this.state.email,
                        type: "Newsletter"
                    };
                    this.props.userContext.subscribe(subscribeRequest).then(() => {
                        this.setState({ isSubmitted: true, message: "", name: "", phone: "", email: "", signUpForMarketingMaterial: false }, () => {
                            this.resetInputValues();
                            window.scrollTo(0, 0);
                        });
                    })
                } else {
                    this.setState({ isSubmitted: true, message: "", name: "", phone: "", email: "", signUpForMarketingMaterial: false }, () => 
                    {
                        this.resetInputValues();
                        window.scrollTo(0, 0);
                    });
                }
            }).catch((error: RestError) => {
                const errorKeys = ErrorCodesExtractor.extract(error);

                this.setState({ errorKeys });
            });
    }

    private resetInputValues(): void {
        if (this.nameRef.current !== undefined && this.nameRef.current != null) {
            this.nameRef.current.overrideValueFromParent("");
        }

        if (this.emailRef.current !== undefined && this.emailRef.current != null) {
            this.emailRef.current.overrideValueFromParent("");
        }

        if (this.phoneRef.current !== undefined && this.phoneRef.current != null) {
            this.phoneRef.current.overrideValueFromParent("");
        }
    }
}

export default ContactUsPage;
