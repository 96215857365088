import * as React from 'react';
import { Link, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { MandatoryConsentCheckboxes, RouterPaths, TranslationKeys, TranslationsPrefix } from '../../../infrastructure/const';
import CheckUtils from '../../../utilities/check-utils';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import CheckoutSummary from './checkout-summary';
import CheckoutShipping, { ShippingType, ShippingTranslations } from './checkout-shipping';
import IUserContext from '../../../infrastructure/context/user/user.context.interface';
import UserContext from '../../../infrastructure/context/user/user.context';
import { Address, UpdateCartRequest, UpdateCartPropertiesRequest, InitializeTransactionResponse } from '../../../infrastructure/http/modules/cart/cart.models';
import CheckoutAddress from './checkout-address';
import TranslationManager from '../../../infrastructure/translation-manager';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import { GetHttpClientInstance } from '../../../infrastructure/context/http/http-context-provider';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';
import { useContext, Ref } from 'react';
import CacheManager from '../../../infrastructure/cache/cache-manager';
import CacheKeys from '../../../infrastructure/cache/cache-keys';
import { AddEditAddressRequest } from '../../../infrastructure/http/modules/account/account.models';
import { UserProfileResponse } from '../../../infrastructure/http/modules/auth/auth.models';

type AdyenCheckoutState = {
    deliveryAddress: Address | null,
    invoiceAddress: Address,
    isFilledCorrectly: boolean,
    displayValidationMessages: boolean,
    paymentCode: string,
    shippingMethod: string,
    isLoaded: boolean,
    apiErrorResponse: ErrorResponse,
    saveInvoiceAddress: boolean,
    saveDeliveryAddress: boolean,
};

type AdyenCheckoutProps = {
 
};

export type ErrorResponse = {
    invoiceAddressErrors: Array<string>,
    deliveryAddressErrors: Array<string>,
};

class AdyenCheckout extends React.Component<RouteComponentProps<AdyenCheckoutProps>, AdyenCheckoutState> {
    private addressRef: React.RefObject<CheckoutAddress>;

    constructor(props: RouteComponentProps<AdyenCheckoutProps>, context: IUserContext) {
        super(props, context);

        const invoiceAddressCached = CacheManager.getItem(CacheKeys.invoiceAddressKey) as Address;
        const deliveryAddressCached = CacheManager.getItem(CacheKeys.deliveryAddressKey) as Address;

        this.state = {
            invoiceAddress: CheckUtils.isNullObject(invoiceAddressCached) ? new Address() : invoiceAddressCached,
            deliveryAddress: deliveryAddressCached,
            displayValidationMessages: false,
            isLoaded: true,
            shippingMethod: context.cart.selectedShippingType,
            paymentCode: context.cart.selectedPaymentType,
            apiErrorResponse: {} as ErrorResponse,
            saveInvoiceAddress: false,
            saveDeliveryAddress: false,
        } as AdyenCheckoutState;

        this.addressRef = React.createRef();
        this.setBinding();
    }

    render(): JSX.Element | null {
        // TODO IJAR: add error handling
        return (
            <div className="checkout-onepage-index">
                <div className="col1-layout">
                    <div className="col-main">
                        <div>
                            <div className="checkout-container wrapper">
                                <CheckoutPageHeaderInner />
                                <div id="checkoutSteps" className="one-page-checkout clearfix">
                                    <ul className="section">
                                        <AddressSectionInner
                                            ref={this.addressRef}
                                            displayValidationMessage={this.state.displayValidationMessages}
                                            onDeliveryAddressChange={this.onDeliveryAddressChange}
                                            onInvoiceAddressChange={this.onInvoiceAddressChange}
                                            onSaveDeliveryAddressChange={this.onSaveDeliveryAddressChange}
                                            onSaveInvoiceAddressChange={this.onSaveInvoiceAddressChange}
                                            apiErrorCodes={this.state.apiErrorResponse}
                                            invoiceAddress={this.state.invoiceAddress}
                                            deliveryAddress={this.state.deliveryAddress}
                                        />
                                    </ul>
                                    <ul className="section">
                                        <ShippingSectionInner selectedShippingMethod={this.state.shippingMethod} onShippingMethodChange={this.onShippingMethodChange} isLoaded={this.state.isLoaded} />
                                    </ul>
                                    <CheckoutSummaryInner
                                        onContinueClick={this.onContinueClick}
                                        isLoaded={this.state.isLoaded}
                                        displayValidationMessage={this.state.displayValidationMessages} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onContinueClick(userId: string, cartHash: string, checkboxesSelected: Array<string>, areAllMandatoryCheckboxesSelected: boolean): Redirect | void {
        this.setState({ apiErrorResponse: {} as ErrorResponse });

        const termsAndConditionsAgreed = checkboxesSelected.includes(MandatoryConsentCheckboxes.TermsAndConditions);
        const privacyPolicyAgreed = checkboxesSelected.includes(MandatoryConsentCheckboxes.PrivacyPolicy);

        // Validate
        if (!areAllMandatoryCheckboxesSelected || !this.isFilledCorrectly()) {
            this.setState({
                displayValidationMessages: true
            });
            return;
        }

        if (!CheckUtils.isNullObject(this.state.invoiceAddress) && this.state.saveInvoiceAddress) {
            const addAddressRequest = this.buildAddAddressRequest(this.state.invoiceAddress);
            this.saveAddress(addAddressRequest, this.context);
        }

        if (!CheckUtils.isNullObject(this.state.deliveryAddress) && this.state.saveDeliveryAddress) {
            const addAddressRequest = this.buildAddAddressRequest(this.state.deliveryAddress as Address);
            this.saveAddress(addAddressRequest, this.context);
        }

        const request: UpdateCartRequest = {
            userId: userId,
            shippingMethod: this.state.shippingMethod,
            invoiceAddress: this.state.invoiceAddress,
            deliveryAddress: this.state.deliveryAddress,
            customProperties: this.context.cart.customProperties,
            termsAndConditionsAgreed,
            privacyPolicyAgreed
        };

        GetHttpClientInstance().cart.initializeTransaction(request).then((result: InitializeTransactionResponse) => {
            if (this.isContinueResultSuccess(result)) {
                this.renderPaymentsRedirect(result.transactionId, result.correlationId);
            } else {
                const errors: Array<string> = result.errorKeys;
                this.setState({ displayValidationMessages: true, apiErrorResponse: this.ConvertApiErrorResponse(errors) });
            }
        });
    }

    private onDeliveryAddressChange(deliveryAddress: Address | null): void {
        this.setState({ deliveryAddress });
        if (CheckUtils.isNullObject(deliveryAddress)) {
            CacheManager.removeItem(CacheKeys.deliveryAddressKey);
            this.setState({ saveDeliveryAddress: false });
        } else {
            CacheManager.setItem(CacheKeys.deliveryAddressKey, deliveryAddress as Address);
        }
    }

    private onSaveInvoiceAddressChange(saveInvoiceAddress: boolean): void {
        this.setState({ saveInvoiceAddress });
    }

    private onSaveDeliveryAddressChange(saveDeliveryAddress: boolean): void {
        this.setState({ saveDeliveryAddress });
    }

    private onInvoiceAddressChange(invoiceAddress: Address): void {
        this.setState({ invoiceAddress });
        CacheManager.setItem(CacheKeys.invoiceAddressKey, invoiceAddress);
    }

    private onShippingMethodChange(shippingMethod: string, context: IUserContext): void {
        this.setState({ shippingMethod }, () => {
            if (shippingMethod !== context.cart.selectedShippingType) {
                this.updateCart(context);
            }
        });
    }

    private isContinueResultSuccess(result: InitializeTransactionResponse): boolean {
        return CheckUtils.isNullOrEmptyArray(result.errorKeys);
    }

    private isFilledCorrectly(): boolean {
        // Component ref is null (Component does not exist) or component does exist and is filled correctly
        const addressValid: boolean = CheckUtils.isNullObject(this.addressRef.current) || (this.addressRef.current!.isComponentFilledCorrectly());

        // Shipping method and Payment code selected
        const shippingMethodSelected: boolean = !CheckUtils.isNullOrEmptyString(this.state.shippingMethod);

        return addressValid && shippingMethodSelected;
    }

    private updateCart(userContext: IUserContext): void {
        this.setState({ isLoaded: false }, () => {
            GetHttpClientInstance().cart.updateProperties({
                userId: userContext.user.userProfile.userId,
                customProperties: userContext.cart.customProperties,
                shippingMethod: this.state.shippingMethod,
            } as UpdateCartPropertiesRequest).then(() => {
                userContext.refreshCart().then(() => {
                    this.setState({ isLoaded: true });
                });
            });
        });
    }

    private ConvertApiErrorResponse(errorCodes: Array<string>): ErrorResponse {
        let response = {} as ErrorResponse;
        if (CheckUtils.isNullOrEmptyArray(errorCodes)) {
            return response;
        }
        const invoiceAddressErrors = errorCodes.filter(x => x.toLowerCase().indexOf(CacheKeys.invoiceAddressKey.toLowerCase()) >= 0);
        const deliveryAddressErrors = errorCodes.filter(x => x.toLowerCase().indexOf(CacheKeys.deliveryAddressKey.toLowerCase()) >= 0);

        response.invoiceAddressErrors = invoiceAddressErrors.map((error) => {
            const arr = error.split('.');
            return arr[arr.length - 1].toLowerCase();
        });
        response.deliveryAddressErrors = deliveryAddressErrors.map((error) => {
            const arr = error.split('.');
            return arr[arr.length - 1].toLowerCase();
        });

        return response;
    }

    private renderPaymentsRedirect = (transactionId: string, correlationId: string) => {
        this.props.history.push(RouterPaths.PaymentsPage + "/" + transactionId + "/" + correlationId);
    }

    private setBinding() {
        this.onInvoiceAddressChange = this.onInvoiceAddressChange.bind(this);
        this.onDeliveryAddressChange = this.onDeliveryAddressChange.bind(this);
        this.onContinueClick = this.onContinueClick.bind(this);
        this.onShippingMethodChange = this.onShippingMethodChange.bind(this);
        this.isFilledCorrectly = this.isFilledCorrectly.bind(this);
        this.isContinueResultSuccess = this.isContinueResultSuccess.bind(this);
        this.renderPaymentsRedirect = this.renderPaymentsRedirect.bind(this);
        this.updateCart = this.updateCart.bind(this);
        this.onSaveInvoiceAddressChange = this.onSaveInvoiceAddressChange.bind(this);
        this.onSaveDeliveryAddressChange = this.onSaveDeliveryAddressChange.bind(this);
    }

    private buildAddAddressRequest(address: Address): AddEditAddressRequest {
        const addressRequest: AddEditAddressRequest = {
            firstName: address.firstName,
            middleName: address.middleName,
            lastName: address.lastName,
            company: address.company,
            telephone: address.phoneNumber,
            streetAddress1: address.addressLine1,
            streetAddress2: address.addressLine2,
            city: address.city,
            postalCode: address.postalCode,
            country: address.countryCode,
            isDefaultBillingAddress: false,
            isDefaultShippingAddress: false,
        };
        return addressRequest;
    }

    private saveAddress(addAddressRequest: AddEditAddressRequest, userContext: IUserContext) {
        GetHttpClientInstance().account
            .addAddress(addAddressRequest, { accessToken: userContext.user.tokenInfo!.accessToken })
            .then((userProfileResponse: UserProfileResponse) => {
                userContext.updateUserProfile(userProfileResponse);
            });
    }
}

const CheckoutPageHeaderInner = (): JSX.Element => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);
    const translations: { [id: string]: string } = {
        header: translationManager.getTranslation(TranslationKeys.CHECKOUT_HEADER),
        backToCart: translationManager.getTranslation(TranslationKeys.CHECKOUT_BACKTOCART_BUTTON),
    };
    return (
        <>
            <Link className="back-link" to={RouterPaths.CartPage} title={translations.backToCart}>
                {translations.backToCart}
            </Link>
            <h1 className="page-big-title">{translations.header}</h1>
        </>
    );
};

const AddressSectionInner = React.forwardRef((props: { displayValidationMessage: boolean, onDeliveryAddressChange: ((address: Address | null) => void), onInvoiceAddressChange: ((address: Address) => void), onSaveInvoiceAddressChange: ((saveAddress: boolean) => void), onSaveDeliveryAddressChange: ((saveAddress: boolean) => void), apiErrorCodes: ErrorResponse, invoiceAddress: Address, deliveryAddress: Address | null }, ref: Ref<CheckoutAddress>): JSX.Element => {
    const userContext = useContext<IUserContext>(UserContext);
    return (
        <CheckoutAddress
            ref={ref}
            userContext={userContext}
            displayValidationMessages={props.displayValidationMessage}
            apiErrorResponse={props.apiErrorCodes}
            onDeliveryAddressChange={(address: Address | null) => props.onDeliveryAddressChange(address)}
            onInvoiceAddressChange={(address: Address) => props.onInvoiceAddressChange(address)}
            onSaveDeliveryAddressChange={(saveAddress: boolean) => props.onSaveDeliveryAddressChange(saveAddress)}
            onSaveInvoiceAddressChange={(saveAddress: boolean) => props.onSaveInvoiceAddressChange(saveAddress)}
            invoiceAddress={props.invoiceAddress}
            deliveryAddress={props.deliveryAddress} />
    );
});

const ShippingSectionInner = (props: { selectedShippingMethod: string, onShippingMethodChange: ((shippingMethod: string, userContext: IUserContext) => void), isLoaded: boolean }): JSX.Element | null => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);
    const userContext = useContext<IUserContext>(UserContext);

    function handleChange(selectedShipping: string) {
        if (selectedShipping !== props.selectedShippingMethod) {
            props.onShippingMethodChange(selectedShipping, userContext);
        }
    }

    const shippingTranslations: ShippingTranslations = {
        header: translationManager.getTranslation(TranslationKeys.CHECKOUT_SHIPPING_HEADER),
    };

    if (CheckUtils.isNullObject(configurationContext.shippingTypes) || !Object.keys(configurationContext.shippingTypes).length) {
        return null;
    }

    let shippingTypes: Array<ShippingType> = [];
    configurationContext.shippingTypes.forEach((shipping) => {
        shippingTypes.push({
            code: shipping.code,
            name: translationManager.getTranslation(TranslationsPrefix.Checkout_ShippingTypes + shipping.code),
            disclaimerTranslation: CheckUtils.isNullOrEmptyString(shipping.disclaimer) ? "" : translationManager.getTranslation(TranslationsPrefix.Checkout_ShippingTypes + shipping.disclaimer)
        } as ShippingType);
    });

    const selectedShipping = !CheckUtils.isNullOrEmptyString(props.selectedShippingMethod) ?
        props.selectedShippingMethod : userContext.cart.selectedShippingType;

    return (
        <li id="opc-shipping_method" >
            <CheckoutShipping
                availableShippingTypes={shippingTypes}
                selectedShippingTypeCode={selectedShipping}
                translations={shippingTranslations}
                onSelectedShippingTypeChange={(shippingCode: string) => handleChange(shippingCode)}
                isLoaded={props.isLoaded}
            />
        </li>
    );
};

const CheckoutSummaryInner = (props: {displayValidationMessage: boolean, onContinueClick: ((userId: string, cartHash: string, checkboxesSelected: Array<string>, areAllMandatoryCheckboxesSelected: boolean) => void), isLoaded: boolean }): JSX.Element => {
    const userContext = useContext<IUserContext>(UserContext);
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);

    const cart = userContext.cart;
    const displayPriceIncludingVat = configurationContext.isFeatureFlagEnabled("displayPriceIncludingVat");

    return (
        <CheckoutSummary
            totalPrice={cart.displayPrice}
            vat={cart.vat}
            vatExempt={cart.vatExempt}
            subtotalExclVat={cart.subTotalExclVat}
            subtotalInclVat={cart.subTotalInclVat}
            shippingPriceExclVat={cart.shippingPriceExclVat}
            shippingPriceInclVat={cart.shippingPriceInclVat}
            onContinueClick={(checkboxesSelected: Array<string>, areAllMandatoryCheckboxesSelected: boolean) => props.onContinueClick(userContext.user.userProfile!.userId, cart.hash, checkboxesSelected, areAllMandatoryCheckboxesSelected)}
            isLoaded={props.isLoaded}
            displayValidationMessages={props.displayValidationMessage}
            discounts={cart.discounts}
            displayVatPrices={displayPriceIncludingVat}
        />
    );
};

AdyenCheckout.contextType = UserContext;

export default withRouter(AdyenCheckout);