import React from 'react';
import { Address } from '../../infrastructure/http/modules/orders/orders.models';
import CheckUtils from '../../utilities/check-utils';
import OrderPrintCountryLabel from './order-print-country-label';

type OrderPrintUserAddressProps = {
    header: string;
    address: Address;
    mainClassName: string;
    translations: OrderUserTranslations;
};

export type OrderUserTranslations = {
    phoneNumber: string;
    email: string;
};

const OrderPrintUserAddress = (props: OrderPrintUserAddressProps): JSX.Element => {

    return (
        <div className={props.mainClassName}>
            <h2>{props.header}</h2>
            {props.address.name}<br/>
            {!CheckUtils.isNullOrEmptyString(props.address.company) &&
                <>{props.address.company}<br /></>
            }
            {props.address.addressLine1}<br />
            {!CheckUtils.isNullOrEmptyString(props.address.addressLine2) &&
                <>{props.address.addressLine2}<br /></>
            }
            {props.address.postalCode} {props.address.city}<br />
            <OrderPrintCountryLabel countryCode={props.address.countryCode} /><br />
            {!CheckUtils.isNullOrEmptyString(props.address.phoneNumber) &&
                <><br />{props.translations.phoneNumber}: {props.address.phoneNumber}<br /></>
            }
            {!CheckUtils.isNullOrEmptyString(props.address.email) &&
                <><br />{props.translations.email}: {props.address.email}<br /></>
            }
        </div>
    );
};

export default OrderPrintUserAddress;