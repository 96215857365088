import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import { TranslationKeys, RouterPaths, RouterStateParams } from '../infrastructure/const';
import TranslationManager from '../infrastructure/translation-manager';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import { RedeemForgotPasswordSessionRequest, ValidateForgotPasswordSessionRequest } from '../infrastructure/http/modules/auth/auth.models';
import PasswordInput from './common/password-input';

type UrlProps = {
    sessionId: string;
    userId: string;
};

type State = {
    newPassword: string;
    confirmNewPassword: string;
    showPasswordsShouldMatchHelpText: boolean;

    [key: string]: any;
    redirectToLogin: boolean;
    redirectToForgotPassword: boolean;

    errors: { [key: string]: boolean };
};

class ChangePasswordPage extends React.Component<RouteComponentProps<UrlProps>, State> {
    constructor(props: RouteComponentProps<UrlProps>) {
        super(props);

        this.state = {
            newPassword: '',
            confirmNewPassword: '',
            redirectToLogin: false,
            redirectToForgotPassword: false,
            showPasswordsShouldMatchHelpText: false,
            errors: {
                newPassword: false,
                confirmNewPassword: false,
            }
        };

        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public componentDidMount(): void {
        this.validateSession();
    }

    public render(): ReactNode {
        const translationManager = new TranslationManager(this.context);
        const translations = {
            header: translationManager.getTranslation(TranslationKeys.CHANGEFORGOTTENPASSWORD_HEADER),
            newPassword: translationManager.getTranslation(TranslationKeys.ACCOUNT_ACCOUNTINFORMATION_NEWPASSWORD),
            confirmNewPassword: translationManager.getTranslation(TranslationKeys.ACCOUNT_ACCOUNTINFORMATION_CONFIRMNEWPASSWORD),
            submit: translationManager.getTranslation(TranslationKeys.CHANGEFORGOTTENPASSWORD_SUBMIT),
            pleaseMakeSurePasswordsMatch: translationManager.getTranslation(TranslationKeys.PLEASE_MAKE_SURE_YOUR_PASSWORDS_MATCH),
        };

        return (
            <div className='col1-layout'>
                <div className='col-main'>
                    <div className='resetpassword-page'>
                        <div className='wrapper wr-block'>
                            <h2>{translations.header}</h2>
                            <form id='form-validate' className='scaffold-form' onSubmit={this.handleSubmit}>
                                <div className='fieldset'>
                                    <ul className='form-list'>
                                        {this.renderInput(translations.newPassword, 'newPassword', this.state.newPassword, this.state.errors.newPassword)}
                                        {this.renderInput(translations.confirmNewPassword, 'confirmNewPassword', this.state.confirmNewPassword, this.state.errors.confirmNewPassword, translations.pleaseMakeSurePasswordsMatch)}
                                    </ul>
                                </div>
                                <div className='buttons-set'>
                                    <button type='submit' title={translations.submit} className='button org-btn'>
                                        {translations.submit}
                                    </button>
                                </div>
                                {this.renderRedirectToLogin()}
                                {this.renderRedirectToResetPassword()}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderInput(label: string, name: string, value: string, error: boolean, helpText: string = '') {
        return (
            <li className='input-container input-container--rev'>
                <PasswordInput
                    isRequired={true}
                    type='password'
                    value={value}
                    label={label}
                    name={name}
                    id={name}
                    onValueChange={this.handleValueChange}
                    hasError={error}
                    showValidationText={error}
                    helpTextHtml={this.state.showPasswordsShouldMatchHelpText ? helpText : ''}
                />
            </li>
        );
    }

    private renderRedirectToLogin(): ReactNode {
        if (!this.state.redirectToLogin) {
            return null;
        }

        return <Redirect push to={{ pathname: RouterPaths.Login, state: { [RouterStateParams.ShowPasswordUpdatedSuccessMessage]: true } }} />;
    }

    private renderRedirectToResetPassword(): ReactNode {
        if (!this.state.redirectToForgotPassword) {
            return null;
        }

        return <Redirect push to={{ pathname: RouterPaths.ForgotPassword, state: { [RouterStateParams.ShowResetLinkExpiredErrorMessage]: true } }} />;
    }

    private getUrlProps(): UrlProps {
        return {
            sessionId: this.props.match.params.sessionId,
            userId: this.props.match.params.userId,
        };
    }

    private handleValueChange(name: string, value: string, isValid: boolean): void {
        const newState = { ...this.state };
        newState[name] = value;
        newState.errors[name] = !isValid;

        if (name === 'confirmNewPassword') {
            const confirmedPasswordDoesNotMatch = value !== this.state.newPassword;
            newState.showPasswordsShouldMatchHelpText = confirmedPasswordDoesNotMatch;
            newState.errors.confirmNewPassword = confirmedPasswordDoesNotMatch;
        }

        this.setState(newState);
    }

    private handleSubmit(event: React.FormEvent) {
        event.preventDefault();

        let newPasswordError: boolean = this.state.errors.newPassword,
            confirmNewPasswordError: boolean = this.state.errors.confirmNewPassword;

        if (!this.state.newPassword) {
            newPasswordError = true;
        }
        if (!this.state.confirmNewPassword || this.state.newPassword !== this.state.confirmNewPassword) {
            confirmNewPasswordError = true;
        }

        this.setState({ errors: { newPassword: newPasswordError, confirmNewPassword: confirmNewPasswordError } }, () => {
            if (Object.values(this.state.errors).filter(x => x === true).length > 0) {
                return;
            }

            this.validateSession()
                .then(() => this.redeemSession());
        });
    }

    private redeemSession(): void {
        const auth = GetHttpClientInstance().auth;
        const urlProps = this.getUrlProps();

        const request: RedeemForgotPasswordSessionRequest = {
            userId: urlProps.userId,
            sessionId: urlProps.sessionId,
            password: this.state.newPassword,
            passwordConfirmed: this.state.confirmNewPassword
        };

        auth
            .redeemForgotPasswordSession(request)
            .then(() => {
                this.setState({ redirectToLogin: true });
            })
            .catch(() => this.handleError());
    }

    private validateSession(): Promise<void> {
        const auth = GetHttpClientInstance().auth;
        const urlProps = this.getUrlProps();

        if (!urlProps.sessionId || !urlProps.userId) {
            this.setState({ redirectToLogin: true });
            return new Promise<void>(() => {/**/ });
        }

        const request: ValidateForgotPasswordSessionRequest = {
            userId: urlProps.userId,
            sessionId: urlProps.sessionId,
        };

        return auth
            .validateForgotPasswordSession(request)
            .catch(() => this.handleError());
    }

    private handleError() {
        this.setState({ redirectToForgotPassword: true });
    }
}

ChangePasswordPage.contextType = TranslationsContext;

export default withRouter(ChangePasswordPage);
