import React from 'react';

type TextImageComponentProps = {
    imgSrc: string;
    headingText: string;
    descriptionText: string;
    buttonText: string;
    buttonUrl: string;
    fullWidth?: boolean;
};

const TextImageComponent: React.FC<TextImageComponentProps> = (props: TextImageComponentProps) => {
    return (
        <div className={`text-component text-image-component${props.fullWidth ? ' w100' : ''}`}>
            <div className="text-image-component__image-wrapper">
                <img src={"./images/" + props.imgSrc} alt={props.headingText} className="text-image-component__image"/>
            </div>
            <div className="text-image-component__wrapper">
                <h2 className="text-component__heading">
                    {props.headingText}
                </h2>
                <p className="text-component__text">
                    {props.descriptionText}
                </p>
                <button className="button" onClick={(e) => {window.open(props.buttonUrl); }}>{props.buttonText}</button>
            </div>
        </div>
    );
};

export default TextImageComponent;