import React, { useState } from "react";
import { useContext } from "react";
import UserContext from "../../infrastructure/context/user/user.context";
import IUserContext from "../../infrastructure/context/user/user.context.interface";
import { Product, Variant } from "../../infrastructure/http/modules/product/product.models";
import Breadcrumb from "../common/breadcrumb";
import { ProductDetailsSchema } from "./product-schema/product-details-schema";
import ProductInformationTabs from "./enrichment-products-components/product-information-tabs";
import Spinner from "../common/spinner";
import InStockAndDeliveryInfo, { InStockAndDeliveryInfoTranslations } from "./in-stock-and-delivery-info";
import AddToCartForm from "./add-to-cart-form";
import ProductImageContainer from "./product-image-container";
import SideDescriptionSection from "./side-description-section";
import { ProductDetailsPageTranslations } from "../product-details-page";
import ProductAttributes from "./product-attributes";
import AddedToCartMessage from "./added-to-cart-message";
import ProductDetailsPriceBox from "./product-details-price-box";
import RelatedProductsSlider from "./enrichment-products-components/related-products-slider";
import CheckUtils from "../../utilities/check-utils";
import AddToFavourites from "./add-to-favourites";

type ProductDetailsInnerProps = {
    product: Product | null,
    translations: ProductDetailsPageTranslations,
    addToCartButtonClicked: boolean,
    activeClass: boolean,
    quantityStr: string,
    quantity: number,
    isProductInCart: ((userContext: IUserContext) => boolean),
    onInputValueButtonChange: ((type: string) => void),
    onAddedToCart: (() => void),
    onCartRefresh: (() => void),
    onInputValueChange: ((e: React.ChangeEvent<HTMLInputElement>) => void),
    onInputBlur: ((e: React.ChangeEvent<HTMLInputElement>) => void)
}

const ProductDetailsInner = (props: ProductDetailsInnerProps): JSX.Element => {
    const userContext = useContext<IUserContext>(UserContext);
    const [selectedVariant, setSelectedVariant] = useState<Variant | null>(null);

    if (props.product === null) {
        return <Spinner show={true} />;
    }

    function setVariant(variant: Variant | null) {
        setSelectedVariant(variant);
    }

    return (
        <div className="product-essential">
            <ProductDetailsSchema product={props.product} />
            <Breadcrumb productName={props.product.name} />
            <div className="wrapper clearfix">
                <div id="messages_product_view">
                </div>
                <div id="product-info-block" className="product-info-block">
                    <div className="product-block">
                        <div className="product-block__part product-block__media">
                            <AddedToCartMessage
                                addToCartButtonClicked={props.addToCartButtonClicked}
                                isProductInCart={props.isProductInCart(userContext)}
                                translation={props.translations.addedToCart}
                                activeClass={props.activeClass}
                            />
                            <div className="text-badge"></div>
                            <div className="br-small info-top">
                                <h1 className="product-name">{props.product.name}</h1>
                                <div className="short-description">
                                    {!props.product.isConfigurable &&
                                        <div className="std">{props.translations.itemNumber}: {props.product.itemNumber}</div>
                                    }
                                </div>
                                <ProductDetailsPriceBox product={props.product} variant={selectedVariant} />
                            </div>
                            <ProductImageContainer product={props.product} variant={selectedVariant} />
                        </div>
                        <div className=" product-block__part product-block__shop">
                            <h1 className="product-name">{props.product.name}</h1>
                            <div className="short-description">
                                {!props.product.isConfigurable &&
                                    <div className="std default-font">
                                        {props.translations.itemNumber}: {props.product.itemNumber}
                                    </div>
                                }
                            </div>

                            <form id="product_addtocart_form">
                                <ProductDetailsPriceBox product={props.product} variant={selectedVariant} />

                                <ProductAttributes
                                    product={props.product}
                                    setSelectedVariant={(variant: Variant | null) => setVariant(variant)}
                                />

                                <AddToCartForm
                                    product={props.product}
                                    variant={selectedVariant}
                                    onAddedToCart={props.onAddedToCart}
                                    onCartRefresh={props.onCartRefresh}
                                    onInputValueChange={props.onInputValueChange}
                                    onInputBlur={props.onInputBlur}
                                    onInputValueButtonChange={props.onInputValueButtonChange}
                                    quantityStr={props.quantityStr}
                                    quantity={props.quantity}
                                />
                            </form>

                            {!CheckUtils.isNullObject(props.product) &&
                                <AddToFavourites
                                    itemNumber={selectedVariant? selectedVariant.itemNumber : props.product.itemNumber}
                                    isConfigurableProduct={props.product.isConfigurable === true && CheckUtils.isNullObject(selectedVariant)} />
                            }

                            <InStockAndDeliveryInfo
                                product={props.product}
                                translations={{
                                    inStock: props.translations.inStock,
                                    outOfStock: props.translations.outOfStock,
                                    deliveredWithin: props.translations.deliveredWithin,
                                    deliveredWithinDaysNumber: props.translations.deliveredWithinDaysNumber,
                                    days: props.translations.days
                                } as InStockAndDeliveryInfoTranslations}
                            />
                            <SideDescriptionSection
                                subtitle={props.product.subtitle}
                                marketingDescription={props.product.marketingDescription}
                                marketingShortDescription={props.product.marketingShortDescription} />
                        </div>
                    </div>
                </div>
                <ProductInformationTabs marketingDescriptionHTML={props.product.marketingDescription} />
                <RelatedProductsSlider products={props.product.relatedProducts} />
            </div >
        </div >
    );
};

export default ProductDetailsInner;