import React from "react";

type Props = {
    show: boolean;
    default?: boolean;
};

const Spinner: React.FC<Props> = (props: Props) => {
    const setSpinnerClass = ():string => {
        return `${props.default ? 'spinner-default' : 'spinner'}`
    }

    const renderSpinnerElements = [...Array(8)].map((_, i) => {
        return (
            <div
                key={i}
                className={`${setSpinnerClass()}__element ${setSpinnerClass()}__element--${i + 1}`}
            ></div>
        )
    });

    return (
        <div
            className={setSpinnerClass()}
            style={props.show ? { display: 'block' } : { display: 'none' }}
        >
            {renderSpinnerElements}
        </div>
    );
};

export default Spinner;