import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { TranslationKeys } from '../../infrastructure/const';
import TranslationManager from '../../infrastructure/translation-manager';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';

type Props = {
    hasError?: boolean;
    onError?(): void;
    onExpiration?(): void;
    onChange(token: string | null): void;
};

class Captcha extends React.Component<Props, {}> {
    constructor(props: Props, private captchaRef: React.RefObject<ReCAPTCHA>) {
        super(props);

        this.captchaRef = React.createRef();
    }

    public render(): React.ReactNode {
        const text = new TranslationManager(this.context).getTranslation(TranslationKeys.REQUIRED);

        return (
            <div className={this.props.hasError ? 'captcha-error' : ''}>
                <ReCAPTCHA
                    ref={this.captchaRef}
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                    onChange={this.props.onChange}
                    onErrored={!!this.props.onError ? this.props.onError : () => {/* */ }}
                    onExpired={!!this.props.onExpiration ? this.props.onExpiration : () => {/* */ }}
                />
                {this.props.hasError &&
                    <label className='validation-advice'>{text}</label>
                }
            </div>
        );
    }

    public resetCaptcha(): void {
        if (this.captchaRef.current) {
            this.captchaRef.current.reset();
        }
    }
}

Captcha.contextType = TranslationsContext;

export default Captcha;
