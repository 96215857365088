import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SearchBar from '../search/search-bar';
import MiniCart from '../cart/mini-cart';
import MobileHeader from './mobile-header';
import PageHeaderLogo from './page-header-logo';
import PageHeaderLoginButton from './page-header-login-button';
import PageHeaderThemeContext from '../../infrastructure/context/pageheader-theme/pageheader-theme.context';
import UserContext from '../../infrastructure/context/user/user.context';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import { RouterPaths, PageHeaderThemes } from '../../infrastructure/const';
import IThemeContext from '../../infrastructure/context/pageheader-theme/pageheader-theme.context.interface';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import AccountHeader from '../account/account-header';
import CategoryMenuLinks from '../menu/category-menu-links';
import PageHeaderLinks from './page-header-links';
import PageHeaderBottomLinks from './page-header-bottom-links';
import PageHeaderNotificationBar from './page-header-notification-bar';
import PageHeaderTopBar from './page-header-top-bar';
import IStaticContentContext from '../../infrastructure/context/static-content/static-content.context.interface';
import CheckUtils from '../../utilities/check-utils';
import StaticContentContext from '../../infrastructure/context/static-content/static-content.context';

type PageHeaderState = {
  translations: { [id: string]: string },
  isNotificationBarVisible: boolean
};

class PageHeader extends React.Component<RouteComponentProps, PageHeaderState> {

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      translations: {},
      isNotificationBarVisible: true
    };
  }

  hideNotificationBar(): void {
    this.setState({ isNotificationBarVisible: false });
  }

  renderSearchForm(): JSX.Element {
    return (
      <form id="search_mini_form">
        <SearchBar searchCallback={() => undefined} />
      </form>
    );
  }

  renderMiniCart(themeContext: IThemeContext): JSX.Element {
    return (
      <UserContext.Consumer>
        {(context: IUserContext) => (
          <MiniCart
            total={context.cart.total}
            totalPrice={context.cart.displayPrice}
            products={context.cart.products}
            redirectLink={RouterPaths.CartPage}
            isLoading={context.cartUpdateInProgress}
            removeFromCartPopupVisible={context.removeFromCartPopupVisible}
            shippingPrice={context.cart.shippingPriceInclVat}
            disabled={themeContext.isMiniCartDisabled}
          />
        )}
      </UserContext.Consumer>
    );
  }

  renderSellingPoinsBar(context: IStaticContentContext): JSX.Element | null {
    if (!context.sellingPointsBar) {
      return null;
    }
    return (
      <>
        {this.state.isNotificationBarVisible && !CheckUtils.isNullOrEmptyString(context.sellingPointsBar.text) &&
          <PageHeaderNotificationBar
            handleClick={() => this.hideNotificationBar()}
            text={context.sellingPointsBar.text}
          />
        }
        {(!CheckUtils.isNullOrEmptyString(context.sellingPointsBar.phoneNumber) || !CheckUtils.isNullOrEmptyArray(context.sellingPointsBar.sellingPoints)) &&
          <PageHeaderTopBar
            phoneNumber={context.sellingPointsBar.phoneNumber}
            contactHours={context.sellingPointsBar.contactHours}
            sellingPoints={context.sellingPointsBar.sellingPoints}
          />
        }
      </>
    );
  }

  render(): JSX.Element {
    return (
      <PageHeaderThemeContext.Consumer>
        {(themeContext: IThemeContext) => (
          <>
            {themeContext.isHeaderVisible &&
              <header id="header" className={"header " + (themeContext.theme === PageHeaderThemes.Light ? 'header-light' : 'header-dark')}>
                <StaticContentContext.Consumer>
                  {(context: IStaticContentContext) => (
                    this.renderSellingPoinsBar(context)
                  )}
                </StaticContentContext.Consumer>
                <ConfigurationContext.Consumer>
                  {(context: IConfigurationContext) => (
                    <>
                      <MobileHeader
                        brand={context.brand!}
                      />
                      <div className="wrapper clearfix header__top br-large">
                        <PageHeaderLogo
                          brand={context.brand!}
                        />
                        {this.renderSearchForm()}
                        <PageHeaderLinks />
                        <PageHeaderLoginButton />
                        {this.renderMiniCart(themeContext)}
                      </div>
                      {/* multi level menu */}
                      <PageHeaderBottomLinks />
                    </>
                  )}
                </ConfigurationContext.Consumer>
                <CategoryMenuLinks />
                {themeContext.isInAccountSection &&
                  <AccountHeader />
                }
              </header>
            }
          </>
        )}
      </PageHeaderThemeContext.Consumer>
    );
  }
}

export default withRouter(PageHeader);