import React, { useContext } from 'react';
import { TranslationKeys } from '../../../infrastructure/const';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';

type Props = {
    receiveMarketingMaterials?: boolean;
    onEditClick: (() => void);
};

const MarketingMaterials: React.FC<Props> = (props: Props) => {
    const context = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(context);

    const translations = {
        header: translationManager.getTranslation(TranslationKeys.ACCOUNT_MARKETINGMATERIAL_HEADER),
        yourAreSubscribed: translationManager.getTranslation(TranslationKeys.ACCOUNT_MARKETINGMATERIAL_YOUARESUBSCRIBED),
        yourAreNotSubscribed: translationManager.getTranslation(TranslationKeys.ACCOUNT_MARKETINGMATERIAL_YOUARENOTSUBSCRIBED),
        edit: translationManager.getTranslation(TranslationKeys.ACCOUNT_EDIT),
    };

    return (
        <div className='col-2 box-account box-account__box'>
            <div className='box-top'>
                <h3>{translations.header}</h3>
            </div>
            <div className='box-content'>
                <p>
                    {props.receiveMarketingMaterials
                      ? translations.yourAreSubscribed
                      : translations.yourAreNotSubscribed
                    }
                </p>
                <button type='button' title={translations.edit} className='button' onClick={() => props.onEditClick()}>{translations.edit}</button>
            </div>
        </div>

    );
};

export default MarketingMaterials;
