import React from 'react';
import { Link } from 'react-router-dom';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import { TranslationKeys } from '../../infrastructure/const';
import { CartProduct, UpdateProductRequest } from '../../infrastructure/http/modules/cart/cart.models';
import RemoveFromCartButton from './remove-from-cart-button';
import CheckUtils from '../../utilities/check-utils';
import NumberUtils from '../../utilities/number-utils';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import { GetHttpClientInstance } from '../../infrastructure/context/http/http-context-provider';
import NavigationContext from '../../infrastructure/context/navigation/navigation.context';
import INavigationContext from '../../infrastructure/context/navigation/navigation.context.interface';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import ProductsEnrichmentUtils from '../../utilities/product-enrichment-utils';

type CartProductListItemProps = {
    product: CartProduct;
    userContext: IUserContext;
};

type CartProductListItemState = {
    quantity: number;
    quantityStr: string;
};

export default class CartProductListItem extends React.Component<CartProductListItemProps, CartProductListItemState> {
    private TranslationManager: TranslationManager;
    private translations: { [id: string]: string };

    constructor(props: CartProductListItemProps, context: ITranslationsContext) {
        super(props, context);
        this.state = {
            quantity: props.product.quantity,
            quantityStr: props.product.quantity.toString()
        } as CartProductListItemState;

        this.TranslationManager = new TranslationManager(this.context);

        this.translations = {
            color: this.TranslationManager.getTranslation(TranslationKeys.PRODUCT_COLOR),
            option: this.TranslationManager.getTranslation(TranslationKeys.PRODUCT_OPTION),
            itemNumber: this.TranslationManager.getTranslation(TranslationKeys.PRODUCT_NUMBER),
            remove: this.TranslationManager.getTranslation(TranslationKeys.REMOVE),
            total: this.TranslationManager.getTranslation(TranslationKeys.TOTAL),
            amount: this.TranslationManager.getTranslation(TranslationKeys.AMOUNT),
            price: this.TranslationManager.getTranslation(TranslationKeys.PRICE),
        };
    }

    componentDidUpdate(prevProps: CartProductListItemProps) {
        if (this.props.product.quantity !== this.state.quantity) {
            this.setState({
                quantity: this.props.product.quantity,
                quantityStr: this.props.product.quantity.toString()
            });
        }
    }

    onIncreaseQuantityClicked(): void {
        if (this.props.product.isAddedByPromotion) {
            return;
        }

        const newQuantity = this.state.quantity + 1;

        this.props.userContext.updateQuantityInCart(this.props.product.id, newQuantity);
           
        this.updateQuantity(newQuantity, true);
    }

    onDecreaseQuantityClicked(): void {
        if (this.props.product.isAddedByPromotion) {
            return;
        }

        const newQuantity = this.state.quantity - 1;

        if (NumberUtils.isPositiveInteger(newQuantity) && newQuantity !== 0) {
           
            this.props.userContext.updateQuantityInCart(this.props.product.id, newQuantity);
            this.updateQuantity(newQuantity, true);
        }
    }

    onInputValueChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (this.props.product.isAddedByPromotion) {
            return;
        }

        if (CheckUtils.isNullObject(event) || CheckUtils.isNullObject(event.target)) {
            return;
        }
        if (event.target.value === '') {
            this.setState({
                quantityStr: ''
            });
        }
        if (NumberUtils.isPositiveInteger(event.target.value) && event.target.value !== "0") {
            const newQuantity: number = NumberUtils.parseNumber(event.target.value);

           this.props.userContext.updateQuantityInCart(this.props.product.id, newQuantity);
                      
           this.updateQuantity(newQuantity, false);
        }
        return;
    }

    onInputBlur(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            quantityStr: this.state.quantity.toString()
        });
    }

    updateQuantity(quantity: number, includePromoItems: boolean) {
        if (this.props.product.isAddedByPromotion) {
            return;
        }

        if(includePromoItems)
        {
            quantity = quantity + this.countAdditionalPromoItems(this.props.product.itemNumber);
        }

        const updateCartRequest: UpdateProductRequest = {
            userId: this.props.userContext.user.userProfile!.userId,
            itemNumber: this.props.product.itemNumber,
            quantity: quantity
        };
        this.props.userContext.setCartUpdateInProgress().then(() => {
            GetHttpClientInstance().cart.updateProduct(updateCartRequest).then(() => {
                this.props.userContext.refreshCart();
            });
        });
    }

   

    renderProductPrice() {
        if (this.props.product.isOnPromotion
            && !CheckUtils.isNullObject(this.props.product.productPromoResponse)
            && !CheckUtils.isNullObject(this.props.product.priceResponse)) {
            return (
                <span className="price"><s>{this.props.product.priceResponse.priceStr}</s> {this.props.product.productPromoResponse.priceStr}</span>
            )

        }
        return <span className="price">{this.props.product.price}</span>;
    }

    renderProductInfo(categoryContext: INavigationContext, configurationContext: IConfigurationContext): JSX.Element {
        return (
            <>
                <div className="product-name-wrapper">
                    <div className="product-name has-options">
                        <Link to={{ pathname: ProductsEnrichmentUtils.getProductDetailsUrl(configurationContext, categoryContext, this.props.product), state: { product: this.props.product } }} title={this.props.product.name}>{this.props.product.name}</Link>
                        {!configurationContext.isFeatureFlagEnabled("hideItemDescriptionInCart") &&
                            <span className="short-description">{this.props.product.description}</span>
                        }
                    </div>
                    {!configurationContext.isFeatureFlagEnabled("useEnrichedProductData")
                        && !CheckUtils.isNullOrEmptyString(this.props.product.colorName) &&
                        <div className="product-name-color">
                            <span className="item-subtitle">{this.translations.color}:</span>
                            <strong>{this.props.product.colorName}</strong>
                        </div>
                    }
                    {configurationContext.isFeatureFlagEnabled("useEnrichedProductData") &&
                        (this.renderAttributes())
                    }
                    <span className="item-number">
                        <span className="item-subtitle">{this.translations.itemNumber}</span>
                        {this.props.product.itemNumber}
                    </span>
                </div>
                <div className="product-info">
                    <div className="price-option">
                        <span className="item-subtitle">{this.translations.price}</span>
                        <span className="cart-price">
                            {this.renderProductPrice()}
                        </span>
                    </div>
                    <div className="increment-block">
                        <span className="item-subtitle">{this.translations.amount}</span>
                        <div className="increment-wr">
                            <span style={this.props.product.isAddedByPromotion ? { visibility: "hidden" } : {}} className="increment-input increment-input__minus" data-action="-" onClick={() => this.onDecreaseQuantityClicked()}></span>
                            <input name="cart" value={this.state.quantityStr} onChange={(e) => this.onInputValueChange(e)} onBlur={(e) => this.onInputBlur(e)} title="Qty" className="input-text increment-input increment-input__qty" />
                            <span style={this.props.product.isAddedByPromotion ? { visibility: "hidden" } : {}} className="increment-input increment-input__plus" data-action="+" onClick={() => this.onIncreaseQuantityClicked()}></span>
                        </div>
                    </div>
                    {!this.props.product.isAddedByPromotion &&
                        <RemoveFromCartButton
                            displayAsLink={true}
                            itemNumber={this.props.product.itemNumber}
                        />
                    }
                    <div>
                        <span className="item-subtitle">{this.translations.total}</span>
                        <div className="product-detailing">
                            <span className="cart-price">
                                <span className="price">{this.props.product.total}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderAttributes(): JSX.Element | null {
        if (this.props.product.productAttributes === undefined && this.props.product.productAttributes === null) {
            return null;
        }

        return (
            <>
                {Object.entries(this.props.product.productAttributes!).map(([key, value]) => {
                    if (CheckUtils.isNullOrEmptyString(value)) {
                        return null;
                    }
                    return (
                        <div className="product-name-color">
                            <span className="item-subtitle">{ProductsEnrichmentUtils.getAttributeTranslation(this.context, key, "Name")}:</span>
                            <strong>{ProductsEnrichmentUtils.getAttributeTranslation(this.context, value, "Value")}</strong>
                        </div>
                    )
                })}
            </>
        )
    }

    render(): JSX.Element {
        return (
            <NavigationContext.Consumer>
                {(categoryContext) => (
                    <ConfigurationContext.Consumer>
                        {(configurationContext) => (
                            <tr>
                                <td>
                                    <div className="cart-table-flex">
                                        <Link to={{ pathname: ProductsEnrichmentUtils.getProductDetailsUrl(configurationContext, categoryContext, this.props.product), state: { product: this.props.product } }} className="product-image" title={this.props.product.name}>
                                            <img src={this.props.product.imageUrl} width="70" height="70" alt={this.props.product.name} />
                                        </Link>
                                        {this.renderProductInfo(categoryContext, configurationContext)}
                                    </div>
                                </td>
                            </tr>
                        )}
                    </ConfigurationContext.Consumer>
                )}
            </NavigationContext.Consumer>
        );
    }

    countAdditionalPromoItems(itemNumber: string) {
        var count = 0;
        this.props.userContext.cart.products.forEach(prod => {
            if(prod.itemNumber === itemNumber && prod.isAddedByPromotion)
            {
                count =  prod.quantity;
            }
        });

        return count;
    }
}

CartProductListItem.contextType = TranslationsContext;
