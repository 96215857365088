import { Product } from '../infrastructure/http/modules/product/product.models';

export default class SortUtils {

    static sortProductsByName(productsList: Product[], isDescending = false): Product[] {
        let result = productsList.sort((a: Product, b: Product) => a.name.localeCompare(b.name));
        if (isDescending) {
            return result.reverse();
        }
        return result;
    }

    static sortProductsByPrice(productsList: Product[], isDescending = false): Product[] {
        let result = productsList.sort((a: Product, b: Product) => (a.priceResponse !== null && a.priceResponse.price !== null ? a.priceResponse.price : Infinity) - (b.priceResponse !== null && b.priceResponse.price !== null ? b.priceResponse.price : Infinity));
        if (isDescending) {
            return result.reverse();
        }
        return result;
    }
}