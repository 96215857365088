import * as React from 'react';
import { Link } from 'react-router-dom';
import TranslationManager from '../../infrastructure/translation-manager';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import RedirectButton from '../common/redirect-button';
import { TranslationKeys } from '../../infrastructure/const';
import { CartProduct } from '../../infrastructure/http/modules/cart/cart.models';
import CheckUtils from '../../utilities/check-utils';
import RemoveFromCartButton from './remove-from-cart-button';
import NavigationContext from '../../infrastructure/context/navigation/navigation.context';
import INavigationContext from '../../infrastructure/context/navigation/navigation.context.interface';
import classNames from 'classnames';
import Spinner from '../common/spinner';
import { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import ProductsEnrichmentUtils from '../../utilities/product-enrichment-utils';


type MiniCartState = {
    showSpinner: boolean
};

type MiniCartProps = {
    redirectLink: string;
    totalPrice: string;
    total: number;
    products: CartProduct[];
    isLoading: boolean;
    shippingPrice: string;
    removeFromCartPopupVisible: boolean;
    disabled: boolean;
};

const defaultProps = {
    redirectLink: '/', //?
    totalPrice: '0',
    total: 0,
    products: [],
    removeFromCartPopupVisible: false,
    disabled: false
};

const MiniCart = (props: MiniCartProps): JSX.Element => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);
    const navigationContext = useContext<INavigationContext>(NavigationContext);
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);

    const [miniCartOpen, setMiniCartOpen] = useState(false);

    const [scheduledCloseTimeoutId, setScheduledCloseTimeoutId] = useState<number | null>(null);

    const clearScheduledClose = () => {
        if (scheduledCloseTimeoutId) {
            window.clearTimeout(scheduledCloseTimeoutId);
            setScheduledCloseTimeoutId(null);
        }
    };

    const scheduleClose = (closeAfterSeconds: number) => {
        clearScheduledClose();
        setScheduledCloseTimeoutId(window.setTimeout(() => {
            setMiniCartOpen(false);
        }, closeAfterSeconds * 1000));
    }

    useEffect(() => setMiniCartOpen(false), [props.removeFromCartPopupVisible]);

    useEffect(() => {
        setMiniCartOpen(true);
        scheduleClose(2)
    }, [props.products]);

    const translations: { [id: string]: string } = {
        goToCart: translationManager.getTranslation(TranslationKeys.MINICART_GOTOCART_BUTTON),
        remove: translationManager.getTranslation(TranslationKeys.REMOVE),
        color: translationManager.getTranslation(TranslationKeys.PRODUCT_COLOR),
        items: translationManager.getTranslation(TranslationKeys.CART_ITEMS),
        itemsInCart: translationManager.getTranslation(TranslationKeys.CART_ITEMSINCART),
        shipping: translationManager.getTranslation(TranslationKeys.CART_SHIPPING)
    };

    const headerCartInfoClasses = classNames({
        'headercart-info': true,
        'is-loading': props.isLoading,
        'headercart-disabled': props.disabled
    });

    function renderProductList(): JSX.Element {
        return (
            <>
                <Scrollbars
                    autoHeight
                    autoHeightMax={400}
                >
                    <ol id="cart-sidebar" className="mini-products-list">
                        {props.products.map((product) => (
                            <li className="item" key={product.id}>
                                <Link to={{ pathname: ProductsEnrichmentUtils.getProductDetailsUrl(configurationContext, navigationContext, product), state: { product: product } }} title={product.name} className="product-image">
                                    <img src={product.imageUrl} width="70" alt={product.name} />
                                </Link>
                                <div className="product-details">
                                    <p className="product-name">
                                        <Link to={{ pathname: ProductsEnrichmentUtils.getProductDetailsUrl(configurationContext, navigationContext, product), state: { product: product } }} title={product.name}>{product.name}</Link>
                                        {!configurationContext.isFeatureFlagEnabled("hideItemDescriptionInCart") &&
                                            <span className="short-description">{product.description}</span>
                                        }
                                    </p>
                                    <div className="product-details-lower">
                                        <span className="price-amount">
                                            {product.quantity} x <span className="price">{product.price}</span>
                                        </span>
                                        <ul className="item-options">
                                            {!CheckUtils.isNullOrEmptyString(product.colorName) &&
                                                <li>{translations.color} : <span>{product.colorName}</span></li>
                                            }
                                        </ul>
                                        <RemoveFromCartButton itemNumber={product.itemNumber} displayAsLink={true} />
                                    </div>
                                </div>
                            </li>
                        ))}
                        <li className="item item--shipping-price">
                            <div>{translations.shipping}</div>
                            <div className="price">{props.shippingPrice}</div>
                        </li>
                    </ol>
                </Scrollbars>
                <div className="headercart-button">
                    <RedirectButton redirectUrl="/cart" buttonLabel={translations.goToCart} additionalClass="buy-button" closeMenu={() => setMiniCartOpen(false)} />
                </div>
            </>
        );
    }

    return (
        <div className="headercart" id="headercart-disabled">
            <div
                className={`headercart__top${miniCartOpen ? ' open' : ''}`}
                onMouseEnter={() => {
                    if (!props.disabled) {
                        clearScheduledClose();
                        setMiniCartOpen(true);
                    }
                }}
                onMouseLeave={() => setMiniCartOpen(false)}
            >

                <Link className={headerCartInfoClasses} to={props.redirectLink} title="Cart">
                    <span className="icon-top-cart"></span>
                    <span>
                        <span className="headercart-items">{props.total}</span>
                        <span> {translations.items}</span>
                    </span>
                </Link>

                {!CheckUtils.isNullOrEmptyArray(props.products) &&
                    <div
                        id="recently-added-container"
                        className="showcart"
                        style={{ display: props.removeFromCartPopupVisible ? 'none' : 'block' }}
                    >
                        <Link className="headercart-info" to="/" title="Cart"></Link>
                        <div className="headercart-inner">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className="headercart-info">
                                <div className="viewing">
                                    <p>
                                        <span>
                                            <span className="headercart-info-items">{props.total}</span> {translations.itemsInCart}
                                        </span>
                                        <span className="sum">{props.totalPrice}</span>
                                    </p>
                                </div>
                            </a>
                            {renderProductList()}
                        </div>
                    </div>
                }
                <Spinner show={props.isLoading} />
            </div>
        </div>
    );
};

MiniCart.defaultProps = defaultProps;
export default MiniCart;