import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { OrderHistoryListResponse } from '../infrastructure/http/modules/orders/orders.models.history';
import { RouterPaths, TranslationKeys } from '../infrastructure/const';
import AccountMenu from './account/menu/account-menu';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import OrderHistoryList, { OrderHistoryListItem } from './account/order-history/order-history-list';
import Pager from './account/order-history/pager';
import TranslationManager from '../infrastructure/translation-manager';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import UserContext from '../infrastructure/context/user/user.context';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import Spinner from './common/spinner';
import CheckUtils from '../utilities/check-utils';

const AccountOrderHistoryPage: React.FC = () => {
    return (
        <TranslationsContext.Consumer>
            {(translationsContext: ITranslationsContext) =>
                <UserContext.Consumer>
                    {(userContext: IUserContext) =>
                        <AccountOrderHistoryPageInner
                            translationsContext={translationsContext}
                            userContext={userContext}
                        />
                    }
                </UserContext.Consumer>
            }
        </TranslationsContext.Consumer>
    );
};

type Props = {
    userContext: IUserContext;
    translationsContext: ITranslationsContext;
};

type State = {
    pageSize: number;
    pageNumber: number,
    orders: OrderHistoryListItem[];

    selectedOrderNumber: string;
    navigateToOrderDetails: boolean;
    navigateToLogin: boolean;

    isLoaded: boolean;
};

class AccountOrderHistoryPageInner extends React.Component<Props, State> {
    constructor(props: Props, private translations: { [key: string]: string }) {
        super(props);

        this.state = {
            pageSize: 10,
            pageNumber: 1,
            orders: [],
            selectedOrderNumber: '',
            navigateToOrderDetails: false,
            navigateToLogin: false,
            isLoaded: false,
        };

        this.handleViewOrderClick = this.handleViewOrderClick.bind(this);
        this.handleReOrderOrderClick = this.handleReOrderOrderClick.bind(this);
        this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);
    }

    public componentDidMount(): void {
        this.initializeTranslations();
        this.getOrderHistory();
    }

    public render(): ReactNode {
        return (
            <div className='col2-left-layout oticon-address'>
                <div className='wrapper'>
                    <AccountMenu />
                    <div className='col-main'>
                        <div className='my-account'>
                            <div className='dashboard account-wr'>
                                <h2>{this.translations.myOrders}</h2>
                                {this.renderList()}
                                {this.state.navigateToOrderDetails && <Redirect push to={`${RouterPaths.AccountOrderHistoryDetails}/${this.state.selectedOrderNumber}`} />}
                                {this.state.navigateToLogin && <Redirect push to={RouterPaths.Login} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderList(): ReactNode {
        if (!this.state.isLoaded) {
            return <Spinner show />;
        }

        if (this.state.orders.length === 0) {
            return (
                <p style={{ marginTop: '25px' }}>{this.translations.youHaveNoPlacedOrders}</p>
            );
        }

        return (
            <>
                <Pager
                    numberOfOrders={this.state.orders.length}
                    onPaginationChange={this.handleItemsPerPageChange}
                />
                <TranslationsContext.Consumer>
                    {(translationsContext: ITranslationsContext) =>
                        <OrderHistoryList
                            orders={this.state.orders}
                            translationsContext={translationsContext}
                            onReorderClick={this.handleReOrderOrderClick}
                            onViewOrderClick={this.handleViewOrderClick}
                        />
                    }
                </TranslationsContext.Consumer>
            </>
        );
    }

    private getOrderHistory() {
        if (!CheckUtils.isNullObject(this.props.userContext.user.tokenInfo)) {
            GetHttpClientInstance()
                .orders
                .getHistory(this.state.pageNumber, this.state.pageSize, { accessToken: this.props.userContext.user.tokenInfo!.accessToken })
                .then((orderHistoryList: OrderHistoryListResponse) => {
                    const orders: OrderHistoryListItem[] = [];

                    for (const listItem of orderHistoryList.items) {
                        const order: OrderHistoryListItem = {
                            orderNumber: listItem.orderNumber,
                            orderDate: listItem.placementDate,
                            orderTotal: '',
                            shipTo: listItem.placedByUserName,
                            status: listItem.status,
                        };

                        orders.push(order);
                    }

                    this.setState({ orders: orders, isLoaded: true });
                })
                .catch(() => {
                    this.props.userContext.clearUser();
                    this.setState({ navigateToLogin: true });
                });
        }
    }

    private initializeTranslations(): void {
        const translationManager = new TranslationManager(this.props.translationsContext);

        this.translations = {
            myOrders: translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_MY_ORDERS),
            youHaveNoPlacedOrders: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_YOUHAVENOPLACEDORDERS),
        };
    }

    private handleViewOrderClick(orderNumber: string): void {
        this.setState({ selectedOrderNumber: orderNumber }, () => {
            this.setState({ navigateToOrderDetails: true });
        });
    }

    private handleReOrderOrderClick(orderNumber: string): void {
        this.setState({ selectedOrderNumber: orderNumber });
    }

    private handleItemsPerPageChange(pageSize: number): void {
        this.setState({ pageSize: pageSize }, () => {
            this.getOrderHistory();
        });
    }
}

export default AccountOrderHistoryPage;
