import * as React from 'react';
import { Link } from 'react-router-dom';
import MobileHeaderMenu from '../page-header/mobile-header-menu';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationsMenager from '../../infrastructure/translation-manager';
import { RouterPaths, TranslationKeys } from '../../infrastructure/const';
import UserContext from '../../infrastructure/context/user/user.context';

type MobileHeaderState = {
    isActive: boolean
}

type MobileHeaderProps = {
    brand: string;
}

export default class MobileHeader extends React.Component<MobileHeaderProps, MobileHeaderState> {

    constructor(props: MobileHeaderProps) {
        super(props);

        this.state = {
            isActive: false
        }
    }

    onMenuClick(): void {
        this.setState({ isActive: !this.state.isActive });
    }

    onMenuVisibilityChange(isVisible: boolean): void {
        this.setState({ isActive: isVisible });
    }

    renderHamburgerMenu(): JSX.Element {
        return (
            <TranslationsContext.Consumer>
                {(translationsContext) => (
                    <div id="js-phone-menu" className="phone-menu" onClick={() => this.onMenuClick()}>
                        <Link to="#" title="Category Menu" className={this.state.isActive ? 'active' : ''}>
                            <span className="hamburger">
                                <span className="bread"></span>
                                <span className="bread"></span>
                                <span className="bread"></span>
                            </span>
                            <span className="sr-only">{new TranslationsMenager(translationsContext).getTranslation(TranslationKeys.MENU)}</span>
                        </Link>
                    </div>
                )}
            </TranslationsContext.Consumer>
        )
    }

    renderLogo(): JSX.Element {
        let logoSrc, logoAlt, logoTitle: string;

        switch (this.props.brand) {
            case 'phonicear':
              logoSrc = '/images/phonic-ear-logo.png';
              logoAlt = 'Phonic Ear Logo';
              logoTitle = 'Phonic Ear';
              break;
            case 'bernafon':
              logoSrc = '/images/bernafon-logo.png';
              logoAlt = 'Bernafon Logo';
              logoTitle = 'Bernafon';
              break;
            case 'horselbutik':
              logoSrc = '/images/horselbutik-logo.png';
              logoAlt = 'Horselbutik Logo';
              logoTitle = 'Horselbutik';
              break;
            default:
              logoSrc = 'https://wdh01.azureedge.net/-/media/oticon/shared/logos/oticon_payoff_pos_185px.svg';
              logoAlt = 'Oticon Logo';
              logoTitle = 'Oticon People First';
        }

        return (
            <Link to="/" title={logoTitle} className="logo-min">
                <img src={logoSrc} alt={logoAlt} />
            </Link>
        )
    }

    render(): JSX.Element {
        return (
            <UserContext.Consumer>
                {(userContext) => (
                    <div className="wrapper mobile-header clearfix br-small">
                        {this.renderHamburgerMenu()}
                        {this.renderLogo()}
                        <div className="headercart" id="headercart-disabled-1">
                            <div className="headercart__top">
                                <Link className="headercart-info" to={RouterPaths.CartPage}>
                                    <span className="icon-top-cart"></span>
                                    <span className="headercart-items">{userContext.cart.total}</span>
                                    {/* <span>{new TranslationsMenager(translationsContext).getTranslation(TranslationKeys.CART_ITEMS)}</span> */}
                                </Link>
                            </div>
                        </div>
                        <MobileHeaderMenu
                            isActive={this.state.isActive}
                            onMenuVisibilityChange={(isVisible: boolean) => this.onMenuVisibilityChange(isVisible)}
                            userContext={userContext}
                        />
                    </div>
                )}
            </UserContext.Consumer>
        )
    }
}