import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './styles/main.scss';
import App from './App';
import GoogleTagManager from './external-scripts/gmt-google-tag-manager';

GoogleTagManager.initialize();

ReactDOM.render(
    <App />,
    document.getElementById('root')
);