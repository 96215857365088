import React, { ReactNode } from 'react';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { ErrorCodes } from '../infrastructure/http/modules/errors';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import { RouterPaths, TranslationKeys, RouterStateParams } from '../infrastructure/const';
import CheckUtils from '../utilities/check-utils';
import CreateAccountSection from './auth/create-account-section';
import ErrorMessages from './common/error-messages';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import LoginForm from './common/auth/login-form';
import TranslationManager from '../infrastructure/translation-manager';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import UserContext from '../infrastructure/context/user/user.context';

type State = {
    redirectToMainPage: boolean;

    cameFromResetPassword: boolean;
    resetPasswordEmail: string;
    cameFromChangeForgottenPassword: boolean;

    errorKeys: string[];

    previousPageRedirect: string;
    redirectToPreviousPath: boolean;
};

class LoginPage extends React.Component<RouteComponentProps, State> {
    private loginFormRef: React.RefObject<LoginForm>;
    constructor(props: RouteComponentProps, private translations: { [key: string]: any }) {
        super(props);

        this.state = {
            redirectToMainPage: false,
            cameFromResetPassword: false,
            resetPasswordEmail: '',
            cameFromChangeForgottenPassword: false,
            errorKeys: [],
            previousPageRedirect: "",
            redirectToPreviousPath: false
        };

        this.loginFormRef = React.createRef<LoginForm>();
    }

    componentDidMount(): void {
        if (!CheckUtils.isNullObject(this.props.location.state)) {
            const state = this.props.location.state as { [key: string]: any };
            if (!CheckUtils.isNullOrEmptyString(state[RouterStateParams.ShowForgotPasswordSubmittedSuccessMessage])) {
                const email = state[RouterStateParams.ShowForgotPasswordSubmittedSuccessMessage];

                this.setState({ resetPasswordEmail: email, cameFromResetPassword: true });
                this.props.history.replace({ state: undefined });
            }

            if (!CheckUtils.isNullOrEmptyString(state[RouterStateParams.ShowPasswordUpdatedSuccessMessage])) {
                this.setState({ cameFromChangeForgottenPassword: true });
                this.props.history.replace({ state: undefined });
            }

            if (!CheckUtils.isNullOrEmptyString(state[RouterStateParams.ShowFavouritesAuthenticateError])) {
                this.setState({
                    errorKeys: [ErrorCodes.FavouritesAuthenticateError],
                    previousPageRedirect: state.prevPath
                })
                this.props.history.replace({ state: undefined });
            }
        }
    }

    public render(): ReactNode {
        const translationManager = new TranslationManager(this.context);

        this.translations = {
            loginHeader: translationManager.getTranslation(TranslationKeys.LOGIN_HEADER),
            youWillReceiveResetLink: translationManager.getTranslation(TranslationKeys.RESETPASSWORD_YOUWILLRECEIVERESETLINK),
            youPasswordHasBeenUpdated: translationManager.getTranslation(TranslationKeys.RESETPASSWORD_YOURPASSWORDHASBEENUPDATED),
        };

        return (
            <div className='account-login wrapper'>
                {this.state.redirectToPreviousPath &&
                    <Redirect to={this.state.previousPageRedirect} />
                }
                {this.state.redirectToMainPage &&
                    <Redirect to={RouterPaths.LandingPage} />
                }
                <h1 className='page-big-title'>{this.translations.loginHeader}</h1>
                {this.renderMessagesSection()}
                <ErrorMessages errorKeys={this.state.errorKeys} />
                <div className='col-2 registered-users'>
                    <UserContext.Consumer>
                        {(userContext: IUserContext) =>
                            <LoginForm
                                ref={this.loginFormRef}
                                isCheckoutPage={false}
                                onLoginClicked={(login, password) => this.handleLoginClicked(login, password, userContext)}
                                resetInvalidLoginOrPasswordError={() => this.resetInvalidLoginOrPasswordError()}
                            />
                        }
                    </UserContext.Consumer>
                </div>
                <div className='col-2 new-users'>
                    <CreateAccountSection />
                </div>
            </div>
        );
    }

    private renderMessagesSection(): ReactNode {
        if (!this.state.cameFromResetPassword && !this.state.cameFromChangeForgottenPassword) {
            return null;
        }

        return (
            <ul className='messages'>
                <li className='success-msg'>
                    <ul>
                        <li>
                            <span>
                                {this.state.cameFromResetPassword && this.translations.youWillReceiveResetLink.replace('{0}', this.state.resetPasswordEmail)}
                                {this.state.cameFromChangeForgottenPassword && this.translations.youPasswordHasBeenUpdated}
                            </span>
                        </li>
                    </ul>
                </li>
            </ul>
        );
    }

    private handleLoginClicked(email: string, password: string, userContext: IUserContext): void {
        const { auth } = GetHttpClientInstance();

        auth.login({ email, password })
            .then(loginResponse => {
                userContext.updateUser(loginResponse).then(() => {
                    if (!CheckUtils.isNullOrEmptyString(this.state.previousPageRedirect)) {
                        this.setState({ redirectToPreviousPath: true })
                    } else {
                        this.setState({ redirectToMainPage: true })
                    }
                });
            }).catch(() => {
                this.setState({ errorKeys: [ErrorCodes.InvalidLoginOrPassword] });
                if (this.loginFormRef !== null && this.loginFormRef.current !== null) {
                    this.loginFormRef.current.resetPasswordInput();
                }
            });
    }

    private resetInvalidLoginOrPasswordError(): void {
        if (!CheckUtils.isNullObject(this.state) && !CheckUtils.isNullOrEmptyArray(this.state.errorKeys)) {
            let errorKeys = [...this.state.errorKeys];
            errorKeys = errorKeys.filter(x => x !== ErrorCodes.InvalidLoginOrPassword);
            this.setState({ errorKeys });
        }
    }
}

LoginPage.contextType = TranslationsContext;

export default withRouter(LoginPage);
