import React, { useContext } from 'react';
import { IUserProfile } from '../../../infrastructure/context/user/user.context.interface';
import { TranslationKeys } from '../../../infrastructure/const';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';

type Props = {
    userProfile: IUserProfile;
    onEditClick: (() => void);
};

const ContactInformation: React.FC<Props> = (props: Props) => {
    const context = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(context);

    const configuration = useContext<IConfigurationContext>(ConfigurationContext);

    const translations = {
        header: translationManager.getTranslation(TranslationKeys.ACCOUNT_CONTACTINFORMATION),
        edit: translationManager.getTranslation(TranslationKeys.ACCOUNT_EDIT),
    };

    function renderMiddleName(): JSX.Element | null {
        if (configuration.isFeatureFlagEnabled("hideCustomerMiddleName")) {
            return null;
        }

        return (
            <> {props.userProfile.middleName}</>
        )
    }

    return (
        <div className='col-2 box-account__box'>
            <div className='box-top'>
                <h3>{translations.header}</h3>
            </div>
            <div className='box-content'>
                <p>
                    {props.userProfile.firstName}{renderMiddleName()} {props.userProfile.lastName}<br />
                    {props.userProfile.email}<br />
                </p>
                <button type='button' title={translations.edit} className='button' onClick={() => props.onEditClick()}>{translations.edit}</button>
            </div>
        </div>
    );
};

export default ContactInformation;
