import { ModuleBase } from "../../common/module.base";
import { IHttp } from "../../http.interface";
import { IStaticContentModule } from "./static-content.interface";
import { StaticContentContainer } from "./static-content.models";

export default class StaticContentModule extends ModuleBase implements IStaticContentModule {
    constructor(private http: IHttp) {
        super('Configuration');
    }

    public getAll(): Promise<StaticContentContainer> {
        return new Promise<StaticContentContainer>((resolve, reject) => {
            this.http
                .get('/api/v1.0/static-content/all')
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                })
                .catch(() => reject());
        });
    }

}