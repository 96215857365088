import { IHttp } from '../../http.interface';
import { TranslationsResult } from './translations.models';
import { ModuleBase } from '../../common/module.base';
import { ITranslationsModule } from './translations.interface';

export class TranslationsModule extends ModuleBase implements ITranslationsModule {

    constructor(private http: IHttp) {
        super('Translations');
    }

    get(): Promise<TranslationsResult> {
        return new Promise<TranslationsResult>((resolve, reject) => {
            this.http.get<TranslationsResult>(`/api/translations`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                });
        });
    }
}