import React from 'react';
import { useContext } from 'react';
import { TranslationKeys } from '../../infrastructure/const';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import { Price, PromoResponse } from '../../infrastructure/http/modules/product/product.models';
import TranslationManager from '../../infrastructure/translation-manager';
import CheckUtils from '../../utilities/check-utils';
import PriceText from '../common/price-text';

type Props = {
    priceResponse: Price,
    productPromoResponse: PromoResponse,
    isOnPromotion: boolean,
    additionalClass?: string | undefined
}

const ProductListPriceBox = (props: Props) => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);
    const translations: { [id: string]: string } = {
        ordinaryPrice: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_ORDINARYPRICE),
        asLowAs: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_ASLOWAS)
    }

    function getMinimumBulkPriceText(): JSX.Element | null {
        if (!props.priceResponse.isBulkPurchaseProduct) {
            return null;
        }

        if (!CheckUtils.isNullObject(props.productPromoResponse) && !CheckUtils.isNullOrEmptyString(props.productPromoResponse.minimumBulkPriceStr)) {
            return (
                <span>
                    {translations.asLowAs + " " + props.productPromoResponse.minimumBulkPriceStr}
                </span>
            )
        }

        return (
            <span>
                {translations.asLowAs + " " + props.priceResponse.minimumBulkPriceStr}
            </span>
        )
    }

    function getAdditionalPriceText(): JSX.Element | null {
        if (!props.priceResponse.isBulkPurchaseProduct && !props.priceResponse.isDiscountedProduct) {
            return null;
        }

        return (
            <>
                {
                    props.priceResponse.isDiscountedProduct &&
                    <span>
                        {translations.ordinaryPrice + " " + props.priceResponse.regularPriceStr}
                    </span>
                }
                {
                    getMinimumBulkPriceText()
                }
            </>
        );
    }

    function getPriceText(): JSX.Element {
        return (
            <PriceText
                promoPrice={props.productPromoResponse ? props.productPromoResponse.priceStr : ""}
                regularPrice={props.priceResponse.priceStr}
                isOnPromotion={props.isOnPromotion}
                additionalClass={props.additionalClass}
            />
        )
    }

    return (
        <div className="price-box">
            {getPriceText()}
            <div className="disclaimer">
                {getAdditionalPriceText()}
            </div>
        </div>
    );
};

export default ProductListPriceBox;