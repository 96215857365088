import React, { useContext } from 'react';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../../infrastructure/translation-manager';
import IUserContext from '../../../infrastructure/context/user/user.context.interface';
import UserContext from '../../../infrastructure/context/user/user.context';
import { TranslationKeys } from '../../../infrastructure/const';

type Props = {
    checked: boolean,
    onChange: ((newValue: boolean) => void),
    id: string
};

const SaveAddressCheckbox = (props: Props): JSX.Element | null => {
    const context = useContext<ITranslationsContext>(TranslationsContext);
    const userContext = useContext<IUserContext>(UserContext);
    const translations = {
        saveAddress: new TranslationManager(context).getTranslation(TranslationKeys.CHECKOUT_SAVE_ADDRESS)
    };

    if (!userContext.user.userProfile.isAuthenticated) {
        return null;
    }

    return (
        <div>
            <input
                checked={props.checked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const checked = e.target.checked;
                    props.onChange(checked);
                }}
                id={props.id}
                type="checkbox"
                name={props.id}
                className="hidden-checkbox css-checkbox" />
            <label className="css-label coupon-open" htmlFor={props.id}>{translations.saveAddress}</label>
        </div>
    );
};

export default SaveAddressCheckbox;