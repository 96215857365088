import * as React from 'react';
import { Cart, CartProduct } from '../../http/modules/cart/cart.models';
import { IUser, IUserProfile, ITokenInfo, IAddress, ISubscriptions } from './user.context.interface';
import CheckUtils from '../../../utilities/check-utils';
import CookieKeys from '../../cookie/cookie-keys';
import CookiesManager from '../../cookie/cookies-manager';
import GuidUtils from '../../../utilities/guid-utils';
import UserContext from './user.context';
import { GetHttpClientInstance } from '../http/http-context-provider';
import { LoginResponse, UserProfileResponse, Address } from '../../http/modules/auth/auth.models';
import Spinner from '../../../components/common/spinner';
import { CreateSubscriptionRequest } from '../../http/modules/subscriptions/subscriptions.models';
import { IoFilterCircle } from 'react-icons/io5';

type State = {
  user: IUser,
  cart: Cart,
  isCartLoaded: boolean,
  cartUpdateInProgress: boolean,
  removeFromCartPopupVisible: boolean
};

class UserContextProvider extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      user: {} as IUser,
      cart: {} as Cart,
      isCartLoaded: false,
      cartUpdateInProgress: false,
      removeFromCartPopupVisible: false
    };

    this.updateQuantityInCart = this.updateQuantityInCart.bind(this)
    this.refreshCart = this.refreshCart.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.updateUserProfile = this.updateUserProfile.bind(this);
    this.clearUser = this.clearUser.bind(this);
    this.setCartUpdateInProgress = this.setCartUpdateInProgress.bind(this);
    this.setRemoveFromCartPopupVisibility = this.setRemoveFromCartPopupVisibility.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.isSubscribed = this.isSubscribed.bind(this);
    this.deleteSubscription = this.deleteSubscription.bind(this);
  }

  public componentDidMount(): void {
    const user: IUser = CookiesManager.getCookie(CookieKeys.user);

    if (this.isAuthenticatedUser(user) && !this.isTokenExpired(user.tokenInfo as ITokenInfo)) {
      this.initializeAuthenticatedUser(user.userProfile, user.tokenInfo!, user.subscriptions);
    } else {
      this.initializeAnonymousUser(user);
    }
  }

  public render(): React.ReactNode {
    if (!this.state.isCartLoaded) {
      return <Spinner show={true} default />;
    }

    return (
      <UserContext.Provider
        value={{
          user: this.state.user,
          cart: this.state.cart,
          updateQuantityInCart: this.updateQuantityInCart,
          refreshCart: this.refreshCart,
          updateUser: this.updateUser,
          updateUserProfile: this.updateUserProfile,
          clearUser: this.clearUser,
          isCartLoaded: this.state.isCartLoaded,
          cartUpdateInProgress: this.state.cartUpdateInProgress,
          setCartUpdateInProgress: this.setCartUpdateInProgress,
          setRemoveFromCartPopupVisibility: this.setRemoveFromCartPopupVisibility,
          removeFromCartPopupVisible: this.state.removeFromCartPopupVisible,
          subscribe: this.subscribe,
          isSubscribed: this.isSubscribed,
          deleteSubscription: this.deleteSubscription
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }

  private isAuthenticatedUser(user: IUser | null): boolean {
    return !CheckUtils.isNullObject(user)
      && !CheckUtils.isNullObject(user!.tokenInfo)
      && !CheckUtils.isNullObject(user!.userProfile);
  }

  private isTokenExpired(token: ITokenInfo) {
    return CheckUtils.isNullObject(token) || new Date(token.expiresAt) <= new Date();
  }

  private initializeAnonymousUser(user: IUser | null): void {
    // TODO: set expiration date

    if (CheckUtils.isNullObject(user) || CheckUtils.isNullObject(user!.userProfile) || CheckUtils.isNullObject(user!.subscriptions) || CheckUtils.isNullOrEmptyString(user!.userProfile.userId)) {
      // Create User ID
      const userId = GuidUtils.create();
      user = {
        userProfile: {
          userId: userId
        },
        subscriptions: { Newsletter: null }
      } as IUser;
    }

    user!.userProfile.isAuthenticated = false;
    user!.tokenInfo = undefined;
    GetHttpClientInstance().http.setToken("");

    // Update Cookie
    CookiesManager.setCookie(CookieKeys.user, user);

    this.setState({ user: user as IUser }, () => {
      this.refreshCart();
    });
  }

  private updateQuantityInCart(id: string, qty: number)
  {
    const updatedProduct = this.state.cart.products.find( p => p.id === id);
    if(!CheckUtils.isNullObject(updatedProduct))
    {
      updatedProduct!.quantity = qty;
    }
  }

  private refreshCart(): Promise<Cart> {
    return new Promise<Cart>((resolve, reject) => {
      this.setState({ cartUpdateInProgress: true });
      GetHttpClientInstance().cart.get(this.state.user.userProfile!.userId).then((result: Cart) => {
        this.setCartState(result).then((cart) => {
          resolve(cart);
        }).catch((error) => {
          reject(error);
        });
      }).catch((error: any) => {
        console.error(error);
        reject(error);
      });
    });
  }

  private overrideCart(prevUserId: string, currentUserId: string): Promise<Cart> {
    return new Promise<Cart>((resolve, reject) => {
      GetHttpClientInstance().cart.override(prevUserId, currentUserId).then((result: Cart) => {
        this.setCartState(result).then((cart) => {
          resolve(cart);
        }).catch((error) => {
          reject(error);
        });
      }).catch((error: any) => {
        console.error(error);
        reject(error);
      });
    });
  }

  private setCartState(cart: Cart): Promise<Cart> {
    return new Promise<Cart>((resolve) => {
      if (CheckUtils.isNullObject(cart)) {
        cart = {} as Cart;
      }
      if (CheckUtils.isNullObject(cart.products)) {
        cart.products = [] as Array<CartProduct>;
      }

      // sort products by recently added
      cart.products = cart.products.reverse();

      this.setState({
        cart,
        isCartLoaded: true,
        cartUpdateInProgress: false
      }, () => {
        resolve(cart);
      });
    });
  }

  private setCartUpdateInProgress(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.setState({ cartUpdateInProgress: true }, () => resolve());
    });
  }

  private setRemoveFromCartPopupVisibility(isVisible: boolean): Promise<void> {
    return new Promise<void>((resolve) => {
      this.setState({ removeFromCartPopupVisible: isVisible }, () => resolve());
    });
  }

  private getUserSubscriptions(accessToken: string): Promise<ISubscriptions> {
    return new Promise<ISubscriptions>((resolve) => {
      let subscriptions: ISubscriptions = { Newsletter: null };
      let newsletterPromise = GetHttpClientInstance().subscriptions.get('Newsletter', accessToken);

      Promise.all([newsletterPromise]).then((values) => {
        subscriptions.Newsletter = values[0];

        resolve(subscriptions);
      });
    });
  }

  private updateUser(loginResponse: LoginResponse): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      if (!loginResponse.userProfile) {
        console.error('UserProfile is not defined!');
        reject();
      }
  
      const userProfile: IUserProfile = {
        userId: loginResponse.userProfile.userId,
        firstName: loginResponse.userProfile.firstName,
        middleName: loginResponse.userProfile.middleName,
        lastName: loginResponse.userProfile.lastName,
        email: loginResponse.userProfile.email,
        addressBook: loginResponse.userProfile.addressBook.map(this.mapAddress),
        isAuthenticated: true,
        customerPreference: loginResponse.userProfile.customerPreference,
        favourites: CheckUtils.isNullObject(loginResponse.userProfile.favouriteProducts) ? [] : loginResponse.userProfile.favouriteProducts
      };

      if (!loginResponse.tokenInfo) {
        console.error('TokenInfo is not defined!');
        reject();
      }
  
      const tokenInfo: ITokenInfo = {
        accessToken: loginResponse.tokenInfo.accessToken,
        tokenType: loginResponse.tokenInfo.tokenType,
        expiresAt: loginResponse.tokenInfo.expiresAt,
        expiresIn: loginResponse.tokenInfo.expiresIn,
        issuedAt: loginResponse.tokenInfo.issuedAt,
        scope: loginResponse.tokenInfo.scope,
      };

      const subscriptionsPromise = this.getUserSubscriptions(loginResponse.tokenInfo.accessToken);

      Promise.all([subscriptionsPromise]).then((values) => {
        this.initializeAuthenticatedUser(userProfile, tokenInfo, values[0]);
        resolve(this.state.user);
      });
    });
  }

  private updateUserProfile(userProfileResponse: UserProfileResponse): void {
    if (!userProfileResponse) {
      console.error('UserProfile is not defined!');
    }

    const userProfile: IUserProfile = {
      userId: userProfileResponse.userId,
      firstName: userProfileResponse.firstName,
      middleName: userProfileResponse.middleName,
      lastName: userProfileResponse.lastName,
      email: userProfileResponse.email,
      addressBook: userProfileResponse.addressBook.map(this.mapAddress),
      isAuthenticated: true,
      customerPreference: userProfileResponse.customerPreference,
      favourites: CheckUtils.isNullObject(userProfileResponse.favouriteProducts) ? [] : userProfileResponse.favouriteProducts
    };

    this.initializeAuthenticatedUser(userProfile, this.state.user.tokenInfo!, this.state.user.subscriptions);
  }

  private subscribe(request: CreateSubscriptionRequest): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const subscriptionsEndpoint = GetHttpClientInstance().subscriptions;

      subscriptionsEndpoint.subscribe(request)
        .then((id: string) => {

          // do not cache subscriptions for unathorized user
          if (CheckUtils.isNullObject(this.state.user) ||
            !this.isAuthenticatedUser(this.state.user) ||
            CheckUtils.isNullObject(this.state.user.subscriptions)) {
            resolve();
          }

          let subscriptions: ISubscriptions = this.state.user.subscriptions;
          subscriptions[request.type] = id;

          this.initializeAuthenticatedUser(this.state.user.userProfile, this.state.user.tokenInfo!, subscriptions);
          resolve();
        }).catch(() => reject());
    });
  }

  private isSubscribed(type: keyof ISubscriptions): boolean {
    if (CheckUtils.isNullObject(this.state.user) ||
      CheckUtils.isNullObject(this.state.user.subscriptions)) {
      return false;
    }

    let subscriptions: ISubscriptions = this.state.user.subscriptions;

    return !CheckUtils.isNullOrEmptyString(subscriptions[type]);
  }

  private deleteSubscription(type: keyof ISubscriptions): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let subscriptions: ISubscriptions = this.state.user.subscriptions;
      let id = subscriptions[type];
      
      if(!CheckUtils.isNullOrEmptyString(id)){
        resolve();
      }

      const subscriptionsEndpoint = GetHttpClientInstance().subscriptions;

      subscriptionsEndpoint.delete(id!)
        .then(() => {
          if (CheckUtils.isNullObject(this.state.user) ||
            !this.isAuthenticatedUser(this.state.user) ||
            CheckUtils.isNullObject(this.state.user.subscriptions)) {
            resolve();
          }

          let subscriptions: ISubscriptions = this.state.user.subscriptions;
          subscriptions[type] = null;

          this.initializeAuthenticatedUser(this.state.user.userProfile, this.state.user.tokenInfo!, subscriptions);
          resolve();
        }).catch(() => reject());
    });
  }

  private initializeAuthenticatedUser(userProfile: IUserProfile, token: ITokenInfo, subscriptions: ISubscriptions): void {
    let previousUser: IUser = CookiesManager.getCookie(CookieKeys.user);
    let { user } = this.state;

    user.tokenInfo = {
      tokenType: token.tokenType,
      scope: token.scope,
      accessToken: token.accessToken,
      issuedAt: token.issuedAt,
      expiresIn: token.expiresIn,
      expiresAt: token.expiresAt,
    } as ITokenInfo;

    user.userProfile = {
      userId: userProfile.userId,
      isAuthenticated: true,
      firstName: userProfile.firstName,
      middleName: userProfile.middleName,
      lastName: userProfile.lastName,
      email: userProfile.email,
      addressBook: userProfile.addressBook.map(this.mapAddress),
      customerPreference: userProfile.customerPreference,
      favourites: userProfile.favourites
    } as IUserProfile;

    user.subscriptions = subscriptions;

    CookiesManager.setCookie(CookieKeys.user, user);
    GetHttpClientInstance().http.setToken(token.accessToken);

    this.setState({ user }, () => {
      if (previousUser.userProfile.userId !== userProfile.userId) {
        this.overrideCart(previousUser.userProfile.userId, userProfile.userId);
      } else {
        this.refreshCart();
      }
    });
  }

  private clearUser(): void {
    this.initializeAnonymousUser(null);
  }

  private mapAddress(addressResponse: Address): IAddress {
    return {
      id: addressResponse.id,
      firstName: addressResponse.firstName,
      middleName: addressResponse.middleName,
      lastName: addressResponse.lastName,
      company: addressResponse.company,
      telephone: addressResponse.telephone,
      fax: addressResponse.fax,
      streetAddress1: addressResponse.streetAddress1,
      streetAddress2: addressResponse.streetAddress2,
      city: addressResponse.city,
      postalCode: addressResponse.postalCode,
      country: addressResponse.country,
      isDefaultBillingAddress: addressResponse.isDefaultBillingAddress,
      isDefaultShippingAddress: addressResponse.isDefaultShippingAddress,
    };
  }
}

export default UserContextProvider;