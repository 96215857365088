import React from 'react';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import { TranslationKeys } from '../../infrastructure/const';
import CartProductListItem from './cart-product-list-item';
import { CartProduct } from '../../infrastructure/http/modules/cart/cart.models';
import CheckUtils from '../../utilities/check-utils';
import IUserContext from '../../infrastructure/context/user/user.context.interface';


type CartProductListProps = {
    products: CartProduct[];
    context: IUserContext;
}

type CartProductListState = {

}

export default class CartProductList extends React.Component<CartProductListProps, CartProductListState> {
    constructor(props: CartProductListProps, context: ITranslationsContext) {
        super(props, context);
        this.state = {
        } as CartProductListState;
    }

    renderProductsList(): JSX.Element {
        return (
            <>
                {this.props.products.map((product) => (
                    <CartProductListItem key={product.id} product={product} userContext={this.props.context} />
                ))}
            </>
        )
    }

    render(): JSX.Element | null {
        if (CheckUtils.isNullOrEmptyArray(this.props.products)) {
            return null;
        }
        return (
            <div className="shopping-cart">
                <form>
                    <fieldset>
                        <table id="shopping-cart-table" className="data-table cart-table">
                            <tbody>
                                {this.renderProductsList()}
                            </tbody>
                        </table>
                    </fieldset>
                </form>
            </div>
        )
    }
}

CartProductList.contextType = TranslationsContext;
