import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { TranslationKeys } from '../infrastructure/const';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../infrastructure/translation-manager';
import CheckUtils from '../utilities/check-utils';
import NewsletterRegisterEmail from './newsletter/newsletter-register-email';

const NewsletterPage = () => {
    const translationsContext: ITranslationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);
    const translations = {
        header: translationManager.getTranslation(TranslationKeys.NEWSLETTER_SUBSCRIBE_PAGE_HEADER),
        text: translationManager.getTranslation(TranslationKeys.NEWSLETTER_SUBSCRIBE_PAGE_HTML)
    }

    return (
        <div className="newsletter-page">
            <h1>{translations.header}</h1>
            {!CheckUtils.isNullOrEmptyString(translations.text) &&
                <p>{ReactHtmlParser(translations.text)}</p>}

            <NewsletterRegisterEmail />
        </div>
    );
};

export default NewsletterPage;