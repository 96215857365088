import React from 'react';
import CheckUtils from '../../utilities/check-utils';

type Props = {
    promoPrice: string,
    regularPrice: string,
    isOnPromotion: boolean,
    additionalClass?: string | undefined
}

const PriceText = (props: Props) => {
    if (props.isOnPromotion) {
        return (
            <span className="regular-price" id="product-price-224">
                <span className={"price " + (CheckUtils.isNullOrEmptyString(props.additionalClass) ? "" : props.additionalClass)}>
                    <span className="promo">{props.promoPrice} </span>
                    <s className="strikethrough">{props.regularPrice}</s>
                </span>
            </span>
        );
    }

    return (
        <span className="regular-price" id="product-price-224">
            <span className={"price " + (CheckUtils.isNullOrEmptyString(props.additionalClass) ? "" : props.additionalClass)}>{props.regularPrice}</span>
        </span>
    )
};

export default PriceText;