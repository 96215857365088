import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import StringUtils from '../../utilities/string-utils';

type Props = {
    text: string;
};

const MultiLineText = (props: Props): JSX.Element => {
    let text = props.text;
    text = StringUtils.convertStringWithNewLinesToHtmlString(text);

    return <>{ReactHtmlParser(text)}</>;
};

export default MultiLineText;