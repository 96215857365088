import React from 'react';

type Props = {
    addToCartButtonClicked: boolean,
    isProductInCart: boolean,
    activeClass: boolean,
    translation: string
}

const AddedToCartMessage = (props: Props): JSX.Element | null => {
    if (props.addToCartButtonClicked || props.isProductInCart) {
        return (
            <div className={`product-detailing-top ${props.activeClass ? 'is-active' : ''}`}>
                <div className="product-incart">
                    <span className="icon"></span>
                    <span className="text">{props.translation}</span>
                </div>
            </div>
        )
    }
    return null;
};

export default AddedToCartMessage;