import CheckUtils from '../../utilities/check-utils';

class CacheManager {
    public static setItem(name: string, item: object): void {
        sessionStorage.setItem(name, JSON.stringify(item));
    }

    public static getItem(name: string): object | null {
        const objStr = sessionStorage.getItem(name);
        if (CheckUtils.isNullOrEmptyString(objStr)) {
            return null;
        }
        return JSON.parse(objStr as string);
    }

    public static removeItem(name: string): void {
        sessionStorage.removeItem(name);
    }
}

export default CacheManager;