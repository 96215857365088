import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { RouterPaths, TranslationKeys } from '../../infrastructure/const';
import { GetHttpClientInstance } from '../../infrastructure/context/http/http-context-provider';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import UserContext from '../../infrastructure/context/user/user.context';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import { Product } from '../../infrastructure/http/modules/product/product.models';
import TranslationManager from '../../infrastructure/translation-manager';
import CheckUtils from '../../utilities/check-utils';
import AccountMenu from '../account/menu/account-menu';
import ProductList from '../product-list/product-list';
import SortByToolbar from '../product-list/sort-by-toolbar';
import ReactPaginate from 'react-paginate';
import Spinner from './spinner';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';

const AccountFavouritesPage = (): JSX.Element => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    const favouritesEnabled = configurationContext.isFeatureFlagEnabled("allowFavourites");

    const translationContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationContext);
    const translations: { [id: string]: string } = {
        noFavouriteProducts: translationManager.getTranslation(TranslationKeys.ACCOUNT_FAVOURITES_NOPRODUCTS),
        next: translationManager.getTranslation(TranslationKeys.PAGINATION_NEXT),
        previous: translationManager.getTranslation(TranslationKeys.PAGINATION_PREVIOUS)
    }

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [productsList, setProductsList] = useState<Array<Product>>([]);
    const [productsListSorted, setProductsListSorted] = useState<Array<Product>>([]);
    const [productListSortedPaginated, setProductListSortedPaginated] = useState<Array<Product>>([]);
    const itemsPerPage = 6;
    const [activePageIndex, setActivePageIndex] = useState<number>(0);

    const userContext = useContext<IUserContext>(UserContext);
    const isAuthenticated = !CheckUtils.isNullObject(userContext.user) && !CheckUtils.isNullObject(userContext.user.userProfile) && userContext.user.userProfile.isAuthenticated;

    useEffect(() => {
        if (isAuthenticated && favouritesEnabled) {
            const { favourites } = userContext.user.userProfile;

            if (!CheckUtils.isNullOrEmptyArray(favourites)) {
                GetHttpClientInstance().product.searchByItemNumbers(favourites, false).then((result) => {
                    if (!CheckUtils.isNullObject(result)) {
                        setProductsList(result.products);
                        setProductsListSorted(result.products);
                        setIsLoaded(true);
                    }
                });
            } else {
                setIsLoaded(true);
            }
        }
    }, [])

    useMemo(() => {
        const indexOfLastItem = (activePageIndex + 1) * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = productsListSorted.slice(indexOfFirstItem, indexOfLastItem);
        setProductListSortedPaginated(currentItems);
    }, [activePageIndex, productsListSorted])


    function handleProductListSorting(newList: Product[]): void {
        setProductsListSorted(newList);
        setActivePageIndex(0);
    }


    if (!isAuthenticated) {
        return <Redirect push to={RouterPaths.Login} />;
    }

    if (!favouritesEnabled) {
        return <Redirect push to={RouterPaths.AccountDashboard} />;
    }

    function renderMessageBox(): JSX.Element | null {
        if (productsList.length > 0) {
            return null;
        }

        return (
            <div className="page-title wrapper message-block">
                <p className="note-msg">
                    {translations.noFavouriteProducts}
                </p>
            </div>
        )
    }

    function renderProductsList(): JSX.Element | null {
        if (productsList.length === 0) {
            return null;
        }

        return (
            <>
                <SortByToolbar
                    currentItemsCount={(activePageIndex) * itemsPerPage + productListSortedPaginated.length}
                    productsList={productsList}
                    handleProductListSorting={(newList: Array<Product>) => handleProductListSorting(newList)}
                />
                <ProductList products={productListSortedPaginated} />
            </>
        )
    }

    function renderPagination(): JSX.Element | null {
        if (productsList.length <= itemsPerPage) {
            return null;
        }

        return (
            <div>
                <ReactPaginate
                    previousLabel={translations.previous}
                    nextLabel={translations.next}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={productsList.length > 0 ? Math.ceil(productsList.length / itemsPerPage) : 1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(selectedItem: { selected: number }) => setActivePageIndex(selectedItem.selected)}
                    containerClassName={'pagination'}
                    activeLinkClassName={'active'}
                    forcePage={activePageIndex}
                />
            </div>
        )
    }

    return (
        <div className='col2-left-layout oticon-address'>
            <div className='wrapper'>
                <AccountMenu />
                <div className='col-main'>
                    <div className='my-account'>
                        <div className='dashboard account-wr'>
                            <h2>{translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_MY_FAVOURITES)}</h2>
                            {!isLoaded ?
                                (
                                    <Spinner show />
                                ) :
                                (
                                    <div className="category-products wrapper favourites">
                                        {renderMessageBox()}
                                        {renderProductsList()}
                                        {renderPagination()}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountFavouritesPage;