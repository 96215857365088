import { AttributeTranslationType, RouterPaths, TranslationsPrefix } from "../infrastructure/const";
import IConfigurationContext from "../infrastructure/context/configuration/configuration.context.interface";
import INavigationContext from "../infrastructure/context/navigation/navigation.context.interface";
import ITranslationsContext from "../infrastructure/context/translations/translations-context.interface";
import { CartProduct } from "../infrastructure/http/modules/cart/cart.models";
import { Product, Variant } from "../infrastructure/http/modules/product/product.models";
import TranslationManager from "../infrastructure/translation-manager";
import CheckUtils from "./check-utils";

interface ProductData {
    urlKey: string,
    partialId: number,
    itemNumber: string
}

export default class ProductsEnrichmentUtils {

    static isInStock(configurationContext: IConfigurationContext, product: Product, variant: Variant | null) {
        if (variant !== null) {
            return variant.inStock;
        }

        return !configurationContext.isFeatureFlagEnabled("useEnrichedProductData") || product.inStock;
    }

    static hasPrice(product: Product, variant: Variant | null) {
        if (variant !== null) {
            return !CheckUtils.isNullObject(variant.priceResponse) && !CheckUtils.isNullOrEmptyString(variant.priceResponse.priceStr);
        }

        return !CheckUtils.isNullObject(product.priceResponse) && !CheckUtils.isNullOrEmptyString(product.priceResponse.priceStr);
    }

    static getProductDetailsUrl(
        configurationContext: IConfigurationContext,
        categoryContext: INavigationContext,
        product: Product | CartProduct
    ): string {
        return this.getUrl(configurationContext, categoryContext, product.urlKey, product.partialId, product.itemNumber);
    }

    private static getUrl(
        configurationContext: IConfigurationContext,
        categoryContext: INavigationContext,
        urlKey: string,
        partialId: number,
        itemNumber: string
    ) {
        if (configurationContext.isFeatureFlagEnabled("useEnrichedProductData") && !CheckUtils.isNullOrEmptyString(urlKey)) {
            return "/" + urlKey;
        }
        return categoryContext.getCategoryUrl(partialId) + RouterPaths.DetailsPage + "/" + itemNumber;

    }

    static getAttributeTranslation(translationContext: ITranslationsContext, key: string, type: keyof typeof AttributeTranslationType) {
        const translationManager = new TranslationManager(translationContext);
        const prefix = type === AttributeTranslationType.Name ? TranslationsPrefix.ProductDetails_Attribute_Name : TranslationsPrefix.ProductDetails_Attribute_Value;
        const translationKey = prefix + key;
        const translation = translationManager.getTranslation(translationKey);

        if (CheckUtils.isNullOrEmptyString(translation)) {
            return key;
        }

        return translation;
    }
}