import React, { ReactNode } from 'react';

type Props = {
    addressId: string;
    isShippingAddress: boolean;
    checked: boolean;
    onDefaultAddressCheckboxClick(addressId: string, isShipping: boolean): void;
};

class AddressBookCheckbox extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    public render(): ReactNode {
        return (
            <>
                <input
                    type='checkbox'
                    id={this.getName()}
                    name={this.getName()}
                    checked={this.props.checked}
                    onChange={this.handleChange}
                    className='css-checkbox hidden-checkbox'
                    disabled={this.props.checked}
                />
                <label
                    htmlFor={this.getName()}
                    className='css-label'
                    style={{ display: 'inline' }}
                />
            </>
        );
    }

    private handleChange(): void {
        this.props.onDefaultAddressCheckboxClick(this.props.addressId, this.props.isShippingAddress);
    }

    private getName(): string {
        return `default-${this.props.isShippingAddress ? 'shipping' : 'billing'}-${this.props.addressId}`;
    }
}

export default AddressBookCheckbox;
