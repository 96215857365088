import React, { useContext } from 'react';
import { IAddress } from '../../../infrastructure/context/user/user.context.interface';
import { TranslationKeys } from '../../../infrastructure/const';
import AddressRow from './address-row';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';

type Props = {
    addressBook: IAddress[];
    onAddressChange(addressId: string, isShipping: boolean): void;
    onEditAddressClick(addressId: string): void;
    onDeleteAddressClick(addressId: string): void;
};

const AddressGrid: React.FC<Props> = (props: Props) => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);

    const translations = {
        name: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_NAME),
        address: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_ADDRESS),
        defaultBillingAddress: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_DEFAULTBILLINGADDRESS),
        defaultShippingAddress: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_DEFAULTSHIPPINGADDRESS),
        actions: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_ACTIONS),
        noAddressMessage: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_NOADDRESSESMESSAGE),
    };

    if (props.addressBook.length === 0) {
        return (
            <h3>{translations.noAddressMessage}</h3>
        );
    }

    return (
        <table>
            <thead>
                <tr>
                    <th>{translations.name}</th>
                    <th>{translations.address}</th>
                    <th>{translations.defaultBillingAddress}</th>
                    <th>{translations.defaultShippingAddress}</th>
                    <th>{translations.actions}</th>
                </tr>
            </thead>
            <tbody>
                {props.addressBook.map((address: IAddress) =>
                    <AddressRow
                        key={address.id}
                        address={address}
                        onDefaultAddressCheckboxClick={props.onAddressChange}
                        onEditAddressClick={props.onEditAddressClick}
                        onDeleteAddressClick={props.onDeleteAddressClick}
                    />)
                }
            </tbody>
        </table>
    );
};

export default AddressGrid;