import * as React from 'react';
import TranslationsContext from './translations-context';
import { Translations, TranslationsResult } from '../../http/modules/translations/translations.models';
import CheckUtils from '../../../utilities/check-utils';
import ITranslationsContext from './translations-context.interface';
import { GetHttpClientInstance } from '../http/http-context-provider';
import Spinner from '../../../components/common/spinner';

type ProviderState = {
  translations: Translations | null
};

export default class TranslationsProvider extends React.Component<{}, ProviderState> {

  constructor(props: any) {
    super(props);

    this.state = {
      translations: null
    };

    GetHttpClientInstance().translations.get().then((result: TranslationsResult) => {
      if (CheckUtils.isNullObject(result) || CheckUtils.isNullObject(result.translations)) {
        console.error('Translations are not loaded');
      } else {
        this.setState({
          translations: result.translations
        });
      }
    });
  }

  render() {
    if (this.state.translations === null) {
      return <Spinner show={true} default />
    }

    const contextValue: ITranslationsContext = {
      translations: this.state.translations
    };

    return (
      <TranslationsContext.Provider
        value={contextValue}
      >
        {this.props.children}
      </TranslationsContext.Provider>
    );
  }
}