import React, { ReactNode, useContext } from 'react';
import { Route, Switch, BrowserRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import PageHeader from './components/page-header/page-header';
import Footer from './components/footer/footer';
import TranslationsProvider from './infrastructure/context/translations/translations-context-provider';
import LandingPage from './components/landing-page';
import ProductListPage from './components/product-list-page';
import SearchResultPage from './components/search-result-page';
import CartPage from './components/cart-page';
import PageheaderThemeContextProvider from './infrastructure/context/pageheader-theme/pageheader-theme.context.provider';
import { RouterPaths } from './infrastructure/const';
import UserContextProvider from './infrastructure/context/user/user.context.provider';
import ProductDetailsPage from './components/product-details-page';
import ScrollTop from './components/scroll-top';
import CheckoutPage from './components/checkout-page';
import LoginPage from './components/login-page';
import AccountDashboardPage from './components/account-dashboard-page';
import AccountAddressBookPage from './components/account-address-book-page';
import AccountOrderHistoryPage from './components/account-order-history-page';
import CreateAccountPage from './components/create-account-page';
import ForgotPasswordPage from './components/forgot-password-page';
import NotFoundPage from './components/not-found-page';
import OrderPrintPage from './components/order-print-page';
import ConfirmationPage from './components/order-confirmation-page';
import FooterContextProvider from './infrastructure/context/footer/footer.context.provider';
import EditContactInformationPage from './components/account-edit-contact-information-page';
import EditMarketingMaterialsPage from './components/account-edit-marketing-materials-page';
import AccountAddressBookAddEditPage from './components/account-address-book-add-edit-page';
import AccountOrderHistoryDetailsPage from './components/account-order-history-details-page';
import CustomerServicePage from './components/customer-service-page';
import HttpProvider from './infrastructure/context/http/http-context-provider';
import ChangePasswordPage from './components/change-password-page';
import OrderFailedPage from './components/order-failed-page';
import LogoutSuccessPage from './components/logout-success-page';
import NavigationContext from './infrastructure/context/navigation/navigation.context';
import NavigationProvider from './infrastructure/context/navigation/navigation.context.provider';
import INavigationContext from './infrastructure/context/navigation/navigation.context.interface';
import ConfigurationProvider from './infrastructure/context/configuration/configuration.context.provider';
import StaticContentProvider from './infrastructure/context/static-content/static-content.context.provider';
import CookieBannerExternal from './external-scripts/cookie-banner-external';
import ErrorPage from './components/error-page';
import PaymentsPage from './components/payments-page';
import IConfigurationContext from './infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from './infrastructure/context/configuration/configuration.context';
import CheckUtils from './utilities/check-utils';
import RecommendedProductListPage from './components/recommended-product-list-page';
import SEOMetatags from './components/metatags/seo-metatags';
import EditCustomerPreferencePage from './components/account-edit-customer-preferences-page';
import RobotsMetatags from './components/metatags/robots-metatags';
import LanguageMetatags from './components/metatags/language-metatags';
import AccountFavouritesPage from './components/common/account-favourites-page';
import NewsletterPage from './components/newsletter-page';

const renderComponent = (props: RouteComponentProps, component: ReactNode): ReactNode => {
  // If trying to access page under /account, verify if user is authenticated
  return component;
};

const App: React.FC = () => {
  const mainContainerRef = React.createRef<HTMLDivElement>();

  return (
    <BrowserRouter> {/* Has to be on top because HttpProvider is using Link component */}
      <HttpProvider mainContainerRef={mainContainerRef}>
        
        <RobotsMetatags />
        
        <TranslationsProvider>
          <ConfigurationProvider>
            <LanguageMetatags />
            <CookieBannerExternal />
            <CookiesProvider>
              <UserContextProvider>
                <NavigationProvider>
                  <StaticContentProvider>
                    <SEOMetatags />
                    <PageContainer ref={mainContainerRef} />
                  </StaticContentProvider>
                </NavigationProvider>
              </UserContextProvider>
            </CookiesProvider>
          </ConfigurationProvider>
        </TranslationsProvider>
      </HttpProvider>
    </BrowserRouter>
  );
};

const PageContainer = React.forwardRef<HTMLDivElement, {}>((props, ref): JSX.Element => {
  const context = useContext<IConfigurationContext>(ConfigurationContext);

  // If there is any brand styling - add additional class to document body
  if (!CheckUtils.isNullOrEmptyString(context.brand)) {
    document.body.classList.add(context.brand!.toLowerCase() + '-main');
  } else {
    document.body.classList.add('oticon-main');
  }

  return (
    <>
      <PageheaderThemeContextProvider>
        <PageHeader />
      </PageheaderThemeContextProvider>
      <div className='main-container' ref={ref}>
        <ScrollTop>
          <RouterInner />
        </ScrollTop>
      </div>
      <FooterContextProvider>
        <Footer />
      </FooterContextProvider>
    </>
  );

});

const RouterInner = (): JSX.Element => {
  const context = useContext<INavigationContext>(NavigationContext);
  let categoriesUrls: string[] = [];
  context.categories.forEach((category) => {
    categoriesUrls.push(category.url);
    if (category.subcategories !== null) {
      category.subcategories.forEach((subcategory) => {
        categoriesUrls.push(category.url + subcategory.url);
      });
    }
  });
  return (
    <Switch>
      <Route path="*.html" component={(props: RouteComponentProps) => LegacyURLsHandler(props)} />
      <Route exact path={RouterPaths.LandingPage} render={(props: RouteComponentProps) => renderComponent(props, <LandingPage />)} />
      <Route exact path={RouterPaths.SearchResultsPage + "/:searchQuery"} component={SearchResultPage} />
      <Route exact path={RouterPaths.RecommendedProductsPage} component={RecommendedProductListPage} />
      <Route exact path={RouterPaths.CartPage} component={CartPage}></Route>
      <Route exact path={"/:categoryName" + RouterPaths.DetailsPage + "/:itemNumber"} component={ProductDetailsPage}></Route>
      <Route exact path={RouterPaths.CheckoutPage} component={CheckoutPage}></Route>
      <Route exact path={RouterPaths.PaymentsPage + "/:id/:correlationId?"} component={PaymentsPage}></Route>
      <Route exact path={RouterPaths.Login} component={LoginPage}></Route>
      <Route exact path={RouterPaths.Logout} component={LogoutSuccessPage}></Route>
      <Route exact path={RouterPaths.CreateAccount} component={CreateAccountPage}></Route>
      <Route exact path={RouterPaths.ForgotPassword} component={ForgotPasswordPage}></Route>
      <Route exact path={RouterPaths.ChangePassword + "/:userId/:sessionId"} component={ChangePasswordPage}></Route>
      <Route exact path={RouterPaths.AccountDashboard} component={AccountDashboardPage}></Route>
      <Route exact path={RouterPaths.AccountAddressBook} component={AccountAddressBookPage}></Route>
      <Route exact path={RouterPaths.AccountOrderHistory} component={AccountOrderHistoryPage}></Route>
      <Route exact path={RouterPaths.AccountFavourites} component={AccountFavouritesPage}></Route>
      <Route exact path={RouterPaths.AccountOrderHistoryDetails + "/:orderNumber"} component={AccountOrderHistoryDetailsPage}></Route>
      <Route exact path={RouterPaths.OrderPrint + "/:orderNumber"} component={OrderPrintPage} target="_blank"></Route>
      <Route exact path={RouterPaths.OrderConfirmation + "/:transactionId/:correlationId?"} component={ConfirmationPage}></Route>
      <Route exact path={RouterPaths.OrderFailed + "/:transactionId"} component={OrderFailedPage}></Route>
      <Route exact path={RouterPaths.AccountEditContactInformation} component={EditContactInformationPage}></Route>
      <Route exact path={RouterPaths.AccountEditMarketingMaterials} component={EditMarketingMaterialsPage}></Route>
      <Route exact path={RouterPaths.AccountEditCustomerPreferences} component={EditCustomerPreferencePage}></Route>
      <Route exact path={RouterPaths.AccountAddressBookAddEdit} component={AccountAddressBookAddEditPage}></Route>
      <Route exact path={RouterPaths.CustomerService + "/:url"} component={CustomerServicePage}></Route>
      <Route exact path={RouterPaths.Information + "/:url"} component={CustomerServicePage}></Route>
      <Route exact path={RouterPaths.Newsletter} component={NewsletterPage}></Route>
      <Route exact path={RouterPaths.Newsletter_SE} component={NewsletterPage}></Route>
      <Route exact path={RouterPaths.Error} component={ErrorPage}></Route>
      {categoriesUrls.map((url: string) => (
        <Route exact path={url} render={() => <ProductListPage routeQuery={url.replace("/", "")} />} key={url} />
      ))}
      {categoriesUrls.map((url: string) => {
        const magentoURL = url.replace(/_/g, "-");
        return <Redirect from={magentoURL} to={url} key={magentoURL} />
      })}
      <Route path={RouterPaths.NotFound} component={NotFoundPage} status={404} />
      <Route exact path={"/:productUrlKey"} component={ProductDetailsPage}></Route>
      <Redirect from='*' to={RouterPaths.NotFound} />
    </Switch>
  );
};

const LegacyURLsHandler = (props: RouteComponentProps) => {
  const to = props.match.url.replace(".html", "")
  return <Redirect to={to} />
}

export default App;
