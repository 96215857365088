import React, { useContext } from 'react';
import IUserContext, { IUserProfile } from '../../../infrastructure/context/user/user.context.interface';
import { CustomerPreferenceKeys, TranslationKeys } from '../../../infrastructure/const';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import UserContext from '../../../infrastructure/context/user/user.context';
import CheckUtils from '../../../utilities/check-utils';

type Props = {
    userProfile: IUserProfile;
    onEditClick: (() => void);
};

const CustomerPreferences: React.FC<Props> = (props: Props) => {
    const context = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(context);

    const { userProfile } = useContext<IUserContext>(UserContext).user;

    const translations = {
        header: translationManager.getTranslation(TranslationKeys.ACCOUNT_CUSTOMERPREFERENCES),
        edit: translationManager.getTranslation(TranslationKeys.ACCOUNT_EDIT),
        selectedSize: translationManager.getTranslation(TranslationKeys.ACCOUNT_CUSTOMERPREFERENCES_SELECTEDBATTERYSIZE),
    };

    function getCustomPreference(key: string) {
        if(CheckUtils.isNullObject(userProfile.customerPreference)){
            return "";
        }

        const entry = Object.entries(userProfile.customerPreference!).find(([k,v]) => k === key);

        if(entry){
            return entry[1];
        }

        return "";
    }

    return (
        <div className='col-2 box-account__box'>
            <div className='box-content'>
                <h2>
                    {translations.header}
                </h2>
                <p>
                    {translations.selectedSize} {getCustomPreference(CustomerPreferenceKeys.SE.BatterySize)}
                </p>
                <button type='button' title={translations.edit} className='button' onClick={() => props.onEditClick()}>{translations.edit}</button>
            </div>
        </div>
    );
};

export default CustomerPreferences;
