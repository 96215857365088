export const KeyCodes = {
  Return: 13,
};

export const EventTypes = {
  Keypress: "keypress",
  Click: "click",
};

export const Environment = {
  Develop: "dev",
  Test: "uat",
  Production: "prod",
};

export const GoogleTagManagerIds = {
  default: "GTM-KX4STMC",
  horselbutik: "GTM-MTZHPRH",
};

export const Format = {
  Date: "dd-MM-yyyy",
};

export enum ProductsSelect {
  Relevance = 0,
  Name = 1,
  Price = 2,
}

export const PageHeaderThemes = {
  Dark: "dark",
  Light: "light",
};

export const ErrorCodes = {
  Unauthorized: 401,
};

export const PasswordMinLength = {
  Login: 6,
  Register: 8,
};

export const RouterPaths = {
  LandingPage: "/",
  SearchResultsPage: "/search-result",
  RecommendedProductsPage: "/recommended-products",
  CartPage: "/cart",
  DetailsPage: "/details",
  CheckoutPage: "/checkout",
  PaymentsPage: "/payments",
  Login: "/account/login",
  Logout: "/account/logoutSuccess",
  CreateAccount: "/account/create",
  ForgotPassword: "/account/forgotpassword",
  ChangePassword: "/account/changepassword",
  AccountDashboard: "/account/dashboard",
  AccountEditContactInformation: "/account/edit",
  AccountEditMarketingMaterials: "/newsletter/manage",
  AccountEditCustomerPreferences: "/account/preferences",
  AccountAddressBook: "/account/addressbook",
  AccountAddressBookAddEdit: "/account/addressbook/addedit",
  AccountOrderHistory: "/account/order-history",
  AccountOrderHistoryDetails: "/account/order-history/order-details",
  AccountFavourites: "/account/favourites",
  OrderPrint: "/order-print",
  OrderConfirmation: "/order-confirmation",
  OrderFailed: "/order-failed",
  CustomerService: "/customer-service",
  Information: "/information",
  CustomerServiceDeliveryReturn: "/customer-service/delivery-return",
  CustomerServiceTermsAndConditions: "/customer-service/terms-and-conditions",
  CustomerServiceVatExempt: "/customer-service/vat-exempt",
  CustomerServiceConnectLineApp: "/customer-service/connectline-app",
  CustomerServiceWarranties: "/customer-service/warranties",
  CustomerServiceConnectLineSupport: "/customer-service/connectline-support",
  CustomerServiceAboutOticon: "/customer-service/oticon-history",
  CustomerServiceCookiePolicy:
    "/customer-service/privacy-policy-cookie-restriction-mode",
  CustomerServiceContactUs: "/customer-service/contact-us",
  Newsletter: "/subscribe",
  Newsletter_SE: "/nyhetsbrev",
  NotFound: "/404",
  Error: "/error",

  UK_OticonConnectline_InstructionalVideos:
    "http://www.oticon.co.uk/solutions/accessories/connectline/",
  UK_MyOticonNHS:
    "http://www.nhs.myoticon.co.uk/hearing-care-professionals/nhs1.aspx",
};

export const AccountRoutersPaths = [
  RouterPaths.AccountDashboard,
  RouterPaths.AccountAddressBook,
  RouterPaths.AccountOrderHistory,
  RouterPaths.AccountOrderHistoryDetails,
  RouterPaths.AccountEditContactInformation,
  RouterPaths.AccountEditMarketingMaterials,
  RouterPaths.AccountAddressBookAddEdit,
];

export const MaxMiniCartProductsNumber = 3;

export const MainPartialId = 240834;
export const MainProductItemNumber = "151238";

export const TranslationKeys = {
  PRODUCT: "Product",
  AMOUNT: "Amount",
  TOTAL: "Total",
  REMOVE: "Remove",
  READMORE: "ReadMore",
  APPLY: "Apply",
  CANCEL: "Cancel",
  MENU: "Menu",
  CONTINUE: "Continue",
  SHIPPINGADDRESS: "ShippingAddress",
  INVOICEADDRESS: "InvoiceAddress",
  SHIPPINGMETHOD: "ShippingMethod",
  PAYMENTMETHOD: "PaymentMethod",
  ACCEPTEDPAYMENTMETHODS: "AcceptedPaymentMethods",
  PRICE: "Price",
  QUANTITY: "Quantity",
  SUBTOTAL: "Subtotal",
  NEWADDRESS: "NewAddress",
  HOME: "Home",
  CLICKHERE: "ClickHere",
  TO_CONTINUE_SHOPPING: "ToContinueShopping",
  BUYNOW: "BuyNow",
  ADDTOCART: "AddToCart",
  ADDEDTOCART: "AddedToCart",
  PAGE_NOT_FOUND: "PageNotFound",
  BULK_PURCHASE_DISCLAIMER: "BulkPurchaseDisclaimer",
  MENUTILES_HEADER: "MenuTiles_Header",
  SEEALL: "SeeAll",
  PAGINATION_NEXT: "Pagination_Next",
  PAGINATION_PREVIOUS: "Pagination_Previous",
  SELECTOPTION: "SelectOption",

  PRODUCT_NAME: "Product_Name",
  PRODUCT_COLOR: "Product_Color",
  PRODUCT_OPTION: "Product_Option",
  PRODUCT_NUMBER: "Product_Number",

  LANDINGPAGE_WELCOMEMESSAGE: "LandingPage_WelcomeMessage",

  // USER INFO
  FIRST_NAME: "FirstName",
  LAST_NAME: "LastName",
  MIDDLE_NAME: "MiddleName",
  EMAIL: "Email",
  COMPANY: "Company",
  ADDRESS: "Address",
  POSTALCODE: "PostalCode",
  CITY: "City",
  PHONE: "PhoneNumber",
  COUNTRY: "Country",

  // SEARCH
  SEARCH_SEARCHRESULTS: "Search_SearchResults",
  SEARCH_NORESULTS: "Search_NoResults",
  SEARCH_SHOWINGPRODUCTSAMOUNT: "Search_ShowingProductsAmount",
  SEARCH_TOTALRESULTS: "Search_TotalResults",
  SEARCH_SORTBYPLACEHOLDER: "Search_SortByPlaceholder",
  SEARCH_SORTBYRELEVANCE: "Search_SortByRelevance",
  SEARCH_SORTBYNAME: "Search_SortByName",
  SEARCH_SORTBYPRICE: "Search_SortByPrice",

  // CART
  CART_HEADER: "Cart_Header",
  CART_CONTINUESHOPPING: "Cart_ContinueShopping",
  CART_ORDERSUMMARY_HEADER: "Cart_OrderSummary_Header",
  CART_GOTOCHECKOUT: "Cart_GoToCheckout",
  CART_SHIPPING: "Cart_Shipping",
  CART_ITEMS: "Cart_Item(s)",
  CART_ITEMSINCART: "Cart_Item(s)InCart",
  CART_ADDEDTOCART: "AddedToCart",
  CART_ADDTOCART: "AddToCart",
  CART_EMPTY: "Cart_ShoppingCartEmpty",
  CART_NOITEMSINCART: "Cart_NoItemsInCart",
  CART_TOTALDISCOUNT: "Cart_TotalDiscount",

  CART_PROMOCODE_LABEL: "Cart_PromoCode_Label",
  CART_PROMOCODE_PLACEHOLDER: "Cart_PromoCode_Placeholder",
  CART_PROMOCODE_APPLIED: "Cart_PromoCode_Applied",
  CART_PROMOCODE_CANCELED: "Cart_PromoCode_Canceled",

  CART_RECOMMENDEDPRODUCTS_HEADER: "Cart_RecommendedProducts_Header",

  // MINI CART
  MINICART_GOTOCART_BUTTON: "MiniCart_GoToCart_Button",
  MINICART_REMOVEPRODUCT_CONFIRMATION: "MiniCart_RemoveProduct_Confirmation",

  // CHECKOUT
  CHECKOUT_DELIVERYINFORMATION_TITLE: "Checkout_DeliveryInformation_Title",
  CHECKOUT_SHIPPING_HEADER: "Checkout_Shipping_Header",
  CHECKOUT_HEADER: "Checkout_Header",
  CHECKOUT_BACKTOCART_BUTTON: "Checkout_BackToCart_Button",
  CHECKOUT_PAYMENT_HEADER: "Checkout_Payment_Header",
  CHECKOUT_PAYMENT_WARNING: "Checkout_Payment_Warning",
  CHECKOUT_SHIP_TO_THIS_ADDRESS_CHECKBOX: "Checkout_ShipToThisAddress_Checkbox",
  CHECKOUT_SHIP_TO_DIFFERENT_ADDRESS_CHECKBOX:
    "Checkout_ShipToDifferentAddress_Checkbox",
  CHECKOUT_SAVE_ADDRESS: "Checkout_SaveAddress",
  CHECKOUT_SELECT_ADDRESS: "Checkout_SelectAddress",
  CHECKOUT_TERMSANDCONDITIONS_CHECKBOX: "Checkout_TermsAndConditionsCheckbox",
  CHECKOUT_CONSENTCHECKBOX_ACCEPTPRIVACYPOLICY:
    "Checkout_ConsentCheckbox_AcceptPrivacyPolicy",

  // PAYMENTS
  PAYMENT_ERRORMESSAGE: "Payment_ErrorMessage",
  PAYMENT_REFUSEDMESSAGE: "Payment_RefusedMessage",
  PAYMENT_CANCELEDMESSAGE: "Payment_CanceledMessage",
  PAYMENT_TRYAGAIN: "Payment_TryAgain",

  // PRINT
  PRINT_ORDERDATE: "Print_OrderDate",
  PRINT_ORDER_HEADER: "Print_Order_Header",
  PRINT_ITEMSORDERED_HEADER: "Print_ItemsOrdered_Header",
  PRINT_PHONENUMBER: "Print_PhoneNumber",
  PRINT_EMAIL: "Print_Email",
  PRINT_ITEMNUMBER: "Print_ItemNumber",
  PRINT_TOTALPRICE: "Print_TotalPrice",
  PRINT_SHIPPING: "Print_Shipping",
  PRINT_VAT: "Print_Vat",
  PRINT_CLOSEWINDOW: "Print_CloseWindow",

  // HEADER
  HEADER_SEARCH_LABEL: "Header_Search_Label",
  HEADER_LOGIN_BUTTON: "Header_LogIn_Button",
  HEADER_CUSTOMERSERVICE_LABEL: "Header_CustomerService_Label",

  // LOGIN
  LOGIN_HEADER: "Login_Header",

  LOGIN_REGISTERED_CUSTOMERS_HEADER: "Login_RegisteredCustomers_Header",
  LOGIN_REGISTERED_CUSTOMERS_DESCRIPTION:
    "Login_RegisteredCustomers_Description",
  LOGIN_FORM_EMAIL: "Login_Form_Email",
  LOGIN_FORM_PASSWORD: "Login_Form_Password",
  LOGIN_FORM_FORGOT_PASSWORD_LINK: "Login_Form_ForgotPassword_Link",
  LOGIN_FORM_BUTTON: "Login_Form_Button",
  LOGIN_FORM_ERROR_LOGIN_OR_PASSWORD_BLANK:
    "Login_Form_Error_LoginOrPasswordBlank",
  LOGIN_FORM_ERROR_INVALID_LOGIN_OR_PASSWORD:
    "Login_Form_Error_InvalidLoginOrPassword",
  LOGIN_FORM_I_HAVE_ACCOUNT: "Login_Form_I_Have_Account",
  LOGIN_FORM_DOYOUHAVEACCOUNT: "Login_Form_DoYouHaveAccount",
  LOGIN_FORM_CLICKTOLOGIN_LINK: "Login_Form_ClickToLogin_Link",
  LOGIN_FORM_NEED_ACCOUNT_FOR_FAVOURITES:
    "Login_Form_Need_Account_For_Favourites",

  LOGIN_NEW_CUSTOMERS_HEADER: "Login_NewCustomers_Header",
  LOGIN_NEW_CUSTOMERS_DESCRIPTION: "Login_NewCustomers_Description",
  LOGIN_NEW_CUSTOMERS_BUTTON: "Login_NewCustomers_Button",

  CREATE_ACCOUNT_HEADER: "CreateAccount_Header",
  CREATE_ACCOUNT_SUBHEADER_YOUR_NAME: "CreateAccount_SubHeader_YourName",
  CREATE_ACCOUNT_SUBHEADER_ACCOUNT_INFO: "CreateAccount_SubHeader_AccountInfo",
  CREATE_ACCOUNT_PASSWORD: "CreateAccount_Password",
  CREATE_ACCOUNT_CONFIRM_PASSWORD: "CreateAccount_ConfirmPassword",
  CREATE_ACCOUNT_SIGNUPFORMARKETINGMATERIALS:
    "CreateAccount_SignUpForMarketingMaterials",
  CREATE_ACCOUNT_SUBMIT: "CreateAccount_Submit",
  CREATE_ACCOUNT_ERROR_EMAILINUSE: "CreateAccount_Error_EmailInUse",
  CREATE_ACCOUNT_CONSENTCHECKBOX_ACCEPTPRIVACYPOLICY:
    "CreateAccount_ConsentCheckbox_AcceptPrivacyPolicy",

  FORGOT_PASSWORD_HEADER: "ForgotPassword_Header",
  FORGOT_PASSWORD_DESCRIPTION: "ForgotPassword_Description",
  FORGOT_PASSWORD_SUBMIT: "ForgotPassword_Submit",

  ACCOUNT_MENU_DASHBOARD: "Account_Menu_Dashboard",
  ACCOUNT_MENU_ADDRESS_BOOK: "Account_Menu_AddressBook",
  ACCOUNT_MENU_MY_ORDERS: "Account_Menu_MyOrders",
  ACCOUNT_MENU_MY_FAVOURITES: "Account_Menu_MyFavourites",
  ACCOUNT_MENU_LOGOUT: "Account_Menu_Logout",

  ACCOUNT_FAVOURITES_NOPRODUCTS: "Account_Favourites_NoProducts",

  ACCOUNT_DASHBOARD_HEADER: "AccountDashboard_Header",
  ACCOUNT_DASHBOARD_DESCRIPTION: "AccountDashboard_Description",
  ACCOUNT_DASHBOARD_ACCOUNTINFORMATION: "AccountDashboard_AccountInformation",
  ACCOUNT_DASHBOARD_EDITCONTACTINFORMATIONSUCCESSMESSAGE:
    "AccountDashboard_EditContactInformationSuccessMessage",
  ACCOUNT_DASHBOARD_EDITMARKETINGMATERIALSUCCESSMESSAGE:
    "AccountDashboard_EditMarketingMaterialSuccessMessage",
  ACCOUNT_DASHBOARD_WELCOMEMESSAGE: "AccountDashboard_WelcomeMessage",

  ACCOUNT_SAVE: "Account_Save",
  ACCOUNT_EDIT: "Account_Edit",
  ACCOUNT_DELETE: "Account_Delete",

  ACCOUNT_MARKETINGMATERIAL_HEADER: "Account_MarketingMaterial_Header",
  ACCOUNT_MARKETINGMATERIAL_IWANTTORECEIVE:
    "Account_MarketingMaterial_IWantToReceive",
  ACCOUNT_MARKETINGMATERIAL_YOUARESUBSCRIBED:
    "Account_MarketingMaterial_YourAreSubscribed",
  ACCOUNT_MARKETINGMATERIAL_YOUARENOTSUBSCRIBED:
    "Account_MarketingMaterial_YourAreNotSubscribed",

  ACCOUNT_CONTACTINFORMATION: "AccountDashboard_ContactInformation",
  ACCOUNT_CUSTOMERPREFERENCES: "AccountDashboard_CustomerPreferences",
  ACCOUNT_CUSTOMERPREFERENCES_SELECTEDBATTERYSIZE:
    "AccountDashboard_CustomerPreferences_SelectedBatterySize",
  ACCOUNT_CUSTOMERPREFERENCES_SELECT_BATTERYSIZE:
    "AccountDashboard_CustomerPreferences_SelectBatterySize",

  // ORDER CONFIRMATION
  ORDERCONFIRMATION_ORDERRECEIVED: "OrderConfirmation_OrderReceived",
  ORDERCONFIRMATION_ORDERNUMBER: "OrderConfirmation_OrderNumber",
  ORDERCONFIRMATION_CONFIRMATIONEMAILWILLBESENT:
    "OrderConfirmation_ConfirmationEmailWillBeSent",
  ORDERCONFIRMATION_CLICKTOPRINT: "OrderConfirmation_ClickToPrint",
  ORDERCONFIRMATION_COPYOFCONFIRMATION: "OrderConfirmation_CopyOfConfirmation",
  PAYMENTCONFIRMATION_PAYMENTID: "PaymentConfirmation_PaymentId",
  PAYMENT_FAILEDHEADER: "Payment_FailedHeader",
  PAYMENT_FAILEDDESCRIPTION: "Payment_FailedDescription",
  PAYMENT_FAILED_PAYMENTID: "Payment_Failed_PaymentId",
  PAYMENT_CONTACTCUSTOMERSERVICE: "Payment_ContactCustomerService",

  // ORDER FAILED
  ORDERFAILED_ERROROCCURRED: "OrderFailed_ErrorOccurred",
  ORDERFAILED_ORDERNUMBER: "OrderFailed_OrderNumber",
  ORDERFAILED_TRYAGAIN: "OrderFailed_TryAgain",

  // ERROR PAGE
  ERRORPAGE_TRYAGAIN: "ErrorPage_TryAgain",
  ERRORPAGE_CUSTOMERSERVICE: "ErrorPage_CustomerService",
  ERRORPAGE_SOMETHINGWENTWRONG: "ErrorPage_SomethingWentWrong",
  ERRORPAGE_PLEASECONTACT: "ErrorPage_PleaseContact",
  ERRORPAGE_GOBACKTOSHOP: "ErrorPage_GoBackToShop",

  ACCOUNT_ACCOUNTINFORMATION_HEADER:
    "AccountDashboard_AccountInformation_Header",
  ACCOUNT_ACCOUNTINFORMATION_CHANGEPASSWORD:
    "AccountDashboard_AccountInformation_ChangePassword",
  ACCOUNT_ACCOUNTINFORMATION_CURRENTPASSWORD:
    "AccountDashboard_AccountInformation_CurrentPassword",
  ACCOUNT_ACCOUNTINFORMATION_NEWPASSWORD:
    "AccountDashboard_AccountInformation_NewPassword",
  ACCOUNT_ACCOUNTINFORMATION_CONFIRMNEWPASSWORD:
    "AccountDashboard_AccountInformation_ConfirmNewPassword",

  ACCOUNT_ADDRESSBOOK_HEADER: "Account_AddressBook_Header",
  ACCOUNT_ADDRESSBOOK_ADDNEWADDRESS: "Account_AddressBook_AddNewAddress",
  ACCOUNT_ADDRESSBOOK_ADDADDRESS: "Account_AddressBook_AddAddress",
  ACCOUNT_ADDRESSBOOK_EDITADDRESS: "Account_AddressBook_EditAddress",
  ACCOUNT_ADDRESSBOOK_TELEPHONE: "Account_AddressBook_Telephone",
  ACCOUNT_ADDRESSBOOK_FAX: "Account_AddressBook_Fax",
  ACCOUNT_ADDRESSBOOK_STREETADDRESS: "Account_AddressBook_StreetAddress",
  ACCOUNT_ADDRESSBOOK_STREETADDRESS2: "Account_AddressBook_StreetAddress2",
  ACCOUNT_ADDRESSBOOK_DELETECONFIRMATION:
    "Account_AddressBook_DeleteConfirmation",

  ACCOUNT_ADDRESSBOOK_NAME: "Account_AddressBook_Name",
  ACCOUNT_ADDRESSBOOK_ADDRESS: "Account_AddressBook_Address",
  ACCOUNT_ADDRESSBOOK_DEFAULTBILLINGADDRESS:
    "Account_AddressBook_DefaultBillingAddress",
  ACCOUNT_ADDRESSBOOK_DEFAULTSHIPPINGADDRESS:
    "Account_AddressBook_DefaultShippingAddress",
  ACCOUNT_ADDRESSBOOK_ACTIONS: "Account_AddressBook_Actions",
  ACCOUNT_ADDRESSBOOK_NOADDRESSESMESSAGE:
    "Account_AddressBook_NoAddressesMessage",
  ACCOUNT_ADDRESSBOOK_YOUHAVENODEFAULTBILLINGADDRESS:
    "Account_AddressBook_YouHaveNoDefaultBillingAddress",
  ACCOUNT_ADDRESSBOOK_YOUHAVENODEFAULTSHIPPINGADDRESS:
    "Account_AddressBook_YouHaveNoDefaultShippingAddress",

  ACCOUNT_LOGOUT_HEADER: "Account_Logout_Header",
  ACCOUNT_LOGOUT_DESCRIPTION: "Account_Logout_Description",

  ACCOUNT_ORDERHISTORY_YOUHAVENOPLACEDORDERS:
    "Account_OrderHistory_YouHaveNoPlacedOrders",
  ACCOUNT_ORDERHISTORY_ORDERNUMBER: "Account_OrderHistory_OrderNumber",
  ACCOUNT_ORDERHISTORY_ORDERDATE: "Account_OrderHistory_OrderDate",
  ACCOUNT_ORDERHISTORY_SHIPTO: "Account_OrderHistory_ShipTo",
  ACCOUNT_ORDERHISTORY_ORDERTOTAL: "Account_OrderHistory_OrderTotal",
  ACCOUNT_ORDERHISTORY_STATUS: "Account_OrderHistory_Status",
  ACCOUNT_ORDERHISTORY_VIEWORDER: "Account_OrderHistory_ViewOrder",
  ACCOUNT_ORDERHISTORY_REORDER: "Account_OrderHistory_Reorder",
  ACCOUNT_ORDERHISTORY_QUANTITYORDERED: "Account_OrderHistory_QuantityOrdered",

  ACCOUNT_ORDERHISTORY_PAGER_ITEMS: "Account_OrderHistory_Pager_Items",
  ACCOUNT_ORDERHISTORY_PAGER_SHOW: "Account_OrderHistory_Pager_Show",
  ACCOUNT_ORDERHISTORY_PAGER_PERPAGE: "Account_OrderHistory_Pager_PerPage",
  ACCOUNT_PRINTORDER: "Account_PrintOrder",

  DELIVERY_UPSSTANDARDOVERNIGHT: "Delivery_UPSStandardOvernight",
  PAYMENT_PENSIO: "Payment_PensioStandardGateway",

  CONTACTFORM_HEADER: "ContactForm_Header",
  CONTACTFORM_SUBHEADER: "ContactForm_SubHeader",
  CONTACTFORM_ADDITIONALTEXT: "ContactForm_AdditionalText",
  CONTACTFORM_MESSAGE: "ContactForm_Message",
  CONTACTFORM_NAME: "ContactForm_Name",
  CONTACTFORM_PHONE: "ContactForm_Phone",
  CONTACTFORM_EMAIL: "ContactForm_Email",
  CONTACTFORM_SEND: "ContactForm_Send",
  CONTACTFORM_SUCCESS: "ContactForm_Success",

  CHANGEFORGOTTENPASSWORD_HEADER: "ChangeForgottenPassword_Header",
  CHANGEFORGOTTENPASSWORD_SUBMIT: "ChangeForgottenPassword_Submit",

  RESETPASSWORD_YOUWILLRECEIVERESETLINK:
    "ResetPassword_YouWillReceiveResetLink",
  RESETPASSWORD_YOURPASSWORDHASBEENUPDATED:
    "ResetPassword_YourPasswordHasBeenUpdated",
  RESETPASSWORD_YOURPASSWORDRESETLINKEXPIRED:
    "ResetPassword_YourPasswordResetLinkExpired",

  STATUS_UNDERPROCESSING: "Status_UnderProcessing",
  STATUS_ORDERSHIPPED: "Status_OrderShipped",
  INVALID_CAPTCHA: "InvalidCaptcha",

  REQUIRED: "RequiredFieldHelperText",
  PLEASE_ENTER_VALID_EMAIL: "PleaseEnterValidEmail",
  PLEASE_ENTER_SIX_OR_MORE_CHARACTERS: "PleaseEnterSixOrMoreCharacters",
  PLEASE_MAKE_SURE_YOUR_PASSWORDS_MATCH: "PleaseMakeSureYourPasswordsMatch",

  COOKIE_BANNER_TEXT: "CookieBannerText",

  // PRODUCT DETAILS
  PRODUCTDETAILS_TABHEADER: "ProductDetails_TabHeader",
  PRODUCTDETAILS_INSTOCK: "ProductDetails_InStock",
  PRODUCTDETAILS_OUTOFSTOCK: "ProductDetails_OutOfStock",
  PRODUCTDETAILS_DELIVEREDWITHIN: "ProductDetails_DeliveredWithin",
  PRODUCTDETAILS_DELIVEREDWITHIN_DAYSNUMBER:
    "ProductDetails_DeliveredWithin_DaysNumber",
  PRODUCTDETAILS_DAYS: "ProductDetails_DaysShortcut",
  PRODUCTDETAILS_ASLOWAS: "ProductDetails_AsLowAs",
  PRODUCTDETAILS_ORDINARYPRICE: "ProductDetails_OrdinaryPrice",
  PRODUCTDETAILS_RELATED_PRODUCTS: "ProductDetails_RelatedProducts",
  PRODUCTDETAILS_ADD_TO_FAVOURITES: "ProductDetails_AddToFavourites",
  PRODUCTDETAILS_REMOVE_FROM_FAVOURITES: "ProductDetails_RemoveFromFavourites",

  // FOOTER
  FOOTER_REGULATIONS: "Footer_Regulations",
  FOOTER_TERMSANDSERVICE: "Footer_TermsAndService",
  FOOTER_COOKIEREGULATION: "Footer_CookieRegulation",
  NEWSLETTER_AGREEMENT: "Newsletter_Agreement",
  NEWSLETTER_SUBMIT: "Newsletter_Submit",
  NEWSLETTER_HEADER_TITLE: "Newsletter_HeaderTitle",

  NEWSLETTER_SUBSCRIBE_PAGE_HEADER: "Newsletter_SubscribePage_Header",
  NEWSLETTER_SUBSCRIBE_PAGE_HTML: "Newsletter_SubscribePage_Html",

  // LANDING PAGE
  PRODUCTSLIDER_HEADER_TITLE: "ProductSlider_HeaderTitle",

  START_SHOPPING_HERE: "StartShoppingHere",

  UK: {
    VAT: "UK_VAT",
    VAT_EXEMPT: "UK_VAT_Exempt",
    // HEADER
    HEADER_INSTRUCTIONALVIDEOS_LABEL: "UK_Header_InstructionalVideos_Label",
    // PRODUCTCATEGORY
    PRODUCTCATEGORY_CONNECTLINESTREAMER_HEADER:
      "UK_ProductCategory_ConnectlineStreamer_Header",
    PRODUCTCATEGORY_CONNECTLINESTREAMER_BANNERDESCRIPTION_HTML:
      "UK_ProductCategory_ConnectlineStreamer_BannerDescriptionHtml",
    //CART
    CART_VAT_LABEL: "UK_Cart_VAT_Label",
    CART_VAT_DESCRIPTION: "UK_Cart_VAT_Description",
    CART_REFERRALCODE_LABEL: "UK_Cart_ReferralCode_Label",
    CART_REFERRALCODE_PLACEHOLDER: "UK_Cart_ReferralCode_Placeholder",
    CART_REFERRALCODE_APPLIED: "UK_Cart_ReferralCode_Applied",
    CART_REFERRALCODE_CANCELED: "UK_Cart_ReferralCode_Canceled",
    CART_SUBTOTAL_INCLUDING_VAT: "UK_Cart_SubTotalIncludingVat",
    CART_SUBTOTAL_EXCLUDING_VAT: "UK_Cart_SubTotalExcludingVat",
  },

  DK: {
    EAN_NUMBER_LABEL: "DK_Cart_EANNumber_Label",
    EAN_NUMBER_PLACEHOLDER: "DK_Cart_EANNumber_Placeholder",
    EAN_NUMBER_APPLIED: "DK_Cart_EANNumber_Applied",
    EAN_NUMBER_CANCELED: "DK_Cart_EANNumber_Canceled",
  },

  SE: {
    INTROBANNER_HEADER_TITLE: "SE_Introbanner_HeaderTitle",
    INTROBANNER_HEADER_DESCRIPTION_LINE1:
      "SE_Introbanner_HeaderDescription_Line1",
    INTROBANNER_HEADER_DESCRIPTION_LINE2:
      "SE_Introbanner_HeaderDescription_Line2",
    BUY_BATTERIES: "SE_BuyBatteries",
    BUY_DOMES: "SE_BuyDomes",
    HEADER_BAR_MAIL_ORDER: "SE_HeaderBar_MailOrder",
    HEADER_BAR_FREE_SHIPPING: "SE_HeaderBar_FreeShipping",
    HEADER_BAR_FAST_DELIVERY: "SE_HeaderBar_FastDelivery",
    HEADER_BAR_PHONENUMBER: "SE_HeaderBar_PhoneNumber",
    HEADER_NOTIFICATION_BAR_TEXT: "SE_Header_NotificationBar_Text",
  },
};

export const TranslationsPrefix = {
  Checkout_PaymentTypes: "Checkout_PaymentType_",
  Checkout_ShippingTypes: "Checkout_ShippingType_",
  ProductDetails_Attribute_Name: "ProductDetails_AttributeName_",
  ProductDetails_Attribute_Value: "ProductDetails_AttributeValue_",
  Cart_CouponResponse: "Cart_CouponResponse_",
};

export const AttributeTranslationType = {
  Value: "Value",
  Name: "Name",
};

export const RouterStateParams = {
  ShowForgotPasswordSubmittedSuccessMessage: "forgotPassword",
  ShowEditedContactInformationSuccessMessage: "editContactInformation",
  ShowEditedMarketingMaterialsSuccessMessage: "editMarketingMaterials",
  EditAddressId: "editAddressId",
  ShowPasswordUpdatedSuccessMessage: "showPasswordUpdatedSuccessMessage",
  ShowResetLinkExpiredErrorMessage: "showResetLinkExpiredErrorMessage",
  ShowWelcomeMessage: "showWelcomeMessage",
  ShowFavouritesAuthenticateError: "showFavouritesAuthenticateError",
};

export const PaymentStatus = {
  Initialized: "Initialized",
  Succeeded: "Succeeded",
  Error: "Error",
  Refused: "Refused",
  Cancelled: "Cancelled",
  Pending: "Pending",
  Incomplete: "Incomplete",
  PartialSuccess: "PartialSuccess",
};

export const CustomPropertyKeys = {
  UK: {
    VatExempt: "applyVATExempt",
    ReferralCode: "referralCode",
  },
  DK: {
    EANNumber: "eanNumberApplied",
  },
  Global: {
    PromoCode: "promoCode",
  },
};

export const CustomPropertyTranslations = {
  eanNumberApplied: "DK_Cart_EANNumber_Label",
};

export type FeatureFlags = {
  landingPageType: string;
  topMenuType: string;
  orderSummaryType: string;
  breadcrumbType: string;
  showRecommendedProducts: boolean;
  hideItemDescriptionInCart: boolean;
  hideInStockInfo: boolean;
  hideDeliveryDateInfo: boolean;
  displayPriceIncludingVat: boolean;
  allowProductSchema: boolean;
  useEnrichedProductData: boolean;
  paymentProvider: string;
  showCustomerPreferences: boolean;
  hideCustomerMiddleName: boolean;
  allowPromoCode: boolean;
  allowFavourites: boolean;
  showPrivacyPolicyConsent: boolean;
  displayShippingDisclaimerInCart: boolean;
};

export const FeatureFlagValues = {
  LandingPage: {
    Tiles: "tiles",
    Splash: "splash",
    Intro: "intro",
  },
  TopMenu: {
    None: "none",
    Links: "links",
    Multilevel: "multilevel",
  },
  OrderSummary: {
    Global: "global",
    UK: "uk",
    DK: "dk",
  },
  BreadcrumbType: {
    Global: "global",
    Uk: "uk",
  },
  PayentProvider: {
    Adyen: "adyen",
    Klarna: "klarna",
    Nets: "nets",
  },
};

export const DefaultFeatureFlags: FeatureFlags = {
  landingPageType: FeatureFlagValues.LandingPage.Tiles,
  topMenuType: FeatureFlagValues.TopMenu.None,
  orderSummaryType: FeatureFlagValues.OrderSummary.Global,
  breadcrumbType: FeatureFlagValues.BreadcrumbType.Global,
  showRecommendedProducts: false,
  hideItemDescriptionInCart: false,
  hideInStockInfo: false,
  hideDeliveryDateInfo: false,
  displayPriceIncludingVat: false,
  allowProductSchema: false,
  useEnrichedProductData: false,
  paymentProvider: FeatureFlagValues.PayentProvider.Adyen,
  showCustomerPreferences: false,
  hideCustomerMiddleName: false,
  allowPromoCode: false,
  allowFavourites: false,
  showPrivacyPolicyConsent: false,
  displayShippingDisclaimerInCart: false,
};

export const Brand = {
  Oticon: "oticon",
  Phonicear: "phonicear",
  Bernafon: "bernafon",
  Horselbutik: "horselbutik",
};

export const BrandName = {
  oticon: "Oticon",
  phonicear: "Phonic Ear",
  bernafon: "Bernafon",
  horselbutik: "Hörselbutik",
};

export const MarketingDescriptionMetatags = {
  "[p]": "<p>",
  "[/p]": "</p>",
  "[b]": "<strong>",
  "[/b]": "</strong>",
  "[u]": "<u>",
  "[/u]": "</u>",
  "[bl]": "<ul>",
  "[/bl]": "</ul>",
  "[bp]": "<li>",
  "[/bp]": "</li>",
};

export const MarketingDescriptionParagraphMetatag = {
  start: "[p]",
  end: "[/p]",
};

export const CustomerPreferenceKeys = {
  SE: {
    BatterySize: "batterySize",
  },
};

export const SE_BatterySizes = {
  10: "10",
  13: "13",
  312: "312",
  675: "675",
  RECHARGEABLE: "Laddningbara batterier",
};

export const CouponResponseCode = {
  Success: "Success",
  NeedToLogIn: "NeedToLogIn",
  ExpiredOrInvalid: "ExpiredOrInvalid",
  AlreadyUsed: "AlreadyUsed",
  NotApplied: "NotApplied",
};

export const MandatoryConsentCheckboxes = {
  TermsAndConditions: "termsAndConditions",
  PrivacyPolicy: "privacyPolicy",
};
