import React, { ReactNode, SyntheticEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { RouterPaths, RouterStateParams, TranslationKeys } from '../infrastructure/const';
import AccountMenu from './account/menu/account-menu';
import AddressGrid from './account/address-book/address-grid';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import IUserContext, { IAddress } from '../infrastructure/context/user/user.context.interface';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import UserContext from '../infrastructure/context/user/user.context';
import TranslationManager from '../infrastructure/translation-manager';
import { UserProfileResponse } from '../infrastructure/http/modules/auth/auth.models';
import { AddEditAddressRequest } from '../infrastructure/http/modules/account/account.models';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import { RestError } from '../infrastructure/http/modules/errors';
import Spinner from './common/spinner';

const AccountAddressBookPage: React.FC = () => {
    return (
        <UserContext.Consumer>
            {(userContext: IUserContext) =>
                <TranslationsContext.Consumer>
                    {(translationsContext: ITranslationsContext) =>
                        <AccountAddressBookPageInner userContext={userContext} translationsContext={translationsContext} />
                    }
                </TranslationsContext.Consumer>
            }
        </UserContext.Consumer>
    );
};

type Props = {
    translationsContext: ITranslationsContext;
    userContext: IUserContext;
};

type State = {
    addressBook: IAddress[];
    editAddressId: string;
    deleteAddressId: string;

    navigateToLogin: boolean;
    navigateToNewAddress: boolean;
    navigateToEditAddress: boolean;

    isLoaded: boolean;
    showSpinner: boolean;
};

class AccountAddressBookPageInner extends React.Component<Props, State> {
    constructor(props: Props, private translations: { [key: string]: string }) {
        super(props);

        this.state = {
            addressBook: [],
            editAddressId: '',
            deleteAddressId: '',
            navigateToLogin: false,
            navigateToNewAddress: false,
            navigateToEditAddress: false,
            isLoaded: false,
            showSpinner: false,
        };

        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleAddNewAddressClick = this.handleAddNewAddressClick.bind(this);
        this.handleEditAddressClick = this.handleEditAddressClick.bind(this);
        this.handleDeleteAddressClick = this.handleDeleteAddressClick.bind(this);
    }

    public componentDidMount(): void {
        const translationManager = new TranslationManager(this.props.translationsContext);
        this.translations = {
            addressBook: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_HEADER),
            addNewAddress: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_ADDNEWADDRESS),
        };

        const { addressBook } = this.props.userContext.user.userProfile;
        this.setState({ addressBook: addressBook, isLoaded: true });
    }

    public render(): ReactNode {
        if (!this.state.isLoaded) {
            return null;
        }

        return (
            <div className='col2-left-layout oticon-address'>
                <div className='wrapper'>
                    <AccountMenu />
                    <div className='col-main'>
                        <div className='my-account'>
                            <div className='dashboard account-wr'>
                                <h2>{this.translations.addressBook}</h2>

                                <Spinner show={this.state.showSpinner} />
                                {!this.state.showSpinner &&
                                    <div className='address-book-table'>
                                        <AddressGrid
                                            addressBook={this.state.addressBook}
                                            onAddressChange={this.handleAddressChange}
                                            onDeleteAddressClick={this.handleDeleteAddressClick}
                                            onEditAddressClick={this.handleEditAddressClick}
                                        />

                                        <button
                                            type='submit'
                                            onClick={this.handleAddNewAddressClick}
                                            title={this.translations.addNewAddress}
                                            className='address-book__btn button'
                                            style={{ margin: 0 }}
                                        >{this.translations.addNewAddress}</button>
                                        {this.renderNavigateToNewAdress()}
                                        {this.renderNavigateToEditAdress()}
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderNavigateToLogin(): ReactNode {
        return (
            <>
                {this.state.navigateToLogin && <Redirect push to={RouterPaths.Login} />}
            </>
        );
    }

    private renderNavigateToNewAdress(): ReactNode {
        return (
            <>
                {this.state.navigateToNewAddress && <Redirect push to={RouterPaths.AccountAddressBookAddEdit} />}
            </>
        );
    }

    private renderNavigateToEditAdress(): ReactNode {
        return (
            <>
                {this.state.navigateToEditAddress && <Redirect push to={{
                    pathname: RouterPaths.AccountAddressBookAddEdit,
                    state: { [RouterStateParams.EditAddressId]: this.state.editAddressId },
                }} />}
            </>
        );
    }

    private handleAddNewAddressClick(event: SyntheticEvent) {
        event.preventDefault();

        this.setState({ navigateToNewAddress: true });
    }

    private handleAddressChange(addressId: string, isShipping: boolean): void {
        const { tokenInfo, userProfile } = this.props.userContext.user;
        if (!tokenInfo || !userProfile.addressBook) {
            return;
        }

        const address = userProfile.addressBook.find(x => x.id === addressId);

        if (!address) {
            return;
        }

        const editAddressRequest: AddEditAddressRequest = {
            firstName: address.firstName,
            middleName: address.middleName,
            lastName: address.lastName,
            telephone: address.telephone,
            fax: address.fax,
            streetAddress1: address.streetAddress1,
            streetAddress2: address.streetAddress2,
            city: address.city,
            postalCode: address.postalCode,
            country: address.country,
            isDefaultBillingAddress: address.isDefaultBillingAddress,
            isDefaultShippingAddress: address.isDefaultShippingAddress,
        };

        if (isShipping) {
            editAddressRequest.isDefaultShippingAddress = true;
        } else {
            editAddressRequest.isDefaultBillingAddress = true;
        }

        const { account } = GetHttpClientInstance();

        account
            .editAddress(addressId, editAddressRequest, { accessToken: tokenInfo.accessToken })
            .then((userProfileResponse: UserProfileResponse) => {
                this.props.userContext.updateUserProfile(userProfileResponse);
                this.setState({ addressBook: userProfileResponse.addressBook });
            })
            .catch(err => this.handleRestError(err));
    }

    private handleEditAddressClick(addressId: string): void {
        this.setState({ editAddressId: addressId }, () => {
            this.setState({ navigateToEditAddress: true });
        });
    }

    private handleDeleteAddressClick(addressId: string): void {
        const { tokenInfo } = this.props.userContext.user;
        if (!tokenInfo) {
            return;
        }

        this.setState({ showSpinner: true });

        const { account } = GetHttpClientInstance();

        account
            .deleteAddress(addressId, { accessToken: tokenInfo!.accessToken })
            .then((userProfileResponse: UserProfileResponse) => {
                this.props.userContext.updateUserProfile(userProfileResponse);
                this.setState({ addressBook: userProfileResponse.addressBook, showSpinner: false });
            })
            .catch(err => this.handleRestError(err));
    }

    private handleRestError(err: RestError) {
        if (err.statusCode === 401) {
            this.props.userContext.clearUser();
            this.setState({ navigateToLogin: true });
            return;
        }
        console.error(err);
    }
}

export default AccountAddressBookPage;
