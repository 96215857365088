import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { CreateUserAccountRequest } from '../infrastructure/http/modules/account/account.models';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import { LoginResponse } from '../infrastructure/http/modules/auth/auth.models';
import { RestError } from '../infrastructure/http/modules/errors';
import { RouterPaths, RouterStateParams } from '../infrastructure/const';
import CreateAccountForm from './auth/create-account-form';
import ErrorCodesExtractor from '../utilities/error-codes-extractor';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import UserContext from '../infrastructure/context/user/user.context';

type State = {
    errorKeys: string[];
    redirectToAccountDashboard: boolean;
};

class CreateAccountPage extends React.Component<{}, State> {
    constructor(props: {}, private form: React.RefObject<CreateAccountForm>) {
        super(props);

        this.state = {
            errorKeys: [],
            redirectToAccountDashboard: false,
        };

        this.form = React.createRef();
    }

    public render(): ReactNode {
        return (
            <div className='col1-layout'>
                <div className='col-main'>
                    <div>
                        <UserContext.Consumer>
                            {(userContext: IUserContext) =>
                                <CreateAccountForm
                                    ref={this.form}
                                    onAccountCreate={(request: CreateUserAccountRequest) => this.handleAccountCreation(request, userContext)}
                                    errorKeys={this.state.errorKeys}
                                    displayPageTitle={true}
                                />}
                        </UserContext.Consumer>
                    </div>
                </div>
                {this.state.redirectToAccountDashboard &&
                    <Redirect to={{
                        pathname: RouterPaths.AccountDashboard,
                        state: {[RouterStateParams.ShowWelcomeMessage]: true},
                    }} />
                }
            </div>
        );
    }

    private handleAccountCreation(request: CreateUserAccountRequest, userContext: IUserContext): void {
        const { auth, account } = GetHttpClientInstance();

        account
            .createUserAccount(request)
            .then(() => {
                if(request.receiveMarketingMaterials){
                    userContext.subscribe({email: request.email, type: 'Newsletter'});
                }
                // Login user & navigate to account dashboard
                auth.login({ email: request.email, password: request.password })
                    .then((loginResponse: LoginResponse) => {
                        userContext.updateUser(loginResponse).then(() => this.setState({ redirectToAccountDashboard: true }));
                    });
            }).catch((error: RestError) => {
                const errorKeys = ErrorCodesExtractor.extract(error);
                if (this.form.current) {
                    this.form.current.handleCaptchaChange(null);
                }

                this.setState({ errorKeys });
            });
    }
}

export default CreateAccountPage;
