import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { RouterPaths, TranslationKeys } from '../infrastructure/const';
import { CartProduct } from '../infrastructure/http/modules/cart/cart.models';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import CheckUtils from '../utilities/check-utils';
import { Product } from '../infrastructure/http/modules/product/product.models';
import NumberUtils from '../utilities/number-utils';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../infrastructure/translation-manager';
import ProductDetailsInner from './product-details/product-details';

type ProductDetailsPageProps = {
    itemNumber?: string;
    productUrlKey?: string;
};

type ProductDetailsPageState = {
    product: Product | null;
    quantity: number;
    quantityStr: string;
    addToCartButtonClicked: boolean;
    redirect: boolean;
    activeClass: boolean;
};

export default class ProductDetailsPage extends React.Component<RouteComponentProps<ProductDetailsPageProps>, ProductDetailsPageState> {
    maxQuantity = 100;
    translations: ProductDetailsPageTranslations;

    constructor(props: RouteComponentProps<ProductDetailsPageProps>, context: ITranslationsContext) {
        super(props, context);

        this.state = {
            product: null,
            quantity: 1,
            quantityStr: "1",
            addToCartButtonClicked: false,
            redirect: false,
            activeClass: false
        };

        const translationManager: TranslationManager = new TranslationManager(context);
        this.translations = {
            addedToCart: translationManager.getTranslation(TranslationKeys.CART_ADDEDTOCART),
            deliveredWithin: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_DELIVEREDWITHIN),
            deliveredWithinDaysNumber: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_DELIVEREDWITHIN_DAYSNUMBER),
            days: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_DAYS),
            inStock: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_INSTOCK),
            outOfStock: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_OUTOFSTOCK),
            itemNumber: translationManager.getTranslation(TranslationKeys.PRINT_ITEMNUMBER)
        };

        this.isProductInCart = this.isProductInCart.bind(this);
        this.onInputValueButtonChange = this.onInputValueButtonChange.bind(this);
        this.onAddedToCart = this.onAddedToCart.bind(this);
        this.onCartRefresh = this.onCartRefresh.bind(this);
        this.onInputValueChange = this.onInputValueChange.bind(this);
        this.onInputBlur = this.onInputBlur.bind(this);
    }

    componentDidMount(): void {
        this.initialize();
    }

    componentDidUpdate(prevProps: RouteComponentProps<ProductDetailsPageProps>) {
        if (this.props.match.params.itemNumber !== prevProps.match.params.itemNumber) {
            this.initialize();
        }
        else if (this.props.match.params.productUrlKey !== prevProps.match.params.productUrlKey) {
            this.initialize();
        }
    }

    initialize(): void {
        // Enrichment products
        if (!CheckUtils.isNullOrEmptyString(this.props.match.params.productUrlKey)) {
            GetHttpClientInstance().product.details(this.props.match.params.productUrlKey!, "url").then((result) => {
                if (!CheckUtils.isNullObject(result)) {
                    this.setState({ product: result as Product });
                } else {
                    this.setState({ redirect: true });
                    return;
                }
            }).catch(() => {
                this.setState({ redirect: true });
            });
        }

        // Regular products
        else if (CheckUtils.isNullObject(this.props.match.params.itemNumber)) {
            this.setState({ redirect: true });
            return;
        } else {
            GetHttpClientInstance().product.details(this.props.match.params.itemNumber!).then((result) => {
                if (!CheckUtils.isNullObject(result)) {
                    this.setState({ product: result as Product });
                } else {
                    this.setState({ redirect: true });
                    return;
                }
            }).catch(() => {
                this.setState({ redirect: true });
            });
        }

        if (!CheckUtils.isNullObject(this.props.location.state)) {
            const { product } = this.props.location.state as { [key: string]: any };
            if (!CheckUtils.isNullObject(product)) {
                this.setState({ product: product as Product });
            }
        }
    }

    onInputValueChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (CheckUtils.isNullObject(event) || CheckUtils.isNullObject(event.target)) {
            return;
        }
        if (event.target.value === '') {
            this.setState({
                quantityStr: ''
            });
        }
        if (NumberUtils.isPositiveInteger(event.target.value) && event.target.value !== "0") {
            let quantityNumber = NumberUtils.parseNumber(event.target.value);
            if (quantityNumber > this.maxQuantity) {
                quantityNumber = this.maxQuantity;
            }

            this.setState({
                quantity: quantityNumber,
                quantityStr: quantityNumber.toString()
            });
        }
        return;
    }

    onInputValueButtonChange(type: string): void {
        this.setState(prevState => {
            return {
                quantity: type === 'Inc' ? prevState.quantity + 1 : prevState.quantity - 1
            };
        }, () => {
            this.setState({
                quantityStr: this.state.quantity.toString()
            });

            if (this.state.quantity > this.maxQuantity) {
                this.setState({
                    quantity: this.maxQuantity,
                    quantityStr: this.maxQuantity.toString()
                });
            }

            if (this.state.quantity === 0) {
                this.setState({
                    quantity: 1,
                    quantityStr: '1'
                });
            }
        });
    }

    onInputBlur(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            quantityStr: this.state.quantity.toString()
        });
    }

    isProductInCart(context: IUserContext) {
        if (CheckUtils.isNullObject(context) || CheckUtils.isNullObject(context.cart) || CheckUtils.isNullOrEmptyArray(context.cart.products)) {
            return false;
        }
        if (this.state.product === null) {
            return false;
        }
        return context.cart.products.some((x: CartProduct) => x.id === this.state.product!.id);
    }

    onAddedToCart() {
        this.setState({
            addToCartButtonClicked: true,
            quantity: 1,
            quantityStr: '1',
            activeClass: true
        }, () => {
            setTimeout(() => {
                this.setState({ activeClass: false });
            }, 2000);
        });
    }

    onCartRefresh() {
        this.setState({ addToCartButtonClicked: false });
    }

    render(): JSX.Element {
        if (this.state.redirect) {
            return <Redirect to={RouterPaths.NotFound} />;
        }

        return (
            <div className="col1-layout">
                <div className="col-main">
                    <div id="configurable-product-wrapper">
                        <ProductDetailsInner
                            product={this.state.product}
                            translations={this.translations}
                            addToCartButtonClicked={this.state.addToCartButtonClicked}
                            isProductInCart={this.isProductInCart}
                            onInputValueButtonChange={this.onInputValueButtonChange}
                            activeClass={this.state.activeClass}
                            quantityStr={this.state.quantityStr}
                            quantity={this.state.quantity}
                            onAddedToCart={this.onAddedToCart}
                            onCartRefresh={this.onCartRefresh}
                            onInputValueChange={this.onInputValueChange}
                            onInputBlur={this.onInputBlur}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ProductDetailsPage.contextType = TranslationsContext;

export type ProductDetailsPageTranslations = {
    addedToCart: string,
    deliveredWithin: string,
    deliveredWithinDaysNumber: string,
    days: string,
    inStock: string,
    outOfStock: string,
    itemNumber: string
}