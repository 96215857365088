import React, { useContext } from 'react';
import AccountMenu from './account/menu/account-menu';
import AddressAddEditForm from './account/address-book/address-add-edit-form';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import UserContext from '../infrastructure/context/user/user.context';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';

const AccountAddressBookAddEditPage: React.FC = () => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const userContext = useContext<IUserContext>(UserContext);
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);

    return (
        <div className='col2-left-layout oticon-address'>
            <div className='wrapper'>
                <AccountMenu />
                <div className='col-main'>
                    <div className='my-account'>
                        <div className='dashboard account-wr address-book'>
                            <AddressAddEditForm translationsContext={translationsContext} userContext={userContext} configurationContext={configurationContext} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountAddressBookAddEditPage;
