import React, { useContext } from 'react';
import { TranslationKeys } from '../../../infrastructure/const';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';

type Props = {
    numberOfOrders: number;
    onPaginationChange(pageSize: number): void;
};

const Pager: React.FC<Props> = (props: Props) => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);

    const pageSizes = [
        10,
        20,
        50,
    ];

    const translations = {
        items: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_PAGER_ITEMS),
        show: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_PAGER_SHOW),
        perPage: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_PAGER_PERPAGE),
    };

    return (
        <div className='pager right'>
            <div className='amount left'>
                <strong>{props.numberOfOrders} {translations.items}</strong>
            </div>
            <div className='limiter'>
                <label>{translations.show}</label>
                <select onChange={evt => props.onPaginationChange(parseInt(evt.currentTarget.value))}>
                    {pageSizes.map((pageSize) =>
                        <option key={pageSize} value={pageSize}>{pageSize} {translations.perPage}</option>
                    )}
                </select>
            </div>
        </div>
    );
};

export default Pager;
