import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

interface Props extends RouteComponentProps {
    link: string;
    translation: string;
    postClickAction?(): void;
}

const AccountMenuLink: React.FC<Props> = (props: Props) => {
    const isCurrent = props.location.pathname.includes(props.link);

    return (
        <li className={isCurrent ? 'current active' : ''}>
            <Link to={props.link} title={props.translation} onClick={() => props.postClickAction === undefined ? {/* */} : props.postClickAction()}>
                {isCurrent &&
                    <strong>{props.translation}</strong>
                }
                {!isCurrent &&
                    props.translation
                }
            </Link>
        </li>
    );
};

export default withRouter(AccountMenuLink);
