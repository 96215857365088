import { IHttp } from '../../http.interface';
import { Product, ProductsResponse, ProductCategories, ProductDetailsParameterType } from './product.models';
import { IProductModule } from './product.interface';
import { ModuleBase } from '../../common/module.base';
import CheckUtils from '../../../../utilities/check-utils';

export class ProductModule extends ModuleBase implements IProductModule {

    constructor(private http: IHttp) {
        super('Product');
    }

    search(query: string): Promise<ProductsResponse<Product>> {
        return new Promise<ProductsResponse<Product>>((resolve, reject) => {
            let queryUrl = `query=${query}`;
            this.http.get<ProductsResponse<Product>>(`/api/products/search?${queryUrl}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    searchByItemNumbers(itemNumbers: Array<string>, ignoreVisiblity: boolean): Promise<ProductsResponse<Product>> {
        return new Promise<ProductsResponse<Product>>((resolve, reject) => {
            let queryUrl = `ItemNumbers=`;

            if (!CheckUtils.isNullOrEmptyArray(itemNumbers)) {
                queryUrl = `ItemNumbers=${itemNumbers[0]}`;
                if (itemNumbers.length > 1) {
                    for (var i = 1; i < itemNumbers.length; i++) {
                        queryUrl += `&ItemNumbers=${itemNumbers[i]}`
                    }
                }
            }

            queryUrl += `&IgnoreVisiblity=${ignoreVisiblity}`;

            this.http.get<ProductsResponse<Product>>(`/api/products/search?${queryUrl}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    browse(route: string): Promise<ProductsResponse<Product>> {
        return new Promise<ProductsResponse<Product>>((resolve, reject) => {
            let routeUrl = `route=${route}`;
            this.http.get<ProductsResponse<Product>>(`/api/products/browse?${routeUrl}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    details(itemNumber: string, type?: keyof typeof ProductDetailsParameterType): Promise<Product> {
        let typeKey = "itemNumber";
        if (type !== undefined) {
            typeKey = type;
        }
        return new Promise<Product>((resolve, reject) => {
            let queryUrl = `${typeKey}=${itemNumber}`;
            this.http.get<Product>(`/api/products/details?${queryUrl}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                }).catch(() => {
                    reject();
                });
        });
    }

    categories(): Promise<ProductCategories> {
        return new Promise<ProductCategories>((resolve, reject) => {
            this.http.get<ProductCategories>(`/api/products/categories`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }
}