import React from 'react';
import { OrderHistoryAddress } from '../../../../infrastructure/http/modules/orders/orders.models.history';

type Props = {
    leftHeader: string;
    address: OrderHistoryAddress;
    rightHeader: string;
    rightText: () => JSX.Element;
};

const OrderHistoryDetailsAddressRow: React.FC<Props> = (props: Props) => {
    return (
        <div className='box-account'>
            <div className='col-2 box-account__box'>
                <div className='box'>
                    <div className='box-title'>
                        <h3>{props.leftHeader}</h3>
                    </div>
                    <div className='box-content'>
                        <div>{props.address.nameLine1}</div>
                        <div>{props.address.nameLine2}</div>
                        <div>{props.address.addressLine1}</div>
                        <div>{props.address.addressLine2}</div>
                        <div>{props.address.postalCode}</div>
                        <div>{props.address.city}</div>
                        <div>{props.address.country}</div>
                    </div>
                </div>
            </div>
            <div className='col-2right box-account__box'>
                <div className='box'>
                    <div className='box-title'>
                        <h3>{props.rightHeader}</h3>
                    </div>
                    <div className='box-content'>
                        {props.rightText()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderHistoryDetailsAddressRow;