import { Translations } from './http/modules/translations/translations.models';
import ITranslationsContext from './context/translations/translations-context.interface';
import CheckUtils from '../utilities/check-utils';

class TranslationManager {

    private translations: Translations;

    constructor(context: ITranslationsContext) {
        if (CheckUtils.isNullObject(context)) {
            console.error('Translations Context is missing');
        }

        this.translations = context.translations;

        if (CheckUtils.isNullObject(context.translations)) {
            console.error('Translations are not loaded');
        }
    }

    getTranslation(key: string): string {
        if (CheckUtils.isNullObject(this.translations)) {
          return '';
        }

        let translation = this.translations[key];
        if (CheckUtils.isNullObject(translation)) {
            return '';
        }

        return translation;
      }
}

export default TranslationManager;