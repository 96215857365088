import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import CheckUtils from '../../utilities/check-utils';
import MetatagInterpreter from '../../utilities/metatag-interpreter';

type Props = {
    description: string,
    useMetatagInterpreter: boolean
};

const ProductDetailsMarketingDescription = (props: Props): JSX.Element | null => {
    if (CheckUtils.isNullOrEmptyString(props.description)) {
        return null;
    }

    let html = props.description;

    if (props.useMetatagInterpreter) {
        html = MetatagInterpreter.convertToHtml(html);
    }

    return (
        <>{ReactHtmlParser(html)}</>
    );
};

export default ProductDetailsMarketingDescription;