import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { TranslationKeys } from '../../../infrastructure/const';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';

type Props = {
    checkboxes: RegulationsCheckboxProps[],
    displayValidationMessages: boolean,
    validationMessageClass?: string,
}

export type RegulationsCheckboxProps = {
    id: string,
    labelHtml: string,
}

export interface MandatoryCheckboxesRefs {
    getSelectedCheckboxesList: (() => Array<string>),
    allCheckboxesSelected: (() => boolean)
}

type IsCheckboxSelected = {
    id: string,
    isSelected: boolean
}

const MandatoryCheckboxesSection = forwardRef((props: Props, ref: Ref<MandatoryCheckboxesRefs>): JSX.Element => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const [selected, setCheckboxSelected] = useState<IsCheckboxSelected[]>(
        props.checkboxes.map(val => ({
            id: val.id,
            isSelected: false,
            labelHtml: val.labelHtml
        } as IsCheckboxSelected))
    );

    useImperativeHandle(ref, () => ({
        getSelectedCheckboxesList(): Array<string> {
            return selected.filter(x => x.isSelected).map(x => x.id);
        },
        allCheckboxesSelected(): boolean  {
            return !selected.some(x => !x.isSelected);
        }
    }));


    return (
        <>
            {
                props.checkboxes.map((checkbox) => renderCheckbox(checkbox))
            }
        </>
    );

    function renderCheckbox(checkboxProps: RegulationsCheckboxProps): JSX.Element | null {
        var checkbox: IsCheckboxSelected | undefined = selected.find(x => x.id === checkboxProps.id);
        if (!checkbox) {
            return null;
        }

        return <Checkbox
            isChecked={checkbox.isSelected}
            onSelectionChange={(e: React.ChangeEvent<HTMLInputElement>, id: string) => onCheckboxSelectionChange(e, id)}
            id={checkboxProps.id}
            labelHtml={checkboxProps.labelHtml}
            displayValidationMessage={props.displayValidationMessages}
            translationsContext={translationsContext}
            validationMessageClass={props.validationMessageClass}
        />
    }

    function onCheckboxSelectionChange(e: React.ChangeEvent<HTMLInputElement>, id: string): void {
        let checkboxes: IsCheckboxSelected[] = Object.assign([], selected);
        var checkbox: IsCheckboxSelected | undefined = checkboxes.find(x => x.id === id);

        if (!checkbox) {
            return;
        }

        checkbox.isSelected = e.target.checked

        setCheckboxSelected(checkboxes);
    }
});

type CheckboxProps = {
    isChecked: boolean,
    onSelectionChange: ((e: React.ChangeEvent<HTMLInputElement>, id: string) => void),
    id: string,
    labelHtml: string,
    displayValidationMessage: boolean,
    translationsContext: ITranslationsContext,
    validationMessageClass?: string
}

const Checkbox = (props: CheckboxProps) => {
    const validationMessageClass = props.validationMessageClass ? props.validationMessageClass : 'validation-advice';
    return (
        <div className='termsAndConditionsCheckbox'>
            <input
                checked={props.isChecked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onSelectionChange(e, props.id)}
                type="checkbox"
                name={props.id}
                id={props.id}
                className="checkbox css-checkbox prettyCheckable hidden-checkbox" />
            <label className="css-label" htmlFor={props.id}>
                <span className="terms-checkbox"></span>
                <span>{ReactHtmlParser(props.labelHtml)}</span>
            </label>
            {props.displayValidationMessage && !props.isChecked &&
                <div className={validationMessageClass}>
                    <span className="icon-arrow-up"></span>
                    {new TranslationManager(props.translationsContext).getTranslation(TranslationKeys.REQUIRED)}
                </div>
            }
        </div>
    )
}

export default MandatoryCheckboxesSection;