import React from "react";
import CheckUtils from "../../utilities/check-utils";

type Props = {
    message: string;
};

const MessageBanner: React.FC<Props> = (props: Props) => {
    if (CheckUtils.isNullOrEmptyString(props.message)) {
        return null;
    }

    return (
        <ul className='messages'>
            <li className='success-msg'>
                <ul>
                    <li>
                        <span>
                            {props.message}
                        </span>
                    </li>
                </ul>
            </li>
        </ul>
    );
};

export default MessageBanner;