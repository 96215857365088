import React from 'react';
import { Link } from 'react-router-dom';
import { TranslationKeys, RouterPaths } from '../../infrastructure/const';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../infrastructure/translation-manager';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import { ErrorCodes } from '../../infrastructure/http/modules/errors';

type Props = {
    errorKeys: string[];
};

const ErrorMessages: React.FC<Props> = (props: Props) => {
    const translationsContext = React.useContext<ITranslationsContext>(TranslationsContext);

    if (props.errorKeys.length === 0) {
        return null;
    }

    const translationManager = new TranslationManager(translationsContext);

    const translations = {
        emailInUse: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_ERROR_EMAILINUSE),
        invalidCaptcha: translationManager.getTranslation(TranslationKeys.INVALID_CAPTCHA),
        yourResetLinkExpired: translationManager.getTranslation(TranslationKeys.RESETPASSWORD_YOURPASSWORDRESETLINKEXPIRED),
        invalidLoginorPassword: translationManager.getTranslation(TranslationKeys.LOGIN_FORM_ERROR_INVALID_LOGIN_OR_PASSWORD),
        favouritesAuthenticateError: translationManager.getTranslation(TranslationKeys.LOGIN_FORM_NEED_ACCOUNT_FOR_FAVOURITES)
    };

    return (
        <ul className='messages'>
            <li className='error-msg'>
                <ul>
                    <li>
                        <span>
                            {props.errorKeys.includes(ErrorCodes.EmailInUse) &&
                                <Link to={RouterPaths.ForgotPassword}>{translations.emailInUse}</Link>
                            }
                            {props.errorKeys.includes(ErrorCodes.InvalidCaptcha) &&
                                translations.invalidCaptcha
                            }
                            {props.errorKeys.includes(ErrorCodes.ResetLinkExpired) &&
                                translations.yourResetLinkExpired
                            }
                            {props.errorKeys.includes(ErrorCodes.InvalidLoginOrPassword) &&
                                translations.invalidLoginorPassword
                            }
                            {props.errorKeys.includes(ErrorCodes.FavouritesAuthenticateError) &&
                                translations.favouritesAuthenticateError
                            }
                        </span>
                    </li>
                </ul>
            </li>
        </ul>
    );
};

export default ErrorMessages;