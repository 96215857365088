import React, { useContext } from 'react';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../infrastructure/translation-manager';
import { TranslationKeys } from '../../infrastructure/const';
import { useHistory } from 'react-router-dom';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import ProductsEnrichmentUtils from '../../utilities/product-enrichment-utils';
import INavigationContext from '../../infrastructure/context/navigation/navigation.context.interface';
import NavigationContext from '../../infrastructure/context/navigation/navigation.context';
import { Product } from '../../infrastructure/http/modules/product/product.models';

type SelectOptionButtonProps = {
    product: Product,
    classAttr?: string
};

const SelectOptionButton = (props: SelectOptionButtonProps) => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    const categoryContext = useContext<INavigationContext>(NavigationContext);

    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const buttonText = new TranslationManager(translationsContext).getTranslation(TranslationKeys.ADDTOCART);

    const history = useHistory();

    function handleClick() {
        const redirectUrl = ProductsEnrichmentUtils.getProductDetailsUrl(configurationContext, categoryContext, props.product);
        history.push(redirectUrl);
    }

    return (
        <button type="button" title={buttonText} className={props.classAttr === undefined ? "button btn-white buy-button" : props.classAttr} onClick={handleClick}>
            <span className="icon-add-to-cart"></span>
            {buttonText}
        </button>
    );
};

export default SelectOptionButton;
