import * as React from 'react';
import { Link } from 'react-router-dom';
import PageHeaderThemeContext from '../../infrastructure/context/pageheader-theme/pageheader-theme.context';
import IThemeContext from '../../infrastructure/context/pageheader-theme/pageheader-theme.context.interface';
import CheckUtils from '../../utilities/check-utils';
import classNames from 'classnames';
import { ProductCategory } from '../../infrastructure/http/modules/product/product.models';
import NavigationContext from '../../infrastructure/context/navigation/navigation.context';
import INavigationContext from '../../infrastructure/context/navigation/navigation.context.interface';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import { FeatureFlagValues } from '../../infrastructure/const';

export default class CategoryMenuLinks extends React.Component {

    render(): JSX.Element {
        return (
            <ConfigurationContext.Consumer>
                {(configuration: IConfigurationContext) => (
                    configuration.getFeatureFlagValue("topMenuType") === FeatureFlagValues.TopMenu.Links ?
                        this.renderThemeContext()
                        : null
                )}
            </ConfigurationContext.Consumer>
        );
    }

    private renderThemeContext(): JSX.Element {
        return (
            <PageHeaderThemeContext.Consumer>
                {(themeContext: IThemeContext) => (
                    this.renderCategoryContext(themeContext)
                )}
            </PageHeaderThemeContext.Consumer>
        );
    }

    private renderCategoryContext(themeContext: IThemeContext): JSX.Element {
        return (
            <NavigationContext.Consumer>
                {(productCategoriesContext: INavigationContext) => (
                    this.renderMenu(themeContext.isMenuVisible, productCategoriesContext.activeCategory, productCategoriesContext.categories)
                )}
            </NavigationContext.Consumer>
        );
    }

    private renderMenu(isMenuVisible: boolean, activeCategory: ProductCategory[] | null, categories: ProductCategory[]): JSX.Element | null {
        if (!isMenuVisible) {
            return null;
        }
        return (
            <div className="wrapper clearfix hide-paymentpage mobile-wr">
                <div className="header__menu">
                    <ul id="nav" className="clearfix menu-standard-navigation">
                        {!CheckUtils.isNullOrEmptyArray(categories) && categories.map(element => (
                            this.renderMenuLink(element.url, element.title, CheckUtils.isNullObject(activeCategory) ? "" : activeCategory![0].url)
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    private renderMenuLink(link: string, title: string, activeCategoryUrl: string) {
        const liClasses = classNames({
            'level0 nav-connectline even first nav-1': true,
            'active': activeCategoryUrl === link
        });

        return (
            <li className={liClasses} key={title}>
                <Link to={link} className="menu-link" title={title}>{title.toLowerCase().indexOf("connectline") > -1 ? "Connectline Product Range" : title}</Link>
            </li>
        );
    }
}