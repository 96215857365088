import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { RouterPaths } from '../../infrastructure/const';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import UserContext from '../../infrastructure/context/user/user.context';

const AccountLoginChecker: React.FC = () => {
    const userContext = useContext<IUserContext>(UserContext);

    let shouldRedirectBackToLogin = false;

    if (userContext.user.userProfile && !userContext.user.userProfile.isAuthenticated) {
        shouldRedirectBackToLogin = true;
    }

    return (
        <>
            {shouldRedirectBackToLogin &&
                <Redirect to={RouterPaths.Login} />
            }
        </>
    );
};

export default AccountLoginChecker;