import React, { ReactNode } from 'react';
import { CreateUserAccountRequest } from '../../infrastructure/http/modules/account/account.models';
import { TranslationKeys } from '../../infrastructure/const';
import Captcha from '../common/captcha';
import CheckUtils from '../../utilities/check-utils';
import EmailInput from '../common/email-input';
import ErrorMessages from '../common/error-messages';
import Input from '../common/input';
import PasswordInput from '../common/password-input';
import TranslationManager from '../../infrastructure/translation-manager';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import MarketingConsentCheckbox from '../common/marketing-consent-checkbox';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import MandatoryCheckboxesSection, { MandatoryCheckboxesRefs, RegulationsCheckboxProps } from '../checkout/adyen/mandatory-checkboxes-section';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';

type Props = {
    errorKeys: string[];

    onAccountCreate(request: CreateUserAccountRequest): void;
    displayPageTitle: boolean;
};

type State = {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    signUpForMarketingMaterial: boolean;
    captcha: string;

    errors: { [key: string]: boolean };

    showPasswordsShouldMatchHelpText: boolean;

    [name: string]: any;
};

class CreateAccountForm extends React.Component<Props, State> {
    private checkboxesRef: React.RefObject<MandatoryCheckboxesRefs>;

    constructor(props: Props, private captcha: React.RefObject<Captcha>) {
        super(props);

        this.state = {
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            showPasswordsShouldMatchHelpText: false,
            signUpForMarketingMaterial: false,
            captcha: '',

            errors: {
                firstName: false,
                lastName: false,
                email: false,
                password: false,
                confirmPassword: false,
                mandatoryCheckboxes: false,
            },
        };

        this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
        this.handleCaptchaErrorOrExpired = this.handleCaptchaErrorOrExpired.bind(this);

        this.captcha = React.createRef();
        this.checkboxesRef = React.createRef<MandatoryCheckboxesRefs>();
    }

    public render(): ReactNode {
        const translations = this.initializeTranslations();

        return (
            <div className='account-create wrapper'>
                {this.props.displayPageTitle &&
                    <h2 className='page-big-title'>{translations.createAccount}</h2>
                }
                <ConfigurationContext.Consumer>
                    {(configurationContext) =>
                        <div className='wr-block'>
                            <ErrorMessages errorKeys={this.props.errorKeys} />
                            <form className='form-validate' onSubmit={(evt) => this.handleSubmit(evt)}>
                                <div className='col-2 margin-r'>
                                    <h2 className='legend'>{translations.yourName}</h2>
                                    <ul className='form-list form-account-name'>
                                        <li className="input-container">
                                            {this.renderInput(this.state.firstName, 'firstName', translations.firstName, true)}
                                        </li>

                                        {!configurationContext.isFeatureFlagEnabled("hideCustomerMiddleName") &&
                                            <li className="input-container">
                                                {this.renderInput(this.state.middleName, 'middleName', translations.middleName, false)}
                                            </li>
                                        }


                                        <li className="input-container">
                                            {this.renderInput(this.state.lastName, 'lastName', translations.lastName, true)}
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-2 account-info'>
                                    <h2 className='legend'>{translations.accountInfo}</h2>
                                    <ul className='form-list'>
                                        <li className='input-container'>
                                            {this.renderEmailInput(this.state.email, 'email', translations.email, true)}
                                        </li>
                                        <li className='input-container'>
                                            {this.renderPasswordInput(this.state.password, 'password', translations.password)}
                                        </li>
                                        <li className="input-container">
                                            {this.renderPasswordInput(this.state.passwordConfirmation, 'passwordConfirmation', translations.passwordConfirmation, translations.pleaseMakeSurePasswordsMatch)}
                                        </li>
                                        <li className='control'>
                                            <MarketingConsentCheckbox
                                                isChecked={this.state.signUpForMarketingMaterial}
                                                handleChange={(newValue) => this.handleCheckboxChange('signUpForMarketingMaterial', newValue)}
                                            />
                                        </li>
                                        <li>
                                            {this.renderMandatoryCheckboxesSection(configurationContext, translations)}
                                        </li>
                                    </ul>
                                    <Captcha ref={this.captcha} hasError={this.state.errors.captcha} onChange={this.handleCaptchaChange} onError={this.handleCaptchaErrorOrExpired} onExpiration={this.handleCaptchaErrorOrExpired} />
                                    <button type='submit' title={translations.submit} className='button'>{translations.submit}</button>
                                </div>
                            </form>
                        </div>
                    }
                </ConfigurationContext.Consumer>
            </div>
        );
    }

    public handleCaptchaChange(token: string | null): void {
        const { errors } = this.state;
        errors.captcha = CheckUtils.isNullOrEmptyString(token) ? true : false;

        if (!token && this.captcha.current) {
            this.captcha.current.resetCaptcha();
        }

        this.setState({
            captcha: CheckUtils.isNullOrEmptyString(token) ? '' : token!,
            errors: errors
        });
    }

    private initializeTranslations(): { [key: string]: string } {
        const translationManager = new TranslationManager(this.context);

        const translations = {
            createAccount: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_HEADER),
            yourName: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_SUBHEADER_YOUR_NAME),
            accountInfo: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_SUBHEADER_ACCOUNT_INFO),
            firstName: translationManager.getTranslation(TranslationKeys.FIRST_NAME),
            middleName: translationManager.getTranslation(TranslationKeys.MIDDLE_NAME),
            lastName: translationManager.getTranslation(TranslationKeys.LAST_NAME),
            email: translationManager.getTranslation(TranslationKeys.EMAIL),
            password: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_PASSWORD),
            passwordConfirmation: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_CONFIRM_PASSWORD),
            signUpForMarketingMaterial: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_SIGNUPFORMARKETINGMATERIALS),
            submit: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_SUBMIT),
            pleaseMakeSurePasswordsMatch: translationManager.getTranslation(TranslationKeys.PLEASE_MAKE_SURE_YOUR_PASSWORDS_MATCH),
            emailInUse: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_ERROR_EMAILINUSE),
            invalidCaptcha: translationManager.getTranslation(TranslationKeys.INVALID_CAPTCHA),
            consentCheckboxAcceptPrivacyPolicy: translationManager.getTranslation(TranslationKeys.CREATE_ACCOUNT_CONSENTCHECKBOX_ACCEPTPRIVACYPOLICY)
        };

        return translations;
    }

    private handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();

        const errors = this.getValidationErrors();

        this.setState({ errors }, () => {
            const errors = Object.values(this.state.errors);
            if (errors.some(x => x === true)) {
                return;
            }

            const request: CreateUserAccountRequest = {
                email: this.state.email,
                firstName: this.state.firstName,
                middleName: this.state.middleName,
                lastName: this.state.lastName,
                password: this.state.password,
                passwordConfirmed: this.state.passwordConfirmation,
                receiveMarketingMaterials: this.state.signUpForMarketingMaterial,
                captcha: this.state.captcha,
            };

            this.props.onAccountCreate(request);
        });
    }

    private getValidationErrors(): { [key: string]: boolean } {
        const { errors } = this.state;

        const keysToCheck = ['firstName', 'lastName', 'email', 'password', 'passwordConfirmation', 'captcha'];

        for (const key of keysToCheck) {
            errors[key] = errors[key] || CheckUtils.isNullOrEmptyString(this.state[key]);
        }

        errors['passwordConfirmation'] = this.state.password !== this.state.passwordConfirmation;

        if (this.checkboxesRef.current) {
            errors['mandatoryCheckboxes'] = !this.checkboxesRef.current.allCheckboxesSelected();
        }

        return errors;
    }

    private handleCheckboxChange(name: keyof State, checked: boolean): void {
        const newState = { ...this.state };
        newState[name] = checked;

        this.setState(newState);
    }

    private handleInputChange(name: keyof State, value: any, isValid: boolean): void {
        const newState = { ...this.state };
        newState[name] = value;

        if (name === 'firstName' || name === 'lastName') {
            newState.errors[name] = CheckUtils.isNullOrEmptyString(value);
        } else if (name === 'password' || name === 'passwordConfirmation' || name === 'email') {
            newState.errors[name] = !isValid;
        } else {
            newState.errors[name] = false;
        }

        if (name === 'passwordConfirmation') {
            const confirmedPasswordDoesNotMatch = value !== this.state.password;
            newState.showPasswordsShouldMatchHelpText = confirmedPasswordDoesNotMatch;
            newState.errors.passwordConfirmation = confirmedPasswordDoesNotMatch;
        }

        this.setState(newState);
    }

    private handleCaptchaErrorOrExpired(): void {
        const { errors } = this.state;
        errors.captcha = true;

        this.setState({ captcha: '', errors: errors });
    }

    private renderMandatoryCheckboxesSection(configuration: IConfigurationContext, translations: { [key: string]: string }) {
        let checkboxes: RegulationsCheckboxProps[] = [];

        if (configuration.isFeatureFlagEnabled("showPrivacyPolicyConsent")) {
            checkboxes.push({
                id: "consentCheckboxAcceptPrivacyPolicy",
                labelHtml: translations.consentCheckboxAcceptPrivacyPolicy
            })
        }

        return (
            <MandatoryCheckboxesSection
                ref={this.checkboxesRef}
                checkboxes={checkboxes}
                displayValidationMessages={this.state.errors['mandatoryCheckboxes']}
            />
        )
    }

    private renderPasswordInput(value: any, name: keyof State, label: string, helpText: string = '') {
        return (
            <PasswordInput
                label={label}
                id={name.toString()}
                name={name.toString()}
                isRequired={true}
                hasError={this.state.errors[name]}
                showValidationText={this.state.errors[name]}
                helpTextHtml={this.state.showPasswordsShouldMatchHelpText ? helpText : ''}
                value={value}
                onValueChange={(name: string, value: any, isValid: boolean) => this.handleInputChange(name, value, isValid)}
            />
        );
    }

    private renderEmailInput(value: any, name: keyof State, label: string, isRequired: boolean): ReactNode {
        const htmlId = 'email';

        return (
            <EmailInput
                label={label}
                id={htmlId}
                name={name.toString()}
                isRequired={isRequired}
                hasError={this.state.errors[name]}
                showValidationText={this.state.errors[name]}
                value={value}
                onValueChange={(name: string, value: any, isValid: boolean) => this.handleInputChange(name, value, isValid)}
            />
        );
    }

    private renderInput(value: any, name: keyof State, label: string, isRequired: boolean): ReactNode {
        if (CheckUtils.isNullOrEmptyString(label)) {
            label = '';
        }

        const htmlId = label.replace(' ', '').toLowerCase();

        return (
            <Input
                label={label}
                id={htmlId}
                name={name.toString()}
                isRequired={isRequired}
                hasError={this.state.errors[name]}
                showValidationText={this.state.errors[name]}
                value={value}
                onValueChange={(name: string, value: any, isValid: boolean) => this.handleInputChange(name, value, isValid)}
            />
        );
    }
}

CreateAccountForm.contextType = TranslationsContext;

export default CreateAccountForm;