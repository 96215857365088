import * as React from 'react';
import { Product } from '../../infrastructure/http/modules/product/product.models';
import ProductListItem from './product-list-item';
import UserContext from '../../infrastructure/context/user/user.context';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import CheckUtils from '../../utilities/check-utils';
import { Cart } from '../../infrastructure/http/modules/cart/cart.models';

type ProductListState = {
};

type ProductListProps = {
  products: Array<Product>;
};

export default class ProductList extends React.Component<ProductListProps, ProductListState> {

  isProductInCart(productId: string, cart: Cart): boolean {
    if (CheckUtils.isNullObject(cart) || CheckUtils.isNullOrEmptyArray(cart.products)) {
      return false;
    }
    return cart.products.some(x => x.id === productId);
  }

  render(): JSX.Element {
    return (
      <UserContext.Consumer>
        {(userContext: IUserContext) => (
          <div className="category-products wrapper">
            <div className="products-grid clearfix" id="products-grid">
              {this.props.products.map((product => {
                return <ProductListItem
                  key={product.id}
                  id={product.id}
                  name={product.name}
                  description={product.description}
                  itemNumber={product.itemNumber}
                  priceResponse={product.priceResponse}
                  imageUrl={product.imageUrl}
                  subtitle={product.subtitle}
                  marketingDescription={product.marketingDescription}
                  marketingShortDescription={product.marketingShortDescription}
                  addedToCart={this.isProductInCart(product.id, userContext.cart)}
                  partialId={product.partialId}
                  urlKey={product.urlKey}
                  canAddToCart={product.canAddToCart}
                  isConfigurable={product.isConfigurable}
                  isOnPromotion={product.isOnPromotion}
                  productPromoResponse={product.productPromoResponse}
                  metaDescription={product.metaDescription}
                  metaKeywords={product.metaKeywords}
                  metaTitle={product.metaTitle} />;
              }))}
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}