import React from 'react';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import { TranslationKeys, RouterPaths, CustomPropertyKeys, FeatureFlagValues, CouponResponseCode, TranslationsPrefix } from '../../infrastructure/const';
import { Redirect } from 'react-router';
import CheckUtils from '../../utilities/check-utils';
import { GetHttpClientInstance } from '../../infrastructure/context/http/http-context-provider';
import { UpdateCartPropertiesRequest } from '../../infrastructure/http/modules/cart/cart.models';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import HyperlinkButton from '../common/hyperlink-button';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import PaymentIcons from '../common/payment-icons';
import CartCouponCode from './cart-coupon-code';
import DiscountsSummary from '../common/discounts-summary';
import TranslationsUtils from '../../utilities/translations-utils';

type CartSummaryProps = {
    userContext: IUserContext;
    configurationContext: IConfigurationContext;
    updateMessage: ((message: string) => void);
};

type CartSummaryState = {
    goToCheckoutClicked: boolean,
    properties: { [id: string]: string },
    hasCustomProperties: boolean,
    vatExemptApplied: boolean,
    isStickyBannerVisible: boolean,
    couponResponseCode: keyof typeof CouponResponseCode
};

export default class CartSummary extends React.Component<CartSummaryProps, CartSummaryState> {
    private TranslationManager: TranslationManager;
    private translations: { [id: string]: string };
    private cartTableRef: React.RefObject<HTMLTableElement>;

    constructor(props: CartSummaryProps, context: ITranslationsContext) {
        super(props, context);
        const hasCustomProperties = !CheckUtils.isNullObject(props.userContext.cart.customProperties);

        this.state = {
            goToCheckoutClicked: false,
            totalPrice: props.userContext.cart.displayPrice,
            vat: props.userContext.cart.vat,
            shippingPrice: props.userContext.cart.shippingPriceExclVat,
            hasCustomProperties,
            properties: hasCustomProperties ? props.userContext.cart.customProperties : {},
            vatExemptApplied: hasCustomProperties && this.VATExemptApplied(props.userContext.cart.customProperties),
            isStickyBannerVisible: true,
            couponResponseCode: props.userContext.cart.couponResponseCode,
        } as CartSummaryState;

        this.TranslationManager = new TranslationManager(this.context);

        this.translations = {
            orderSummary: this.TranslationManager.getTranslation(TranslationKeys.CART_ORDERSUMMARY_HEADER),
            vatLabel: this.TranslationManager.getTranslation(TranslationKeys.UK.CART_VAT_LABEL),
            vatDescription: this.TranslationManager.getTranslation(TranslationKeys.UK.CART_VAT_DESCRIPTION),
            readMore: this.TranslationManager.getTranslation(TranslationKeys.READMORE),
            referralCodeLabel: this.TranslationManager.getTranslation(TranslationKeys.UK.CART_REFERRALCODE_LABEL),
            referralCodePlaceholder: this.TranslationManager.getTranslation(TranslationKeys.UK.CART_REFERRALCODE_PLACEHOLDER),
            referralCodeApplied: this.TranslationManager.getTranslation(TranslationKeys.UK.CART_REFERRALCODE_APPLIED),
            referralCodeCanceled: this.TranslationManager.getTranslation(TranslationKeys.UK.CART_REFERRALCODE_CANCELED),
            eanNumberLabel: this.TranslationManager.getTranslation(TranslationKeys.DK.EAN_NUMBER_LABEL),
            eanNumberPlaceholder: this.TranslationManager.getTranslation(TranslationKeys.DK.EAN_NUMBER_PLACEHOLDER),
            eanNumberApplied: this.TranslationManager.getTranslation(TranslationKeys.DK.EAN_NUMBER_APPLIED),
            eanNumberCanceled: this.TranslationManager.getTranslation(TranslationKeys.DK.EAN_NUMBER_CANCELED),
            apply: this.TranslationManager.getTranslation(TranslationKeys.APPLY),
            cancel: this.TranslationManager.getTranslation(TranslationKeys.CANCEL),
            total: this.TranslationManager.getTranslation(TranslationKeys.TOTAL),
            subtotalIncllVat: this.TranslationManager.getTranslation(TranslationKeys.UK.CART_SUBTOTAL_INCLUDING_VAT),
            subtotalExclVat: this.TranslationManager.getTranslation(TranslationKeys.UK.CART_SUBTOTAL_EXCLUDING_VAT),
            shipping: this.TranslationManager.getTranslation(TranslationKeys.CART_SHIPPING),
            vat: this.TranslationManager.getTranslation(TranslationKeys.UK.VAT),
            vatExempt: this.TranslationManager.getTranslation(TranslationKeys.UK.VAT_EXEMPT),
            continueShopping: this.TranslationManager.getTranslation(TranslationKeys.CART_CONTINUESHOPPING),
            goToCheckout: this.TranslationManager.getTranslation(TranslationKeys.CART_GOTOCHECKOUT),
            acceptedPaymentMethods: this.TranslationManager.getTranslation(TranslationKeys.ACCEPTEDPAYMENTMETHODS),
            promoCodeLabel: this.TranslationManager.getTranslation(TranslationKeys.CART_PROMOCODE_LABEL),
            promoCodePlaceholder: this.TranslationManager.getTranslation(TranslationKeys.CART_PROMOCODE_PLACEHOLDER),
            promoCodeApplied: this.TranslationManager.getTranslation(TranslationKeys.CART_PROMOCODE_APPLIED),
            promoCodeCanceled: this.TranslationManager.getTranslation(TranslationKeys.CART_PROMOCODE_CANCELED),
            youSaved: this.TranslationManager.getTranslation(TranslationKeys.CART_TOTALDISCOUNT)
        };

        this.cartTableRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleStickyBannerDisplay);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleStickyBannerDisplay);
    }

    isElementVisible(el: any) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    handleStickyBannerDisplay = () => {
        if (this.isElementVisible(this.cartTableRef.current)) {
            this.setState({ isStickyBannerVisible: false });
        } else {
            this.setState({ isStickyBannerVisible: true });
        }
    }

    renderRedirect = () => {
        if (this.state.goToCheckoutClicked) {
            return <Redirect push to={RouterPaths.CheckoutPage} />;
        }
    }

    renderStickyBanner(): JSX.Element {
        return (
            <div className="cart-stickybanner">
                <div className="cart-stickybanner__wrapper">
                    <div>
                        <strong>{this.translations.total}</strong>
                    </div>
                    <div>
                        <strong><span className="price">{this.props.userContext.cart.displayPrice}</span></strong>
                    </div>
                </div>
                <button type="button" className="button-addToCart buy-button" title="Go to checkout" onClick={() => {
                    this.setState({ goToCheckoutClicked: true });
                }}>
                    {this.translations.goToCheckout}
                </button>
            </div>
        );
    }

    render(): JSX.Element {
        return (
            <>
                {this.state.isStickyBannerVisible && this.renderStickyBanner()}
                <div className="cart-collateral">
                    <div>
                        {this.renderRedirect()}
                        <div className="title">{this.translations.orderSummary}</div>
                        <div className="cart-info">
                            {this.renderOrderSummary()}
                            {this.renderPriceSection()}
                            {this.renderShippingPriceDisclaimer()}
                            <button type="button" className="button-addToCart buy-button" title="Go to checkout" onClick={() => {
                                this.setState({ goToCheckoutClicked: true });
                            }}>
                                {this.translations.goToCheckout}
                            </button>
                            <div className="link-wrapper">
                                <a href={RouterPaths.LandingPage} className="continue-link" title="Continue Shopping">{this.translations.continueShopping}</a>
                            </div>
                        </div>
                    </div>
                    <PaymentIcons paymentMethodsTranslation={this.translations.acceptedPaymentMethods} />
                </div>
            </>
        );
    }

    private updateProperties(propertyKey: string, propertyValue: string): Promise<void> {
        return new Promise<void>((resolve) => {
            let { properties } = this.state;
            properties[propertyKey] = propertyValue;
            this.setState({ properties }, () => {
                resolve();
            });
        });
    }

    private updateCart(): void {
        GetHttpClientInstance().cart.updateProperties({
            userId: this.props.userContext.user.userProfile.userId,
            customProperties: this.state.properties,
            shippingMethod: this.props.userContext.cart.selectedShippingType,
            paymentCode: this.props.userContext.cart.selectedPaymentType
        } as UpdateCartPropertiesRequest).then(() => {
            this.props.userContext.refreshCart().then((response) => {
                this.setState({ couponResponseCode: response.couponResponseCode })
            });
        });
    }

    private renderShippingPriceDisclaimer(): JSX.Element | null {
        const displayDisclaimer = this.props.configurationContext.getFeatureFlagValue("displayShippingDisclaimerInCart");
        const { shippingTypes } = this.props.configurationContext;

        if (!displayDisclaimer || !shippingTypes || !shippingTypes.length || !shippingTypes[0].disclaimer) {
            return null;
        }

        const shippingMethodTranslation = this.TranslationManager.getTranslation(TranslationsPrefix.Checkout_ShippingTypes + shippingTypes[0].disclaimer);

        return (
            <span className='cart-shipping-disclaimer'>
                {shippingMethodTranslation}
            </span>
        );
    }

    private renderPriceSection() {
        const displayPriceIncludingVat = this.props.configurationContext.getFeatureFlagValue("displayPriceIncludingVat");
        return (
            <table id="shopping-cart-totals-table" ref={this.cartTableRef}>
                <tbody>
                    <tr>
                        <td className="a-right">
                            {displayPriceIncludingVat ? this.translations.subtotalIncllVat : this.translations.subtotalExclVat}
                        </td>
                        <td className="a-right"> <span className="price">
                            {displayPriceIncludingVat ? this.props.userContext.cart.subTotalInclVat : this.props.userContext.cart.subTotalExclVat}
                        </span> </td>
                    </tr>
                    {!CheckUtils.isNullOrEmptyString(this.props.userContext.cart.shippingPriceExclVat) &&
                        <tr>
                            <td className="a-right">{this.translations.shipping}</td>
                            <td className="a-right"> <span className="price">
                                {displayPriceIncludingVat ? this.props.userContext.cart.shippingPriceInclVat : this.props.userContext.cart.shippingPriceExclVat}
                            </span></td>
                        </tr>
                    }
                    <tr>
                        <td className="a-right"> {this.translations.vat} </td>
                        <td className="a-right"><span className="price">{this.props.userContext.cart.vat}</span></td>
                    </tr>
                    {this.state.vatExemptApplied &&
                        <tr>
                            <td className="a-right">{this.translations.vatExempt}</td>
                            <td className="a-right"><span className="price">-{this.props.userContext.cart.vatExempt}</span></td>
                        </tr>
                    }
                    <DiscountsSummary discounts={this.props.userContext.cart.discounts} />
                    {this.promoDiscountApplied() &&
                        <tr className="total-discount">
                            <td className="a-right">{this.translations.youSaved}</td>
                            <td className="a-right"><span className="price">{this.props.userContext.cart.totalDiscount}</span></td>
                        </tr>
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td className="a-right" colSpan={1}>
                            <strong>{this.translations.total}</strong>
                        </td>
                        <td className="a-right">
                            <strong><span className="price">{this.props.userContext.cart.displayPrice}</span></strong>
                        </td>
                    </tr>
                </tfoot>
            </table>
        )
    }

    private renderOrderSummary() {
        const orderSummaryType = this.props.configurationContext.getFeatureFlagValue("orderSummaryType");

        if (orderSummaryType === FeatureFlagValues.OrderSummary.UK) {
            return (
                <>
                    {this.renderVatExemptCheckbox()}
                    {this.renderReferralCode()}
                </>
            );
        }

        if (orderSummaryType === FeatureFlagValues.OrderSummary.DK) {
            return (
                <>
                    {this.renderEANNumber()}
                    {this.renderPromoCode()}
                </>
            );
        }

        return this.renderPromoCode();
    }

    private renderPromoCode(): JSX.Element | null {
        if (!this.props.configurationContext.isFeatureFlagEnabled("allowPromoCode")) {
            return null;
        }

        const promoCodeValue = this.state.properties[CustomPropertyKeys.Global.PromoCode];
        const promoCodeSelected = !CheckUtils.isNullObject(this.state.properties) && !CheckUtils.isNullOrEmptyString(promoCodeValue);
        const promoCodeApplied = this.state.hasCustomProperties && promoCodeSelected && !CheckUtils.isNullOrEmptyString(promoCodeValue);

        return (
            <CartCouponCode
                onValueChange={(value: string) => {
                    this.updateProperties(CustomPropertyKeys.Global.PromoCode, value).then(() => {
                        this.updateCart();
                    });
                }}
                id="promo-code"
                selected={promoCodeSelected}
                value={promoCodeValue}
                applied={promoCodeApplied}
                label={this.translations.promoCodeLabel}
                placeholder={this.translations.promoCodePlaceholder}
                buttonApplyLabel={this.translations.apply}
                buttonCancelLabel={this.translations.cancel}
                displayResponseMessage={true}
                responseMessage={this.state.couponResponseCode !== CouponResponseCode.NotApplied ?
                    TranslationsUtils.getPromoCodeResponseTranslation(this.context, this.state.couponResponseCode) : ""
                }
                isErrorMessage={this.state.couponResponseCode !== CouponResponseCode.Success && this.state.couponResponseCode !== CouponResponseCode.NotApplied}
            />
        );
    }

    private renderReferralCode(): JSX.Element {
        const referralCodeValue = this.state.properties[CustomPropertyKeys.UK.ReferralCode];
        const referralCodeSelected = !CheckUtils.isNullObject(this.state.properties) && !CheckUtils.isNullOrEmptyString(referralCodeValue);
        const referralCodeApplied = this.state.hasCustomProperties && referralCodeSelected && !CheckUtils.isNullOrEmptyString(referralCodeValue);

        return (
            <CartCouponCode
                onValueChange={(value: string) => {
                    this.updateProperties(CustomPropertyKeys.UK.ReferralCode, value).then(() => {
                        this.updateCart();
                        if (CheckUtils.isNullOrEmptyString(value)) {
                            this.props.updateMessage(this.translations.referralCodeCanceled);
                        } else {
                            this.props.updateMessage(this.translations.referralCodeApplied);
                        }
                    });
                }}
                id="referral"
                selected={referralCodeSelected}
                value={referralCodeValue}
                applied={referralCodeApplied}
                label={this.translations.referralCodeLabel}
                placeholder={this.translations.referralCodePlaceholder}
                buttonApplyLabel={this.translations.apply}
                buttonCancelLabel={this.translations.cancel}
                displayResponseMessage={false}
                responseMessage=""
                isErrorMessage={false}
            />
        );
    }

    private renderEANNumber(): JSX.Element {
        const eanNumberValue = this.state.properties[CustomPropertyKeys.DK.EANNumber];
        const eanNumberSelected = !CheckUtils.isNullObject(this.state.properties) && !CheckUtils.isNullOrEmptyString(eanNumberValue);
        const eanNumberApplied = this.state.hasCustomProperties && eanNumberSelected && !CheckUtils.isNullOrEmptyString(eanNumberValue);

        return (
            <CartCouponCode
                onValueChange={(value: string) => {
                    this.updateProperties(CustomPropertyKeys.DK.EANNumber, value).then(() => {
                        this.updateCart();
                        if (CheckUtils.isNullOrEmptyString(value)) {
                            this.props.updateMessage(this.translations.eanNumberCanceled);
                        } else {
                            this.props.updateMessage(this.translations.eanNumberApplied);
                        }
                    });
                }}
                id="ean"
                selected={eanNumberSelected}
                value={eanNumberValue}
                applied={eanNumberApplied}
                label={this.translations.eanNumberLabel}
                placeholder={this.translations.eanNumberPlaceholder}
                buttonApplyLabel={this.translations.apply}
                buttonCancelLabel={this.translations.cancel}
                displayResponseMessage={false}
                responseMessage=""
                isErrorMessage={false}
            />
        );
    }

    private VATExemptApplied(properties: { [id: string]: string }): boolean {
        const vatPropertyValue = properties[CustomPropertyKeys.UK.VatExempt];
        let isSelected = false;

        if (!CheckUtils.isNullOrEmptyString(vatPropertyValue)) {
            isSelected = vatPropertyValue.toLowerCase() === "true" ? true : false;
        }
        return isSelected;
    }

    private renderVatExemptCheckbox(): JSX.Element {
        let isSelected = this.VATExemptApplied(this.state.properties);

        return (
            <div className="vat-block">
                <form>
                    <input
                        checked={isSelected}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const checked = e.target.checked;
                            this.updateProperties(CustomPropertyKeys.UK.VatExempt, String(checked)).then(() => {
                                this.setState({ vatExemptApplied: checked });
                                this.updateCart();
                            });
                        }}
                        type="checkbox"
                        name="vatrelief"
                        id="vatrelief"
                        className="hidden-checkbox css-checkbox" />
                    <label className="css-label" htmlFor="vatrelief">
                        <strong>{this.translations.vatLabel}</strong>
                        <span>{this.translations.vatDescription}&nbsp;<HyperlinkButton href={RouterPaths.CustomerServiceVatExempt} onClick={() => { window.open(RouterPaths.CustomerServiceVatExempt); }}>{this.translations.readMore}</HyperlinkButton></span>
                    </label>
                </form>
            </div>
        );
    }

    private promoDiscountApplied() {
        return !CheckUtils.isNullOrEmptyString(this.props.userContext.cart.totalDiscount) &&
            this.props.userContext.cart.priceBreakdown.totalDiscount > 0;
    }
}

CartSummary.contextType = TranslationsContext;