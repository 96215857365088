import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReactHtmlParser from 'react-html-parser';

type Props = {
    tabs: Array<TabProps>
}

export type TabProps = {
    name: string,
    html: string,
    isDisabled: boolean
}

const SwitchableTabs = (props: Props) => {
    return (
        <Tabs>
            <TabList>
                {props.tabs.map((tab) => (
                    <Tab
                        disabled={tab.isDisabled}
                        key={tab.name}>
                        {tab.name}
                    </Tab>
                ))}
            </TabList>

            {
                props.tabs.map((tab) => (
                    <TabPanel key={tab.name}>
                        {ReactHtmlParser(tab.html)}
                    </TabPanel>
                ))
            }
        </Tabs>
    );
};

export default SwitchableTabs;