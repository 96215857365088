import * as React from 'react';
import IUserContext, { ISubscriptions, IUserProfile } from './user.context.interface';
import { IUser } from './user.context.interface';
import { Cart } from '../../http/modules/cart/cart.models';
import { CreateSubscriptionRequest } from '../../http/modules/subscriptions/subscriptions.models';

const defaultContext: IUserContext = {
    user: {} as IUser,
    cart: {} as Cart,
    updateQuantityInCart:((id: string, qty: number) => {}),
    refreshCart: (() => new Promise<Cart>((resolve) => resolve)),
    updateUser: (() => new Promise<IUser>((resolve) => resolve)),
    updateUserProfile: (() => new Promise<IUserProfile>((resolve) => resolve)),
    clearUser: (() => {/* */ }),
    isCartLoaded: false,
    cartUpdateInProgress: false,
    setCartUpdateInProgress: (() => new Promise<void>((resolve) => resolve)),
    setRemoveFromCartPopupVisibility: ((isVisible: boolean) => new Promise<void>((resolve) => resolve)),
    removeFromCartPopupVisible: false,
    subscribe: ((request: CreateSubscriptionRequest) => new Promise<void>((resolve) => resolve)),
    isSubscribed:((type: keyof ISubscriptions) => false),
    deleteSubscription: ((type: keyof ISubscriptions) => new Promise<void>((resolve) => resolve))
};

const UserContext = React.createContext<IUserContext>(defaultContext);
export default UserContext;
