import React from 'react';
import UserContext from '../../../infrastructure/context/user/user.context';
import IUserContext from '../../../infrastructure/context/user/user.context.interface';
import Spinner from '../../common/spinner';
import { GetHttpClientInstance } from '../../../infrastructure/context/http/http-context-provider';
import { InitializeNetsResponse, Transaction, TransactionResponse } from '../../../infrastructure/http/modules/cart/cart.models';
import CheckUtils from '../../../utilities/check-utils';
import { RouterPaths } from '../../../infrastructure/const';
import { RouteComponentProps, withRouter } from 'react-router';
import PaymentSummary from '../../common/payments/payment-summary';

type NetsCheckoutState = {
    isLoading: boolean;
    transaction: Transaction | null;
    orderPlaced: boolean;
    transactionId: string;
    paymentId: string;
    correlationId: string;
    clientKey: string;
    language: string
};

class NetsCheckout extends React.Component<RouteComponentProps, NetsCheckoutState> {
    constructor(props: RouteComponentProps<{}>, context: IUserContext) {
        super(props, context);

        this.state = {
            isLoading: true,
            transaction: null,
            transactionId: "",
            paymentId: "",
            orderPlaced: false,
            correlationId: "",
            clientKey: "",
            language: ""
        };

        this.initializeDropinComponent = this.initializeDropinComponent.bind(this);
        this.createOrderConfirmationReditect = this.createOrderConfirmationReditect.bind(this);
        this.createErrorRedirect = this.createErrorRedirect.bind(this);

    }

    componentDidMount(): void {
        this.fetchNetsDropIn();
    }

    fetchNetsDropIn(): void {
        GetHttpClientInstance().cart.initializeNets(this.context.user.userProfile.userId).then((response: InitializeNetsResponse) => {
            if (CheckUtils.isNullOrEmptyString(response.paymentId)) {
                this.createErrorRedirect();
                return;
            }
            this.setState(
                {
                    paymentId: response.paymentId,
                    transactionId: response.transactionId,
                    correlationId: response.correlationId,
                    clientKey: response.clientKey,
                    language: response.language,
                    isLoading: false,
                }, () => {
                    this.initializeDropinComponent();
                    GetHttpClientInstance().cart.getTransaction(response.transactionId, this.context.user.userProfile.userId, response.correlationId).then((transactionResponse: TransactionResponse) => {
                        this.setState({ transaction: transactionResponse });
                    })
                });
        }).catch(()=> {
            this.createErrorRedirect();
        });

    }

    private createOrderConfirmationReditect= () => {
        this.props.history.push(RouterPaths.OrderConfirmation + `/${this.state.transactionId}/${this.state.correlationId}`)
    }

    private createErrorRedirect = () => {
        this.props.history.push(RouterPaths.Error);
    }

    private initializeDropinComponent(): void {
        const checkoutOptions = {
            checkoutKey: this.state.clientKey,
            paymentId: this.state.paymentId,
            containerId: "checkout-container-div",
            language: this.state.language
        };

        const checkout = new window.Dibs.Checkout(checkoutOptions);

        checkout.on('payment-completed', this.createOrderConfirmationReditect);
        
    };

    render(): JSX.Element {
        if (this.state.isLoading) {
            return <Spinner show />;
        }
        else {
            return (
                <div className="nets-wrapper">
                    <div className="nets-checkout-wrapper" id="checkout-container-div" >
                    </div>
                    <div className="nets-summary">
                        {this.state.transaction !== null && <PaymentSummary transaction={this.state.transaction} />}
                    </div>
                </div>
            )
        }
    }
}

declare global {
    interface Window { Dibs: any; }
}


NetsCheckout.contextType = UserContext;
export default withRouter(NetsCheckout);