import React from 'react';
import { Link } from 'react-router-dom';

type PageHeaderLogoProps = {
  brand?: string;
}

const PageHeaderLogo = (props: PageHeaderLogoProps): JSX.Element => {
  let logoSrc, logoAlt, logoTitle: string;

  switch (props.brand) {
    case 'phonicear':
      logoSrc = '/images/phonic-ear-logo.png';
      logoAlt = 'Phonic Ear Logo';
      logoTitle = 'Phonic Ear';
      break;
    case 'bernafon':
      logoSrc = '/images/bernafon-logo.png';
      logoAlt = 'Bernafon Logo';
      logoTitle = 'Bernafon';
      break;
    case 'horselbutik':
      logoSrc = '/images/horselbutik-logo.png';
      logoAlt = 'Horselbutik Logo';
      logoTitle = 'Horselbutik';
      break;
    default:
      logoSrc = 'https://cdn.dgs.com/ecommerce/oticon_130px.svg';
      logoAlt = 'Oticon Logo';
      logoTitle = 'Oticon People First';
  }
  return (
    <Link to="/" title={logoTitle} className="logo">
      <img src={logoSrc} alt={logoAlt} />
    </Link>
  );
};

export default PageHeaderLogo;