import React, { useContext } from "react";
import { RouterPaths, TranslationKeys } from "../../../infrastructure/const";
import TranslationsContext from "../../../infrastructure/context/translations/translations-context";
import ITranslationsContext from "../../../infrastructure/context/translations/translations-context.interface";
import TranslationManager from "../../../infrastructure/translation-manager";
import HyperlinkButton from "../../common/hyperlink-button";

const OrderConfirmationInner = (props: { orderNumber: string }): JSX.Element => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);

    const translations = {
        orderReceived: translationManager.getTranslation(TranslationKeys.ORDERCONFIRMATION_ORDERRECEIVED),
        orderNumberIs: translationManager.getTranslation(TranslationKeys.ORDERCONFIRMATION_ORDERNUMBER),
        emailWillBeSent: translationManager.getTranslation(TranslationKeys.ORDERCONFIRMATION_CONFIRMATIONEMAILWILLBESENT),
        clickToPrint: translationManager.getTranslation(TranslationKeys.ORDERCONFIRMATION_CLICKTOPRINT),
        copyOfConfirmation: translationManager.getTranslation(TranslationKeys.ORDERCONFIRMATION_COPYOFCONFIRMATION),
    };

    function getOrderPrintLink() {
        return RouterPaths.OrderPrint + "/" + props.orderNumber;
    }

    return (
        <div className="col-main">
            <div className="checkout-success">
                <div className="wr-success">
                    <div className="wrapper">
                        <h1>{translations.orderReceived}</h1>
                        <div className="order-confirmation-wrapper">
                            <p className="order-success">{translations.orderNumberIs}: <span className="order-number">{props.orderNumber}</span>.</p>
                            <p>{translations.emailWillBeSent}</p>
                            <p><HyperlinkButton href={getOrderPrintLink()} onClick={() => { window.open(getOrderPrintLink()); }}>{translations.clickToPrint} </HyperlinkButton>{translations.copyOfConfirmation}                                          </p>
                        </div>
                    </div>
                </div>
            </div><div className="adyen-boleto-download-pdf">
            </div>
        </div>
    );
};

export default OrderConfirmationInner;