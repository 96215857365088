import React, { useContext } from 'react';
import { GetHttpClientInstance } from '../../infrastructure/context/http/http-context-provider';
import { GetPaymentsMethodsResult, InitializePaymentRequest, PaymentResponse, UpdateDetailsRequest } from '../../infrastructure/http/modules/payments/payments.models';
import { Transaction } from '../../infrastructure/http/modules/cart/cart.models';
import UserContext from '../../infrastructure/context/user/user.context';
import Spinner from '../common/spinner';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import { Redirect } from 'react-router-dom';
import { RouterPaths, PaymentStatus, TranslationKeys } from '../../infrastructure/const';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';

type Props = {
    id: string,
    transaction: Transaction,
    urlQuery: string,
    correlationId: string,
    redirectToErrorPage: (() => void),
};

type PropsInner = Props & {
    userContext: IUserContext,
    configurationContext: IConfigurationContext,
    translationsContext: ITranslationsContext
};

type State = {
    isLoading: boolean,
    redirect: boolean,
    orderPlaced: boolean,
    orderNumber: string,
    hasError: boolean
};

const PaymentMethodSelection = (props: Props): JSX.Element => {
    const propsInner: PropsInner = { ...props } as PropsInner;

    propsInner.userContext = useContext<IUserContext>(UserContext);
    propsInner.configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    propsInner.translationsContext = useContext<ITranslationsContext>(TranslationsContext);

    return (
        <PaymentMethodSelectionInner {...propsInner} />
    );
};

class PaymentMethodSelectionInner extends React.Component<PropsInner, State> {
    private dropinComponent: any = null;
    private translations: { [key: string]: string };

    constructor(props: PropsInner) {
        super(props);
        const translationsManager: TranslationManager = new TranslationManager(props.translationsContext);

        this.state = {
            isLoading: true,
            redirect: false,
            orderPlaced: false,
            orderNumber: '',
            hasError: false
        };

        this.translations = {
            errorMessage: translationsManager.getTranslation(TranslationKeys.PAYMENT_ERRORMESSAGE),
            refusedMessage: translationsManager.getTranslation(TranslationKeys.PAYMENT_REFUSEDMESSAGE),
            canceledMessage: translationsManager.getTranslation(TranslationKeys.PAYMENT_CANCELEDMESSAGE),
            tryAgain: translationsManager.getTranslation(TranslationKeys.PAYMENT_TRYAGAIN)
        };

        this.onAdditionalDetails = this.onAdditionalDetails.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleBackendResponse = this.handleBackendResponse.bind(this);
        this.isTransactionSuccessStatus = this.isTransactionSuccessStatus.bind(this);
    }

    public componentDidMount(): void {
        // validate if transaction is already payed
        if (this.isTransactionSuccessStatus()) {
            this.setState({
                redirect: true,
                orderNumber: this.props.id,
                orderPlaced: true,
                hasError: false
            });
            return;
        }
        GetHttpClientInstance().payments.getStatus(this.props.id, this.props.correlationId).then((result: PaymentResponse) => {
            this.handlePaymentAttemptStatusResponse(result);
        });
    }

    public render() {
        const showSpinner = this.state.isLoading || this.dropinComponent === null;
        return (
            <>
                <Spinner show={showSpinner} />
                {this.renderRedirect()}
                <div
                    id="dropin-container"
                    style={{ display: showSpinner ? 'none' : 'block' }}
                ></div>
                {this.state.hasError &&
                    <div className="link-wrapper">
                        <a href={RouterPaths.CheckoutPage} className="continue-link" title={this.translations.tryAgain}>{this.translations.tryAgain}</a>
                    </div>
                }
            </>
        );
    }

    private setDropinLoadingState(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.dropinComponent !== null) {
                this.dropinComponent.setStatus('loading');
            }
            resolve();
        });
    }

    private setDropinReadyState(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.dropinComponent !== null) {
                this.dropinComponent.setStatus('ready');
            }
            resolve();
        });
    }

    private setDropinErrorState(paymentStatus: string): Promise<void> {
        return new Promise<void>((resolve) => {
            this.setState({ hasError: true });
            const errorText = this.getErrorText(paymentStatus);
            if (this.dropinComponent !== null) {
                this.dropinComponent.setStatus('error', { message: errorText });
            }
            resolve();
        });
    }

    private getErrorText(paymentStatus: string): string {
        if (paymentStatus === PaymentStatus.Cancelled) {
            return this.translations.canceledMessage;
        }
        if (paymentStatus === PaymentStatus.Refused) {
            return this.translations.refusedMessage;
        }

        return this.translations.errorMessage;
    }

    private isTransactionSuccessStatus(): boolean {
        return this.props.transaction.status === PaymentStatus.Succeeded || this.props.transaction.status === PaymentStatus.Pending || this.props.transaction.status === PaymentStatus.PartialSuccess;
    }

    private isPaymentErrorStatus(paymentStatus: string): boolean {
        return paymentStatus === PaymentStatus.Error || paymentStatus === PaymentStatus.Cancelled || paymentStatus === PaymentStatus.Refused;
    }

    private initializeDropinComponent(): Promise<void> {
        return new Promise<void>((resolve) => {
            // Get Payment methods
            this.getPaymentMethods().then((result: GetPaymentsMethodsResult) => {
                const configuration = {
                    paymentMethodsResponse: JSON.parse(result.paymentMethods),
                    // originKey: result.originKey,
                    clientKey: result.clientKey,
                    locale: result.culture,
                    environment: result.environment,
                    onSubmit: (state: any, dropin: any) => this.onSubmit(state.data),
                    onAdditionalDetails: (state: any, dropin: any) => {
                        this.onAdditionalDetails(state.data, "").then((result: PaymentResponse) => {
                            this.handleBackendResponse(result);
                            resolve();
                        });
                    },
                    paymentMethodsConfiguration: {
                        //   card: { // Example optional configuration for Cards
                        //     hasHolderName: true,
                        //     holderNameRequired: true,
                        //     enableStoreDetails: true,
                        //     hideCVC: false, // Change this to true to hide the CVC field for stored cards
                        //     name: 'Credit or debit card'
                        //   }
                    }
                };

                // @ts-ignore
                const checkout = new AdyenCheckout(configuration);
                const dropin = checkout.create('dropin').mount('#dropin-container');
                this.dropinComponent = dropin;
                this.setState({ isLoading: false }, () => {
                    resolve();
                });
            });
        });
    }

    private onAdditionalDetails = (detailsJSON: any, paymentData: any): Promise<PaymentResponse> => {
        return new Promise<PaymentResponse>((resolve) => {
            this.setDropinLoadingState().then(() => {
                const request: UpdateDetailsRequest = {
                    transactionId: this.props.id,
                    userId: this.props.userContext.user.userProfile.userId,
                    correlationId: this.props.correlationId,
                    details: JSON.stringify(detailsJSON),
                    paymentData: paymentData
                };

                GetHttpClientInstance().payments.updateDetails(request).then((result: PaymentResponse) => {
                    resolve(result);
                }).catch(() => this.props.redirectToErrorPage());
            });
        });
    };

    private onSubmit = (dataJSON: any): void => {
        this.setDropinLoadingState().then(() => {
            const request: InitializePaymentRequest = {
                data: JSON.stringify(dataJSON),
                userId: this.props.userContext.user.userProfile.userId,
                transactionId: this.props.id,
                correlationId: this.props.correlationId
            };

            GetHttpClientInstance().payments.initializePayment(request).then((response: PaymentResponse) => {
                this.handleBackendResponse(response);
            }).catch(() => {
                this.setDropinErrorState(PaymentStatus.Error);
            });
        });
    }

    private getPaymentMethods = (): Promise<GetPaymentsMethodsResult> => {
        return new Promise<GetPaymentsMethodsResult>((resolve) => {
            this.props.configurationContext.getPaymentMethods().then((result) => {
                resolve(result);
            }).catch(() => this.props.redirectToErrorPage());
        });

    }

    private handleBackendResponse = (response: PaymentResponse) => {
        if (this.isPaymentErrorStatus(response.status)) {
            this.setDropinErrorState(response.status);
            return;
        }
        if (response.action) {
            this.setDropinReadyState().then(() => {
                this.dropinComponent.handleAction(JSON.parse(response.action));
            });
        } else if (!response.userActionRequired) {
            this.setState({
                orderPlaced: response.orderPlaced,
                orderNumber: response.orderNumber,
                redirect: true
            }, () => {
                this.setDropinReadyState();
            });
        } else {
            this.setDropinReadyState().then(() => {
                this.setDropinErrorState(PaymentStatus.Error);
            });
        }
    }

    private handlePaymentAttemptStatusResponse = (response: PaymentResponse) => {
        if (response.orderPlaced && !this.isPaymentErrorStatus(response.status)) {
            this.setState({
                redirect: true,
                orderNumber: this.props.id,
                orderPlaced: true
            });
            return;
        }

        this.initializeDropinComponent().then(() => {
            setTimeout(() => {
                if (this.isPaymentErrorStatus(response.status)) {
                    this.setDropinErrorState(response.status);
                } else if (response.action) { //We do not expect any action after being redirected back to the shop
                    this.setDropinErrorState(PaymentStatus.Error);
                }
            }, 100);
        });
    }

    private renderRedirect = () => {
        if (this.state.redirect) {
            if (this.state.orderPlaced) {
                return <Redirect to={RouterPaths.OrderConfirmation + "/" + this.props.id} />;
            }

            return <Redirect to={RouterPaths.OrderFailed + "/" + this.props.id} />;
        }
    }
}

export default PaymentMethodSelection;