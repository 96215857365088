import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { RemoveProductRequest } from '../../infrastructure/http/modules/cart/cart.models';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import UserContext from '../../infrastructure/context/user/user.context';
import { GetHttpClientInstance } from '../../infrastructure/context/http/http-context-provider';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import { TranslationKeys } from '../../infrastructure/const';
import RemoveProductConfirmationPopup from './remove-product-confirmation-popup';

type RemoveFromCartButtonProps = {
    itemNumber: string,
    displayAsLink: boolean,
    additionalClass: string
};

const RemoveFromCartButton = (props: RemoveFromCartButtonProps) => {
    const context = useContext<IUserContext>(UserContext);
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);

    const [displayPopup, setDisplayPopup] = useState(false);

    const translations = {
        remove: translationManager.getTranslation(TranslationKeys.REMOVE),
    };

    function displayConfirmationMessage() {
        context.setRemoveFromCartPopupVisibility(true).then(() => {
            setDisplayPopup(true);
        });
    }

    function handleCancelClick() {
        context.setRemoveFromCartPopupVisibility(false).then(() => {
            setDisplayPopup(false);
        });
    }

    function handleConfirmClick() {
        context.setCartUpdateInProgress().then(() => {
            context.setRemoveFromCartPopupVisibility(false).then(() => {
                setDisplayPopup(false);
                const request: RemoveProductRequest = {
                    userId: context.user.userProfile!.userId,
                    itemNumber: props.itemNumber
                };
                GetHttpClientInstance().cart.removeProduct(request).then((result) => {
                    context.refreshCart();
                });
            });
        });
    }

    return (
        <>

            <RemoveProductConfirmationPopup
                onCancelClick={handleCancelClick}
                onConfirmClick={handleConfirmClick}
                isOpen={displayPopup}
            />

            { props.displayAsLink ?
                (
                    <Link to="#" className={"btn-remove " + props.additionalClass} title={translations.remove} onClick={displayConfirmationMessage}>({translations.remove})</Link>
                ) : (
                    <Link to="#" className={"btn-remove " + props.additionalClass} title={translations.remove} onClick={handleConfirmClick}></Link>
                )
            }
        </>
    )
};

RemoveFromCartButton.defaultProps = {
    displayAsLink: false,
    additionalClass: ""
} as RemoveFromCartButtonProps;

export default RemoveFromCartButton;