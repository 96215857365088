import React, { useContext } from 'react';
import Linkify from 'react-linkify';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import CheckUtils from '../../utilities/check-utils';
import ProductDetailsMarketingDescription from './product-details-marketing-description';

type Props = {
    subtitle: string,
    marketingShortDescription: string,
    marketingDescription: string
}

const SideDescriptionSection = (props: Props): JSX.Element => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);

    if (configurationContext.isFeatureFlagEnabled("useEnrichedProductData")) {
        return (
            <div className="description" data-role="conf-main-descr-long">
                <Linkify>
                    <div className="std">
                        {!CheckUtils.isNullOrEmptyString(props.marketingShortDescription) &&
                            <>
                                <ProductDetailsMarketingDescription
                                    description={props.marketingShortDescription}
                                    useMetatagInterpreter={false} />
                                <br />
                            </>
                        }
                        <ProductDetailsMarketingDescription
                            description={props.subtitle}
                            useMetatagInterpreter={false} />
                    </div>
                </Linkify>
            </div>
        )
    }

    return (
        <div className="description" data-role="conf-main-descr-long">
            <Linkify>
                <div className="std">
                    <ProductDetailsMarketingDescription
                        description={props.marketingDescription}
                        useMetatagInterpreter={true} />

                    {!CheckUtils.isNullOrEmptyString(props.marketingShortDescription) &&
                        <>
                            <br />
                            <ProductDetailsMarketingDescription description={props.marketingShortDescription} useMetatagInterpreter={true} />
                        </>
                    }
                    <br />
                    <ProductDetailsMarketingDescription description={props.subtitle} useMetatagInterpreter={true} />
                </div>
            </Linkify>
        </div>
    );
};

export default SideDescriptionSection;