import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';

const LanguageMetatags = () => {
    const configuration = useContext<IConfigurationContext>(ConfigurationContext);
    return (
        <Helmet>
            <meta http-equiv="content-language" content={configuration.cultureName}></meta>
            <link rel="alternate" href={window.location.href} hrefLang={configuration.cultureName} />
        </Helmet>
    );
};

export default LanguageMetatags;