import { IHttp } from '../../http.interface';
import { ModuleBase } from '../../common/module.base';
import { ICartModule } from './cart.interface';
import { Cart, AddProductRequest, RemoveProductRequest, UpdateCartRequest, UpdateProductRequest, UpdateCartPropertiesRequest, TransactionResponse, InitializeKlarnaResponse, KlarnaTransactionConfirmResponse, InitializeTransactionResponse, InitializeNetsResponse } from './cart.models';

export class CartModule extends ModuleBase implements ICartModule {

    constructor(private http: IHttp) {
        super('Cart');
    }

    get(userId: string): Promise<Cart> {
        return new Promise<Cart>((resolve, reject) => {
            let queryUrl = `userId=${userId}`;
            this.http.get<Cart>(`/api/cart/get?${queryUrl}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                });
        });
    }

    addProduct(request: AddProductRequest): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.http.post<boolean>(`/api/cart/add`, request)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                });
        });
    }

    removeProduct(request: RemoveProductRequest): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.http.post<boolean>(`/api/cart/remove`, request)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                });
        });
    }

    updateProduct(request: UpdateProductRequest): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.http.post<boolean>(`/api/cart/updateProduct`, request)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                });
        });
    }

    update(request: UpdateCartRequest): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.http.post<boolean>(`/api/cart/update`, request)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                });
        });
    }

    updateProperties(request: UpdateCartPropertiesRequest): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.http.post<boolean>(`/api/cart/update-properties`, request)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    initializeTransaction(request: UpdateCartRequest): Promise<InitializeTransactionResponse> {
        return new Promise<InitializeTransactionResponse>((resolve, reject) => {
            this.http.post<InitializeTransactionResponse>(`/api/cart/initialize-transaction`, request)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                }).catch(() => reject());
        });
    }

    getTransaction(transactionId: string, userId: string, correlationId: string): Promise<TransactionResponse> {
        return new Promise<TransactionResponse>((resolve, reject) => {
            let queryUrl = `transactionId=${transactionId}&userId=${userId}&correlationId=${correlationId}`;
            this.http.get<TransactionResponse>(`/api/cart/get-transaction?${queryUrl}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }

    override(previousUserId: string, userId: string): Promise<Cart> {
        return new Promise<Cart>((resolve, reject) => {
            let queryUrl = `previousUserId=${previousUserId}&userId=${userId}`;
            this.http.get<Cart>(`/api/cart/override-cart?${queryUrl}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                });
        });
    }

    initializeKlarna(userId: string): Promise<InitializeKlarnaResponse> {
        return new Promise<InitializeKlarnaResponse>((resolve, reject) => {
            let queryUrl = `userId=${userId}`;
            this.http.get<InitializeKlarnaResponse>(`/api/klarna?${queryUrl}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }

    getKlarnaOrder(transactionId: string, userId: string, correlationId: string, customErrorHandling?: (error: any) => void): Promise<KlarnaTransactionConfirmResponse> {
        return new Promise<KlarnaTransactionConfirmResponse>((resolve, reject) => {
            let queryUrl = `userId=${userId}&transactionId=${transactionId}&correlationId=${correlationId}`;
            this.http.get<KlarnaTransactionConfirmResponse>(`/api/klarna/order?${queryUrl}`, customErrorHandling)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }

    initializeNets(userId: string): Promise<InitializeNetsResponse> {
        return new Promise<InitializeNetsResponse>((resolve, reject) => {
            let queryUrl = `userId=${userId}`;
            this.http.get<InitializeNetsResponse>(`/api/nets?${queryUrl}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                        // let response = this.mapSearchResponse(pageDetails, result);
                        // resolve(response);
                    } else {
                        reject();
                        // reject(ERRPROD001);
                    }
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }
}