import React, { useContext } from 'react';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import { TranslationKeys } from '../../../infrastructure/const';
import TranslationManager from '../../../infrastructure/translation-manager';
import CheckUtils from '../../../utilities/check-utils';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';

type Props = {
    id: string,
    imageUrl: string,
    name: string,
    description: string,
    colorName: string,
    itemNumber: string,
    total: string
};

const PaymentSummaryProduct = (props: Props): JSX.Element => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);
    const translations: { [id: string]: string } = {
        color: translationManager.getTranslation(TranslationKeys.PRODUCT_COLOR),
        itemNumber: translationManager.getTranslation(TranslationKeys.PRODUCT_NUMBER),
    };

    return (
        <tr key={props.id}>
            <td className="product-image">
                <img src={props.imageUrl} width="70" height="70" alt={props.name} />
            </td>
            <td className="product-info">
                <div className="product-name has-options">
                    {props.name}
                    {!configurationContext.isFeatureFlagEnabled("hideItemDescriptionInCart") &&
                        <span className="short-description">{props.description}</span>
                    }
                </div>
                <ul className="item-options">
                    {!CheckUtils.isNullOrEmptyString(props.colorName) &&
                        <li>{translations.color}: <span>{props.colorName}</span></li>
                    }
                </ul>
                <ul className="item-options">
                    <li className="item-number">{translations.itemNumber}: <span>{props.itemNumber}</span></li>
                </ul>
            </td>
            <td className="product-price">
                <div className="product-detailing">
                    <span className="cart-price">
                        <span className="price">{props.total}</span>
                    </span>
                </div>
            </td>
        </tr>
    );
};

export default PaymentSummaryProduct;