import React from 'react';
import ReactHtmlParser from 'react-html-parser';

type PageHeaderNotificationBarProps = {
    handleClick: (() => void);
    text: string;
};

const PageHeaderNotificationBar: React.FC<PageHeaderNotificationBarProps> = (props: PageHeaderNotificationBarProps) => {

    return (
        <div className="header-notification-bar">
            {ReactHtmlParser(props.text)}
            <button
                className="header-notification-bar__close-btn"
                type="button"
                onClick={props.handleClick}
            ></button>
        </div>
    );
};

export default PageHeaderNotificationBar;