import React, { useContext } from "react";
import TextComponent from "./text-component";
import TextImageComponent from "./text-image-component";
import CheckUtils from "../../utilities/check-utils";
import {
  AvailableNestedComponents,
  TextImageSpotComponent,
  TextImageSpotType,
} from "../../infrastructure/http/modules/static-content/static-content.models";
import PartnersSpot from "../landing-page/partners-spot";
import StaticContentContext from "../../infrastructure/context/static-content/static-content.context";
import groupTextImageSpotsByTypeFunction from "./helpers/groupSpotsByTypeFunction";
import NestedComponent from "../common/nested-component";
import IStaticContentContext from "../../infrastructure/context/static-content/static-content.context.interface";

const TextImageSpots = (): JSX.Element | null => {
  const context = useContext<IStaticContentContext>(StaticContentContext);

  if (CheckUtils.isNullOrEmptyArray(context.imageTextSpots)) {
    return null;
  }

  const availableNestedComponents: AvailableNestedComponents = {
    partnersSpot: {
      component: PartnersSpot,
      props: { partners: context.partners },
    },
  };

  let components: TextImageSpotComponent[] = groupTextImageSpotsByTypeFunction(
    context.imageTextSpots
  );

  return (
    <>
      {components.map((component: TextImageSpotComponent, index: number) => {
        switch (component.Type) {
          case TextImageSpotType.NestedComponent:
            return (
              <NestedComponent
                nestedComponentName={component.Items[0].nestedComponentName}
                key={`text-image-nested-component-${index}`}
                availableComponentTypes={availableNestedComponents}
              />
            );
          case TextImageSpotType.Image:
            return (
              <div
                className="wrapper components-group-wrapper"
                key={`text-image-component-${index}`}
              >
                {component.Items.map((item) => (
                  <TextImageComponent
                    key={item.header}
                    imgSrc={item.image}
                    headingText={item.header}
                    descriptionText={item.text}
                    buttonText={item.buttonText}
                    buttonUrl={item.buttonUrl}
                    fullWidth={component.Items.length === 1}
                  />
                ))}
              </div>
            );
          default:
            return (
              <div key={`text-image-component-${index}`}>
                {component.Items.map((item) => (
                  <TextComponent
                    key={item.header}
                    headingText={item.header}
                    descriptionText={item.text}
                    buttonText={item.buttonText}
                    buttonUrl={item.buttonUrl}
                  />
                ))}
              </div>
            );
        }
      })}
    </>
  );
};

export default TextImageSpots;
