import { IntroBanner } from "../customer-service/customer-service.models";

export type MaterialSpot = {
    title: string,
    subtitle: string,
    link: string,
    linkDescription: string
};

export type FooterColumn = {
    title: string,
    subtitle: string,
    links: FooterLink[]
};

export type FooterLink = {
    title: string,
    url: string
};

export type ProductCategoryHeader = {
    title: string,
    subtitle: string,
    route: string,
    image: string,
    additionalLines: string[],
    longDescriptionHTML: string
};

export type StaticContentContainer = {
    materials : MaterialSpot[],
    categories: ProductCategoryHeader[],
    footer: FooterContainer,
    introBanner: IntroBanner,
    introBannerList: IntroBanner[],
    imageTextSpots: TextImageSpot[],
    sellingPointsBar: SellingPointsBar,
    seo: Seo,
    partners: Partners
 };

 export type FooterContainer = {
    columns: FooterColumn[],
    links: FooterLink[],
    newsletter: boolean
 };

 export type TextImageSpot = {
    buttonText: string,
    buttonUrl: string,
    header: string,
    image: string,
    text: string,
    nestedComponentName: string
 };


 export type SellingPointsBar = {
     text: string;
     phoneNumber: string;
     contactHours: string;
     sellingPoints: string[];
 }

 export type Seo = {
    faviconIcon: string,
    defaultTitle: string,
    defaultDescription: string,
    defaultKeywords: string,
    pages: Array<SeoPage>,
    imageUrl: string
 }

 export type SeoPage = {
    route: string,
    title: string,
    description: string,
    keywords: string,
    imageUrl: string
 }

 export type Partners = {
     text: string,
     images: Array<string>
 }

 export enum TextImageSpotType {
    Text = 0,
    Image = 1,
    NestedComponent = 2,
}

export type TextImageSpotComponent = {
    Type: TextImageSpotType;
    Items: TextImageSpot[];
};

export type AvailableNestedComponents = {
    [key: string]: {
        component: (props: any) => JSX.Element | null,
        props?: any
    }
}