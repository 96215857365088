import { TranslationKeys } from "../infrastructure/const";
import ITranslationsContext from "../infrastructure/context/translations/translations-context.interface";
import TranslationManager from "../infrastructure/translation-manager";

export default class OrderUtils {

    static getOrderStatusTranslation(translationContext: ITranslationsContext, orderStatus: string) {
        const translationManager = new TranslationManager(translationContext);
        const translations = {
            statusUnderProcessing: translationManager.getTranslation(TranslationKeys.STATUS_UNDERPROCESSING),
            statusOrderShipped: translationManager.getTranslation(TranslationKeys.STATUS_ORDERSHIPPED)
        };


        if (orderStatus === OrderStatusKeys.Complete || orderStatus === OrderStatusKeys.CompleteWithTracking) {
            return translations.statusOrderShipped;
        }

        return translations.statusUnderProcessing;
    }
}

const OrderStatusKeys = {
    Open: "Open",
    Complete: "Complete",
    CompleteWithTracking: "CompleteWithTracking"
}