import React from 'react';
import StringUtils from '../../utilities/string-utils';
import MultiLineText from '../common/new-line-text';

type TextComponentProps = {
    headingText: string;
    descriptionText: string;
    buttonText?: string;
    buttonUrl?: string;
};

const TextComponent: React.FC<TextComponentProps> = (props: TextComponentProps) => {
    const descriptionWithEmail = StringUtils.convertEmailInTextToLink(props.descriptionText);
    const descriptionWithEmailAndPhones = StringUtils.convertPhoneNumberInTextToLink(descriptionWithEmail);

    return (
        <div className="text-component">
            <h2 className="text-component__heading">
                {props.headingText}
            </h2>
            <div className="text-component__text">
                <MultiLineText text={descriptionWithEmailAndPhones} />
            </div>
            {props.buttonText && <button className="button" onClick={(e) => { window.open(props.buttonUrl); }}>{props.buttonText}</button>}
        </div>
    );
};

export default TextComponent;