import React from 'react';
import { Partners } from '../../infrastructure/http/modules/static-content/static-content.models';
import CheckUtils from '../../utilities/check-utils';

interface Props {
    partners: Partners
}

const PartnersSpot = (props: Props): JSX.Element | null => {
    if (CheckUtils.isNullObject(props.partners) || CheckUtils.isNullOrEmptyArray(props.partners.images)) {
        return null;
    };

    return (
        <div className="partners-spot">
            {!CheckUtils.isNullOrEmptyString(props.partners.text) &&
                <h2 className="partners-spot__heading">
                    {props.partners.text}
                </h2>
            }
            <div>
                {props.partners.images.map((imageUrl) =>
                    <img key={imageUrl} src={imageUrl} alt={imageUrl} />
                )}
            </div>
        </div>
    );
};

export default PartnersSpot;