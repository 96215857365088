import React from 'react';
import { DefaultFeatureFlags, FeatureFlags } from '../../const';
import IConfigurationContext from './configuration.context.interface';
import { ShippingType } from '../../http/modules/configuration/configuration.models';
import { GetPaymentsMethodsResult } from '../../http/modules/payments/payments.models';

const defaultContext: IConfigurationContext = {
    featureFlags: DefaultFeatureFlags,
    isFeatureFlagEnabled: ((flag: keyof FeatureFlags) => false),
    getFeatureFlagValue: ((flag: keyof FeatureFlags) => null),
    supportedCountries : new Map<string, string>(),
    shippingTypes : new Array<ShippingType>(),
    getPaymentMethods: (() => new Promise<GetPaymentsMethodsResult>((reject) => reject({} as GetPaymentsMethodsResult))),
    cookieBannerCulture: '',
    brand: null,
    displaySellingPointsBar: false,
    paymentProvider: 'adyen',
    cultureName: 'en',
    recommendedProducts: [],
    cartRecommendedProducts: []
};

const ConfigurationContext = React.createContext<IConfigurationContext>(defaultContext);
export default ConfigurationContext;
