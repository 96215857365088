import * as React from 'react';
import CheckUtils from '../../../utilities/check-utils';
import { GetHttpClientInstance } from '../http/http-context-provider';
import { ProductCategory, ProductCategories, ProductSubcategory } from '../../http/modules/product/product.models';
import INavigationContext from './navigation.context.interface';
import NavigationContext from './navigation.context';
import { withRouter, RouteComponentProps } from 'react-router';
import Spinner from '../../../components/common/spinner';

type ProviderState = {
    categories: ProductCategory[],
    isLoaded: boolean,
    activeCategory: ProductCategory[] | null,
    previousPath: string,
    currentPath: string
};

class NavigationProvider extends React.Component<RouteComponentProps, ProviderState> {

    constructor(props: RouteComponentProps) {
        super(props);

        this.state = {
            categories: [],
            isLoaded: false,
            activeCategory: null,
            previousPath: "",
            currentPath: props.location.pathname
        };

        GetHttpClientInstance().product.categories().then((result: ProductCategories) => {
            if (!CheckUtils.isNullOrEmptyString(result.errorMessage)) {
                console.error(result.errorMessage);
                return;
            }
            this.setState({ categories: result.categories }, () => {
                this.getActiveCategory();
            });
        });

        this.getCategoryUrl = this.getCategoryUrl.bind(this);
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                previousPath: prevProps.location.pathname,
                currentPath: this.props.location.pathname
            });
            this.getActiveCategory();
        }
    }

    getActiveCategory(): void {
        if (CheckUtils.isNullOrEmptyArray(this.state.categories)) {
            this.setState({ activeCategory: null });
            return;
        }

        let activeCategory = this.state.categories.find(x => this.props.location.pathname.indexOf(x.url) > -1);
        let activeCategoryList: ProductCategory[] = [];

        if (CheckUtils.isNullObject(activeCategory)) {
            this.setState({ activeCategory: null });
            return;
        }

        activeCategoryList.push(activeCategory!);

        const subcategoryRoute = this.props.location.pathname.replace(activeCategory!.url, "");
        let activeSubcategory = activeCategory!.subcategories
            .filter(x => !CheckUtils.isNullOrEmptyString(x.url) && x.url !== "/")
            .find(x => subcategoryRoute.indexOf(x.url) > -1);
            
        if (activeSubcategory) {
            activeCategoryList.push(activeSubcategory as ProductCategory);
        }

        this.setState({ activeCategory: activeCategoryList });
        return;

    }

    // Get category URL based on partial ID
    getCategoryUrl(partialId: number): string {
        if (CheckUtils.isNullObject(partialId)) {
            return "";
        }
        const activeCategory: ProductCategory | undefined = this.state.categories.find(x => x.partialIds.some(id => id === partialId));
        return CheckUtils.isNullObject(activeCategory) ? "" : activeCategory!.url;
    }

    render() {
        const contextValue: INavigationContext = {
            activeCategory: this.state.activeCategory,
            categories: this.state.categories,
            getCategoryUrl: this.getCategoryUrl,
            previousPath: this.state.previousPath,
            currentPath: this.state.currentPath
        };
        if (CheckUtils.isNullOrEmptyArray(this.state.categories)) {
            return <Spinner show={true} default />;
        }
        return (
            <NavigationContext.Provider
                value={contextValue}
            >
                {this.props.children}
            </NavigationContext.Provider>
        );
    }
}

export default withRouter(NavigationProvider);