import React, { useContext } from 'react';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import { TranslationKeys } from '../../../infrastructure/const';
import TranslationManager from '../../../infrastructure/translation-manager';

type Props = {
    setIsOpen: (() => void)
    productsCount: number
};

const PaymentSummaryHeader = (props: Props): JSX.Element => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);
    const translations: { [id: string]: string } = {
        orderSummary: translationManager.getTranslation(TranslationKeys.CART_ORDERSUMMARY_HEADER),
        items: translationManager.getTranslation(TranslationKeys.CART_ITEMS),
        cart: translationManager.getTranslation(TranslationKeys.CART_HEADER),
    };
    return (
        <div>
            <div className="payments-label--h2">
                {translations.orderSummary}
            </div>
            <div className="payments-label--p-bold">
                {translations.cart} ({props.productsCount} {translations.items})
            <button className="payments-list-toggle" onClick={() => props.setIsOpen()}></button>
            </div>
        </div>
    );
};

export default PaymentSummaryHeader;