import React, { ReactNode } from 'react';
import UserContext from '../infrastructure/context/user/user.context';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import { Order } from '../infrastructure/http/modules/orders/orders.models';
import CheckUtils from '../utilities/check-utils';
import OrderPrint from './order-print/order-print';
import PageHeaderLogo from './page-header/page-header-logo';
import { RouteComponentProps, Redirect } from 'react-router';
import { RouterPaths } from '../infrastructure/const';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';

type OrderPrintPageProps = {
    orderNumber: string;
};

type OrderPrintPageState = {
    order: Order | null;
    redirect: boolean;
};

export default class OrderPrintPage extends React.Component<RouteComponentProps<OrderPrintPageProps>, OrderPrintPageState> {
    private orderNumber: string;
    private currentContext: IUserContext;
    private userId: string | null;

    constructor(props: RouteComponentProps<OrderPrintPageProps>, context: IUserContext) {
        super(props, context);
        this.currentContext = context;
        this.userId = this.getUserIdFromContext(context);
        this.orderNumber = this.props.match.params.orderNumber;

        this.state = {
            order: null,
            redirect: false
        };
    }

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate() {
        if (this.currentContext.user !== this.context.user && this.userId !== this.getUserIdFromContext(this.context)) {
            this.userId = this.getUserIdFromContext(this.context);
            this.initialize();
        }
    }

    getUserIdFromContext(context: IUserContext) {
        if (CheckUtils.isNullObject(context) || CheckUtils.isNullObject(context.user) || CheckUtils.isNullObject(context.user.userProfile)) {
            return null;
        }

        return context.user.userProfile.userId;
    }

    initialize() {
        if (CheckUtils.isNullObject(this.userId) || CheckUtils.isNullObject(this.orderNumber)) {
            return;
        }

        GetHttpClientInstance().orders.get(this.orderNumber, this.userId!).then((order: Order) => {
            this.setState({
                order,
                redirect: CheckUtils.isNullObject(order)
            });
        }).catch(() => {
            this.setState({ redirect: true });
        });
    }

    render(): ReactNode {
        const { redirect, order } = this.state;

        if (redirect) {
            return <Redirect push to={RouterPaths.LandingPage} />;
        }

        if (CheckUtils.isNullObject(order)) {
            return null; // TODO: return loader
        }

        return (
            <div className="page-print">
                <div>
                    <div className="print-head">
                        <ConfigurationContext.Consumer>
                            {(context: IConfigurationContext) => (
                                <PageHeaderLogo
                                    brand={context.brand!}
                                />
                            )}
                        </ConfigurationContext.Consumer>
                    </div>
                    <OrderPrint order={this.state.order!} />
                </div>
            </div>
        );
    }
}

OrderPrintPage.contextType = UserContext;