import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { RouterPaths } from '../../infrastructure/const';
import { TranslationKeys } from '../../infrastructure/const';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import IUserContext, { IUserProfile } from '../../infrastructure/context/user/user.context.interface';
import TranslationManager from '../../infrastructure/translation-manager';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import UserContext from '../../infrastructure/context/user/user.context';

const PageHeaderLoginButton: React.FC = () => {
  const translationsContext: ITranslationsContext = useContext<ITranslationsContext>(TranslationsContext);
  const translationManager: TranslationManager = new TranslationManager(translationsContext);

  const userContext: IUserContext = useContext<IUserContext>(UserContext);
  const userProfile: IUserProfile = userContext.user.userProfile;

  return (
    <ul className="links">
      <li className="first last log-in deskstop-login">
        {userProfile && userProfile.isAuthenticated &&
          <Link to={RouterPaths.AccountDashboard} title={userProfile.email} className='logged-in'>{userProfile.firstName} {userProfile.lastName}</Link>
        }
        {userProfile && !userProfile.isAuthenticated &&
          <Link to={RouterPaths.Login} title="Log In">{translationManager.getTranslation(TranslationKeys.HEADER_LOGIN_BUTTON)}</Link>
        }
      </li>
    </ul>
  );
};

export default PageHeaderLoginButton;