import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import NavigationContext from '../../../infrastructure/context/navigation/navigation.context';
import IStaticContentContext from '../../../infrastructure/context/static-content/static-content.context.interface';
import StaticContentContext from '../../../infrastructure/context/static-content/static-content.context';
import CheckUtils from '../../../utilities/check-utils';

const LandingPageSplashBanner: React.FC = () => {
    const configurationContext = useContext<IStaticContentContext>(StaticContentContext);
    const banner = configurationContext.introBanner;

    if (CheckUtils.isNullObject(banner)) {
        return null;
    }

    return (
        <div className="category-top">
            <div className="wrapper">
                <div className="category-description">
                    <div className="category-description__img">&nbsp;</div>
                    <div className="category-description__title home-banner-section">
                        <h2>{banner.title}</h2>
                        <div>{ReactHtmlParser(banner.description)}</div>
                        {
                            banner.buttons.map((button, index) =>
                                <NavigationContext.Consumer>
                                    {(categoryContext) => (
                                        <Link to={button.url} key={index} target="_self">
                                            <button className="button">{button.title}</button>
                                        </Link>
                                    )}
                                </NavigationContext.Consumer>
                            )
                        }
                    </div>
                    <div className="category-description__title">&nbsp;<img alt="Oticon Connectline" src={banner.imageUrl} /></div>
                </div>
            </div>
        </div>
    );
};

export default LandingPageSplashBanner;