import * as React from 'react';
import INavigationContext from './navigation.context.interface';

const defaultContext: INavigationContext = {
    categories: [],
    activeCategory: null,
    getCategoryUrl: ((partialId: number) => ""),
    previousPath: "",
    currentPath: ""
};

const NavigationContext = React.createContext<INavigationContext>(defaultContext);
export default NavigationContext;