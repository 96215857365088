import { RestError, RestErrorSingleErrorEntry } from "../infrastructure/http/modules/errors";

class ErrorCodesExtractor {
    public static extract(error: RestError): string[] {
        const { data } = error;

        let errorKeys: string[] = [];

        if (data.errorCode) {
            errorKeys.push(data.errorCode);
        }

        if (data.errors) {
            const errorCodes = data.errors.map((e: RestErrorSingleErrorEntry) => e.errorCode);
            errorKeys = errorKeys.concat(errorCodes);
        }

        return errorKeys;
    }
}

export default ErrorCodesExtractor;
