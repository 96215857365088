import React, { useContext } from 'react';
import { TranslationKeys } from '../../../infrastructure/const';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import CheckUtils from '../../../utilities/check-utils';

type Props = {
    paymentMethod: string;
    poNumber: string;
};

const SelectedPaymentMethod = (props: Props): JSX.Element => {
    let translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);

    if (CheckUtils.isNullOrEmptyString(props.paymentMethod) && !CheckUtils.isNullOrEmptyString(props.poNumber)) {
        return <>{translationManager.getTranslation(TranslationKeys.DK.EAN_NUMBER_LABEL)}</>;
    }

    const paymentMethodTranslation = translationManager.getTranslation(props.paymentMethod);

    return (
        <>{paymentMethodTranslation}</>
    )
};

export default SelectedPaymentMethod;