import { MarketingDescriptionMetatags, MarketingDescriptionParagraphMetatag } from "../infrastructure/const";
import CheckUtils from "./check-utils";

export default class MetatagInterpreter {
    static convertToRegex(metatag: string): RegExp {
        return new RegExp(metatag.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), 'g');
    }

    static convertParagraphsToNewLines(html: string): string {
        if(CheckUtils.isNullOrEmptyString(html)){
            return "";
        }
        html = html.replace(this.convertToRegex(MarketingDescriptionParagraphMetatag.start), "");
        html = html.replace(this.convertToRegex(MarketingDescriptionParagraphMetatag.end), "\n");

        return html;
    }

    static convertToHtml(html: string): string {
        if(CheckUtils.isNullOrEmptyString(html)){
            return "";
        }
        Object.entries(MarketingDescriptionMetatags).forEach(([key, value]) => {
            let re = MetatagInterpreter.convertToRegex(key);
            html = html.replace(re, value);
        });
        return html;
    }

    static removeMarketingMetatags(html: string): string {
        if(CheckUtils.isNullOrEmptyString(html)){
            return "";
        }
        Object.entries(MarketingDescriptionMetatags).forEach(([key, value]) => {
            let re = MetatagInterpreter.convertToRegex(key);
            html = html.replace(re, "");
        });
        return html;
    }
}