import React from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import { TranslationKeys, RouterStateParams, RouterPaths } from '../../../infrastructure/const';
import Input from '../../common/input';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import IUserContext from '../../../infrastructure/context/user/user.context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import { UserProfileResponse } from '../../../infrastructure/http/modules/auth/auth.models';
import { GetHttpClientInstance } from '../../../infrastructure/context/http/http-context-provider';
import { AddEditAddressRequest } from '../../../infrastructure/http/modules/account/account.models';
import { RestError } from '../../../infrastructure/http/modules/errors';
import Select, { SelectOption } from '../../common/select';
import CheckUtils from '../../../utilities/check-utils';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';

interface Props extends RouteComponentProps {
    translationsContext: ITranslationsContext;
    userContext: IUserContext;
    configurationContext: IConfigurationContext;
}

type State = {
    isAdd: boolean;

    addressId: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    company?: string;
    telephone: string;
    fax?: string;

    streetAddress1: string;
    streetAddress2?: string;
    city: string;
    postalCode: string;
    country: string;
    isDefaultShippingAddress: boolean;
    isDefaultBillingAddress: boolean;

    [key: string]: any;
    errors: { [key: string]: boolean };

    navigateToAddressBook: boolean;
    navigateToLogin: boolean;
    isLoaded: boolean;
};

type StateRequiredPropsType = Pick<State, 'firstName' | 'lastName' | 'telephone' | 'streetAddress1' | 'city' | 'postalCode'>;
const StateRequiredProps: StateRequiredPropsType = { firstName: '', lastName: '', telephone: '', streetAddress1: '', city: '', postalCode: '' };

class AddressAddEditForm extends React.Component<Props, State> {
    private supportedCountries: Map<string, string>;
    constructor(props: Props, private translations: { [key: string]: string }) {
        super(props);
        this.supportedCountries = CheckUtils.isNullObject(props.configurationContext.supportedCountries) ? new Map() : props.configurationContext.supportedCountries;

        this.state = {
            isAdd: true,
            addressId: '',
            firstName: '',
            lastName: '',
            telephone: '',
            streetAddress1: '',
            city: '',
            postalCode: '',
            country: Object.keys(this.supportedCountries).length > 0 ? Object.keys(this.supportedCountries)[0] : "",
            isDefaultBillingAddress: false,
            isDefaultShippingAddress: false,
            errors: {},
            navigateToAddressBook: false,
            navigateToLogin: false,
            isLoaded: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public componentDidMount(): void {
        const translationManager = new TranslationManager(this.props.translationsContext);

        this.translations = {
            addAddress: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_ADDADDRESS),
            editAddress: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_EDITADDRESS),
            firstName: translationManager.getTranslation(TranslationKeys.FIRST_NAME),
            middleName: translationManager.getTranslation(TranslationKeys.MIDDLE_NAME),
            lastName: translationManager.getTranslation(TranslationKeys.LAST_NAME),
            company: translationManager.getTranslation(TranslationKeys.COMPANY),
            telephone: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_TELEPHONE),
            fax: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_FAX),
            address: translationManager.getTranslation(TranslationKeys.ADDRESS),
            streetAddress1: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_STREETADDRESS),
            streetAddress2: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_STREETADDRESS2),
            city: translationManager.getTranslation(TranslationKeys.CITY),
            postalCode: translationManager.getTranslation(TranslationKeys.POSTALCODE),
            country: translationManager.getTranslation(TranslationKeys.COUNTRY),
            save: translationManager.getTranslation(TranslationKeys.ACCOUNT_SAVE),
        };

        let editAddressId = null;
        if (this.props.location.state) {
            const state = this.props.location.state as { [key: string]: any };
            if (state[RouterStateParams.EditAddressId]) {
                editAddressId = state[RouterStateParams.EditAddressId];
            }
        }

        if (editAddressId) {
            this.setState({ isAdd: false });
            this.initializeAddressForEdit(editAddressId);
            this.props.history.replace({ state: undefined });
        } else {
            const { userProfile } = this.props.userContext.user;
            if (userProfile) {
                this.setState({
                    firstName: userProfile.firstName!,
                    middleName: userProfile.middleName!,
                    lastName: userProfile.lastName!,
                });
            }
        }

        this.setState({ isLoaded: true });
    }

    public initializeAddressForEdit(addressId: string) {
        const address = this.props.userContext.user.userProfile.addressBook.find(x => x.id === addressId);

        if (address) {
            this.setState({
                addressId: address.id,
                firstName: address.firstName,
                middleName: address.middleName,
                lastName: address.lastName,
                company: address.company,
                telephone: address.telephone,
                fax: address.fax,
                streetAddress1: address.streetAddress1,
                streetAddress2: address.streetAddress2,
                city: address.city,
                postalCode: address.postalCode,
                country: address.country,
            });
        }
    }

    public render(): React.ReactNode {
        if (!this.state.isLoaded) {
            return null;
        }

        return (
            <form onSubmit={this.handleSubmit}>
                <h2>{this.state.isAdd ? this.translations.addAddress : this.translations.editAddress}</h2>
                <div className='fieldset'>
                    <ul className='form-list clearfix'>
                        {this.renderInput(this.translations.firstName, 'firstName', this.state.firstName, true, this.state.errors.firstName)}
                        {!this.props.configurationContext.isFeatureFlagEnabled("hideCustomerMiddleName") &&
                            this.renderInput(this.translations.middleName, 'middleName', this.state.middleName, false, this.state.errors.middleName)
                        }
                        {this.renderInput(this.translations.lastName, 'lastName', this.state.lastName, true, this.state.errors.lastName)}
                        {this.renderInput(this.translations.company, 'company', this.state.company, false, this.state.errors.company)}
                        {this.renderInput(this.translations.telephone, 'telephone', this.state.telephone, true, this.state.errors.telephone)}
                        {this.renderInput(this.translations.fax, 'fax', this.state.fax, false, this.state.errors.fax)}
                    </ul>
                </div>
                <div className='fieldset'>
                    <h2 className='legend'>{this.translations.address}</h2>
                    <ul className='form-list clearfix'>
                        {this.renderInput(this.translations.streetAddress1, 'streetAddress1', this.state.streetAddress1, true, this.state.errors.streetAddress1)}
                        {this.renderInput(this.translations.streetAddress2, 'streetAddress2', this.state.streetAddress2, false, this.state.errors.streetAddress2)}
                        {this.renderInput(this.translations.city, 'city', this.state.city, true, this.state.errors.city)}
                        {this.renderInput(this.translations.postalCode, 'postalCode', this.state.postalCode, true, this.state.errors.postalCode)}
                        {this.renderSelectInput(this.translations.country, 'country', this.state.country, true, this.state.errors.country)}
                    </ul>
                </div>
                <div className='buttons-set'>
                    <button type='submit' title={this.translations.save} className='button save-address'>{this.translations.save}</button>
                </div>
                {this.state.navigateToAddressBook && <Redirect push to={RouterPaths.AccountAddressBook} />}
                {this.state.navigateToLogin && <Redirect push to={RouterPaths.Login} />}
            </form>
        );
    }

    private renderInput(label: string, name: string, value: string | undefined, isRequired: boolean, hasError: boolean): React.ReactNode {
        return (
            <li className="input-container input-container--rev">
                <Input
                    label={label}
                    name={name}
                    value={value}
                    isRequired={isRequired}
                    hasError={hasError}
                    showValidationText={hasError}
                    onValueChange={this.handleChange}
                />
            </li>
        );
    }

    private renderSelectInput(label: string, name: string, value: string | undefined, isRequired: boolean, hasError: boolean): React.ReactNode {
        let options: SelectOption[] = [];

        Object.entries(this.supportedCountries).forEach(
            ([key, value]) => options.push({ valueAttribute: key, valueTranslated: value } as SelectOption)
        );

        return (
            <li className="select-container">
                <Select
                    name={name}
                    label={label}
                    id={name}
                    title={label}
                    isRequired={isRequired}
                    additionalClassName="billing-country-wr"
                    options={options}
                    selectedOption={options[0].valueAttribute}
                    onSelectionChange={(countryCode: string) => {
                        this.setState({ countryCode });
                    }}
                />
            </li>
        );
    }

    private validateBlankFields(): void {
        const { errors } = this.state;

        const keysToCheck = Object.keys(StateRequiredProps);
        for (const key of keysToCheck) {
            errors[key] = CheckUtils.isNullOrEmptyString(this.state[key]);
        }

        this.setState({ errors });
    }

    private handleChange(name: any, value: string, isValid: boolean) {
        const errors = this.state.errors;

        const required: (keyof StateRequiredPropsType)[] = ['firstName', 'lastName', 'telephone', 'streetAddress1', 'city', 'postalCode'];

        errors[name] = required.includes(name)
            ? CheckUtils.isNullOrEmptyString(value)
            : false;

        this.setState({ errors: errors, [name]: value });
    }

    private handleSubmit(event: React.FormEvent): void {
        event.preventDefault();

        this.validateBlankFields();

        for (const key of Object.keys(this.state.errors)) {
            if (this.state.errors[key]) {
                return;
            }
        }

        if (this.state.isAdd) {
            this.addAddress();
        } else {
            this.editAddress();
        }
    }

    private addAddress(): void {
        if (!this.props.userContext.user.tokenInfo) {
            return;
        }

        const { account } = GetHttpClientInstance();
        const addAddressRequest = this.buildRequest();

        account
            .addAddress(addAddressRequest, { accessToken: this.props.userContext.user.tokenInfo!.accessToken })
            .then((userProfileResponse: UserProfileResponse) => {
                this.props.userContext.updateUserProfile(userProfileResponse);
                this.setState({ navigateToAddressBook: true });
            })
            .catch(err => this.handleRestError(err));
    }

    private editAddress(): void {
        if (!this.props.userContext.user.tokenInfo) {
            return;
        }

        const { account } = GetHttpClientInstance();
        const editAddressRequest = this.buildRequest();

        account
            .editAddress(this.state.addressId, editAddressRequest, { accessToken: this.props.userContext.user.tokenInfo!.accessToken })
            .then((userProfileResponse: UserProfileResponse) => {
                this.props.userContext.updateUserProfile(userProfileResponse);
                this.setState({ navigateToAddressBook: true });
            })
            .catch(err => this.handleRestError(err));
    }

    private buildRequest(): AddEditAddressRequest {
        const addressRequest: AddEditAddressRequest = {
            firstName: this.state.firstName,
            middleName: this.state.middleName,
            lastName: this.state.lastName,
            company: this.state.company,
            telephone: this.state.telephone,
            fax: this.state.fax,
            streetAddress1: this.state.streetAddress1,
            streetAddress2: this.state.streetAddress2,
            city: this.state.city,
            postalCode: this.state.postalCode,
            country: this.state.country,
            isDefaultBillingAddress: this.state.isDefaultBillingAddress,
            isDefaultShippingAddress: this.state.isDefaultShippingAddress,
        };

        return addressRequest;
    }

    private handleRestError(err: RestError) {
        if (err.statusCode === 401) {
            this.props.userContext.clearUser();
            this.setState({ navigateToLogin: true });
            return;
        }
    }
}

export default withRouter(AddressAddEditForm);
