import React from 'react';
import { Link } from 'react-router-dom';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import AddToCartButton from './add-to-cart-button';
import { TranslationKeys } from '../../infrastructure/const';
import { Product, Price, PromoResponse } from '../../infrastructure/http/modules/product/product.models';
import NavigationContext from '../../infrastructure/context/navigation/navigation.context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../infrastructure/translation-manager';
import ProductListMarketingDescription from './product-list-marketing-description';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import ProductsEnrichmentUtils from '../../utilities/product-enrichment-utils';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import SelectOptionButton from './select-option-button';
import ProductListPriceBox from './product-list-price-box';
import MultiLineText from '../common/new-line-text';

type ProductListItemState = {
  addedToCart: boolean;
  activeClass: boolean;
};

type ProductListItemProps = {
  id: string;
  itemNumber?: string;
  name: string;
  description?: string;
  priceResponse: Price;
  imageUrl?: string;
  addedToCart: boolean;
  subtitle: string;
  marketingDescription: string;
  marketingShortDescription: string;
  partialId: number;
  inStock?: boolean;
  urlKey: string;
  canAddToCart: boolean;
  isConfigurable: boolean;
  isOnPromotion: boolean;
  productPromoResponse: PromoResponse;
  relatedProducts: Array<string>;
  metaDescription: string;
  metaKeywords: string;
  metaTitle: string;
};

export default class ProductListItem extends React.Component<ProductListItemProps, ProductListItemState> {

  static defaultProps: ProductListItemProps = {
    id: '',
    itemNumber: '',
    name: '',
    description: '',
    imageUrl: '',
    addedToCart: false,
    subtitle: '',
    marketingDescription: '',
    marketingShortDescription: '',
    partialId: 0,
    priceResponse: {} as Price,
    urlKey: '',
    canAddToCart: false,
    isConfigurable: false,
    isOnPromotion: false,
    productPromoResponse: {} as PromoResponse,
    relatedProducts: [],
    metaDescription: '',
    metaKeywords: '',
    metaTitle: ''
  };

  constructor(props: ProductListItemProps) {
    super(props);
    this.state = {
      addedToCart: props.addedToCart,
      activeClass: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ addedToCart: false });
    }, 2000);
  }

  componentDidUpdate(prevProps: ProductListItemProps) {
    if (prevProps.addedToCart !== this.props.addedToCart) {
      this.setState({ addedToCart: this.props.addedToCart });
    }
  }

  onAddedToCart() {
    this.setState({ addedToCart: true, activeClass: true }, () => {
      setTimeout(() => {
        this.setState({ addedToCart: false, activeClass: false })
      }, 2000)
    });
  }

  renderTopDetails(translationsContext: ITranslationsContext) {
    return (
      <div className={`product-detailing-top ${this.state.activeClass ? 'is-active' : ''}`}>
        {this.state.addedToCart &&
          <div className="product-incart">
            <span className="icon"></span>
            <span className="text"> {new TranslationManager(translationsContext).getTranslation(TranslationKeys.CART_ADDEDTOCART)}</span>
          </div>
        }
        <div className="text-badge"></div>
      </div>
    );
  }

  renderItemNumber(translationsContext: ITranslationsContext): JSX.Element | null {
    if (this.props.isConfigurable) {
      return null;
    }

    return (
      <span className="short-description">{new TranslationManager(translationsContext).getTranslation(TranslationKeys.PRINT_ITEMNUMBER)}: <span className="default-font">{this.props.itemNumber}</span></span>
    );
  }

  renderAddToCartButton(): JSX.Element | null {
    if (this.props.isConfigurable) {
      return (
        <SelectOptionButton product={this.props as Product} />
      );
    }

    if (this.props.canAddToCart) {
      return (
        <AddToCartButton productId={this.props.id} onClick={() => this.onAddedToCart()} />
      );
    }

    return null;
  }

  renderDescription(configurationContext: IConfigurationContext): JSX.Element | null {
    if (configurationContext.isFeatureFlagEnabled("useEnrichedProductData")) {
      return null;
    }

    return (
      <>
        <span className="short-description default-font">
          <ProductListMarketingDescription description={this.props.subtitle} />
        </span>
        <span className="long-description">
          <ProductListMarketingDescription description={this.props.marketingDescription} />
        </span>
      </>
    )
  }

  render(): JSX.Element {
    return (
      <TranslationsContext.Consumer>
        {(translationsContext: ITranslationsContext) => (
          <article className="products-grid__item col-4">
            {this.renderTopDetails(translationsContext)}
            <NavigationContext.Consumer>
              {(categoryContext) => (
                <ConfigurationContext.Consumer>
                  {(configurationContext) => (
                    <Link to={{ pathname: ProductsEnrichmentUtils.getProductDetailsUrl(configurationContext, categoryContext, this.props as Product), state: { product: (this.props) as Product } }} className="product-image">
                      <div className="image-div">
                        <img src={this.props.imageUrl} alt={this.props.name} />
                      </div>
                      <div className="actions">
                        <div className="actions__inner">
                          <h5 className="product-name">
                            {this.props.name}
                          </h5>
                          {this.renderItemNumber(translationsContext)}
                          {this.renderDescription(configurationContext)}
                        </div>
                        <ProductListPriceBox
                          priceResponse={this.props.priceResponse}
                          productPromoResponse={this.props.productPromoResponse}
                          isOnPromotion={this.props.isOnPromotion}
                        />
                        <span className="attr-tax"></span>
                      </div>
                    </Link>
                  )}
                </ConfigurationContext.Consumer>
              )}
            </NavigationContext.Consumer>
            {this.renderAddToCartButton()}
          </article>
        )}
      </TranslationsContext.Consumer>
    );
  }
}