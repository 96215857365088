import React, { useContext } from 'react';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../infrastructure/translation-manager';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import { RouterPaths, TranslationKeys } from '../../infrastructure/const';
import { Link } from 'react-router-dom';
import CheckUtils from '../../utilities/check-utils';

const AccountHeader: React.FC = () => {
    const context = useContext<ITranslationsContext>(TranslationsContext);
    const translationsManager = new TranslationManager(context);

    const translations = {
        header: translationsManager.getTranslation(TranslationKeys.ACCOUNT_DASHBOARD_HEADER),
        text: translationsManager.getTranslation(TranslationKeys.ACCOUNT_DASHBOARD_DESCRIPTION),
        goToShopping: translationsManager.getTranslation(TranslationKeys.START_SHOPPING_HERE)
    };

    return (
        <div className='wrapper welcome-msg'>
            <h2>{translations.header}</h2>
            <p>
                {translations.text}
            </p>
            {!CheckUtils.isNullOrEmptyString(translations.goToShopping) &&
                <Link to={RouterPaths.LandingPage}>{translations.goToShopping}</Link>
            }
        </div>
    );
};

export default AccountHeader;