import React, { useContext } from 'react';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import { Product } from '../../infrastructure/http/modules/orders/orders.models';

type OrderPrintProductTableProps = {
    productList: Product[];
    translations: OrderPrintProductTableTranslations;
    totalPrice: string;
    subtotalExclVat: string;
    subtotalInclVat: string;
    vatPrice: string;
    shippingPriceExclVat: string;
    shippingPriceInclVat: string;
};

export type OrderPrintProductTableTranslations = {
    productName: string;
    itemNumber: string;
    price: string;
    quantity: string;
    subtotal: string;
    subtotalInclVat: string;
    subtotalExclVat: string;
    totalPrice: string;
    shippingPrice: string;
    vatPrice: string;
};

const OrderPrintProductTable = (props: OrderPrintProductTableProps): JSX.Element => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    const displayPriceIncludingVat = configurationContext.isFeatureFlagEnabled("displayPriceIncludingVat");

    return (
        <div className="table-overflow-wrapper">
            <table className="data-table" id="my-orders-table">
                <colgroup>
                    <col />
                    <col width="1" />
                    <col width="1" />
                    <col width="1" />
                    <col width="1" />
                    <col width="1" />
                </colgroup>
                <thead>
                    <tr className="first last">
                        <th>{props.translations.productName}</th>
                        <th>{props.translations.itemNumber}</th>
                        <th className="a-right">{props.translations.price}</th>
                        <th className="a-center">{props.translations.quantity}</th>
                        <th className="a-right">{props.translations.subtotal}</th>
                    </tr>
                </thead>
                <tbody className="odd">
                    {props.productList.map((product: Product, i) => (
                        <tr className="border" id={"order-item-row-" + product.itemNumber} key={i}>
                            <td>
                                <h3 className="product-name">{product.name}</h3>
                            </td>
                            <td>{product.itemNumber}</td>
                            <td className="a-right">
                                <span className="price-incl-tax">
                                    <span className="cart-price">
                                        <span className="price">{product.price}</span>
                                    </span>
                                </span>
                            </td>
                            <td className="a-right">
                                <span className="nobr">
                                    <strong>{product.quantity}</strong><br />
                                </span>
                            </td>
                            <td className="a-right last">
                                <span className="price-incl-tax">
                                    <span className="cart-price">
                                        <span className="price">{product.total}</span>
                                    </span>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    {displayPriceIncludingVat ? (
                        <tr className="subtotal first">
                            <td colSpan={4} className="a-right" align="right">
                                {props.translations.subtotalInclVat}
                            </td>
                            <td align="right" className="last a-right">
                                <span className="price">{props.subtotalInclVat}</span>
                            </td>
                        </tr>
                    ) : (
                            <tr className="subtotal first">
                                <td colSpan={4} className="a-right" align="right">
                                    {props.translations.subtotalExclVat}
                                </td>
                                <td align="right" className="last a-right">
                                    <span className="price">{props.subtotalExclVat}</span>
                                </td>
                            </tr>
                        )}
                    <tr className="shipping">
                        <td colSpan={4} className="a-right" align="right">
                            {props.translations.shippingPrice}
                        </td>
                        {displayPriceIncludingVat ? (
                            <td align="right" className="last a-right">
                                <span className="price">{props.shippingPriceInclVat}</span>
                            </td>
                        ) : (
                                <td align="right" className="last a-right">
                                    <span className="price">{props.shippingPriceExclVat}</span>
                                </td>
                            )}
                    </tr>
                    <tr>
                        <td colSpan={4} className="a-right">
                            {props.translations.vatPrice}
                        </td>
                        <td className="last a-right">
                            <span className="price">{props.vatPrice}</span>
                        </td>
                    </tr>
                    <tr className="grand_total last">
                        <td colSpan={4} className="a-right" align="right">
                            <strong>{props.translations.totalPrice}</strong>
                        </td>
                        <td align="right" className="last a-right">
                            <strong><span className="price">{props.totalPrice}</span></strong>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default OrderPrintProductTable;