import React, { useContext } from 'react';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';

const OrderPrintCountryLabel = (props: { countryCode: string }): JSX.Element => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);

    let countryName = props.countryCode;

    Object.entries(configurationContext.supportedCountries).forEach(
        ([key, value]) => {
            if (key === props.countryCode) {
                countryName = value;
            }
        }
    );

    return <>{countryName}</>;
};

export default OrderPrintCountryLabel;