import * as React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useContext } from 'react';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';
import AdyenCheckout from './checkout/adyen/checkout-adyen';
import KlarnaCheckout from './checkout/klarna/checkout-klarna';
import { FeatureFlagValues, RouterPaths } from '../infrastructure/const';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import UserContext from '../infrastructure/context/user/user.context';
import CheckUtils from '../utilities/check-utils';
import NetsCheckout from './checkout/nets/checkout-nets';

const CheckoutPage = (): JSX.Element | null => {
  const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
  const userContext = useContext<IUserContext>(UserContext);

  if ((CheckUtils.isNullObject(userContext.cart) || CheckUtils.isNullOrEmptyArray(userContext.cart.products)) && userContext.isCartLoaded) {
    return <Redirect push to={RouterPaths.CartPage} />;
  }

  switch (configurationContext.paymentProvider) {
    case FeatureFlagValues.PayentProvider.Adyen:
      return <AdyenCheckout />;
    case FeatureFlagValues.PayentProvider.Klarna:
      return <KlarnaCheckout />;
    case FeatureFlagValues.PayentProvider.Nets:
      return <NetsCheckout />;
    default:
      return null;
  }
}

export default withRouter(CheckoutPage);