import React from 'react';
import TranslationManager from '../infrastructure/translation-manager';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import { Redirect } from 'react-router-dom';
import { RouterPaths, TranslationKeys } from '../infrastructure/const';

type State = {
    redirectToLandingPage: boolean;
};

class LogoutSuccessPage extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            redirectToLandingPage: false,
        };
    }

    public componentDidMount(): void {
        setTimeout(() => {
            this.setState({ redirectToLandingPage: true });
        }, 5 * 1000);
    }

    public render(): React.ReactNode {
        const translationManager = new TranslationManager(this.context);

        const translations = {
            header: translationManager.getTranslation(TranslationKeys.ACCOUNT_LOGOUT_HEADER),
            description: translationManager.getTranslation(TranslationKeys.ACCOUNT_LOGOUT_DESCRIPTION),
        };

        return (
            <div className='logout-page'>
                <div className='wr-success'>
                    <div className='wrapper'>
                        <h1>{translations.header}</h1>
                        <p>{translations.description}</p>
                        {this.state.redirectToLandingPage &&
                            <Redirect push to={RouterPaths.LandingPage} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

LogoutSuccessPage.contextType = TranslationsContext;

export default LogoutSuccessPage;
