import React from 'react';

// https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx
const useScript = (url: string, id: string, data: {[key: string]: string} = {}) => {
  React.useEffect(() => {

    if(data === undefined || Object.keys(data).length === 0){
      return () => {};
    }

    const script = document.createElement('script');

    script.src = url;
    script.id = id;
    script.async = true;
    script.type = 'text/javascript';

    const dataKeys = Object.keys(data);

    dataKeys.forEach((key: string) => {
        script.setAttribute(`data-${key}`, data[key]);
    });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, id, data]);
};

export default useScript;
