import React, { SyntheticEvent } from "react";
import { IntroBanner } from "../../../../infrastructure/http/modules/customer-service/customer-service.models";
import CheckUtils from "../../../../utilities/check-utils";
import MultiLineText from "../../../common/new-line-text";
import defaultImage from "../../../../images/intro-banner-image.jpg";
import NewsletterRegisterEmail from "../../../newsletter/newsletter-register-email";
import { AvailableNestedComponents } from "../../../../infrastructure/http/modules/static-content/static-content.models";
import NestedComponent from "../../../common/nested-component";
import classNames from "classnames";

interface IProps {
  banner: IntroBanner;
  onButtonClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

const IntroBannerSlide = ({ banner, onButtonClick }: IProps) => {
  const imageStyling: React.CSSProperties = {
    background: `url('${
      banner.imageUrl && banner.imageUrl.length > 0
        ? banner.imageUrl
        : defaultImage
    }') center top / cover`,
  };

  const hasNestedComponent = banner.nestedComponentName && banner.nestedComponentName.length > 0;

  const classes = {
    wrapper: classNames("intro-banner__flex-wrapper", "wrapper"),
    leftSide: classNames("intro-banner__left", {
      nested: hasNestedComponent
    }),
    header: classNames("intro-banner__heading", {
      nested: hasNestedComponent,
    }),
    text: classNames("intro-banner__text", {
      nested: hasNestedComponent
    })
  }

  const availableNestedComponents: AvailableNestedComponents = {
    newsletterRegisterEmail: {
      component: NewsletterRegisterEmail,
    },
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftSide}>
        <h1 className={classes.header}>
          <MultiLineText text={banner.title} />
        </h1>
        <p className={classes.text}>
          <MultiLineText text={banner.description} />
        </p>
        {hasNestedComponent && (
          <NestedComponent
            nestedComponentName={banner.nestedComponentName}
            availableComponentTypes={availableNestedComponents}
          />
        )}
        <div className="intro-banner__flex-wrapper">
          {banner.buttons.map((button, index) => (
            <button
              key={index}
              className="button buy-button"
              data-url={button.url}
              onClick={onButtonClick}
            >
              {button.title}
            </button>
          ))}
        </div>
        {!CheckUtils.isNullOrEmptyString(banner.bottomDisclaimer) && (
          <span className="intro-banner__disclaimer">
            {banner.bottomDisclaimer}
          </span>
        )}
      </div>
      <div className="intro-banner__right" style={imageStyling}></div>
    </div>
  );
};

export default IntroBannerSlide;
