import React from 'react';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import OrderHistoryListRow from './order-history-list-row';
import TranslationManager from '../../../infrastructure/translation-manager';
import { TranslationKeys } from '../../../infrastructure/const';

export type OrderHistoryListItem = {
    orderNumber: string;
    orderDate: string;
    shipTo: string;
    orderTotal: string;
    status: string;
};

type Props = {
    translationsContext: ITranslationsContext;

    orders: OrderHistoryListItem[];

    onViewOrderClick(orderNumber: string): void;
    onReorderClick(orderNumber: string): void;
};

const OrderHistoryList: React.FC<Props> = (props: Props) => {
    const translationManager = new TranslationManager(props.translationsContext);

    const translations = {
        orderNumber: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_ORDERNUMBER),
        date: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_ORDERDATE),
        shipTo: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_SHIPTO),
        orderTotal: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_ORDERTOTAL),
        status: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_STATUS),
    };

    return (
        <table className='data-table no-more-table my-order-block__table' id='my-orders-table'>
            <thead>
                <tr className='first last'>
                    <th>{translations.orderNumber}</th>
                    <th>{translations.date}</th>
                    <th className='hidden-xs'>{translations.shipTo}</th>
                    {/* <th className='hidden-xs'><span className='nobr'>{translations.orderTotal}</span></th> */}
                    <th>{translations.status}</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {props.orders.map(o =>
                    <OrderHistoryListRow
                        key={o.orderNumber}
                        order={o}
                        onReorderClick={props.onReorderClick}
                        onViewOrderClick={props.onViewOrderClick}
                        translationsContext={props.translationsContext}
                    />)}
            </tbody>
        </table>
    );
};

export default OrderHistoryList;