import { IHttp } from "./http.interface";
import { IResponse } from "./http-response.interface";
import axios, { AxiosRequestConfig, AxiosResponse, } from 'axios';
import { HttpClientConfiguration } from './http.client.configuration';

import TelemetryEntry from "./http.telemetry.model";
import { Observable, Observer } from 'rxjs';
import CheckUtils from "../../utilities/check-utils";

// import Logger from "../logger";

export default class B2CHttp implements IHttp {

    // private configuration: HttpClientConfiguration;
    private config: AxiosRequestConfig;
    // private telemetry: boolean;
    private telemetryObservable: Observable<TelemetryEntry>;
    private telemetryObserver: Observer<TelemetryEntry> | undefined;

    /**
     * @constructor Constructor of HTTP adapter
     */
    constructor(config: any, errorCallback: ((error: any) => void)) {
        this.initErrorHandling((error) => {
            // network or server error
            if (!error.response || error.response.status >= 500) {
                errorCallback(error);
            }
        });

        this.config = this.initConfiguration(config);

        // this.telemetry = configuration.telemetry === true;
        this.telemetryObservable = Observable.create((observer: Observer<TelemetryEntry>) => {
            this.telemetryObserver = observer;
        });
        this.initTelemetry();
    }

    public initErrorHandling(callback: (error: any) => void): void {
        this.errorHandlingCallback = callback;
    }

    public get<T>(url: string, customErrorHandling?: (error: any) => void): Promise<IResponse<T>> {
        return new Promise<IResponse<T>>((resolve, reject) => {
            let start = Date.now();
            axios.get(url, this.config)
                .then((response: AxiosResponse<any>) => {
                    this.logTimeExecution(url, start);
                    let getResponse: IResponse<T> = {
                        data: response.data,
                        statusCode: response.status
                    };
                    resolve(getResponse);
                }).catch(error => {
                    if(customErrorHandling){
                        customErrorHandling(error);
                    } else {
                        this.errorHandlingCallback(error);
                    }
                    reject(error);
                });
        });
    }

    public post<T>(url: string, data: any): Promise<IResponse<T>> {
        return new Promise<IResponse<T>>((resolve, reject) => {
            let start = Date.now();
            axios.post(url, data, this.config)
                .then((response: AxiosResponse<any>) => {
                    this.logTimeExecution(url, start);
                    let postResponse: IResponse<T> = {
                        data: response.data,
                        statusCode: response.status
                    };
                    resolve(postResponse);
                }).catch(error => {
                    this.logTimeExecution(url, start);
                    this.errorHandlingCallback(error);
                    reject(error);
                });
        });
    }

    public put<T>(url: string, data: any): Promise<IResponse<T>> {
        return new Promise<IResponse<T>>((resolve, reject) => {
            let start = Date.now();
            axios.put(url, data, this.config)
                .then((response: AxiosResponse<any>) => {
                    this.logTimeExecution(url, start);
                    let putResponse: IResponse<T> = {
                        data: response.data,
                        statusCode: response.status
                    };
                    resolve(putResponse);
                }).catch(error => {
                    this.logTimeExecution(url, start);
                    this.errorHandlingCallback(error);
                    reject(error);
                });
        });
    }

    public delete<T>(url: string): Promise<IResponse<T>> {
        return new Promise<IResponse<T>>((resolve, reject) => {
            let start = Date.now();
            axios.delete(url, this.config)
                .then((response: AxiosResponse<any>) => {
                    this.logTimeExecution(url, start);
                    let putResponse: IResponse<T> = {
                        data: response.data,
                        statusCode: response.status
                    };
                    resolve(putResponse);
                }).catch(error => {
                    this.logTimeExecution(url, start);
                    this.errorHandlingCallback(error);
                    reject(error);
                });
        });
    }

    public setToken(accessToken: string) {
        const headers = this.config.headers;

        if(CheckUtils.isNullOrEmptyString(accessToken)){
            headers['Authorization'] = "";
        } else {
            headers['Authorization'] = 'Bearer ' + accessToken;
        }
    }

    private errorHandlingCallback: (error: any) => void = () => {/* */};

    private initConfiguration(config: HttpClientConfiguration) {
        const defaultConfiguration = new HttpClientConfiguration();

        let result: AxiosRequestConfig = {
            baseURL: process.env.REACT_APP_GATEWAY_ENDPOINT,
            timeout: CheckUtils.isNullObject(config.timeout) ? defaultConfiguration.timeout : config.timeout,
            headers: {
                // 'Authorization': 'Bearer ' + this.configuration.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };

        return result;
    }

    private logTimeExecution(method: string, timeStarted: number): void {
        // let time = Date.now();
        // let duration = time - timeStarted;
        // if (this.telemetry) {
        //     let entry: TelemetryEntry = {
        //         module: '',
        //         method: method,
        //         timeConsumed: duration
        //     };
        //     if (this.telemetryObserver != null && this.telemetryObserver != undefined) {
        //         this.telemetryObserver.next(entry);
        //     }
        // }
    }

    private initTelemetry() {
        // let telemetryStorageDelay = this.configuration.telemetryStoreDelay;
        // this.telemetryObservable
        //     .bufferWithTime(telemetryStorageDelay)
        //     .subscribe(
        //         (events: Array<any>) => {
        //             if (events.length > 0) {
        //                 //console.log(`store telemetry ${events.length} entries...`);
        //                 this.storeTimeExecution(events);
        //             }
        //         },
        //         (error: any) => {
        //             console.error('[JsClient] Collecting telemetry failed with error:', error);
        //         }
        //     );
    }

    private storeTimeExecution(telemetryEntries: TelemetryEntry[]) {
        axios
            .put(`/api/telemetry`, telemetryEntries, this.config)
            .catch(error => {
                console.error('[JsClient] Collecting telemetry failed with error:', error);
            });
    }
}