import * as React from 'react';
import {  withRouter } from 'react-router-dom';
import { useContext } from 'react';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';
import AdyenConfirmation from './confirmation/confirmation-adyen';
import KlarnaConfirmation from './confirmation/confirmation-klarna';
import NetsConfirmation from './confirmation/confirmation-nets';
import { FeatureFlagValues } from '../infrastructure/const';



type ConfirmationPageProps = {

};

const ConfirmationPage = (props: ConfirmationPageProps) : JSX.Element => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    return (
        <div>
       {(() => {
         switch (configurationContext.paymentProvider) {
           case FeatureFlagValues.PayentProvider.Adyen:
             return <AdyenConfirmation />;
           case FeatureFlagValues.PayentProvider.Klarna:
             return <KlarnaConfirmation />;
             case FeatureFlagValues.PayentProvider.Nets:
              return <NetsConfirmation />;
           default:
             return null;
         }
       })()}
     </div>
   );
}


export default withRouter(ConfirmationPage);