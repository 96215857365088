import CheckUtils from "./check-utils";

export default class StringUtils {

    static removeSpaces(value: string): string {
        return value.replace(/\s/g, '');
    }

    static removeSpecialCharacters(value: string): string {
        return value.replace(/[^\w\s]/gi, '');
    }

    static removeSpacesAndSpecialCharacters(value: string): string {
        const noCharactersLabel = this.removeSpecialCharacters(value);
        return StringUtils.removeSpaces(noCharactersLabel);
    }

    static convertEmailInTextToLink(text: string): string {
        if(CheckUtils.isNullOrEmptyString(text)){
            return "";
        }
        
        let emailsArray = text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);

        if (emailsArray === null) {
            return text;
        }

        for (let email of emailsArray) {
            const re = new RegExp(email, 'g');
            text = text.replace(re, `<a href="mailto:${email}">${email}</a>`);
        }

        return text;
    }

    static convertPhoneNumberInTextToLink(text: string): string {
        let phonesNumberArray = text.match(/[+]*[(]{0,1}[0-9]{2,4}[)]{0,1}[-\s/0-9]{3,4}[-\s/0-9]{4,5}/g);

        if (phonesNumberArray === null) {
            return text;
        }

        for (let phoneNuber of phonesNumberArray) {
            const re = new RegExp(phoneNuber, 'g');
            text = text.replace(re, `<a href="tel:${phoneNuber}">${phoneNuber}</a>`);
        }

        return text;
    }

    static convertStringWithNewLinesToHtmlString(text: string): string {
        const lines = text.split('\n');
        if (lines.length > 1) {
            text = text.replace(/[\n\r]/g, '<br />');
        }

        return text;
    }
}