import React from 'react';
import IUserContext from '../../../infrastructure/context/user/user.context.interface';
import CheckoutAddressForm from './checkout-address-form';
import CheckoutAddressSelectionCheckbox from './checkout-address-selection-checkbox';
import CheckoutLoginBox from './checkout-login-box';
import { Address } from '../../../infrastructure/http/modules/cart/cart.models';
import CheckUtils from '../../../utilities/check-utils';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import { TranslationKeys } from '../../../infrastructure/const';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';
import { ErrorResponse } from '../adyen/checkout-adyen';
import CacheManager from '../../../infrastructure/cache/cache-manager';
import CacheKeys from '../../../infrastructure/cache/cache-keys';

type CheckoutAddressProps = {
    userContext: IUserContext,
    onDeliveryAddressChange: ((address: Address | null) => void),
    onInvoiceAddressChange: ((address: Address) => void),
    displayValidationMessages: boolean,
    apiErrorResponse: ErrorResponse,
    invoiceAddress: Address | null,
    deliveryAddress: Address | null,
    onSaveInvoiceAddressChange: ((saveAddress: boolean) => void),
    onSaveDeliveryAddressChange: ((saveAddress: boolean) => void),
};

type CheckoutAddressState = {
    deliveryAddress: Address | null,
    invoiceAddress: Address,
    displayDeliveryAddessForm: boolean,
    saveInvoiceAddress: boolean,
    saveDeliveryAddress: boolean
};

export default class CheckoutAddress extends React.Component<CheckoutAddressProps, CheckoutAddressState> {
    private invoiceAddressRef: React.RefObject<CheckoutAddressForm>;
    private deliveryAddressRef: React.RefObject<CheckoutAddressForm>;
    private TranslationsManager: TranslationManager;
    private translations: { [id: string]: string };

    constructor(props: CheckoutAddressProps, context: ITranslationsContext) {
        super(props, context);
        const shipToDifferentAddress = CacheManager.getItem(CacheKeys.shipToDifferentAddress);

        this.state = {
            invoiceAddress: CheckUtils.isNullObject(this.props.invoiceAddress) ? new Address() : this.props.invoiceAddress as Address,
            deliveryAddress: CheckUtils.isNullObject(this.props.deliveryAddress) ? null : this.props.deliveryAddress as Address,
            displayDeliveryAddessForm: CheckUtils.isNullObject(shipToDifferentAddress) ? false : shipToDifferentAddress as unknown as boolean,
            saveInvoiceAddress: false,
            saveDeliveryAddress: false,
        };

        this.TranslationsManager = new TranslationManager(context);
        this.translations = {
            deliveryInformation: this.TranslationsManager.getTranslation(TranslationKeys.CHECKOUT_DELIVERYINFORMATION_TITLE),
        };

        this.invoiceAddressRef = React.createRef();
        this.deliveryAddressRef = React.createRef();
    }

    componentDidUpdate(prevProps: CheckoutAddressProps) {
        if (prevProps.userContext.user.userProfile !== this.props.userContext.user.userProfile) {
            this.setState({
                invoiceAddress: this.initializeInvoiceAddress(this.props.userContext, new Address())
            });
        }
    }

    initializeInvoiceAddress(userContex: IUserContext, invoiceAddress: Address): Address {
        // Get invoice name from user profile
        if (!CheckUtils.isNullObject(userContex.user) && !CheckUtils.isNullObject(userContex.user.userProfile)) {
            const firstName = userContex.user.userProfile.firstName ? userContex.user.userProfile.firstName : '';
            const middleName = userContex.user.userProfile.middleName ? userContex.user.userProfile.middleName : '';
            const lastName = userContex.user.userProfile.lastName ? userContex.user.userProfile.lastName : '';
            invoiceAddress.firstName = firstName;
            invoiceAddress.middleName = middleName;
            invoiceAddress.lastName = lastName;
        }
        return invoiceAddress;
    }

    onAddressSelectionChange(isDeliveryDisplayed: boolean) {
        CacheManager.setItem(CacheKeys.shipToDifferentAddress, isDeliveryDisplayed as unknown as object);
        if (!isDeliveryDisplayed) {
            CacheManager.removeItem(CacheKeys.deliveryAddressKey);
            this.setState({ saveDeliveryAddress: false });
            this.onDeliveryAddressChange(null);
        }
        this.setState({
            displayDeliveryAddessForm: isDeliveryDisplayed,
            deliveryAddress: isDeliveryDisplayed ? new Address() : null
        });
    }

    onDeliveryAddressChange(address: Address | null) {
        this.setState({ deliveryAddress: address });
        this.props.onDeliveryAddressChange(address);
    }

    onInvoiceAddressChange(address: Address) {
        this.setState({ invoiceAddress: address });
        this.props.onInvoiceAddressChange(address);
    }

    isComponentFilledCorrectly(): boolean {
        // Component ref is null (Component does not exist) or component does exist and is filled correctly
        const invoiceAddressValid: boolean = CheckUtils.isNullObject(this.invoiceAddressRef.current) || (this.invoiceAddressRef.current!.isComponentFilledCorrectly());
        const deliveryAddressValid: boolean = CheckUtils.isNullObject(this.deliveryAddressRef.current) || (this.deliveryAddressRef.current!.isComponentFilledCorrectly());

        return invoiceAddressValid && deliveryAddressValid;
    }

    renderLoginBox(userContex: IUserContext): JSX.Element | null {
        if (userContex.user.tokenInfo) {
            return null;
        }
        return (
            <CheckoutLoginBox userContext={userContex} />
        );
    }

    render() {
        return (
            <ConfigurationContext.Consumer>
                {(configurationContext) => (
                    <>
                        <li id="opc-billing" >
                            <div id="checkout-step-billing" className="checkout-step">
                                <div className="checkout-step__title">
                                    <span className="text">{this.translations.deliveryInformation}</span>
                                    {this.renderLoginBox(this.props.userContext)}
                                </div>
                                <div className="content-container">
                                    <form className="form-validate">
                                        <CheckoutAddressForm
                                            ref={this.invoiceAddressRef}
                                            displayEmailAddress={true}
                                            address={this.state.invoiceAddress}
                                            onUpdate={(address: Address) => this.onInvoiceAddressChange(address)}
                                            displayValidationMessages={this.props.displayValidationMessages}
                                            defaultAddressId={this.GetDefaultAddressId(true)}
                                            supportedCountries={configurationContext.supportedCountries}
                                            apiErrorResponse={CheckUtils.isNullObject(this.props.apiErrorResponse) ? [] : this.props.apiErrorResponse.invoiceAddressErrors}
                                            saveAddress={this.state.saveInvoiceAddress}
                                            onSaveAddressChanged={(newValue: boolean) => {
                                                this.setState({ saveInvoiceAddress: newValue });
                                                this.props.onSaveInvoiceAddressChange(newValue);
                                            }}
                                            isInvoiceAddress={true}
                                        />

                                        <CheckoutAddressSelectionCheckbox
                                            isDifferentAddress={this.state.displayDeliveryAddessForm}
                                            onChange={(newValue) => this.onAddressSelectionChange(newValue)} />
                                    </form>
                                </div>
                            </div>
                        </li>
                        <li id="opc-shipping">
                            <div id="checkout-step-shipping" className="checkout-step">
                                <div className="content-container">
                                    <form className="different-address-form-validate" >
                                        {this.state.displayDeliveryAddessForm && !CheckUtils.isNullObject(this.state.deliveryAddress) &&
                                            <CheckoutAddressForm
                                                ref={this.deliveryAddressRef}
                                                displayEmailAddress={false}
                                                address={this.state.deliveryAddress as Address}
                                                onUpdate={(address: Address) => this.onDeliveryAddressChange(address)}
                                                displayValidationMessages={this.props.displayValidationMessages}
                                                defaultAddressId={this.GetDefaultAddressId(false)}
                                                supportedCountries={configurationContext.supportedCountries}
                                                apiErrorResponse={CheckUtils.isNullObject(this.props.apiErrorResponse) ? [] : this.props.apiErrorResponse.deliveryAddressErrors}
                                                saveAddress={this.state.saveDeliveryAddress}
                                                onSaveAddressChanged={(newValue: boolean) => {
                                                    this.setState({ saveDeliveryAddress: newValue });
                                                this.props.onSaveDeliveryAddressChange(newValue);
                                                }}
                                                isInvoiceAddress={false}
                                            />
                                        }
                                    </form>
                                </div>
                            </div>
                        </li>
                    </>
                )}
            </ConfigurationContext.Consumer>
        );
    }

    private GetDefaultAddressId(isInvoiceAddress: boolean) {
        if (CheckUtils.isNullOrEmptyArray(this.props.userContext.user.userProfile.addressBook)) {
            return null;
        }

        const defaultAddress = isInvoiceAddress ? this.props.userContext.user.userProfile.addressBook.find(x => x.isDefaultBillingAddress) : this.props.userContext.user.userProfile.addressBook.find(x => x.isDefaultShippingAddress);

        if (CheckUtils.isNullObject(defaultAddress) || CheckUtils.isNullOrEmptyString(defaultAddress!.id)) {
            return null;
        }
        return defaultAddress!.id;
    }
}

CheckoutAddress.contextType = TranslationsContext;