import React, { ReactNode, FormEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { TranslationKeys, RouterPaths, RouterStateParams, SE_BatterySizes, CustomerPreferenceKeys } from '../infrastructure/const';
import AccountMenu from './account/menu/account-menu';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import TranslationManager from '../infrastructure/translation-manager';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import UserContext from '../infrastructure/context/user/user.context';
import CheckUtils from '../utilities/check-utils';
import { UserProfileResponse } from '../infrastructure/http/modules/auth/auth.models';
import { UpdateMeRequest } from '../infrastructure/http/modules/account/account.models';
import { RestError, ErrorCodes } from '../infrastructure/http/modules/errors';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import ErrorMessages from './common/error-messages';
import Select, { SelectOption } from './common/select';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';

const EditCustomerPreferencePage: React.FC = () => {
    return (
        <UserContext.Consumer>
            {(userContext: IUserContext) =>
                <TranslationsContext.Consumer>
                    {(translationsContext: ITranslationsContext) =>
                        <ConfigurationContext.Consumer>
                            {(configurationContext: IConfigurationContext) =>
                                <EditCustomerPreferenceInner
                                    translationsContext={translationsContext}
                                    userContext={userContext}
                                    configurationContext={configurationContext}
                                />
                            }
                        </ConfigurationContext.Consumer>
                    }
                </TranslationsContext.Consumer>
            }
        </UserContext.Consumer>
    );
};

type Props = {
    userContext: IUserContext;
    translationsContext: ITranslationsContext;
    configurationContext: IConfigurationContext;
};

type State = {
    userId: string;
    selectedBatterySize: string

    errors: { [key: string]: boolean };
    errorCodes: Array<keyof typeof ErrorCodes>;

    isLoaded: boolean;
    validationErrorMessage: string;

    [key: string]: any;

    redirectToAccountDashboard: boolean;
    redirectToLogin: boolean;
};

class EditCustomerPreferenceInner extends React.Component<Props, State> {
    constructor(props: Props, private translations: { [key: string]: string }) {
        super(props);

        const selectedBatterySize = CheckUtils.isNullObject(props.userContext.user.userProfile.customerPreference) ?
            '' : Object.entries(props.userContext.user.userProfile.customerPreference!).find(([k, v]) => k === CustomerPreferenceKeys.SE.BatterySize);

        this.state = {
            userId: '',
            selectedBatterySize: selectedBatterySize ? selectedBatterySize[1] : '',
            isLoaded: false,
            validationErrorMessage: '',
            errors: {
            },
            errorCodes: [],
            redirectToAccountDashboard: false,
            redirectToLogin: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    public componentDidMount(): void {
        if (!this.props.configurationContext.isFeatureFlagEnabled("showCustomerPreferences")) {
            this.setState({ redirectToAccountDashboard: true });
        }
        this.initializeTranslations();
        this.setState({ isLoaded: true });
    }

    public onSelectChange(value: string) {
        this.setState({ selectedBatterySize: value });
    }

    public render(): ReactNode {
        if (!this.state.isLoaded) {
            return null;
        }

        const options: SelectOption[] = Object.entries(SE_BatterySizes).map(([key, value]) =>
            ({ valueAttribute: key, valueTranslated: value } as SelectOption)
        );

        return (
            <div className='col2-left-layout oticon-address'>
                <div className='wrapper'>
                    <AccountMenu />
                    <div className='col-main'>
                        <div className='my-account'>
                            <div className='dashboard account-wr'>
                                {this.renderRedirectToDashboard()}
                                {this.renderRedirectToLogin()}
                                <h2>{this.translations.header}</h2>
                                <h3>{this.state.validationErrorMessage && this.state.validationErrorMessage}</h3>
                                <ErrorMessages errorKeys={this.state.errorCodes.map(x => ErrorCodes[x])} />
                                <form onSubmit={this.handleSubmit}>
                                    <div className='fieldset'>
                                        <ul className='form-list form-list--flex-rev'>
                                            <Select
                                                name={CustomerPreferenceKeys.SE.BatterySize}
                                                id={CustomerPreferenceKeys.SE.BatterySize}
                                                title={this.translations.selectBatterySize}
                                                isRequired={true}
                                                label={this.translations.selectBatterySize}
                                                displayLabel={true}
                                                additionalClassName=""
                                                options={options}
                                                selectedOption={this.state.selectedBatterySize}
                                                onSelectionChange={this.onSelectChange}
                                            />
                                        </ul>
                                    </div>

                                    <div className='buttons-set'>
                                        <button type='submit' title={this.translations.save} className='button'>
                                            {this.translations.save}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderRedirectToLogin(): ReactNode {
        return (
            <>
                {this.state.redirectToLogin && <Redirect to={RouterPaths.Login} />}
            </>
        );
    }

    private renderRedirectToDashboard(): ReactNode {
        return (
            <>
                {this.state.redirectToAccountDashboard &&
                    <Redirect
                        push
                        to={{
                            pathname: RouterPaths.AccountDashboard,
                            state: { [RouterStateParams.ShowEditedContactInformationSuccessMessage]: true },
                        }}
                    />
                }
            </>
        );
    }

    private initializeTranslations(): void {
        const translationManager = new TranslationManager(this.props.translationsContext);

        this.translations = {
            header: translationManager.getTranslation(TranslationKeys.ACCOUNT_CUSTOMERPREFERENCES),
            save: translationManager.getTranslation(TranslationKeys.ACCOUNT_SAVE),
            selectBatterySize: translationManager.getTranslation(TranslationKeys.ACCOUNT_CUSTOMERPREFERENCES_SELECT_BATTERYSIZE),
        };
    }

    private handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();

        const newState = this.state;

        this.setState({ newState }, () => {
            for (const key of Object.keys(this.state.errors)) {
                if (this.state.errors[key]) {
                    return;
                }
            }

            this.updateMe();
        });
    }

    private updateMe() {
        const { userProfile } = this.props.userContext.user;
        const { tokenInfo } = this.props.userContext.user;

        if (!tokenInfo) {
            this.handleUnauthorized();
            return;
        }

        const { account } = GetHttpClientInstance();

        const updateMe: UpdateMeRequest = {
            firstName: userProfile.firstName,
            middleName: userProfile.middleName,
            lastName: userProfile.lastName,
            email: userProfile.email,
            receiveMarketingMaterials: this.props.userContext.user.subscriptions.Newsletter !== null,
            updatePassword: false,
            customerPreference: { [CustomerPreferenceKeys.SE.BatterySize]: this.state.selectedBatterySize }
        };


        account
            .updateMe(updateMe, { accessToken: tokenInfo.accessToken })
            .then((userProfileResponse: UserProfileResponse) => {
                this.props.userContext.updateUserProfile(userProfileResponse);
                this.setState({ redirectToAccountDashboard: true });
            })
            .catch((err: RestError) => this.catchHttpError(err));
    }

    private catchHttpError(err: RestError): void {
        if (err.statusCode === 401) {
            this.handleUnauthorized();
            return;
        }
        if (!CheckUtils.isNullObject(err.data) && !CheckUtils.isNullOrEmptyArray(err.data.errors)) {
            const errorCodes: Array<keyof typeof ErrorCodes> = Object.keys(ErrorCodes).filter(((x: string) => err.data.errors.some(error => error.errorCode === ErrorCodes[x as keyof typeof ErrorCodes]))).map((x: string) => (x as keyof typeof ErrorCodes));
            if (!CheckUtils.isNullOrEmptyArray(errorCodes)) {
                this.setState({ errorCodes });
                return;
            }
        }
        this.setState({ validationErrorMessage: err.data.message });
    }

    private handleUnauthorized(): void {
        this.props.userContext.clearUser();
        this.setState({ redirectToLogin: true });
    }
}

export default EditCustomerPreferencePage;
