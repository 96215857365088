import * as React from 'react';
import MaterialSpotItem from './material-spot-item';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import { MaterialSpot } from '../../infrastructure/http/modules/static-content/static-content.models';
import CheckUtils from '../../utilities/check-utils';

export type MaterialSpotsProps = {
    items: MaterialSpot[];
};

export default class MaterialSpots extends React.Component<MaterialSpotsProps> {
    render(): JSX.Element | null {
        if (CheckUtils.isNullOrEmptyArray(this.props.items)) {
            return null;
        }

        return (
            <div className="info-blocks-wrapper">
                <div className="wrapper testimonial">
                    <div className="bottom-info-block">
                        {this.props.items.map(block =>
                            <MaterialSpotItem
                                key={block.title}
                                title={block.title}
                                subtitle={block.subtitle}
                                link={block.link}
                                linkDescription={block.linkDescription}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

MaterialSpots.contextType = TranslationsContext;