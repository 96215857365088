import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { RouterPaths, TranslationKeys } from '../../infrastructure/const';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';

const CreateAccountSection: React.FC = () => {
    const context: ITranslationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(context);

    const translations = {
        header: translationManager.getTranslation(TranslationKeys.LOGIN_NEW_CUSTOMERS_HEADER),
        description: translationManager.getTranslation(TranslationKeys.LOGIN_NEW_CUSTOMERS_DESCRIPTION),
        button: translationManager.getTranslation(TranslationKeys.LOGIN_NEW_CUSTOMERS_BUTTON),
    };

    return (
        <>
            <h2>{translations.header}</h2>
            <div className='login-block'>
                <p>{translations.description}</p>
                <div className='buttons-set'>
                    <Link to={RouterPaths.CreateAccount} className='button' title={translations.button}>{translations.button}</Link>
                </div>
            </div>
        </>
    );
};

export default CreateAccountSection;
