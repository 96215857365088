import * as React from 'react';
import { RouterPaths } from '../../const';
import { RouteComponentProps, withRouter } from 'react-router';
import FooterContext from './footer.context';

type ProviderState = {
    isVisible: boolean
};

class FooterContextProvider extends React.Component<RouteComponentProps, ProviderState> {

    private noFooterRoutes = [RouterPaths.OrderPrint];

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            isVisible: true
        } as ProviderState;
    }

    componentDidMount() {
        this.setFooterVisibility();
      }

      componentDidUpdate(prevProps: RouteComponentProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
          this.setFooterVisibility();
        }
      }

      setFooterVisibility() {
        if (this.noFooterRoutes.some(route => this.props.location.pathname.includes(route))) {
          this.setState({ isVisible: false });
        } else {
          this.setState({ isVisible: true });
        }
      }

      render() {
        return (
          <FooterContext.Provider
            value={{
                isVisible: this.state.isVisible
            }}
          >
            {this.props.children}
          </FooterContext.Provider>
        );
      }
}

export default withRouter(FooterContextProvider);