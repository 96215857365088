import React, { useContext } from "react";
import { TranslationKeys } from "../../../infrastructure/const";
import TranslationsContext from "../../../infrastructure/context/translations/translations-context";
import ITranslationsContext from "../../../infrastructure/context/translations/translations-context.interface";
import TranslationManager from "../../../infrastructure/translation-manager";
import CheckUtils from "../../../utilities/check-utils";

const PaymentConfirmationInner = (props: {
  paymentId: string | null;
  transactionId: string;
}): JSX.Element => {
  const translationsContext =
    useContext<ITranslationsContext>(TranslationsContext);
  const translationManager = new TranslationManager(translationsContext);

  const translations = {
    header: translationManager.getTranslation(
      TranslationKeys.ORDERCONFIRMATION_ORDERRECEIVED
    ),
    description: translationManager.getTranslation(
      TranslationKeys.PAYMENTCONFIRMATION_PAYMENTID
    ),
  };

  function getPaymentReferenceNumber() {
    return CheckUtils.isNullOrEmptyString(props.paymentId)
      ? props.transactionId
      : props.paymentId;
  }

  return (
    <div className="col-main">
      <div className="checkout-success">
        <div className="wr-success">
          <div className="wrapper">
            <h1>{translations.header}</h1>
            <div className="order-confirmation-wrapper">
              <p className="order-success">
                {translations.description}:{" "}
                <span className="order-number">
                  {getPaymentReferenceNumber()}
                </span>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="adyen-boleto-download-pdf"></div>
    </div>
  );
};

export default PaymentConfirmationInner;
