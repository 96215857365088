import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../infrastructure/context/user/user.context';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import { IoIosHeartEmpty, IoIosHeart } from 'react-icons/all'
import CheckUtils from '../../utilities/check-utils';
import { useHistory } from 'react-router-dom';
import { RouterPaths, RouterStateParams, TranslationKeys } from '../../infrastructure/const';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import { GetHttpClientInstance } from '../../infrastructure/context/http/http-context-provider';
import { UserProfileResponse } from '../../infrastructure/http/modules/auth/auth.models';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';

type Props = {
    itemNumber: string,
    isConfigurableProduct: boolean
}

const AddToFavourites = (props: Props): JSX.Element | null => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    const favouritesEnabled = configurationContext.isFeatureFlagEnabled("allowFavourites");

    const history = useHistory();
    const userContext = useContext<IUserContext>(UserContext);
    const isLoggedInUser = userContext.user.userProfile.isAuthenticated;
    const { favourites } = userContext.user.userProfile;
    const { account } = GetHttpClientInstance();

    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);
    const translations: { [id: string]: string } = {
        add: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_ADD_TO_FAVOURITES),
        remove: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_REMOVE_FROM_FAVOURITES)
    }

    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    useEffect(() => {
        const f = isLoggedInUser && !CheckUtils.isNullObject(favourites) && favourites.includes(props.itemNumber);
    
        setIsFavorite(f);
    }, [props.itemNumber]);


    function addToFavourites(): void {
        if (!isLoggedInUser) {
            redirectToLogin();
        } else {
            setIsFavorite(true);

            account
                .addFavourite(props.itemNumber, { accessToken: userContext.user.tokenInfo!.accessToken })
                .then((userProfileResponse: UserProfileResponse) => {
                    userContext.updateUserProfile(userProfileResponse);
                })
                .catch(err => setIsFavorite(false));
        }
    }

    function removeFromFavourites(): void {
        setIsFavorite(false);

        account
            .deleteFavourite(props.itemNumber, { accessToken: userContext.user.tokenInfo!.accessToken })
            .then((userProfileResponse: UserProfileResponse) => {
                userContext.updateUserProfile(userProfileResponse);
            })
            .catch(err => setIsFavorite(true));
    }

    function redirectToLogin(): void {
        history.push({
            pathname: RouterPaths.Login,
            state: { [RouterStateParams.ShowFavouritesAuthenticateError]: true, prevPath: history.location.pathname }
        })
    }

    function renderEmptyHeartButton(): JSX.Element {
        return (
            <button
                className="favourites-button"
                onClick={() => addToFavourites()}
            >
                <IoIosHeartEmpty
                    size={23}
                />
                <span> {translations.add}</span>
            </button>
        )
    }

    function renderHeartButton(): JSX.Element {
        return (
            <button
                className="favourites-button"
                onClick={() => removeFromFavourites()}
            >
                <IoIosHeart
                    size={23}
                    className="favourites-button-full"
                />
                <span> {translations.remove}</span>
            </button>
        )
    }

    if (!favouritesEnabled) {
        return null;
    }

    if (props.isConfigurableProduct) {
        return null;
    }

    return isFavorite ? renderHeartButton() : renderEmptyHeartButton();
};

export default AddToFavourites;