import React, { useContext } from 'react';
import StaticContentContext from '../../../infrastructure/context/static-content/static-content.context';
import NavigationContext from '../../../infrastructure/context/navigation/navigation.context';
import CheckUtils from '../../../utilities/check-utils';
import ReactHtmlParser from 'react-html-parser';

const ProductsCategoryLongDescription = (): JSX.Element | null => {
    const { activeCategory } = useContext(NavigationContext);
    const { categoryHeaders } = useContext(StaticContentContext);

    if (CheckUtils.isNullObject(activeCategory) || CheckUtils.isNullOrEmptyArray(categoryHeaders) || activeCategory!.length === 0) {
        return null;
    }

    let activeCategoryRoute = activeCategory![0].url.replace("/", "");
    for (let index = 1; index < activeCategory!.length; index++) {
        activeCategoryRoute += activeCategory![index].url;
    }
    
    const header = categoryHeaders.find(x => x.route === activeCategoryRoute);

    if (header === null || header === undefined) {
        return null;
    }

    if (CheckUtils.isNullOrEmptyString(header.longDescriptionHTML)) {
        return null;
    }

    return (
        <div className="category-bottom">
            <div className="wrapper">
                {ReactHtmlParser(header.longDescriptionHTML)}
            </div>
        </div>
    );
};

export default ProductsCategoryLongDescription;