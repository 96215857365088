import React, { ReactNode } from 'react';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import { Order } from '../../infrastructure/http/modules/orders/orders.models';
import OrderPrintUserAddress, { OrderUserTranslations } from './order-print-user-address';
import OrderPrintProductTable, { OrderPrintProductTableTranslations } from './order-print-product-table';
import { TranslationKeys } from '../../infrastructure/const';
import SelectedPaymentMethod from '../common/order/selected-payment-method';
import SelectedShippingMethod from '../common/order/selected-shipping-method';

export type OrderPrintProps = {
    order: Order;
};

export default class OrderPrint extends React.Component<OrderPrintProps> {
    private translationsManager: TranslationManager;

    private translations: { [id: string]: string };
    private orderUserTranslations: OrderUserTranslations;
    private productTableTranslations: OrderPrintProductTableTranslations;

    constructor(props: any, context: ITranslationsContext) {
        super(props, context);
        this.translationsManager = new TranslationManager(context);

        this.translations = {
            orderDate: this.translationsManager.getTranslation(TranslationKeys.PRINT_ORDERDATE),
            order: this.translationsManager.getTranslation(TranslationKeys.PRINT_ORDER_HEADER),
            shippingAddress: this.translationsManager.getTranslation(TranslationKeys.SHIPPINGADDRESS),
            invoiceAddress: this.translationsManager.getTranslation(TranslationKeys.INVOICEADDRESS),
            shippingMethod: this.translationsManager.getTranslation(TranslationKeys.SHIPPINGMETHOD),
            paymentMethod: this.translationsManager.getTranslation(TranslationKeys.PAYMENTMETHOD),
            itemsOrdered: this.translationsManager.getTranslation(TranslationKeys.PRINT_ITEMSORDERED_HEADER),
            closeWindow: this.translationsManager.getTranslation(TranslationKeys.PRINT_CLOSEWINDOW),
            eanNumberLabel: this.translationsManager.getTranslation(TranslationKeys.DK.EAN_NUMBER_LABEL)
        };

        this.orderUserTranslations = {
            phoneNumber: this.translationsManager.getTranslation(TranslationKeys.PRINT_PHONENUMBER),
            email: this.translationsManager.getTranslation(TranslationKeys.PRINT_EMAIL)
        };

        this.productTableTranslations = {
            productName: this.translationsManager.getTranslation(TranslationKeys.PRODUCT_NAME),
            itemNumber: this.translationsManager.getTranslation(TranslationKeys.PRINT_ITEMNUMBER),
            price: this.translationsManager.getTranslation(TranslationKeys.PRICE),
            quantity: this.translationsManager.getTranslation(TranslationKeys.QUANTITY),
            subtotal: this.translationsManager.getTranslation(TranslationKeys.SUBTOTAL),
            subtotalInclVat: this.translationsManager.getTranslation(TranslationKeys.UK.CART_SUBTOTAL_INCLUDING_VAT),
            subtotalExclVat: this.translationsManager.getTranslation(TranslationKeys.UK.CART_SUBTOTAL_EXCLUDING_VAT),
            totalPrice: this.translationsManager.getTranslation(TranslationKeys.PRINT_TOTALPRICE),
            shippingPrice: this.translationsManager.getTranslation(TranslationKeys.PRINT_SHIPPING),
            vatPrice: this.translationsManager.getTranslation(TranslationKeys.PRINT_VAT),
        };
    }

    componentDidMount() {
        setTimeout(() => {
            window.print();
        }, 100);
    }

    render(): ReactNode {
        return (
            <>
                <h1>{this.translations.order} #{this.props.order.orderNumber}</h1>
                <p className="order-date">{this.translations.orderDate}: {this.props.order.orderDateFormatted}</p>

                {this.props.order.purchaseOrderNumber &&
                    <p className="order-date">{this.translations.eanNumberLabel}: {this.props.order.purchaseOrderNumber}</p>
                }

                <div className="col2-set">
                    <OrderPrintUserAddress
                        header={this.translations.shippingAddress}
                        address={this.props.order.shippingAddress}
                        mainClassName="col-1"
                        translations={this.orderUserTranslations}
                    />
                    <OrderPrintUserAddress
                        header={this.translations.invoiceAddress}
                        address={this.props.order.invoiceAddress}
                        mainClassName="col-2"
                        translations={this.orderUserTranslations}
                    />
                </div>
                <div className="col2-set">
                    <div className="col-1">
                        <h2>{this.translations.shippingMethod}</h2>
                        <SelectedShippingMethod shippingMethod={this.props.order.shippingType} />
                    </div>
                    <div className="col-2">
                        <h2>{this.translations.paymentMethod}</h2>
                        <SelectedPaymentMethod paymentMethod={this.props.order.paymentType} poNumber={this.props.order.purchaseOrderNumber} />
                    </div>
                </div>
                <h2>{this.translations.itemsOrdered}</h2>
                
                <OrderPrintProductTable
                    translations={this.productTableTranslations}
                    productList={this.props.order.productList}
                    totalPrice={this.props.order.totalPrice}
                    subtotalExclVat={this.props.order.subtotalPrice}
                    subtotalInclVat={this.props.order.subtotalPriceInclVat}
                    vatPrice={this.props.order.vatPrice}
                    shippingPriceExclVat={this.props.order.shippingPrice}
                    shippingPriceInclVat={this.props.order.shippingPriceInclVat}
                />
                <div className="buttons-set no-print">
                    <button type="button" title={this.translations.closeWindow} className="button" onClick={() => { window.close(); }}>
                        {this.translations.closeWindow}
                    </button>
                </div>
            </>
        );
    }
}

OrderPrint.contextType = TranslationsContext;