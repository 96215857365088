import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import StaticContentContext from '../../infrastructure/context/static-content/static-content.context';
import IStaticContentContext from '../../infrastructure/context/static-content/static-content.context.interface';

const SEOMetatags = (): JSX.Element | null => {
    const location = useLocation();
    const staticContentContext = useContext<IStaticContentContext>(StaticContentContext);
    const { seo } = staticContentContext;
    
    if (!seo)
        return null;

    const metadata = GetMetadataBasedOnLocation();

    return (
        <Helmet>
            {/* Favicon */}
            <link rel="icon" type="image/png" href={seo.faviconIcon} sizes="16x16" />
            <link rel="shortcut icon" href={seo.faviconIcon} />
            <link rel="apple-touch-icon" href={seo.faviconIcon} />

            {/* HTML Meta Tags */}
            <title>{metadata.title}</title>
            <meta name="description" content={metadata.description} />
            <meta name="keywords" content={metadata.keywords} />

            {/* Google + Search Engine Tags */}
            <meta itemProp="name" content={metadata.title} />
            <meta itemProp="description" content={metadata.description} />
            <meta itemProp="image" content={metadata.imageUrl} />

            {/* Facebook Meta Tags */}
            <meta property="og:title" content={metadata.title} />
            <meta property="og:description" content={metadata.description} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={metadata.imageUrl} />

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={metadata.title} />
            <meta name="twitter:description" content={metadata.description} />
            <meta name="twitter:image" content={metadata.imageUrl} />
        </Helmet>
    );

    function GetMetadataBasedOnLocation(): MetaData {
        const currentPage = seo.pages.find(x => x.route === location.pathname);

        if (currentPage) {
            return {
                title: currentPage.title,
                description: currentPage.description,
                keywords: currentPage.keywords,
                imageUrl: currentPage.imageUrl ? currentPage.imageUrl : seo.imageUrl
            }
        }

        return {
            title: seo.defaultTitle,
            description: seo.defaultDescription,
            keywords: seo.defaultKeywords,
            imageUrl: seo.imageUrl
        };
    };
}

type MetaData = {
    title: string,
    description: string,
    keywords: string,
    imageUrl: string
}


export default SEOMetatags;