
import * as React from 'react';
import { EventTypes, KeyCodes, RouterPaths, TranslationKeys } from '../../infrastructure/const';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import CheckUtils from '../../utilities/check-utils';
import TranslationManager from '../../infrastructure/translation-manager';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';

type SearchBarState = {
  searchPhrase: string;
  redirect: boolean;
};

interface SearchBarProps extends RouteComponentProps {
  searchCallback: (() => void);
}

class SearchBox extends React.Component<SearchBarProps, SearchBarState> {
  private searchInputRef: React.RefObject<HTMLInputElement>;

  static defaultProps: Partial<SearchBarProps> = {
    searchCallback: () => {/* */ }
  };

  constructor(props: SearchBarProps) {
    super(props);
    this.state = {
      searchPhrase: '',
      redirect: false
    };
    this.searchInputRef = React.createRef();
  }

  componentDidUpdate(prevProps: RouteComponentProps): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let searchPhrase : string = '';
      if (this.props.location.pathname.includes(RouterPaths.SearchResultsPage)) {
        searchPhrase = this.getSearchPhraseFromURL();
      }
      this.setState({ searchPhrase });
    }
  }

  isClickEvent(event: any): boolean {
    return event.type === EventTypes.Click;
  }

  isEnterKeypressEvent(event: any): boolean {
    return event.type === EventTypes.Keypress && event.charCode === KeyCodes.Return;
  }

  handleSearchAndRedirect(event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (!this.isClickEvent(event) && !this.isEnterKeypressEvent(event)) {
      this.setState({ redirect: false });
      return;
    }
    event.preventDefault();

    if (CheckUtils.isNullOrEmptyString(this.searchInputRef.current!.value)) {
      this.setState({ redirect: false });
      return;
    }

    this.setState({ redirect: true }, () => {
      if (!CheckUtils.isNullObject(this.props.searchCallback)) {
        this.props.searchCallback();
        this.setState({ redirect: false });
      }
    });
  }

  onSearchPhraseUpdated(event: any): void {
    this.setState({
      searchPhrase: event.target.value,
    });
  }

  getSearchPhraseFromURL() : string {
    const searchPhrase = this.props.location.pathname.split('/').pop();
    return searchPhrase !== this.props.location.pathname && !CheckUtils.isNullObject(searchPhrase) ? searchPhrase as string : '';
  }

  renderRedirect = (): any => {
    if (this.state.redirect) {
      return <Redirect push to={`${RouterPaths.SearchResultsPage}/${this.state.searchPhrase}`} />;
    }
  }

  render(): any {
    const translationManager = new TranslationManager(this.context);

    return (
      <div className="input-wrapper">
        {this.renderRedirect()}
        <input
          type="text"
          className="input-search"
          placeholder={translationManager.getTranslation(TranslationKeys.HEADER_SEARCH_LABEL)}
          value={this.state.searchPhrase}
          onChange={event => this.onSearchPhraseUpdated(event)}
          onKeyPress={event => this.handleSearchAndRedirect(event)}
          ref={this.searchInputRef}
        />
        <button className="search-icon" onClick={event => this.handleSearchAndRedirect(event)} role="presentation"></button>
        {/* <button className="delete-value" role="button" onClick={event => this.clearSearchValue(event)} ></button>  */}
      </div>
    );
  }
}

SearchBox.contextType = TranslationsContext;

export default withRouter(SearchBox);