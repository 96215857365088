import React, { useContext } from 'react';
import StaticContentContext from '../../../infrastructure/context/static-content/static-content.context';
import NavigationContext from '../../../infrastructure/context/navigation/navigation.context';
import CheckUtils from '../../../utilities/check-utils';

const ProductsCategoryBanner = (): JSX.Element | null => {
    const { activeCategory } = useContext(NavigationContext);
    const { categoryHeaders } = useContext(StaticContentContext);

    if (CheckUtils.isNullObject(activeCategory) || CheckUtils.isNullOrEmptyArray(categoryHeaders) || activeCategory!.length === 0) {
        return null;
    }

    let activeCategoryRoute = activeCategory![0].url.replace("/", "");
    for (let index = 1; index < activeCategory!.length; index++) {
        activeCategoryRoute += activeCategory![index].url;
    }
    
    const header = categoryHeaders.find(x => x.route === activeCategoryRoute);

    if (header === null || header === undefined) {
        return null;
    }

    if (CheckUtils.isNullOrEmptyString(header.image) || CheckUtils.isNullOrEmptyString(header.title)) {
        return null;
    }

    return (
        <div className="category-top">
            <div className="wrapper">
                <div className="category-top">
                    <div className="category-description__title">
                        <h2>{header.title}</h2>
                        <p><span>{header.subtitle}</span></p>
                        {header.additionalLines.map((line, index) => (
                            <p key={`${header!.title}-line-${index}`}><span>{line}</span></p>
                        ))}
                    </div>
                    <div className="category-description__img">
                        <img alt={header.title} src={header.image} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductsCategoryBanner;