/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

type HyperlinkButtonProps = {
  onClick: (() => void),
  id: string,
  title: string,
  className: string,
  href: string
};

const HyperlinkButton = (props: HyperlinkButtonProps & Readonly<{ children?: React.ReactNode }>) => {

  function handleClick(e: React.SyntheticEvent<HTMLAnchorElement>) {
    e.preventDefault();
    props.onClick();
  }

  return (
    <a href={props.href} id={props.id} title={props.title} className={props.className} onClick={(e: React.SyntheticEvent<HTMLAnchorElement>) => handleClick(e)}>
      {props.children}
    </a>
  );
};

HyperlinkButton.defaultProps = {
  id: '',
  title: '',
  href: '#'
} as Pick<HyperlinkButtonProps, Exclude<keyof HyperlinkButtonProps, "onClick">>;
export default HyperlinkButton;