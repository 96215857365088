import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../search/search-bar';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import { RouterPaths, TranslationKeys } from '../../infrastructure/const';
import TranslationManager from '../../infrastructure/translation-manager';
import IUserContext, { IUserProfile } from '../../infrastructure/context/user/user.context.interface';
import HyperlinkButton from '../common/hyperlink-button';
import AccountMenuLink from '../account/menu/account-menu-link';
import NavigationContext from '../../infrastructure/context/navigation/navigation.context';
import StaticContentContext from '../../infrastructure/context/static-content/static-content.context';
import CheckUtils from '../../utilities/check-utils';
import { ProductCategory } from '../../infrastructure/http/modules/product/product.models';

type MobileHeaderMenuProps = {
    userContext: IUserContext;
    isActive: boolean;
    onMenuVisibilityChange: ((isVisible: boolean) => void);
};

type State = {
    showAccountLink: boolean;
    showCustomerServiceLink: boolean;
    expandedCategoryId: string;
};

export default class MobileHeaderMenu extends React.Component<MobileHeaderMenuProps, State> {
    constructor(props: MobileHeaderMenuProps) {
        super(props);

        this.state = {
            showAccountLink: false,
            showCustomerServiceLink: false,
            expandedCategoryId: ""
        };
    }

    renderCategory(category: ProductCategory): ReactNode {
        if (category.subcategories && category.subcategories.length > 0) {
            return (
                <li className="level0 nav-connectline even first nav-1">
                    <HyperlinkButton
                        className="menu-link"
                        onClick={() => this.handleExpandableCategoryClicked(category)}>
                        {category.title}
                        <span className="icon-my-account"></span>
                    </HyperlinkButton>
                    <ul className="account-nav" style={{ display: this.isCategoryExpanded(category) ? "block" : "none" }}>
                        {category.subcategories.map((subcategory) => (
                            <li className="level1"><Link to={category.url + subcategory.url} title={subcategory.title} onClick={() => this.handleRedirectClick()}>{subcategory.title}</Link></li>
                        ))}
                    </ul>
                </li>
            )
        }

        return (
            <li className="level0 nav-connectline even first nav-1">
                <Link to={category.url} className="menu-link" onClick={() => this.handleRedirectClick()}>{category.title}</Link>
            </li>
        )
    }

    public render(): ReactNode {
        if (!this.props.isActive) {
            return null;
        }

        const translationManager: TranslationManager = new TranslationManager(this.context);
        const translations = {
            accountDashboard: translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_DASHBOARD),
            addressBook: translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_ADDRESS_BOOK),
            myOrders: translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_MY_ORDERS),
            logout: translationManager.getTranslation(TranslationKeys.ACCOUNT_MENU_LOGOUT),
            customerService: translationManager.getTranslation(TranslationKeys.HEADER_CUSTOMERSERVICE_LABEL)
        };

        const userProfile: IUserProfile = this.props.userContext.user.userProfile;

        return (
            <div className="wrapper clearfix hide-paymentpage mobile-wr">
                <div className="header__menu">
                    <ul id="nav" className="clearfix menu-standard-navigation">
                        <NavigationContext.Consumer>
                            {(navigation) => (
                                navigation.categories.map((category) => (

                                    this.renderCategory(category)
                                ))
                            )}
                        </NavigationContext.Consumer>
                    </ul>
                    <div className="br-small">
                        <form id="search_mini_form-1">
                            <SearchBar searchCallback={() => this.handleRedirectClick()} />
                        </form>

                        <ul className="links active">
                            <li className="first last customer-service">
                                <HyperlinkButton
                                    onClick={() => this.handleCustomerServiceMenuClicked()}>
                                    {translations.customerService}
                                    <span className="icon-my-account"></span>
                                </HyperlinkButton>
                                <StaticContentContext.Consumer>
                                    {(staticContent) => (
                                        !CheckUtils.isNullObject(staticContent.customerService) && !CheckUtils.isNullObject(staticContent.customerService.menu) &&
                                        <ul className="account-nav" style={{ display: this.state.showCustomerServiceLink ? "block" : "none" }}>
                                            {staticContent.customerService.menu.map((link) => (
                                                <li><Link to={RouterPaths.CustomerService + '/' + link.route} title={link.title} onClick={() => this.handleRedirectClick()}>{link.title}</Link></li>
                                            ))}
                                        </ul>
                                    )}
                                </StaticContentContext.Consumer>
                            </li>
                        </ul>

                        <ul className="links active">
                            <li className="first last">
                                {userProfile && userProfile.isAuthenticated &&
                                    <>
                                        <HyperlinkButton
                                            onClick={() => this.handleAccountShowMenuClicked()}
                                            title={userProfile.email}
                                            className='my-account logged-in'
                                        >
                                            {userProfile.firstName} {userProfile.lastName}
                                            <span className="icon-my-account"></span>
                                        </HyperlinkButton>
                                    </>
                                }
                                {userProfile && !userProfile.isAuthenticated &&
                                    <Link to={RouterPaths.Login} onClick={() => this.handleRedirectClick()} title="Log In">{translationManager.getTranslation(TranslationKeys.HEADER_LOGIN_BUTTON)}</Link>
                                }
                                <ul className="account-nav" style={{ display: this.state.showAccountLink ? "block" : "none" }}>
                                    <AccountMenuLink link={RouterPaths.AccountDashboard} translation={translations.accountDashboard} postClickAction={() => this.handleRedirectClick()} />
                                    <AccountMenuLink link={RouterPaths.AccountAddressBook} translation={translations.addressBook} postClickAction={() => this.handleRedirectClick()} />
                                    <AccountMenuLink link={RouterPaths.AccountOrderHistory} translation={translations.myOrders} postClickAction={() => this.handleRedirectClick()} />
                                    <li>
                                        <HyperlinkButton title={translations.logout} onClick={() => this.handleLogoutClicked()}>
                                            {translations.logout}
                                        </HyperlinkButton>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    private handleRedirectClick() {
        this.hideMenu();
        this.setState({ showAccountLink: false, showCustomerServiceLink: false });
    }

    private hideMenu(): void {
        this.props.onMenuVisibilityChange(false);
    }

    private handleLogoutClicked() {
        this.props.userContext.clearUser();
        this.hideMenu();
    }

    private handleAccountShowMenuClicked() {
        this.setState({ showAccountLink: !this.state.showAccountLink });
    }

    private handleCustomerServiceMenuClicked() {
        this.setState({ showCustomerServiceLink: !this.state.showCustomerServiceLink });
    }

    private handleExpandableCategoryClicked(category: ProductCategory) {
        if (this.state.expandedCategoryId === category.id.toString()) {
            this.setState({ expandedCategoryId: "" })
        } else {
            this.setState({ expandedCategoryId: category.id.toString() })
        }
    }

    private isCategoryExpanded(category: ProductCategory) {
        return this.state.expandedCategoryId === category.id.toString();
    }
}

MobileHeaderMenu.contextType = TranslationsContext;
