import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { RouterPaths, RouterStateParams, TranslationKeys } from '../infrastructure/const';
import AccountMenu from './account/menu/account-menu';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import TranslationManager from '../infrastructure/translation-manager';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import UserContext from '../infrastructure/context/user/user.context';
import { CreateSubscriptionRequest } from '../infrastructure/http/modules/subscriptions/subscriptions.models';

export const EditMarketingMaterialsPage: React.FC = () => {
    return (
        <UserContext.Consumer>
            {(userContext: IUserContext) =>
                <TranslationsContext.Consumer>
                    {(translationsContext: ITranslationsContext) =>
                        <EditMarketingMaterialsPageInner userContext={userContext} translationsContext={translationsContext} />
                    }
                </TranslationsContext.Consumer>
            }
        </UserContext.Consumer>
    );
};

type Props = {
    userContext: IUserContext;
    translationsContext: ITranslationsContext;
};

type State = {
    receiveMarketingMaterials: boolean;
    redirectToAccountDashboard: boolean;
    redirectToLogin: boolean;
    isLoaded: boolean;
};

class EditMarketingMaterialsPageInner extends React.Component<Props, State> {
    constructor(props: Props, private translations: { [key: string]: string }) {
        super(props);

        this.state = {
            receiveMarketingMaterials: false,
            redirectToAccountDashboard: false,
            redirectToLogin: false,
            isLoaded: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(): void {
        this.setState({ receiveMarketingMaterials: this.props.userContext.isSubscribed('Newsletter') });
        const translationManager = new TranslationManager(this.props.translationsContext);

        this.translations = {
            header: translationManager.getTranslation(TranslationKeys.ACCOUNT_MARKETINGMATERIAL_HEADER),
            iWantToReceiveMarketingMaterials: translationManager.getTranslation(TranslationKeys.ACCOUNT_MARKETINGMATERIAL_IWANTTORECEIVE),
            save: translationManager.getTranslation(TranslationKeys.ACCOUNT_SAVE),
        };

        this.setState({ isLoaded: true });
    }

    public render(): ReactNode {
        if (!this.state.isLoaded) {
            return null;
        }

        return (
            <div className='col2-left-layout oticon-address newsletter-manage-index'>
                <div className='wrapper'>
                    <AccountMenu />
                    {this.renderRedirectToLogin()}
                    {this.renderRedirectToDashboard()}
                    <div className='col-main'>
                        <div className='my-account'>
                            <div className='dashboard account-wr '>
                                <h2>{this.translations.header}</h2>
                                <form onSubmit={this.handleSubmit}>
                                    <ul>
                                        <li className='control'>
                                            <input
                                                type='checkbox'
                                                name='is_subscribed'
                                                id='subscription'
                                                checked={this.state.receiveMarketingMaterials}
                                                onChange={this.handleChange}
                                                className='css-checkbox hidden-checkbox'
                                            />
                                            <label htmlFor='subscription' className='css-label'>{this.translations.iWantToReceiveMarketingMaterials}</label>
                                        </li>
                                    </ul>
                                    <button type='submit' title={this.translations.save} className='button btn-white'>{this.translations.save}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderRedirectToLogin(): ReactNode {
        return (
            <>
                {this.state.redirectToLogin && <Redirect to={RouterPaths.Login} />}
            </>
        );
    }

    private renderRedirectToDashboard(): ReactNode {
        return (
            <>
                {this.state.redirectToAccountDashboard &&
                    <Redirect
                        push
                        to={{
                            pathname: RouterPaths.AccountDashboard,
                            state: { [RouterStateParams.ShowEditedMarketingMaterialsSuccessMessage]: true },
                        }}
                    />
                }
            </>
        );
    }

    private handleSubmit(event: React.FormEvent) {
        event.preventDefault();

        const userContext: IUserContext = this.props.userContext;

        if (!userContext.user.tokenInfo) {
            return;
        }

        const { userProfile } = this.props.userContext.user;

        let request: Promise<void>;

        if (this.state.receiveMarketingMaterials) {
            const data: CreateSubscriptionRequest = {
                email: userProfile.email!,
                type: 'Newsletter'
            };
            request = userContext.subscribe(data);
        } else {
            request = userContext.deleteSubscription('Newsletter');
        }

        request
            .then(() => this.setState({ redirectToAccountDashboard: true }))
            .catch(() => this.catchHttpError(userContext));
    }

    private handleChange(event: React.SyntheticEvent<HTMLInputElement>) {
        const { checked } = event.currentTarget;

        this.setState({ receiveMarketingMaterials: checked });
    }

    private catchHttpError(userContext: IUserContext) {
        userContext.clearUser();
        this.setState({ redirectToLogin: true });
    }
}

export default EditMarketingMaterialsPage;
