import React from 'react';
import { useContext } from 'react';
import { ProductsSelect, TranslationKeys } from '../../infrastructure/const';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import { Product } from '../../infrastructure/http/modules/product/product.models';
import TranslationManager from '../../infrastructure/translation-manager';
import CheckUtils from '../../utilities/check-utils';
import SortUtils from '../../utilities/sort-utils';

type Props = {
    productsList: Array<Product>,
    handleProductListSorting: ((newList: Array<Product>) => void),
    currentItemsCount: number
}

const SortByToolbar = (props: Props) => {
    const translationContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationContext);
    const translations: { [id: string]: string } = {
        showingProductsAmount: translationManager.getTranslation(TranslationKeys.SEARCH_SHOWINGPRODUCTSAMOUNT),
        totalResults: translationManager.getTranslation(TranslationKeys.SEARCH_TOTALRESULTS),
        sortByPlaceholder: translationManager.getTranslation(TranslationKeys.SEARCH_SORTBYPLACEHOLDER),
        sortByRelevance: translationManager.getTranslation(TranslationKeys.SEARCH_SORTBYRELEVANCE),
        sortByName: translationManager.getTranslation(TranslationKeys.SEARCH_SORTBYNAME),
        sortByPrice: translationManager.getTranslation(TranslationKeys.SEARCH_SORTBYPRICE),
    }

    function handleProductListSorting(event: React.FormEvent<HTMLSelectElement>): void {
        const productsList = [...props.productsList];

        if (CheckUtils.isNullObject(event.currentTarget) || CheckUtils.isNullObject(event.currentTarget.value)) {
            return props.handleProductListSorting(productsList);
        }

        const selectedValue = event.currentTarget.value;

        switch (selectedValue) {
            case ProductsSelect.Name.toString():
                props.handleProductListSorting(SortUtils.sortProductsByName(productsList));
                break;
            case ProductsSelect.Price.toString():
                props.handleProductListSorting(SortUtils.sortProductsByPrice(productsList));
                break;
            default:
                props.handleProductListSorting(productsList);
                break;
        }
    }


    return (
        <div className="toolbar">
            <div className="pagers-block" data-role="pagers-block">
                <div className="amount">{translations.showingProductsAmount} {props.currentItemsCount} {translations.totalResults} {props.productsList.length}</div>
                <div className="sort-by">
                    <select onChange={event => handleProductListSorting(event)}>
                        <option disabled={true}>{translations.sortByPlaceholder}</option>
                        <option value={ProductsSelect.Relevance} key={ProductsSelect.Relevance}>{translations.sortByRelevance}</option>
                        <option value={ProductsSelect.Name} key={ProductsSelect.Name}>{translations.sortByName}</option>
                        <option value={ProductsSelect.Price} key={ProductsSelect.Price}>{translations.sortByPrice}</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default SortByToolbar;