import React, { useContext } from 'react';
import { TranslationsPrefix } from '../../../infrastructure/const';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';

type Props = {
    shippingMethod: string;
};

const SelectedShippingMethod = (props: Props): JSX.Element => {
    let translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);

    const shippingMethodTranslation = translationManager.getTranslation(TranslationsPrefix.Checkout_ShippingTypes + props.shippingMethod);

    return (
        <>{shippingMethodTranslation}</>
    )
};

export default SelectedShippingMethod;