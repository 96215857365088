import { Address, UserProfileResponse } from "../auth/auth.models";

export class AuthResponseMapper {
    public static mapUserProfileResponse(jsonResponse: any): UserProfileResponse {
        return {
            userId: jsonResponse.id,
            firstName: jsonResponse.firstName,
            middleName: jsonResponse.middleName,
            lastName: jsonResponse.lastName,
            email: jsonResponse.email,
            receiveMarketingMaterials: jsonResponse.receiveMarketingMaterials,
            addressBook: this.mapAddressBook(jsonResponse),
            customerPreference: jsonResponse.customerPreference,
            favouriteProducts: jsonResponse.favouriteProducts
        };
    }

    public static mapAddressBook(jsonResponse: any): Address[] {
        if (!jsonResponse || !jsonResponse.addressBook || jsonResponse.addressBook.length === 0) {
            return [];
        }

        return jsonResponse.addressBook.map((jsonAddress: any) => this.mapSingleAddress(jsonAddress));
    }

    private static mapSingleAddress(jsonAddress: any): Address {
        return {
            id: jsonAddress.id,
            firstName: jsonAddress.firstName,
            middleName: jsonAddress.middleName,
            lastName: jsonAddress.lastName,
            company: jsonAddress.company,
            telephone: jsonAddress.telephone,
            fax: jsonAddress.fax,
            streetAddress1: jsonAddress.streetAddress1,
            streetAddress2: jsonAddress.streetAddress2,
            city: jsonAddress.city,
            postalCode: jsonAddress.postalCode,
            country: jsonAddress.country,
            isDefaultBillingAddress: jsonAddress.isDefaultBillingAddress,
            isDefaultShippingAddress: jsonAddress.isDefaultShippingAddress,
        };
    }
}