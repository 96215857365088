export const ItemAvailability = {
    InStock: "https://schema.org/InStock",
    Discontinued: "https://schema.org/Discontinued",
    InStoreOnly: "https://schema.org/InStoreOnly",
    LimitedAvailability: "https://schema.org/LimitedAvailability",
    OnlineOnly: "https://schema.org/OnlineOnly",
    OutOfStock: "https://schema.org/OutOfStock",
    PreOrder: "https://schema.org/PreOrder",
    PreSale: "https://schema.org/PreSale",
    SoldOut: "https://schema.org/SoldOut"
}

export const ItemCondition = {
    New: "http://schema.org/NewCondition"
}

export const SchemaDataTypes = {
    Product: "Product",
    Brand: "Brand",
    Offer: "Offer",
    OfferShippingDetails: "OfferShippingDetails",
    MonetaryAmount: "MonetaryAmount",
    DefinedRegion: "DefinedRegion",
    ShippingDeliveryTime: "ShippingDeliveryTime",
    OpeningHoursSpecification: "OpeningHoursSpecification"
}

export const Context = "http://schema.org/";

export const DayOfWeek = {
    Monday: "https://schema.org/Monday",
    Tuesday: "https://schema.org/Tuesday",
    Wednesday: "https://schema.org/Wednesday",
    Thursday: "https://schema.org/Thursday",
    Friday: "https://schema.org/Friday"
}