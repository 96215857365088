import { CouponResponseCode, TranslationsPrefix } from "../infrastructure/const";
import ITranslationsContext from "../infrastructure/context/translations/translations-context.interface";
import TranslationManager from "../infrastructure/translation-manager";
import CheckUtils from "./check-utils";

export default class TranslationsUtils {

    static getPromoCodeResponseTranslation(translationContext: ITranslationsContext, key: keyof typeof CouponResponseCode) {
        const translationManager = new TranslationManager(translationContext);
        const prefix = TranslationsPrefix.Cart_CouponResponse;

        const translationKey = prefix + key;
        
        const translation = translationManager.getTranslation(translationKey);

        if (CheckUtils.isNullOrEmptyString(translation)) {
            return key;
        }

        return translation;
    }
    
}