import React, { useContext } from 'react';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import { TranslationKeys } from '../../infrastructure/const';
import TranslationManager from '../../infrastructure/translation-manager';
import CheckUtils from '../../utilities/check-utils';
import { Price, PromoResponse, QuantityTreshold } from '../../infrastructure/http/modules/product/product.models';

type Props = {
    priceResponse: Price,
    promoResponse: PromoResponse
};

const BulkPurchaseDisclaimer = (props: Props): JSX.Element => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);
    const translations: { [id: string]: string } = {
        disclaimer: translationManager.getTranslation(TranslationKeys.BULK_PURCHASE_DISCLAIMER)
    };

    function getDisclaimerText(quantityTresholdItem: QuantityTreshold) {
        return translations.disclaimer
            .replace("{0}", quantityTresholdItem.priceStr)
            .replace("{1}", quantityTresholdItem.quantity.toString());
    }

    const disclaimerArray: string[] = [];
    if (!CheckUtils.isNullObject(props.promoResponse) && !CheckUtils.isNullOrEmptyArray(props.promoResponse.quantityTreshold)) {
        props.promoResponse.quantityTreshold.forEach((item) => {
            disclaimerArray.push(getDisclaimerText(item));
        });
    }
    else if (!CheckUtils.isNullObject(props.priceResponse) && !CheckUtils.isNullOrEmptyArray(props.priceResponse.quantityTreshold)) {
        props.priceResponse.quantityTreshold.forEach((item) => {
            disclaimerArray.push(getDisclaimerText(item));
        });
    }
    
    return (
        <div className="disclaimer">
            {disclaimerArray.map((disclaimer) => (
                <span>{disclaimer}</span>
            ))}
        </div>
    );
};

BulkPurchaseDisclaimer.defaultProps = {
    displayAll: true
};

export default BulkPurchaseDisclaimer;