
import React from 'react';
import { GetHttpClientInstance } from '../http/http-context-provider';
import { FeatureFlags, DefaultFeatureFlags } from '../../const';
import ConfigurationContext from './configuration.context';
import CheckUtils from '../../../utilities/check-utils';
import { FrontendConfiguration, ShippingType } from '../../http/modules/configuration/configuration.models';
import { GetPaymentsMethodsResult } from '../../http/modules/payments/payments.models';

type State = {
    featureFlags: FeatureFlags;
    supportedCountries: Map<string, string>;
    shippingTypes: Array<ShippingType>;
    paymentTypes: GetPaymentsMethodsResult | null;
    cookieBannerCulture: string;
    brand: string | null;
    displaySellingPointsBar: boolean;
    paymentProvider: string;
    cultureName: string;
    recommendedProducts: Array<string>;
    cartRecommendedProducts: Array<string>;
};

class ConfigurationProvider extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);

        this.state = {
            featureFlags: DefaultFeatureFlags,
            supportedCountries: new Map<string, string>(),
            shippingTypes: new Array<ShippingType>(),
            paymentTypes: null,
            cookieBannerCulture: '',
            brand: null,
            displaySellingPointsBar: false,
            paymentProvider: '',
            cultureName: '',
            recommendedProducts: [],
            cartRecommendedProducts: [],
        };

        this.isFeatureFlagEnabled = this.isFeatureFlagEnabled.bind(this);
        this.getFeatureFlagValue = this.getFeatureFlagValue.bind(this);
        this.getPaymentTypes = this.getPaymentTypes.bind(this);
    }

    public componentDidMount(): void {
        const { configuration } = GetHttpClientInstance();

        configuration
            .getFrontEndconfiguration()
            .then((response: FrontendConfiguration) => {
                this.setState({
                    featureFlags: response.featureFlags,
                    supportedCountries: response.supportedCountries,
                    shippingTypes: response.shippingTypes,
                    cookieBannerCulture: response.cookieBannerCulture,
                    brand: response.brand,
                    displaySellingPointsBar: response.displaySellingPointsBar,
                    paymentProvider: response.paymentProvider,
                    cultureName: response.cultureName,
                    recommendedProducts: response.recommendedProducts,
                    cartRecommendedProducts: response.cartRecommendedProducts,
                });
            });
    }

    public getFeatureFlagValue(flag: keyof FeatureFlags): any {
        if (CheckUtils.isNullObject(this.state.featureFlags)) {
            return null;
        }
        let value: any = this.state.featureFlags[flag];
        if (CheckUtils.isNullObject(value)) {
            return DefaultFeatureFlags[flag];
        }
        return value;
    }

    public isFeatureFlagEnabled(flag: keyof FeatureFlags): boolean {
        const value = this.getFeatureFlagValue(flag);
        return (value === true || value === 'true');
    }

    public getPaymentTypes(): Promise<GetPaymentsMethodsResult> {
        return new Promise<GetPaymentsMethodsResult>((resolve, reject) => {
            if (!CheckUtils.isNullObject(this.state.paymentTypes)) {
                resolve(this.state.paymentTypes as GetPaymentsMethodsResult);
            }
            GetHttpClientInstance().payments.getPaymentMethods().then((result: GetPaymentsMethodsResult) => {
                this.setState({ paymentTypes: result }, () => {
                    resolve(result);
                });
            }).catch(() => {
                reject();
            });
        });
    }

    public render(): React.ReactNode {
        return (
            <ConfigurationContext.Provider
                value={{
                    featureFlags: this.state.featureFlags,
                    getPaymentMethods: this.getPaymentTypes,
                    shippingTypes: this.state.shippingTypes,
                    supportedCountries: this.state.supportedCountries,
                    isFeatureFlagEnabled: this.isFeatureFlagEnabled,
                    getFeatureFlagValue: this.getFeatureFlagValue,
                    cookieBannerCulture: this.state.cookieBannerCulture,
                    brand: this.state.brand,
                    displaySellingPointsBar: this.state.displaySellingPointsBar,
                    paymentProvider: this.state.paymentProvider,
                    cultureName: this.state.cultureName,
                    recommendedProducts: this.state.recommendedProducts,
                    cartRecommendedProducts: this.state.cartRecommendedProducts,
                }}
            >
                {this.props.children}
            </ConfigurationContext.Provider>
        );
    }
}

export default ConfigurationProvider;
