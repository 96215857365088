import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { TranslationKeys, RouterPaths, RouterStateParams } from '../../../infrastructure/const';
import IUserContext, { IAddress } from '../../../infrastructure/context/user/user.context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';

type Props = {
    type: string;
    userContext: IUserContext;
    configurationContext: IConfigurationContext;
};

type State = {
    navigateToAddAddress: boolean,
    navigateToEditAddress: boolean;
    address: IAddress;
    isAddressFound: boolean;
};

class DefaultAddress extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            navigateToAddAddress: false,
            navigateToEditAddress: false,
            address: {
                id: '',
                firstName: '',
                lastName: '',
                company: '',
                telephone: '',
                streetAddress1: '',
                city: '',
                postalCode: '',
                country: '',
                isDefaultBillingAddress: false,
                isDefaultShippingAddress: false,
            },
            isAddressFound: false,
        };
    }

    public componentDidMount(): void {
        const { addressBook } = this.props.userContext.user.userProfile;

        let address: IAddress;
        if (this.props.type === 'shipping') {
            const defaultAddress = addressBook.find(x => x.isDefaultShippingAddress);
            if (defaultAddress) {
                address = defaultAddress;
            } else {
                return;
            }
        } else {
            const defaultAddress = addressBook.find(x => x.isDefaultBillingAddress);
            if (defaultAddress) {
                address = defaultAddress;
            } else {
                return;
            }
        }

        this.setState({ isAddressFound: true, address: address });
    }

    public render(): ReactNode {
        const translationManager = new TranslationManager(this.context);

        const translations = {
            defaultBillingAddress: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_DEFAULTBILLINGADDRESS),
            defaultShippingAddress: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_DEFAULTSHIPPINGADDRESS),
            edit: translationManager.getTranslation(TranslationKeys.ACCOUNT_EDIT),
            youHaveNoDefaultBillingAddress: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_YOUHAVENODEFAULTBILLINGADDRESS),
            youHaveNoDefaultShippingAddress: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_YOUHAVENODEFAULTSHIPPINGADDRESS),
        };

        return (
            <div className='col-2 box-account__box'>
                <div className='box-top'>
                    <h3>{this.props.type === 'billing' ? translations.defaultBillingAddress : translations.defaultShippingAddress}</h3>
                </div>
                <div className='box-content'>
                    {this.state.isAddressFound ? this.renderAddress(translations) : this.renderNoAddress(translations)}
                </div>
            </div>
        );
    }

    private renderAddress(translations: { [key: string]: string }): ReactNode {
        return (
            <>
                <p>{this.state.address.firstName}</p>
                {!this.props.configurationContext.isFeatureFlagEnabled("hideCustomerMiddleName") &&
                    <p>{this.state.address.middleName}</p>
                }
                <p>{this.state.address.lastName}</p>
                <p>{this.state.address.company}</p>
                <p>T: {this.state.address.telephone}</p>
                <p>{this.state.address.fax}</p>
                <p>{this.state.address.streetAddress1}</p>
                <p>{this.state.address.streetAddress2}</p>
                <p>{this.state.address.city}</p>
                <p>{this.state.address.postalCode}</p>
                <p>{this.state.address.country}</p>
                <button type='button' className='button' onClick={() => { this.setState({ navigateToEditAddress: true }); }}>{translations.edit}</button>
                {this.state.navigateToEditAddress &&
                    <Redirect
                        push
                        to={{
                            pathname: RouterPaths.AccountAddressBookAddEdit,
                            state: { [RouterStateParams.EditAddressId]: this.state.address.id },
                        }}
                    />
                }
            </>
        );
    }

    private renderNoAddress(translations: { [key: string]: string }): ReactNode {
        const label = this.props.type === 'shipping'
            ? translations.youHaveNoDefaultShippingAddress
            : translations.youHaveNoDefaultBillingAddress;

        return (
            <>
                <p>{label}</p>
                <button type='button' className='button' onClick={() => { this.setState({ navigateToAddAddress: true }); }}>{translations.edit}</button>
                {this.state.navigateToAddAddress &&
                    <Redirect
                        push
                        to={{
                            pathname: RouterPaths.AccountAddressBookAddEdit,
                        }}
                    />
                }
            </>
        );
    }
}

DefaultAddress.contextType = TranslationsContext;

export default DefaultAddress;
