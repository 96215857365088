import React, { useState } from 'react';
import CheckUtils from '../../utilities/check-utils';

type CouponCodeProps = {
    id: string,
    selected: boolean,
    applied: boolean,
    value: string, //this.state.properties[CustomPropertyKeys.UK.ReferralCode]
    label: string, //this.translations.referralCodeLabel
    placeholder: string, //this.translations.referralCodePlaceholder
    buttonApplyLabel: string, //this.translations.apply
    buttonCancelLabel: string, //this.translations.cancel
    onValueChange: ((value: string) => void),
    displayResponseMessage: boolean,
    responseMessage: string,
    isErrorMessage: boolean
};

const CartCouponCode: React.FC<CouponCodeProps> = (props: CouponCodeProps) => {
    const [value, setValue] = useState(props.value);
    const [codeApplied, setCodeApplied] = useState(props.applied);
    const [checkboxSelected, setCheckboxSelected] = useState(props.selected);

    function applyClicked() {
        if (!CheckUtils.isNullOrEmptyString(value)) {
            setCodeApplied(true);
            props.onValueChange(value.trim());
        }
    }

    function cancelClicked() {
        setCheckboxSelected(false);
        setCodeApplied(false);
        props.onValueChange("");
    }

    function checkboxValueChanged(newValue: boolean) {
        setValue("");
        setCheckboxSelected(newValue);
        // If coupon code has already been added to cart -> update cart
        if (!newValue && props.value) {
            setCodeApplied(false);
            props.onValueChange("");
        }
    }

    return (
        <form id="discount-coupon-form">
            <div className="discount">
                <CheckboxInner
                    id={props.id}
                    label={props.label}
                    checked={checkboxSelected}
                    onValueChange={checkboxValueChanged}
                />
                {checkboxSelected &&
                    <div className="coupon-box">
                        <input
                            type="text"
                            value={value}
                            onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
                            className="input-text"
                            id={`${props.id}-coupon_code`}
                            name={`${props.id}-coupon_code`}
                            placeholder={props.placeholder} />
                        <ButtonInner
                            label={props.buttonApplyLabel}
                            onClick={applyClicked} />
                        {value && codeApplied &&
                            <ButtonInner
                                label={props.buttonCancelLabel}
                                onClick={cancelClicked} />
                        }
                    </div>
                }
                {props.displayResponseMessage && !CheckUtils.isNullOrEmptyString(props.responseMessage) &&
                    <div className={"response-disclaimer" + (props.isErrorMessage ? " error" : "")}>
                        {props.responseMessage}</div>
                }
            </div>
        </form>
    );
};

const CheckboxInner = (props: { checked: boolean, id: string, label: string, onValueChange: ((value: boolean) => void) }): JSX.Element => {
    return (
        <>
            <input
                checked={props.checked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const checked = e.target.checked;
                    props.onValueChange(checked);
                }}
                id={`${props.id}-coupon-label`}
                type="checkbox"
                name={`${props.id}-coupon-label`}
                className="hidden-checkbox css-checkbox" />
            <label className="css-label coupon-open" htmlFor={`${props.id}-coupon-label`}>{props.label}</label>
        </>
    );
};

const ButtonInner = (props: { label: string, onClick: (() => void) }): JSX.Element => {
    return (
        <>
            <button
                type="button"
                title={props.label}
                className="button"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.onClick()}
            >{props.label}
            </button>
        </>
    );
};

export default CartCouponCode;