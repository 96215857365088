import React, { ReactNode } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import { RouterPaths, ErrorCodes, TranslationKeys } from '../infrastructure/const';
import { Transaction, TransactionResponse } from '../infrastructure/http/modules/cart/cart.models';
import UserContext from '../infrastructure/context/user/user.context';
import CheckUtils from '../utilities/check-utils';
import Spinner from './common/spinner';
import PaymentMethodSelection from './payments/payment-method-selection';
import PaymentSummary from './common/payments/payment-summary';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import TranslationManager from '../infrastructure/translation-manager';

type Props = {
    id: string,
    correlationId?: string | undefined
};

type State = {
    isLoading: boolean,
    transaction: Transaction | null,
    redirectToLandingPage: boolean
};

class PaymentsPage extends React.Component<RouteComponentProps<Props>, State> {
    private id: string;
    private correlationId: string = "";

    constructor(props: RouteComponentProps<Props>, context: IUserContext) {
        super(props, context);

        this.id = this.props.match.params.id;
        if(this.props.match.params.correlationId !== undefined){
            this.correlationId = this.props.match.params.correlationId;
        }
        
        this.state = {
            isLoading: false,
            transaction: null,
            redirectToLandingPage: false
        };

        this.redirectToErrorPage = this.redirectToErrorPage.bind(this);
    }

    public componentDidMount(): void {
        GetHttpClientInstance().cart.getTransaction(this.id, this.context.user.userProfile.userId, this.correlationId).then((transactionResponse: TransactionResponse) => {
            this.correlationId = transactionResponse.correlationId;
            this.setState({ transaction: transactionResponse });
        }).catch((error: any) => {
            if (!CheckUtils.isNullObject(error) && !CheckUtils.isNullObject(error.response) && error.response.status === ErrorCodes.Unauthorized) {
                this.setState({
                    redirectToLandingPage: true
                });
                return;
            }
            this.redirectToErrorPage();
        });
    }

    public render(): ReactNode {
        if (this.state.redirectToLandingPage) {
            return <Redirect push to={RouterPaths.LandingPage} />;
        }

        if (CheckUtils.isNullObject(this.state.transaction)) {
            return (
                <div className='col1-layout'>
                    <div className="col-main">
                        <Spinner show />
                    </div>
                </div>
            );
        }

        return (
            <TranslationsContext.Consumer>
                {(translationsContext) =>
                    <div className='col1-layout'>
                        <h1 className="page-big-title payments-label--h1">{new TranslationManager(translationsContext).getTranslation(TranslationKeys.CHECKOUT_PAYMENT_HEADER)}</h1>
                        <div className="col-main">
                            <div className="cart-block">
                                <div className="wrapper">
                                    <div className="flex-wrapper">
                                        <div className="flex-wrapper__box-50">
                                            <PaymentSummary transaction={this.state.transaction as Transaction} />
                                        </div>
                                        <div className="flex-wrapper__box-50">
                                            <div className="payments-container">
                                                <div className="payments-container__heading payments-label--h2">
                                                    {new TranslationManager(translationsContext).getTranslation(TranslationKeys.PAYMENTMETHOD)}
                                                </div>
                                                <div className="payments-container__inner">
                                                    <PaymentMethodSelection
                                                        transaction={this.state.transaction as Transaction}
                                                        urlQuery={this.props.location.search}
                                                        id={this.id}
                                                        correlationId={this.correlationId}
                                                        redirectToErrorPage={this.redirectToErrorPage}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </TranslationsContext.Consumer>
        );
    }

    private redirectToErrorPage() {
        this.props.history.push(RouterPaths.Error);
    }
}

PaymentsPage.contextType = UserContext;

export default PaymentsPage;