import B2CHttp from './http';
import { HttpClientConfiguration } from './http.client.configuration';
import { IProductModule } from './modules/product/product.interface';
import { ProductModule } from './modules/product/product.client';
import { ITranslationsModule } from './modules/translations/translations.interface';
import { TranslationsModule } from './modules/translations/translations.client';
import { ICartModule } from './modules/cart/cart.interface';
import { CartModule } from './modules/cart/cart.client';
import { IOrdersModule } from './modules/orders/orders.interface';
import { OrdersModule } from './modules/orders/orders.client';
import { ICustomerServiceModule } from './modules/customer-service/customer-service.interface';
import { CustomerServiceModule } from './modules/customer-service/customer-service.client';
import { IHttp } from './http.interface';
import { IAuthModule } from './modules/auth/auth.interface';
import { AuthModule } from './modules/auth/auth.client';
import { IAccountModule } from './modules/account/account.interface';
import { AccountModule } from './modules/account/account.client';
import { IConfigurationModule } from './modules/configuration/configuration.interface';
import ConfigurationModule from './modules/configuration/configuration.client';
import { IStaticContentModule } from './modules/static-content/static-content.interface';
import StaticContentModule from './modules/static-content/static-content.client';
import { IPaymentsModule } from './modules/payments/payments.interface';
import { PaymentsModule } from './modules/payments/payments.client';
import { ISubscriptionsModule } from './modules/subscriptions/subscriptions.interface';
import { SubscriptionsModule } from './modules/subscriptions/subscriptions.client';

export default class HttpClient {
    public http: IHttp;
    public product: IProductModule;
    public translations: ITranslationsModule;
    public cart: ICartModule;
    public orders: IOrdersModule;
    public customerService: ICustomerServiceModule;
    public auth: IAuthModule;
    public account: IAccountModule;
    public configuration: IConfigurationModule;
    public staticContent: IStaticContentModule;
    public payments: IPaymentsModule;
    public subscriptions: ISubscriptionsModule;

    constructor(config: HttpClientConfiguration, errorCallback: (() => void)) {
        this.http = new B2CHttp(config, errorCallback);
        this.product = new ProductModule(this.http);
        this.translations = new TranslationsModule(this.http);
        this.cart = new CartModule(this.http);
        this.orders = new OrdersModule(this.http);
        this.customerService = new CustomerServiceModule(this.http);
        this.auth = new AuthModule(this.http);
        this.account = new AccountModule(this.http);
        this.configuration = new ConfigurationModule(this.http);
        this.staticContent = new StaticContentModule(this.http);
        this.payments = new PaymentsModule(this.http);
        this.subscriptions = new SubscriptionsModule(this.http);
    }
}