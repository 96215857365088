import React, { useContext } from 'react';
import { TranslationKeys } from '../../infrastructure/const';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import { Price, Product, PromoResponse, Variant } from '../../infrastructure/http/modules/product/product.models';
import TranslationManager from '../../infrastructure/translation-manager';
import CheckUtils from '../../utilities/check-utils';
import BulkPurchaseDisclaimer from '../common/bulk-purchase-disclaimer';
import PriceText from '../common/price-text';

type Props = {
    product: Product,
    variant: Variant | null,
}

const ProductDetailsPriceBox = (props: Props) => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);

    function renderPriceBox(priceResponse: Price, promoResponse: PromoResponse, isOnPromotion: boolean): JSX.Element {
        return (
            <div className="price-box">
                <PriceText
                    promoPrice={promoResponse ? promoResponse.priceStr : ""}
                    regularPrice={priceResponse ? priceResponse.priceStr : ""}
                    isOnPromotion={isOnPromotion}
                />
                {(!CheckUtils.isNullObject(priceResponse) && priceResponse.isDiscountedProduct) &&
                    <div className="disclaimer">
                        <span>{new TranslationManager(translationsContext).getTranslation(TranslationKeys.PRODUCTDETAILS_ORDINARYPRICE) + " " + props.product.priceResponse.regularPriceStr}</span>
                    </div>
                }
                <BulkPurchaseDisclaimer priceResponse={priceResponse} promoResponse={promoResponse} />
            </div>
        );
    }

    if (props.variant !== null) {
        return renderPriceBox(props.variant.priceResponse, props.variant.productPromoResponse, props.variant.isOnPromotion);
    }

    return renderPriceBox(props.product.priceResponse, props.product.productPromoResponse, props.product.isOnPromotion);
};

export default ProductDetailsPriceBox;