import React, { useContext } from 'react';
import ConfigurationContext from '../../../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../../../infrastructure/context/configuration/configuration.context.interface';
import { OrderHistoryPricingDetails } from '../../../../infrastructure/http/modules/orders/orders.models.history';

type Props = {
    translations: { [key: string]: string };
    pricingDetails: OrderHistoryPricingDetails;
};

const OrderHistoryDetailsSummaryRow: React.FC<Props> = (props: Props) => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    const displayPriceIncludingVat = configurationContext.isFeatureFlagEnabled("displayPriceIncludingVat");

    return (
        <tfoot>
            {displayPriceIncludingVat ? (
                <tr className='subtotal'>
                    <td colSpan={4} className='a-right' align='right'>
                        {props.translations.subtotalInclVat}
                    </td>
                    <td align='right' className='last a-right'>
                        <span className='price'>{props.pricingDetails.subtotalInclVatStr}</span>
                    </td>
                </tr>
            ) : (
                    <tr className='subtotal'>
                        <td colSpan={4} className='a-right' align='right'>
                            {props.translations.subtotalExclVat}
                        </td>
                        <td align='right' className='last a-right'>
                            <span className='price'>{props.pricingDetails.subtotalStr}</span>
                        </td>
                    </tr>
                )}
            <tr className='shipping'>
                <td colSpan={4} className='a-right' align='right'>
                    {props.translations.shipping}
                </td>
                {displayPriceIncludingVat ? (
                    <td align='right' className='last a-right'>
                        <span className='price'>{props.pricingDetails.shippingInclVatStr}</span>
                    </td>
                ) : (
                        <td align='right' className='last a-right'>
                            <span className='price'>{props.pricingDetails.shippingStr}</span>
                        </td>
                    )}
            </tr>
            <tr>
                <td colSpan={4} className='a-right'>
                    {props.translations.tax}
                </td>
                <td className='last a-right'>
                    <span className='price'>{props.pricingDetails.taxStr}</span>
                </td>
            </tr>
            <tr className='grand_total'>
                <td colSpan={4} className='a-right' align='right'>
                    <strong>{props.translations.total}</strong>
                </td>
                <td align='right' className='last a-right'>
                    <strong><span className='price'>{props.pricingDetails.totalStr}</span></strong>
                </td>
            </tr>
        </tfoot>
    );
};

export default OrderHistoryDetailsSummaryRow;
