import React, { useContext } from 'react';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../../infrastructure/translation-manager';
import { TranslationKeys } from '../../../infrastructure/const';

const LandingPageSimpleWelcomeBanner = (): JSX.Element | null => {
    const context = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(context);
    return (
        <div className="welcome-banner">
            <div className="welcome-banner__img"></div>

            <div className="welcome-banner__wrapper">
                <h1 className="welcome-banner__heading">{translationManager.getTranslation(TranslationKeys.LANDINGPAGE_WELCOMEMESSAGE)}</h1>
            </div>
        </div>
    );
};

export default LandingPageSimpleWelcomeBanner;