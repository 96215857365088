import React, { ReactNode } from 'react';
import Input, { InputProps } from './input';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import { TranslationKeys } from '../../infrastructure/const';

class EmailInput extends React.Component<InputProps> {
    private inputRef = React.createRef<Input>();
    public render(): ReactNode {
        const parentProps = { ...this.props };

        parentProps.onValueChange = (name: string, value: any, isValid: boolean) => this.handleChange(name, value, isValid);
        parentProps.helpTextHtml = new TranslationManager(this.context).getTranslation(TranslationKeys.PLEASE_ENTER_VALID_EMAIL);
        parentProps.type = 'email';

        return (
            <Input {...parentProps} ref={this.inputRef} />
        );
    }

    overrideValueFromParent(newValue: string) {
        if (this.inputRef.current !== undefined && this.inputRef.current != null) {
            this.inputRef.current.overrideValueFromParent(newValue);
        }
    }

    private handleChange(name: string, value: any, isValid: boolean): void {
        let isRegexValid = false;

        if (/\S+@\S+\.\S+/.test(value)) {
            isRegexValid = true;
        }

        if (this.props.onValueChange) {
            this.props.onValueChange(name, value, isRegexValid);
        }
    }
}

EmailInput.contextType = TranslationsContext;

export default EmailInput;
