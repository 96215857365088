import TagManager from 'react-gtm-module';
import { GoogleTagManagerIds } from '../infrastructure/const';
import { TransactionResponse } from '../infrastructure/http/modules/cart/cart.models';

export default class GoogleTagManager {

    static initialize(): void {
        const isHorselbutik = window.location.href.includes("horselbutik.se");

        const tagManagerArgs = {
            gtmId: isHorselbutik ? GoogleTagManagerIds.horselbutik : GoogleTagManagerIds.default
        };

        TagManager.initialize(tagManagerArgs);
    }

    static purchase(transaction: TransactionResponse): void {
        const dataLayer = {
            dataLayer: {
                event: 'purchase',
                ecommerce: {
                    currencyCode: 'SEK', // temporary value for Horselbutik - to be changed to: transaction.currencyCode, // Currency code, SEK in this case
                    purchase: {
                        actionField: {
                            id: transaction.orderNumber, // Mandatory: Insert dynamic order ID
                            revenue: this.parsePrice(transaction.totalPrice), // Mandatory: Insert dynamic revenue
                            tax: this.parsePrice(transaction.vat), // Insert dynamic VAT 
                            shipping: this.parsePrice(transaction.shippingPriceInclVat) // Insert shipping cost paid by the customer
                        },
                        products: transaction.products.map((product) => ({
                            name: product.name,
                            id: product.itemNumber,
                            price: product.priceResponse.price,
                            quantity: product.quantity,
                        }))
                    }
                }
            }
        };
        TagManager.dataLayer(dataLayer);
    }

    private static parsePrice(priceStr: string): number {
        var price = priceStr.replace(/ /g,"").replace("kr", "").replace(",",".");
        return parseFloat(price);
    }
}