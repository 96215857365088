import React, { useContext, useRef, useState } from 'react';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import { TranslationKeys } from '../../infrastructure/const';
import CheckUtils from '../../utilities/check-utils';
import EmailInput from '../common/email-input';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import UserContext from '../../infrastructure/context/user/user.context';
import { CreateSubscriptionRequest } from '../../infrastructure/http/modules/subscriptions/subscriptions.models';

const NewsletterRegisterEmail: React.FC = () => {
    // Set context
    const translationsContext: ITranslationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);
    const userContext: IUserContext = useContext<IUserContext>(UserContext);

    // Set state
    const [email, setEmailValue] = useState('');
    const [emailHasError, setEmailError] = useState(false);

    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [termsAndConditionsHasError, setTermsAndConditionsError] = useState(false);

    const emailInputRef =  useRef<EmailInput>(null);

    // Functions
    function handleEmailValueChange(value: string, isValid: boolean): void {
        setEmailValue(value);
        setEmailError(!isValid);
    }

    function handleCheckboxValueChange(isChecked: boolean): void {
        setTermsAndConditions(isChecked);
        setTermsAndConditionsError(isChecked ? false : true);
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        let emailError = false;
        let termsAndConditionsError = false;

        // Validate
        if (CheckUtils.isNullOrEmptyString(email)) {
            setEmailError(true);
            emailError = true;
        }

        if (!termsAndConditions) {
            setTermsAndConditionsError(true);
            termsAndConditionsError = true;
        }

        if (emailError || termsAndConditionsError) {
            return;
        }

        var request: CreateSubscriptionRequest = {
            email: email,
            type: 'Newsletter'
        }
        userContext.subscribe(request)
            .finally(() => {
                setEmailValue('');
                if(emailInputRef && emailInputRef.current){
                    emailInputRef.current.overrideValueFromParent('');
                }
                setTermsAndConditions(false);
            })
    }

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="input-container">
                    <EmailInput
                        ref={emailInputRef}
                        id="newsletter-email"
                        name="newsletter-email"
                        label={translationManager.getTranslation(TranslationKeys.EMAIL)}
                        value={email}
                        hasError={emailHasError}
                        showValidationText={emailHasError}
                        isRequired={true}
                        onValueChange={(_name, value, isValid) => handleEmailValueChange(value, isValid)}
                        validationMessageClass="footer-validation-advice"
                    />
                </div>
                <div className="input-container">
                    <label className="custom-checkbox inverted">
                        {translationManager.getTranslation(TranslationKeys.NEWSLETTER_AGREEMENT)}
                        <input
                            type='checkbox'
                            id='newsletter-subscription'
                            className="custom-checkbox__input"
                            checked={termsAndConditions}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxValueChange(e.target.checked)}
                        />
                        <span className="custom-checkbox__checkmark"></span>
                    </label>
                    {termsAndConditionsHasError &&
                        <div className="footer-validation-advice" id="advice-required-entry:terms-and-conditions">
                            <span className="icon-arrow-up"></span>
                            {translationManager.getTranslation(TranslationKeys.REQUIRED)}
                        </div>
                    }
                </div>
                <button className="button" title={translationManager.getTranslation(TranslationKeys.NEWSLETTER_SUBMIT)} type="submit">
                    {translationManager.getTranslation(TranslationKeys.NEWSLETTER_SUBMIT)}
                </button>
            </form>
        </>
    );
};

export default NewsletterRegisterEmail;