import React from 'react';
import { MarketingDescriptionMetatags } from '../../infrastructure/const';
import ReactHtmlParser from 'react-html-parser';
import CheckUtils from '../../utilities/check-utils';
import MetatagInterpreter from '../../utilities/metatag-interpreter';

type Props = {
    description: string,
};

const ProductListMarketingDescription = (props: Props): JSX.Element | null => {

    if (CheckUtils.isNullOrEmptyString(props.description)) {
        return null;
    }

    let html = props.description;
    html = getLongDescriptionWithEllipsis(html);
    html = MetatagInterpreter.convertToHtml(html);

    return (
        <p>{ReactHtmlParser(html)}</p>
    );

    function getLongDescriptionWithEllipsis(description: string): string {
        if (CheckUtils.isNullOrEmptyString(description)) {
            return "";
        }

        // Convert custom metatags to new lines
        description = MetatagInterpreter.convertParagraphsToNewLines(description);

        // If description has paragraphs, show only 1st paragraph
        const paragraphs = description.split("\n");
        if (paragraphs.length > 1) {
            description = paragraphs[0];
        }

        // If there is no paragraphs in description or 1st paragraph is longer than 150 characters, show "..."
        if (description.length < 150) {
            return description;
        }

        //Do not inclued HTML tags into characters limit
        let charactersToAdd = 0;
        Object.keys(MarketingDescriptionMetatags).forEach((key: string) => {
            let re = MetatagInterpreter.convertToRegex(key);
            let count = (description.match(re) || []).length; // Number of occurences in the text
            const metatagLength = key.length;
            charactersToAdd += count * metatagLength;
        });

        return description.substr(0, 150 + charactersToAdd) + "...";
    }

};

export default ProductListMarketingDescription;