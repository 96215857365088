import React, { useContext, useEffect, useState } from 'react';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import { RouterPaths, TranslationKeys } from '../../infrastructure/const';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import { GetHttpClientInstance } from '../../infrastructure/context/http/http-context-provider';
import CheckUtils from '../../utilities/check-utils';
import { Product } from '../../infrastructure/http/modules/product/product.models';
import ProductListSlider, { SliderTranslations } from './product-list-slider';

type Props = {
    productIds: Array<string>;
    displaySeeAllButton: boolean;
    headerTitle?: string;
};

const RecommendedProductsSlider = (props: Props) => {
    const translationsContext: ITranslationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager: TranslationManager = new TranslationManager(translationsContext);

    const [products, setProducts] = useState<Product[]>([]);

    const sliderTranslations: SliderTranslations = {
        headerTitle: CheckUtils.isNullOrEmptyString(props.headerTitle) ? translationManager.getTranslation(TranslationKeys.PRODUCTSLIDER_HEADER_TITLE) : props.headerTitle!,
        seeAll: translationManager.getTranslation(TranslationKeys.SEEALL),
        addedToCart: translationManager.getTranslation(TranslationKeys.CART_ADDEDTOCART)
    }

    useEffect(() => {
        const client = GetHttpClientInstance();
        if (CheckUtils.isNullOrEmptyArray(props.productIds) || !CheckUtils.isNullOrEmptyArray(products)) {
            return;
        }

        var searchString = props.productIds.map((id) => `${id}`).join(',');
        client.product.search(searchString).then((response) => {
            let result: Array<Product> = []

            props.productIds.forEach((id) => {
                let product = response.products.find(x => x.itemNumber === id);
                if (product) {
                    result.push(product);
                }
            })

            setProducts(result);
        })
    }
    );

    if (CheckUtils.isNullOrEmptyArray(products)) {
        return null;
    }

    return (
        <ProductListSlider
            translations={sliderTranslations}
            products={products}
            displaySeeAllButton={props.displaySeeAllButton}
            seeAllRedirectionLink={RouterPaths.RecommendedProductsPage}
        />
    );
};

export default RecommendedProductsSlider;