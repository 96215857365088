export default class CheckUtils {

    static isNullObject(obj: any): boolean {
        return obj === undefined || obj === null;
    }

    static isNullOrEmptyArray(obj: any): boolean {
        return this.isNullObject(obj) || !Array.isArray(obj) || obj.length === 0;
    }

    static isNullOrEmptyString(str: string | undefined | null): boolean {
        return this.isNullObject(str) || str === '';
    }
}