import React from 'react';
import { Product, Variant } from '../../infrastructure/http/modules/product/product.models';
import CheckUtils from '../../utilities/check-utils';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

type Props = {
    product: Product,
    variant: Variant | null
}

const ProductImageContainer = (props: Props): JSX.Element | null => {
    // Variant is selected
    if (props.variant !== null) {
        if (!CheckUtils.isNullOrEmptyArray(props.variant.allImages)) {
            return <ProductImageGallery imagesUrls={props.variant.allImages} />;
        }

        if (!CheckUtils.isNullOrEmptyString(props.variant.imageUrl)) {
            return <SingleImageContainer imageUrl={props.variant.imageUrl} name={props.product.name} />;
        }
    }

    if (!CheckUtils.isNullOrEmptyArray(props.product.allImages)) {
        return <ProductImageGallery imagesUrls={props.product.allImages!} />;
    }

    if (!CheckUtils.isNullOrEmptyString(props.product.imageUrl)) {
        return <SingleImageContainer imageUrl={props.product.imageUrl} name={props.product.name} />;
    }

    return null;
};

const SingleImageContainer = (props: { imageUrl: string, name: string }) => {
    return (
        <div className="product-image-container">
            <img data-role="conf-main-image" src={props.imageUrl} alt={props.name} title={props.name} />
        </div>
    );
}

const ProductImageGallery = (props: { imagesUrls: Array<string> }) => {
    const images: ReactImageGalleryItem[] = [];

    props.imagesUrls!.forEach((imageUrl) => images.push({
        original: imageUrl,
    }));

    const renderNav = (onClick: React.MouseEventHandler<HTMLElement>, disabled: boolean, side: string): JSX.Element => {
        return (
            <button
                className={`image-gallery__nav image-gallery__nav--${side} ${disabled ? 'image-gallery__nav--disabled' : ''}`}
                disabled={disabled}
                onClick={onClick}
            />
        )
    };

    const renderLeftNav = (onClick: React.MouseEventHandler<HTMLElement>, disabled: boolean) => {
        return renderNav(onClick, disabled, 'left');
    }

    const renderRightNav = (onClick: React.MouseEventHandler<HTMLElement>, disabled: boolean) => {
        return renderNav(onClick, disabled, 'right');
    }

    return (
        <div className="product-image-container">
            <ImageGallery
                items={images}
                showNav={true}
                autoPlay={false}
                lazyLoad={false}
                infinite={false}
                showIndex={false}
                showBullets={false}
                showThumbnails={false}
                showPlayButton={false}
                showFullscreenButton={false}
                disableKeyDown={true}
                useBrowserFullscreen={false}
                renderLeftNav={renderLeftNav}
                renderRightNav={renderRightNav}
            // startIndex?: number;
            />
        </div>
    );
};


export default ProductImageContainer;