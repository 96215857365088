import React, { useContext, useState } from 'react';
import { TranslationKeys } from '../../infrastructure/const';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../infrastructure/context/translations/translations-context.interface';
import { Product, Variant } from '../../infrastructure/http/modules/product/product.models';
import TranslationManager from '../../infrastructure/translation-manager';
import ProductsEnrichmentUtils from '../../utilities/product-enrichment-utils';
import Select, { SelectOption } from '../common/select';

type Props = {
    product: Product,
    setSelectedVariant: ((variant: Variant | null) => void)
}

const ProductAttributes = (props: Props): JSX.Element | null => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);

    const [selectedAttributes, setSelectedAttributes] = useState<{ [key: string]: string }>({});

    if (!configurationContext.isFeatureFlagEnabled("useEnrichedProductData")) {
        return null;
    }

    if (!props.product.configurableAttributes || Object.keys(props.product.configurableAttributes).length === 0) {
        return null;
    }

    const configurableAttributes = Object.entries(props.product.configurableAttributes);

    function setAttribute(name: string, value: string) {
        let currentAttributes = Object.assign({}, selectedAttributes);
        currentAttributes[name] = value;
        setSelectedAttributes(currentAttributes);

        if (props.product.variations !== undefined) {
            const variant = props.product.variations.find(x => JSON.stringify(x.attributes) === JSON.stringify(currentAttributes));
            if (variant) {
                props.setSelectedVariant(variant)
            } else {
                props.setSelectedVariant(null);
            }
        }
    }

    return (
        <div className="attributes">
            {configurableAttributes.map(([name, values]) => (
                <div key={name}>
                    <span className="attributes__label">{ProductsEnrichmentUtils.getAttributeTranslation(translationsContext, name, "Name")}*</span>
                    <Select
                        name={name}
                        options={values.map((value) => (
                            {
                                valueAttribute: value,
                                valueTranslated: ProductsEnrichmentUtils.getAttributeTranslation(translationsContext, value, "Value")
                            } as SelectOption
                        ))}
                        isRequired={true}
                        displayLabel={false}
                        id={"select-" + name}
                        additionalClassName=""
                        selectedOption={selectedAttributes[name]}
                        onSelectionChange={(value: string) => {
                            setAttribute(name, value);
                        }}
                        placeholderText={new TranslationManager(translationsContext).getTranslation(TranslationKeys.SELECTOPTION)}
                    />
                </div>
            ))}

        </div>
    );
};

export default ProductAttributes;