import React, { useContext, useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { RouterPaths } from "../../infrastructure/const";
import INavigationContext from "../../infrastructure/context/navigation/navigation.context.interface";
import NavigationContext from "../../infrastructure/context/navigation/navigation.context";
import IConfigurationContext from "../../infrastructure/context/configuration/configuration.context.interface";
import ConfigurationContext from "../../infrastructure/context/configuration/configuration.context";
import CheckUtils from "../../utilities/check-utils";
import { Product } from "../../infrastructure/http/modules/product/product.models";
import AddToCartButton from "./add-to-cart-button";
import { Link } from "react-router-dom";
import ProductsEnrichmentUtils from "../../utilities/product-enrichment-utils";
import ProductListPriceBox from "./product-list-price-box";

type Props = {
  translations: SliderTranslations;
  products: Product[];
  displaySeeAllButton: boolean;
  seeAllRedirectionLink: string;
};

export type SliderTranslations = {
  headerTitle: string;
  seeAll: string;
  addedToCart: string;
};

const ProductListSlider = (props: Props): JSX.Element | null => {
  SwiperCore.use([Navigation]);

  if (CheckUtils.isNullOrEmptyArray(props.products)) {
    return null;
  }

  return (
    <div className="product-list-slider">
      <div className="product-list-slider__wrapper wrapper">
        <div className="product-list-slider__header">
          <h3 className="product-list-slider__heading-title">
            {props.translations.headerTitle}
          </h3>

          {props.displaySeeAllButton && (
            <Link
              to={{
                pathname: props.seeAllRedirectionLink,
                state: { products: props.products as Product[] },
              }}
              className="product-list-slider__link"
            >
              {props.translations.seeAll}
            </Link>
          )}
        </div>
        <Swiper
          speed={700}
          spaceBetween={16}
          slidesPerView={1.2}
          slidesPerGroup={1}
          className={"product-list-slider__slider-container"}
          navigation={{
            prevEl: ".product-list-slider__arrow--prev",
            nextEl: ".product-list-slider__arrow--next",
            disabledClass: "product-list-slider__arrow--disabled",
          }}
          breakpoints={{
            576: {
              spaceBetween: 16,
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            768: {
              spaceBetween: 16,
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            1024: {
              spaceBetween: 16,
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
          }}
        >
          {props.products.map((product) => (
            <SwiperSlide key={product.id}>
              <ProductListSliderItem
                addToCartTranslation={props.translations.addedToCart}
                product={product}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <button className="product-list-slider__arrow product-list-slider__arrow--prev"></button>
        <button className="product-list-slider__arrow product-list-slider__arrow--next"></button>
      </div>
    </div>
  );
};

const ProductListSliderItem = (props: {
  addToCartTranslation: string;
  product: Product;
}): JSX.Element => {
  const product = { ...props.product };

  const navigationContext = useContext<INavigationContext>(NavigationContext);
  const configurationContext =
    useContext<IConfigurationContext>(ConfigurationContext);

  const [addedToCart, setAddedToCart] = useState(false);

  function onAddedToCart() {
    setAddedToCart(true);

    setTimeout(() => {
      setAddedToCart(false);
    }, 2000);
  }

  return (
    <div className="product-list-slider__item-wrapper">
      {addedToCart && (
        <div className="product-incart">
          <span className="icon"></span>
          <span className="text">{props.addToCartTranslation}</span>
        </div>
      )}
      <Link
        to={{
          pathname: ProductsEnrichmentUtils.getProductDetailsUrl(
            configurationContext,
            navigationContext,
            product
          ),
          state: { product: product as Product },
        }}
        className="product-list-slider__item"
      >
        <div className="product-list-slider__img">
          <img src={product.imageUrl} alt="" />
        </div>
        <h4 className="product-list-slider__product-heading">{product.name}</h4>
        <div>
          <ProductListPriceBox
            priceResponse={product.priceResponse}
            productPromoResponse={product.productPromoResponse}
            isOnPromotion={product.isOnPromotion}
            additionalClass="slider-price"
          />
        </div>
      </Link>
      {!product.isConfigurable && (
        <AddToCartButton
          productId={product.id}
          quantity={1}
          classAttr="product-list-slider__button button buy-button"
          onClick={() => onAddedToCart()}
        />
      )}
    </div>
  );
};

export default ProductListSlider;
