import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { BrandName } from '../../../infrastructure/const';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';
import { Product } from '../../../infrastructure/http/modules/product/product.models';
import { ShippingType } from '../../../infrastructure/http/modules/configuration/configuration.models';
import { OfferShippingDetails, ProductSchema, ProductBrand, ProductOffer, ShippingRate, ShippingDestination, DeliveryTime } from './product-schema.models';
import { DayOfWeek } from './consts';
import MetatagInterpreter from '../../../utilities/metatag-interpreter';
import INavigationContext from '../../../infrastructure/context/navigation/navigation.context.interface';
import NavigationContext from '../../../infrastructure/context/navigation/navigation.context';
import ProductsEnrichmentUtils from '../../../utilities/product-enrichment-utils';
import CheckUtils from '../../../utilities/check-utils';

type Props = {
    product: Product
};


export const ProductDetailsSchema = (props: Props): JSX.Element | null => {
    const navigationContext = useContext<INavigationContext>(NavigationContext);

    const configurationContext: IConfigurationContext = useContext<IConfigurationContext>(ConfigurationContext);

    const shippingTypes: ShippingType[] = configurationContext.shippingTypes;
    const supportedCountries: string[] = Object.keys(configurationContext.supportedCountries);

    function GetBrandNameString(brandCode: string | null): string | null {
        if (!brandCode) {
            return BrandName.oticon; //If there is no brand in configuration, it is by default set to "oticon"
        }
        const brandObject: [string, string] | undefined = Object.entries(BrandName).find(([key, value]) => key === brandCode);
        const brand: string | null = brandObject ? brandObject[1] : null;
        return brand;
    }

    function GetDescription(product: Product): string {
        var marketingDescription = MetatagInterpreter.removeMarketingMetatags(product.marketingDescription);
        var marketingShortDescription = MetatagInterpreter.removeMarketingMetatags(product.marketingShortDescription);
        var subtitle = MetatagInterpreter.removeMarketingMetatags(product.subtitle);

        var description = [product.description, marketingDescription, marketingShortDescription, subtitle].filter(Boolean).join(" ");
        return description;
    }

    const structuredJSON = structuredData(props.product, GetBrandNameString(configurationContext.brand), shippingTypes, supportedCountries, navigationContext, configurationContext, GetDescription);

    const name = CheckUtils.isNullOrEmptyString(props.product.metaTitle) ? props.product.name : props.product.metaTitle;


    if (!configurationContext.isFeatureFlagEnabled("allowProductSchema")) {
        return (
            <Helmet>
                <title>{name}</title>
                <meta property="og:type" content="product" />
                {!CheckUtils.isNullOrEmptyString(props.product.metaDescription) && <meta name="description" content={props.product.metaDescription} />}
                {!CheckUtils.isNullOrEmptyString(props.product.metaKeywords) && <meta name="keywords" content={props.product.metaKeywords} />}
            </Helmet>
        )
    }

    return (
        <Helmet>
            <title>{name}</title>
            <meta property="og:type" content="product" />
            {!CheckUtils.isNullOrEmptyString(props.product.metaDescription) && <meta name="description" content={props.product.metaDescription} />}
            {!CheckUtils.isNullOrEmptyString(props.product.metaKeywords) && <meta name="keywords" content={props.product.metaKeywords} />}
            <script type="application/ld+json">{structuredJSON}</script>
        </Helmet>
    )
}

export const structuredData = (product: Product, brand: string | null, shippingTypes: ShippingType[], supportedCountries: string[], navigationContext: INavigationContext, configurationContext: IConfigurationContext, GetDescription: ((product: Product) => string)) => {

    let schema: ProductSchema = new ProductSchema();
    schema.name = product.name;
    schema.description = GetDescription(product);
    schema.image = product.imageUrl;
    schema.sku = product.itemNumber;
    schema.mpn = product.itemNumber;

    schema.offers = InitializeProductOffers();

    if (brand) {
        schema.brand = new ProductBrand(brand);
    }

    return JSON.stringify(schema);

    function InitializeProductOffers(): ProductOffer {
        let offer: ProductOffer = new ProductOffer();
        offer.price = product.priceResponse.price;
        offer.priceCurrency = product.priceResponse.currency;
        offer.url = ProductsEnrichmentUtils.getProductDetailsUrl(configurationContext, navigationContext, product);
        offer.shippingDetails = InitializeShippingDetails();
        return offer;
    }

    function InitializeShippingDetails(): OfferShippingDetails[] {
        let shippingDetailsList: OfferShippingDetails[] = [];

        shippingTypes.forEach((shipping: ShippingType) => {
            let shippingRate = new ShippingRate();
            shippingRate.value = shipping.cost;
            shippingRate.currency = shipping.currency;

            let shippingDetails = new OfferShippingDetails();
            shippingDetails.shippingRate = shippingRate;
            shippingDetails.shippingDestination = new ShippingDestination(supportedCountries.length > 1 ? supportedCountries : supportedCountries[0]);
            shippingDetails.deliveryTime = new DeliveryTime([DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday]);

            shippingDetailsList.push(shippingDetails);
        });

        return shippingDetailsList;
    }
};







