import React, { useContext } from 'react';
import 'react-tabs/style/react-tabs.css';
import { TranslationKeys } from '../../../infrastructure/const';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import CheckUtils from '../../../utilities/check-utils';
import SwitchableTabs, { TabProps } from '../../common/switchable-tabs';

export type Props = {
    marketingDescriptionHTML: string
}

const ProductInformationTabs = (props: Props): JSX.Element | null => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);

    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);
    if (!configurationContext.isFeatureFlagEnabled("useEnrichedProductData")) {
        return null;
    }

    if (CheckUtils.isNullOrEmptyString(props.marketingDescriptionHTML)) {
        return null;
    }

    const marketingDescriptionTab: TabProps = {
        name: translationManager.getTranslation(TranslationKeys.PRODUCTDETAILS_TABHEADER),
        html: props.marketingDescriptionHTML,
        isDisabled: false
    }

    const switchableTabs: Array<TabProps> = [marketingDescriptionTab];
    return (
        <SwitchableTabs tabs={switchableTabs} />
    );
};

export default ProductInformationTabs;