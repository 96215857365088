import React from "react";
import {
  AvailableNestedComponents,
} from "../../infrastructure/http/modules/static-content/static-content.models";

const NestedComponent = (props: {
  nestedComponentName: string;
  availableComponentTypes: AvailableNestedComponents;
}) => {
  if(!props.nestedComponentName || !props.availableComponentTypes) {
    return null;
  }

  const componentTypeKey = Object.keys(props.availableComponentTypes).find(
    (x) => x.toLowerCase() === props.nestedComponentName.toLowerCase()
  );

  if (!componentTypeKey) {
    return null;
  }

  const componentInfo = props.availableComponentTypes[componentTypeKey];
  const Component = componentInfo.component;
  return <Component {...componentInfo.props} />;
};

export default NestedComponent;
