import React from 'react';
import { GetPaymentsMethodsResult } from '../../infrastructure/http/modules/payments/payments.models';
import CheckUtils from '../../utilities/check-utils';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import classNames from 'classnames';
import { FeatureFlagValues } from '../../infrastructure/const';

type Props = {
    paymentMethodsTranslation: string,
    boldLabel?: boolean
};

type State = {
    paymentMethodsList: string[]
};

const imageSize = {
    Small: "small",
    Medium: "medium",
    Large: "large"
};

const creditCartType = "scheme";

const imageUrlPrefix = "https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos";

class PaymentIcons extends React.Component<Props, State>  {
    constructor(props: Props, context: IConfigurationContext) {
        super(props, context);

        this.state = {
            paymentMethodsList: []
        };
    }

    componentDidMount() {
        if(this.shouldRender())
        {
            this.getPaymentMethods();
        }
    }

    render() {
        if(this.shouldRender())
        {
            const paymentMethodsLabelClasses = classNames({
                'payment-methods__label': true,
                'payment-methods__label--bold': this.props.boldLabel
            });

            return (
                <div className="payment-methods">
                    <div className={paymentMethodsLabelClasses}>{this.props.paymentMethodsTranslation}</div>
                    {
                        this.state.paymentMethodsList.map((method, i) =>
                            <span className="payment-methods__icon" key={i}>
                                <img src={this.getIconURL(method)} width="48" alt={method} />
                            </span>
                        )
                    }
                </div >
            );
        }
        else
        {
            return null;
        }
    }

    private getPaymentMethods = (): void => {
        this.context.getPaymentMethods().then((result: GetPaymentsMethodsResult) => {
            if (CheckUtils.isNullObject(result) || CheckUtils.isNullOrEmptyString(result.paymentMethods)) {
                return;
            }

            const response = JSON.parse(result.paymentMethods);
            if (CheckUtils.isNullObject(response) || CheckUtils.isNullOrEmptyArray(response.paymentMethods)) {
                return;
            }

            let paymentMethodsList: string[] = [];
            response.paymentMethods.forEach((method: any) => {
                if (method.type === creditCartType) {
                    method.brands.forEach((brand: string) => paymentMethodsList.push(brand));
                } else {
                    paymentMethodsList.push(method.type);
                }
            });

            this.setState({ paymentMethodsList });
        });
    }

    private getIconURL = (paymentMethod: string): string => {
        return imageUrlPrefix + '/' + imageSize.Medium + '/' + paymentMethod + '.png';
    }

    private shouldRender() : boolean
    {
        return (this.context.paymentProvider == FeatureFlagValues.PayentProvider.Adyen);

    }
}

PaymentIcons.contextType = ConfigurationContext;
export default PaymentIcons;