import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import UserContext from '../../infrastructure/context/user/user.context';
import IUserContext from '../../infrastructure/context/user/user.context.interface';
import { UpdateCartRequest, TransactionResponse } from '../../infrastructure/http/modules/cart/cart.models';
import CacheManager from '../../infrastructure/cache/cache-manager';
import Spinner from '.././common/spinner';
import CheckUtils from '../../utilities/check-utils';
import { GetHttpClientInstance } from '../../infrastructure/context/http/http-context-provider';
import { withRouter } from 'react-router-dom';
import PaymentConfirmationInner from './common/payment-confirmation';
import OrderConfirmationInner from './common/order-confirmation';

type AdyenConfirmationProps = {
    transactionId: string;
    correlationId?: string | undefined;
};

type AdyenConfirmationState = {
    intervalId: NodeJS.Timeout | null;
    isLoading: boolean;
    orderNumber: string | null;
    paymentId: string | null;
};

class AdyenConfirmation extends React.Component<RouteComponentProps<AdyenConfirmationProps>, AdyenConfirmationState> {
    private transactionId: string;
    private correlationId: string = "";
    private invoideAddressKey: keyof UpdateCartRequest;
    private deliveryAddressKey: keyof UpdateCartRequest;

    constructor(props: RouteComponentProps<AdyenConfirmationProps>, context: IUserContext) {
        super(props, context);
        this.transactionId = this.props.match.params.transactionId;

        if(this.props.match.params.correlationId){
            this.correlationId = this.props.match.params.correlationId;
        }

        this.invoideAddressKey = "invoiceAddress";
        this.deliveryAddressKey = "deliveryAddress";

        this.state = {
            isLoading: true,
            intervalId: null,
            orderNumber: null,
            paymentId: null
        };
    }

    componentDidMount() {
        this.context.refreshCart();

        // Remove checkout address from cache
        CacheManager.removeItem(this.invoideAddressKey);
        CacheManager.removeItem(this.deliveryAddressKey);

        // Check if order was created
        this.startCheckingForOrderNumber();
        setTimeout(() => {
            this.stopCheckingForOrderNumber();
            this.setState({ isLoading: false });
        }, 15000);
    }

    componentWillUnmount() {
        this.stopCheckingForOrderNumber();
    }

    getOrderNumberFromTransaction() {
        GetHttpClientInstance().cart.getTransaction(this.transactionId, this.context.user.userProfile.userId, this.correlationId).then((transactionResponse: TransactionResponse) => {
            // Save Payment ID for payment confirmation
            this.setState({ paymentId: transactionResponse.paymentId });

            // If order number was returned - display order confirmation page
            if (!CheckUtils.isNullOrEmptyString(transactionResponse.orderNumber)) {
                this.stopCheckingForOrderNumber();
                this.setState({
                    orderNumber: transactionResponse.orderNumber,
                    isLoading: false
                });
            }
        }).catch((error: any) => {
            // Display payment confirmation
            this.stopCheckingForOrderNumber();
            this.setState({ isLoading: false });
        });
    }

    render(): ReactNode {
        if (this.state.isLoading) {
            return <Spinner show />;
        }

        if (this.state.orderNumber !== null) {
            return <OrderConfirmationInner orderNumber={this.state.orderNumber} />;
        }

        return <PaymentConfirmationInner transactionId={this.transactionId} paymentId={this.state.paymentId} />;
    }

    private startCheckingForOrderNumber() {
        const intervalId = setInterval(() => {
            this.getOrderNumberFromTransaction();
        }, 3000);
        this.setState({ intervalId: intervalId });
    }

    private stopCheckingForOrderNumber() {
        if (this.state.intervalId !== null) {
            clearInterval(this.state.intervalId);
        }
    }
}

AdyenConfirmation.contextType = UserContext;

export default withRouter(AdyenConfirmation);