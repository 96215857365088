import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../infrastructure/translation-manager';
import { GetHttpClientInstance } from '../infrastructure/context/http/http-context-provider';
import { Transaction } from '../infrastructure/http/modules/cart/cart.models';
import { RouterPaths, TranslationKeys } from '../infrastructure/const';
import { Link } from 'react-router-dom';
import RedirectButton from './common/redirect-button';
import CheckUtils from '../utilities/check-utils';
import UserContext from '../infrastructure/context/user/user.context';
import IUserContext from '../infrastructure/context/user/user.context.interface';

type OrderFailedPageProps = {
    transactionId: string;
};

type OrderFailedPageState = {
    transaction: Transaction | null;
};

export default class OrderFailedPage extends React.Component<RouteComponentProps<OrderFailedPageProps>, OrderFailedPageState> {
    private transactionId: string;

    constructor(props: RouteComponentProps<OrderFailedPageProps>, context: IUserContext) {
        super(props, context);
        this.transactionId = this.props.match.params.transactionId;

        this.state = {
            transaction: null
        };
    }

    componentDidMount() {
        GetHttpClientInstance().cart.getTransaction(this.transactionId, this.context.user.userProfile.userId, "").then((result: Transaction) => {
            this.setState({ transaction: result });
        });
    }

    render(): ReactNode {
        return (
            <OrderFailedPageInner
                transaction={this.state.transaction}
                transactionId={this.transactionId}
            />
        );
    }
}

type OrderFailedPageInnerProps = {
    transaction: Transaction | null;
    transactionId: string;
};

class OrderFailedPageInner extends React.Component<OrderFailedPageInnerProps> {
    private translations: { [id: string]: string };
    private TranslationManager: TranslationManager;

    constructor(props: OrderFailedPageInnerProps, context: ITranslationsContext) {
        super(props, context);
        this.TranslationManager = new TranslationManager(context);

        this.translations = {
            errorOccured: this.TranslationManager.getTranslation(TranslationKeys.ORDERFAILED_ERROROCCURRED),
            orderNumber: this.TranslationManager.getTranslation(TranslationKeys.ORDERFAILED_ORDERNUMBER),
            clickHere: this.TranslationManager.getTranslation(TranslationKeys.CLICKHERE),
            continueShopping: this.TranslationManager.getTranslation(TranslationKeys.TO_CONTINUE_SHOPPING),
            tryAgain: this.TranslationManager.getTranslation(TranslationKeys.ORDERFAILED_TRYAGAIN)
        };

        this.state = {
            transaction: null
        };
    }

    render(): ReactNode {
        return (
            <div className="col-main">
                <div className="checkout-failed">
                    <div className="wr-failed">
                        <div className="wrapper">
                            <h1>{this.translations.errorOccured}</h1>
                            <div className="order-confirmation-wrapper">
                                <p className="order-success">{this.translations.orderNumber}: {CheckUtils.isNullObject(this.props.transaction) || CheckUtils.isNullOrEmptyString(this.props.transaction!.orderNumber) ? this.props.transactionId : this.props.transaction!.orderNumber}</p>
                                <p>{CheckUtils.isNullObject(this.props.transaction) ? "" : this.props.transaction!.errorMessage}</p>
                                <p><Link to={RouterPaths.LandingPage}>{this.translations.clickHere} </Link>{this.translations.continueShopping}                                          </p>
                            </div>
                            <RedirectButton buttonLabel={this.translations.tryAgain} redirectUrl={RouterPaths.CheckoutPage} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

OrderFailedPageInner.contextType = TranslationsContext;
OrderFailedPage.contextType = UserContext;