import { CouponResponseCode } from "../../../const";

export type CartProduct = {
    id: string;
    name: string;
    description: string;
    colorName: string;
    imageUrl: string;
    itemNumber: string;
    quantity: number;
    price: string;
    total: string;
    partialId: number;
    urlKey: string;
    productAttributes?: { [key: string]: string };
    isOnPromotion: boolean;
    priceResponse: Price;
    productPromoResponse: PromoResponse;
    isAddedByPromotion: boolean;
};

export type Cart = {
    products: CartProduct[];
    total: number; // number of items
    totalPrice: string;
    displayPrice: string;
    vat: string;
    vatExempt: string;
    subTotalExclVat: string;
    subTotalInclVat: string;
    shippingPriceExclVat: string;
    shippingPriceInclVat: string;
    hash: string;
    customProperties: { [id: string]: string };
    discounts: { [id: string]: string };
    selectedShippingType: string;
    selectedPaymentType: string;
    couponResponseCode: keyof typeof CouponResponseCode;
    totalDiscount: string;
    priceBreakdown: PriceBreakdown;
};

export type AddProductRequest = {
    userId: string;
    products: { [id: string]: number };
};

export type RemoveProductRequest = {
    userId: string;
    itemNumber: string;
};

export type UpdateProductRequest = {
    userId: string;
    itemNumber: string;
    quantity: number;
};

export type UpdateCartRequest = {
    userId: string,
    invoiceAddress: Address,
    deliveryAddress: Address | null,
    shippingMethod: string,
    customProperties: { [id: string]: string },
    termsAndConditionsAgreed: boolean,
    privacyPolicyAgreed: boolean
};

export type UpdateCartPropertiesRequest = {
    userId: string,
    customProperties: { [id: string]: string },
    shippingMethod: string,
};

export class Address {
    id: string = '';
    firstName: string = '';
    middleName: string = '';
    lastName: string = '';
    email: string = '';
    company: string = '';
    addressLine1: string = '';
    addressLine2: string = '';
    postalCode: string = '';
    city: string = '';
    phoneNumber: string = '';
    countryCode: string = '';
}

export type Transaction = {
    paymentId: string;
    orderNumber: string;
    status: string;
    errorMessage: string;
    currencyCode: string;
    subTotalExclVat: string;
    subTotalInclVat: string;
    totalPrice: string;
    shippingPriceExclVat: string;
    shippingPriceInclVat: string;
    vat: string;
    vatExempt: string;
    products: CartProduct[];
    isTransactionOpen: boolean;
    orderPlaced: boolean;
};

export type TransactionResponse = Transaction & {
    correlationId: string;
};

export type InitializeKlarnaResponse = {
    dropInHtml: string;
    transactionId: string;
}

export type KlarnaTransactionConfirmResponse = {
    dropInHtml: string;
    orderNumber: string;
    paymentId: string;
};

export type InitializeNetsResponse = {
    transactionId: string;
    paymentId: string;
    correlationId: string;
    clientKey: string;
    language: string;
}

export type Price = {
    priceStr: string;
    price: number;
    currency: string;
};

export type PromoResponse = {
    price: number,
    priceStr: string,
    discount: number
}

export type PriceBreakdown = {
    currencySymbol: string,
    deductibleVat: number,
    displayPrice: number,
    shippingPrice: number,
    shippingPriceExclVat: number,
    shippingPriceVat: number,
    subTotalInclVat: number,
    subTotalVat: number,
    subtotalExclVat: number,
    totalDiscount: number,
    totalPrice: number,

}

export type InitializeTransactionResponse = {
    transactionId: string,
    correlationId: string,
    errorKeys: string[]
}