import React, { useContext } from 'react';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';
import { Product } from '../../infrastructure/http/modules/product/product.models';
import CheckUtils from '../../utilities/check-utils';

type Props = {
    product: Product,
    translations: InStockAndDeliveryInfoTranslations
}

export type InStockAndDeliveryInfoTranslations = {
    inStock: string,
    outOfStock: string,
    deliveredWithin: string,
    deliveredWithinDaysNumber: string,
    days: string
}

const InStockAndDeliveryInfo = (props: Props): JSX.Element | null => {
    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);

    const hideInStockInfo = configurationContext.isFeatureFlagEnabled("hideInStockInfo");
    const hideDeliveryInfo = configurationContext.isFeatureFlagEnabled("hideDeliveryDateInfo");

    function getDeliveryDate(): string {
        const defaultDeliveryDate = "5";
        if (CheckUtils.isNullOrEmptyString(props.translations.deliveredWithinDaysNumber)) {
            return defaultDeliveryDate;
        }
        return props.translations.deliveredWithinDaysNumber;
    }

    if (hideInStockInfo && hideDeliveryInfo) {
        return null;
    }

    if (configurationContext.isFeatureFlagEnabled("useEnrichedProductData")) {
        const isInStock = props.product.inStock;

        // If there is no information yet - do not render
        if (isInStock === undefined) {
            return null;
        }

        return (
            <p className="info-block">
                {!hideInStockInfo &&
                    isInStock ?
                    (
                        <span className="inStock">{props.translations.inStock} </span>
                    ) : (
                        <span className="outOfStock">{props.translations.outOfStock} </span>
                    )
                }

                {!hideDeliveryInfo && isInStock && !CheckUtils.isNullOrEmptyString(props.translations.deliveredWithin) &&
                    <span className="delivery-status">
                        {props.translations.deliveredWithin} {getDeliveryDate()}{props.translations.days}
                    </span>
                }
            </p>
        )
    }

    return (
        <p className="info-block">
            {!hideInStockInfo &&
                <span className="inStock">{props.translations.inStock} </span>
            }

            {!hideDeliveryInfo && !CheckUtils.isNullOrEmptyString(props.translations.deliveredWithin) &&
                <span className="delivery-status">
                    {props.translations.deliveredWithin} {getDeliveryDate()}{props.translations.days}
                </span>
            }
        </p>
    )
};

export default InStockAndDeliveryInfo;