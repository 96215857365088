import React from 'react';
import { OrderHistoryListItem } from './order-history-list';
import { TranslationKeys } from '../../../infrastructure/const';
import HyperlinkButton from '../../common/hyperlink-button';
import ITranslationsContext from '../../../infrastructure/context/translations/translations-context.interface';
import TranslationManager from '../../../infrastructure/translation-manager';
import OrderUtils from '../../../utilities/order-utils';

type Props = {
    translationsContext: ITranslationsContext;
    order: OrderHistoryListItem;
    onViewOrderClick(orderNumber: string): void;
    onReorderClick(orderNumber: string): void;
};

type State = {
    isLoaded: boolean;
};

class OrderHistoryListRow extends React.Component<Props, State> {
    constructor(props: Props, private translations: {[key: string]: string}) {
        super(props);

        this.state = {
            isLoaded: false,
        };
    }

    public componentDidMount(): void {
        const translationManager = new TranslationManager(this.props.translationsContext);
        this.translations = {
            viewOrder: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_VIEWORDER),
            reorder: translationManager.getTranslation(TranslationKeys.ACCOUNT_ORDERHISTORY_REORDER)
        };

        this.setState({ isLoaded: true });
    }

    public render() {
        if (!this.state.isLoaded) {
            return null;
        }

        return (
            <tr className='first last odd'>
                <td>{this.props.order.orderNumber}</td>
                <td>
                    <span className='nobr'>{this.props.order.orderDate.slice(0, 10)}</span>
                </td>
                <td className='hidden-xs'>{this.props.order.shipTo}</td>
                {/* <td className='hidden-xs'>
                    <span className='price'>{this.props.order.orderTotal}</span>
                </td> */}
                <td>
                    <em>{OrderUtils.getOrderStatusTranslation(this.props.translationsContext, this.props.order.status)}</em>
                </td>
                <td className='a-center last'>
                    <span className='nobr'>
                        <HyperlinkButton onClick={() => this.props.onViewOrderClick(this.props.order.orderNumber)}>
                            {this.translations.viewOrder}
                        </HyperlinkButton>
                        {/* <HyperlinkButton onClick={() => this.props.onReorderClick(this.props.order.orderNumber)}>
                            {this.translations.reorder}
                        </HyperlinkButton> */}
                    </span>
                </td>
            </tr>
        );
    }
}

export default OrderHistoryListRow;