import * as React from 'react';
import { useHistory } from "react-router-dom";

type RedirectButtonProps = {
    redirectUrl: string,
    buttonLabel: string,
    closeMenu?: () => void,
    additionalClass?: string
};

const RedirectButton = (props: RedirectButtonProps) => {
  let history = useHistory();

  function handleClick() {
    history.push(props.redirectUrl);

    if (props.closeMenu) {
      props.closeMenu();
    }
  }

  return (
    <button className={"button " + (props.additionalClass ? props.additionalClass : "")} onClick={handleClick}>
      {props.buttonLabel}
    </button>
  );
};

export default RedirectButton;