import { IHttp } from '../../http.interface';
import { PageContainer, ContactForm } from './customer-service.models';
import { ModuleBase } from '../../common/module.base';
import { ICustomerServiceModule } from './customer-service.interface';
import { AxiosError } from 'axios';

export class CustomerServiceModule extends ModuleBase implements ICustomerServiceModule {

    constructor(private http: IHttp) {
        super('CustomerService');
    }

    get(): Promise<PageContainer> {
        return new Promise<PageContainer>((resolve, reject) => {
            this.http.get<PageContainer>(`/api/customer-service`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    postContactUs(request: ContactForm): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.http.post<void>(`/api/customer-service/contact-us`, request)
            .then(() => resolve())
            .catch((error: AxiosError) => reject(error.response));
        });
    }
}