import { Context, ItemAvailability, ItemCondition, SchemaDataTypes } from './consts';

export class ProductSchema {
    private "@context": string;
    private "@type": string;

    name: string;
    description: string;
    image: string;
    sku: string; // The Stock Keeping Unit (SKU), i.e. a merchant-specific identifier for a product or service, or the product to which the offer refers
    mpn: string; //The Manufacturer Part Number (MPN) of the product, or the product to which the offer refers,
    brand?: ProductBrand;
    offers: ProductOffer;

    constructor() {
        this['@context'] = Context;
        this['@type'] = SchemaDataTypes.Product;
    }
}

export class ProductBrand {
    private "@type": string;

    private name: string;

    constructor(name: string) {
        this['@type'] = SchemaDataTypes.Brand;
        this.name = name;
    }
}

export class ProductOffer {
    private "@type": string;
    private availability: string;
    private itemCondition: string;

    price: number;
    priceCurrency: string; // three-letter ISO 4217 format.
    url: string; // url to the product web page (that includes the Offer),
    shippingDetails: OfferShippingDetails[];

    constructor() {
        this['@type'] = SchemaDataTypes.Offer;
        this.availability = ItemAvailability.InStock;
        this.itemCondition = ItemCondition.New;
    }
}

// Enables people to see shipping costs and estimated delivery timeframes
export class OfferShippingDetails {
    private "@type": string;

    shippingRate: ShippingRate;
    shippingDestination: ShippingDestination;
    deliveryTime: DeliveryTime;

    constructor() {
        this['@type'] = SchemaDataTypes.OfferShippingDetails;
    }
}

export class ShippingRate {
    private "@type": string;
    value: number;
    currency: string;

    constructor(){
        this['@type'] = SchemaDataTypes.MonetaryAmount;
    }
}

export class ShippingDestination {
    private "@type": string;
    private addressCountry: string[] | string;

    constructor(addressCountry: string[] | string){
        this['@type'] = SchemaDataTypes.DefinedRegion;
        this.addressCountry = addressCountry;
    }
}
export class DeliveryTime {
    private "@type": string;
    private businessDays: BusinessDays;

    constructor(dayOfWeek: string[]){
        this['@type'] = SchemaDataTypes.ShippingDeliveryTime;
        this.businessDays = new BusinessDays(dayOfWeek);
    }
}

class BusinessDays {
    private "@type": string;
    private dayOfWeek: string[];

    constructor(dayOfWeek: string[]){
        this['@type'] = "";
        this.dayOfWeek = dayOfWeek;
    }
}