import * as React from 'react';
import HttpClient from '../../http/http.client';
import CheckUtils from '../../../utilities/check-utils';
import { HttpClientConfiguration } from '../../http/http.client.configuration';
import { IHttp } from '../../http/http.interface';
import { IProductModule } from '../../http/modules/product/product.interface';
import { ITranslationsModule } from '../../http/modules/translations/translations.interface';
import { ICartModule } from '../../http/modules/cart/cart.interface';
import { IOrdersModule } from '../../http/modules/orders/orders.interface';
import { ICustomerServiceModule } from '../../http/modules/customer-service/customer-service.interface';
import B2CHttp from '../../http/http';
import { ProductModule } from '../../http/modules/product/product.client';
import { TranslationsModule } from '../../http/modules/translations/translations.client';
import { CartModule } from '../../http/modules/cart/cart.client';
import { OrdersModule } from '../../http/modules/orders/orders.client';
import { CustomerServiceModule } from '../../http/modules/customer-service/customer-service.client';
import { IAuthModule } from '../../http/modules/auth/auth.interface';
import { AuthModule } from '../../http/modules/auth/auth.client';
import { IAccountModule } from '../../http/modules/account/account.interface';
import { AccountModule } from '../../http/modules/account/account.client';
import Spinner from '../../../components/common/spinner';
import { RouterPaths } from '../../const';
import ServerErrorMessage from '../../../components/common/server-error-message';

let client: HttpClient;
interface ProviderState {
    http: IHttp | null;
    product: IProductModule | null;
    translations: ITranslationsModule | null;
    cart: ICartModule | null;
    orders: IOrdersModule | null;
    customerService: ICustomerServiceModule | null;
    auth: IAuthModule | null;
    account: IAccountModule | null;
    hasError: boolean;
}

interface ProviderProps {
    mainContainerRef: React.RefObject<HTMLDivElement>;
}

export let GetHttpClientInstance = function () {
    return client;
};

class HttpProvider extends React.Component<ProviderProps, ProviderState> {

    constructor(props: any) {
        super(props);

        this.state = {
            translations: null,
            http: null,
            product: null,
            cart: null,
            orders: null,
            customerService: null,
            auth: null,
            account: null,
            hasError: false
        };

        this.handleError = this.handleError.bind(this);
    }

    componentDidMount(): void {
        const configuration = new HttpClientConfiguration();
        const b2chttp = new B2CHttp(configuration, this.handleError);
        client = new HttpClient(configuration, this.handleError);

        this.setState({
            http: b2chttp,
            product: new ProductModule(b2chttp),
            translations: new TranslationsModule(b2chttp),
            cart: new CartModule(b2chttp),
            orders: new OrdersModule(b2chttp),
            customerService: new CustomerServiceModule(b2chttp),
            auth: new AuthModule(b2chttp),
            account: new AccountModule(b2chttp),
        });
    }

    handleError() {
        if (CheckUtils.isNullObject(this.props.mainContainerRef.current)) {
            this.setState({ hasError: true });
        } else {
            // Reload and redirect to error page
            window.location.href = RouterPaths.Error;
        }
    }

    render() {
        if (this.state.hasError) {
            return <ServerErrorMessage />;
        }

        if (this.state.translations === null) {
            return <Spinner show={true} default />;
        }

        return (
            this.props.children
        );
    }
}

export default HttpProvider;