import React, { ReactNode, useContext } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { RouterStateParams, RouterPaths, TranslationKeys, FeatureFlagValues } from '../infrastructure/const';
import AccountMenu from './account/menu/account-menu';
import CheckUtils from '../utilities/check-utils';
import ContactInformation from './account/dashboard/contact-information';
import IUserContext from '../infrastructure/context/user/user.context.interface';
import MarketingMaterials from './account/dashboard/marketing-materials';
import TranslationManager from '../infrastructure/translation-manager';
import TranslationsContext from '../infrastructure/context/translations/translations-context';
import UserContext from '../infrastructure/context/user/user.context';
import DefaultAddress from './account/dashboard/default-address';
import MessageBanner from './common/message-banner';
import ITranslationsContext from '../infrastructure/context/translations/translations-context.interface';
import CustomerPreferences from './account/dashboard/customer-preferences';
import IConfigurationContext from '../infrastructure/context/configuration/configuration.context.interface';
import ConfigurationContext from '../infrastructure/context/configuration/configuration.context';

type State = {
    successMessageKey: string;
    redirectToEditContactInformation: boolean;
    redirectToEditMarketingMaterials: boolean;
    redirectToCustomerPreferences: boolean;
};

class AccountDashboardPage extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps, context: IUserContext) {
        super(props, context);

        this.state = {
            successMessageKey: '',
            redirectToEditContactInformation: false,
            redirectToEditMarketingMaterials: false,
            redirectToCustomerPreferences: false
        };

        this.handleEditContactInformationClick = this.handleEditContactInformationClick.bind(this);
        this.handleEditMarketingMaterialsClick = this.handleEditMarketingMaterialsClick.bind(this);
        this.handleEditCustomerPreferencesClick = this.handleEditCustomerPreferencesClick.bind(this);
    }

    public componentDidMount(): void {
        const params = [
            { key: RouterStateParams.ShowEditedContactInformationSuccessMessage, translationKey: TranslationKeys.ACCOUNT_DASHBOARD_EDITCONTACTINFORMATIONSUCCESSMESSAGE },
            { key: RouterStateParams.ShowEditedMarketingMaterialsSuccessMessage, translationKey: TranslationKeys.ACCOUNT_DASHBOARD_EDITMARKETINGMATERIALSUCCESSMESSAGE },
            { key: RouterStateParams.ShowWelcomeMessage, translationKey: TranslationKeys.ACCOUNT_DASHBOARD_WELCOMEMESSAGE },
        ];

        for (const p of params) {
            this.setSucessMessageKey(p.key, p.translationKey);
        }
    }

    public render(): ReactNode {
        if (!this.context.user.userProfile.isAuthenticated) {
            return <Redirect push to={RouterPaths.Login} />;
        }

        return (
            <div className='col2-left-layout oticon-address'>
                <div className='wrapper'>
                    <AccountMenu />
                    {this.state.redirectToEditContactInformation && <Redirect push to={RouterPaths.AccountEditContactInformation} />}
                    {this.state.redirectToEditMarketingMaterials && <Redirect push to={RouterPaths.AccountEditMarketingMaterials} />}
                    {this.state.redirectToCustomerPreferences && <Redirect push to={RouterPaths.AccountEditCustomerPreferences} />}
                    <ConfigurationContext.Consumer>
                        {(configurationContext: IConfigurationContext) =>
                            <div className='col-main'>
                                <div className='my-account'>
                                    <AccountDashboardPageInner
                                        successMessageKey={this.state.successMessageKey}
                                        userContext={this.context}
                                        handleEditContactInformationClick={this.handleEditContactInformationClick}
                                        handleEditMarketingMaterialsClick={this.handleEditMarketingMaterialsClick}
                                        handleEditCustomerPreferencesClick={this.handleEditCustomerPreferencesClick}
                                        configurationContext={configurationContext}
                                    />
                                </div>
                            </div>
                        }
                    </ConfigurationContext.Consumer>
                </div>
            </div>
        );
    }

    private setSucessMessageKey(routerStateParam: string, translationKey: string) {
        if (CheckUtils.isNullObject(this.props.location.state)) {
            return;
        }

        let state = this.props.location.state as { [key: string]: string | boolean };

        if (state[routerStateParam]) {
            this.setState({ successMessageKey: translationKey });
            this.props.history.replace({ state: undefined });
        }
    }

    private handleEditContactInformationClick() {
        this.setState({ redirectToEditContactInformation: true });
    }

    private handleEditMarketingMaterialsClick() {
        this.setState({ redirectToEditMarketingMaterials: true });
    }

    private handleEditCustomerPreferencesClick() {
        this.setState({ redirectToCustomerPreferences: true });
    }
}

const AccountDashboardPageInner = (props: { successMessageKey: string, userContext: IUserContext, configurationContext: IConfigurationContext, handleEditContactInformationClick: (() => void), handleEditMarketingMaterialsClick: (() => void), handleEditCustomerPreferencesClick: (() => void) }): JSX.Element => {
    const translationsContext = useContext<ITranslationsContext>(TranslationsContext);
    const translationManager = new TranslationManager(translationsContext);

    const configurationContext = useContext<IConfigurationContext>(ConfigurationContext);

    const translations = {
        header: translationManager.getTranslation(TranslationKeys.ACCOUNT_DASHBOARD_ACCOUNTINFORMATION),
        addressBook: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_HEADER),
    };

    function renderMessagesSection(): ReactNode {
        if (CheckUtils.isNullOrEmptyString(props.successMessageKey)) {
            return null;
        }

        return (
            <MessageBanner message={translationManager.getTranslation(props.successMessageKey)} />
        );
    }

    return (
        <div className='dashboard account-wr'>
            <h2>{translations.header}</h2>
            {renderMessagesSection()}
            <div className='box-account'>
                <ContactInformation
                    userProfile={props.userContext.user.userProfile}
                    onEditClick={props.handleEditContactInformationClick}
                />
                <MarketingMaterials
                    receiveMarketingMaterials={props.userContext.isSubscribed('Newsletter')}
                    onEditClick={props.handleEditMarketingMaterialsClick}
                />
            </div>
            <h2>{translations.addressBook}</h2>
            <div className='box-account'>
                <DefaultAddress type='billing' userContext={props.userContext} configurationContext={props.configurationContext} />
                <DefaultAddress type='shipping' userContext={props.userContext} configurationContext={props.configurationContext} />
            </div>
            {configurationContext.isFeatureFlagEnabled("showCustomerPreferences") &&
                <div>
                    <CustomerPreferences
                        userProfile={props.userContext.user.userProfile}
                        onEditClick={props.handleEditCustomerPreferencesClick}
                    />
                </div>
            }
        </div>
    );
};

AccountDashboardPage.contextType = UserContext;

export default AccountDashboardPage;
