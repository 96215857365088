import React from 'react';
import { IAddress } from '../../../infrastructure/context/user/user.context.interface';
import { TranslationKeys } from '../../../infrastructure/const';
import AddressBookCheckbox from './address-book-checkbox';
import HyperlinkButton from '../../common/hyperlink-button';
import TranslationManager from '../../../infrastructure/translation-manager';
import TranslationsContext from '../../../infrastructure/context/translations/translations-context';
import ConfigurationContext from '../../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../../infrastructure/context/configuration/configuration.context.interface';

type Props = {
    address: IAddress;
    onDefaultAddressCheckboxClick(addressId: string, isShipping: boolean): void;
    onEditAddressClick(addressId: string): void;
    onDeleteAddressClick(addressId: string): void;
};

class AddressRow extends React.Component<Props> {
    constructor(props: Props, private translations: { [key: string]: string }) {
        super(props);
    }

    public render(): React.ReactNode {
        const translationManager = new TranslationManager(this.context);

        this.translations = {
            edit: translationManager.getTranslation(TranslationKeys.ACCOUNT_EDIT),
            delete: translationManager.getTranslation(TranslationKeys.ACCOUNT_DELETE),
            deleteConfirmation: translationManager.getTranslation(TranslationKeys.ACCOUNT_ADDRESSBOOK_DELETECONFIRMATION),
        };

        return (
            <tr>
                <td>
                    <p>{this.props.address.firstName}</p>
                    <ConfigurationContext.Consumer>
                        {(configurationContext: IConfigurationContext) =>
                            !configurationContext.isFeatureFlagEnabled("hideCustomerMiddleName") &&
                            <p>{this.props.address.middleName}</p>
                        }
                    </ConfigurationContext.Consumer>
                    <p>{this.props.address.lastName}</p>
                    <p>{this.props.address.company}</p>
                    <p>{this.props.address.telephone}</p>
                    <p>{this.props.address.fax}</p>
                </td>
                <td>
                    <p>{this.props.address.streetAddress1}</p>
                    <p>{this.props.address.streetAddress2}</p>
                    <p>{this.props.address.city}</p>
                    <p>{this.props.address.postalCode}</p>
                    <p>{this.props.address.country}</p>
                </td>
                <td>
                    <AddressBookCheckbox
                        checked={this.props.address.isDefaultBillingAddress}
                        onDefaultAddressCheckboxClick={this.props.onDefaultAddressCheckboxClick}
                        addressId={this.props.address.id}
                        isShippingAddress={false}
                    />
                </td>
                <td>
                    <AddressBookCheckbox
                        checked={this.props.address.isDefaultShippingAddress}
                        onDefaultAddressCheckboxClick={this.props.onDefaultAddressCheckboxClick}
                        addressId={this.props.address.id}
                        isShippingAddress={true}
                    />
                </td>
                <td>
                    <p>
                        <u>
                            <HyperlinkButton
                                onClick={() => this.props.onEditAddressClick(this.props.address.id)}
                                title={this.translations.edit}
                            >{this.translations.edit}</HyperlinkButton>
                        </u>
                    </p>
                    {!this.props.address.isDefaultBillingAddress && !this.props.address.isDefaultShippingAddress &&
                        <u>
                            <HyperlinkButton
                                onClick={() => this.handleOnDeleteAddressClick(this.props.address.id)}
                                title={this.translations.delete}
                            >{this.translations.delete}</HyperlinkButton>
                        </u>
                    }
                </td>
            </tr>
        );
    }

    private handleOnDeleteAddressClick(addressId: string) {
        // eslint-disable-next-line no-restricted-globals
        const del = confirm(this.translations.deleteConfirmation);

        if (del) {
            this.props.onDeleteAddressClick(addressId);
        }
    }
}

AddressRow.contextType = TranslationsContext;

export default AddressRow;
