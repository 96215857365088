import { IHttp } from '../../http.interface';
import { ModuleBase } from '../../common/module.base';
import { IPaymentsModule } from './payments.interface';
import { GetPaymentsMethodsResult, InitializePaymentRequest, PaymentResponse, UpdateDetailsRequest } from './payments.models';

export class PaymentsModule extends ModuleBase implements IPaymentsModule {

    constructor(private http: IHttp) {
        super('Payments');
    }

    getPaymentMethods(): Promise<GetPaymentsMethodsResult> {
        return new Promise<GetPaymentsMethodsResult>((resolve, reject) => {
            this.http.get<GetPaymentsMethodsResult>(`/api/payments/paymentMethods`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    initializePayment(request: InitializePaymentRequest): Promise<PaymentResponse> {
        return new Promise<PaymentResponse>((resolve, reject) => {
            this.http.post<PaymentResponse>(`/api/payments/initializePayment`, request)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                }).catch(() => reject());
        });
    }

    updateDetails(request: UpdateDetailsRequest): Promise<PaymentResponse> {
        return new Promise<PaymentResponse>((resolve, reject) => {
            this.http.post<PaymentResponse>(`/api/payments/paymentDetails`, request)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    getStatus(transactionId: string, correlationId: string): Promise<PaymentResponse> {
        return new Promise<PaymentResponse>((resolve, reject) => {
            this.http.get<PaymentResponse>(`/api/payments/status/${transactionId}?correlationId=${correlationId}`)
                .then((result: any) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        reject();
                    }
                });
        });
    }
}